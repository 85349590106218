import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectComponent } from './redirect/redirect.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AuthGuard } from './guards/auth.guard';
import {
    UserResolver,
    EmployeesResolver,
    EmployeeResolver,
    CorporateInvoiceResolver,
    CorporateInvoiceVouchersResolver,
    EmployeeDocumentResolver,
    EmployeeDocumentsResolver,
    ParksLocationsResolver,
    NonAuthenticationDeviceResolver,
    TaxesResolver,
    PartnerPayResolver,
} from './resolvers/resolvers.module';
import { AccountComponent } from './account/account.component';
import { ResetpasswordGuard } from './guards/resetpassword.guard';
import { PaymentsComponent } from './payments/payments.component';
import { CorporateinvoiceGuard } from './guards/corporateinvoice.guard';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentComponent } from './documents/document/document.component';
import { DocumentGuard } from './guards/document.guard';
import { ParkscanadaComponent } from './parkscanada/parkscanada.component';
import { ContactComponent } from './contact/contact.component';
import { HelpComponent } from './help/help.component';
import { PunchClockComponent } from './punch-clock/punch-clock.component';
import { NonLoginAuthenticationGuard } from './guards/nonloginauthentication.guard';
import { SelfServeComponent } from './self-serve/self-serve.component';
import { RemovedarkthemeGuard } from './magic/guards/removedarktheme.guard';
import { ScreenComponent } from './screen/screen.component';
import { RipleysEmployeeScreeningComponent } from './ripleys-employee-screening/ripleys-employee-screening.component';
import { PartnerPayComponent } from './partner-pay/partner-pay.component';
import { ParksKioskComponent } from './parks-kiosk/parks-kiosk.component';
import { RNFEmployeeScreeningComponent } from './rnf-employee-screening/rnf-employee-screening.component';
import { StaffPassComponent } from './account/staff-pass/staff-pass.component';

// When using lazy loading (sub-modules / loadChildren), you HAVE to use single quotes.
const routes: Routes = [
    {
        path: '',
        component: RedirectComponent,
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
        canLoad: [AuthGuard],
        data: { location: 'admin' },
    },
    {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
        canLoad: [AuthGuard],
        data: { location: 'crm' },
    },
    {
        path: 'hr',
        loadChildren: () => import('./hr/hr.module').then((m) => m.HrModule),
        canLoad: [AuthGuard],
        data: { location: 'hr' },
    },
    {
        path: 'partners',
        loadChildren: () =>
            import('./partners/partners.module').then((m) => m.PartnersModule),
        canLoad: [AuthGuard],
        data: { location: 'partners' },
    },
    {
        path: 'pos',
        loadChildren: () => import('./pos/pos.module').then((m) => m.PosModule),
        canLoad: [AuthGuard],
        data: { location: 'pos' },
    },
    {
        path: 'magic',
        loadChildren: () =>
            import('./magic/magic.module').then((m) => m.MagicModule),
    },
    {
        path: 'store-groups',
        loadChildren: () =>
            import('./store-groups/store-groups.module').then(
                (m) => m.StoreGroupsModule
            ),
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard, ResetpasswordGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: { user: UserResolver },
    },
    // {
    //     path: 'account/staff-pass',
    //     component: StaffPassComponent,
    //     canActivate: [AuthGuard, ResetpasswordGuard],
    //     data: {
    //         requiresLogin: true,
    //         requiresAllowedCheck: false,
    //         sentAllowedLocation: '',
    //         failRedirectAuth: 'auth/login',
    //         resetPasswordBypass: true,
    //     },
    //     resolve: { user: UserResolver },
    // },
    {
        path: 'payments/:id/:hash',
        component: PaymentsComponent,
        canActivate: [CorporateinvoiceGuard],
        resolve: {
            order: CorporateInvoiceResolver,
            vouchers: CorporateInvoiceVouchersResolver,
        },
    },
    {
        path: 'partner-pay/:id',
        component: PartnerPayComponent,
        resolve: {
            payment: PartnerPayResolver,
        },
    },
    {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard, ResetpasswordGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: { user: UserResolver, documents: EmployeeDocumentsResolver },
    },
    {
        path: 'documents/:id',
        component: DocumentComponent,
        canActivate: [AuthGuard, ResetpasswordGuard, DocumentGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: {
            user: UserResolver,
            employeeDocument: EmployeeDocumentResolver,
        },
    },
    {
        path: 'parks-canada',
        component: ParkscanadaComponent,
        canActivate: [AuthGuard, ResetpasswordGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: { user: UserResolver, locations: ParksLocationsResolver },
    },
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard, ResetpasswordGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: { user: UserResolver },
    },
    {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard, ResetpasswordGuard],
        data: {
            requiresLogin: true,
            requiresAllowedCheck: false,
            sentAllowedLocation: '',
            failRedirectAuth: 'auth/login',
            resetPasswordBypass: true,
        },
        resolve: { user: UserResolver },
    },
    {
        path: 'punch-clock',
        component: PunchClockComponent,
        canActivate: [NonLoginAuthenticationGuard],
        data: { type: 'punch' },
    },
    {
        path: 'self-serve',
        component: SelfServeComponent,
        canActivate: [NonLoginAuthenticationGuard, RemovedarkthemeGuard],
        data: { type: 'kiosk' },
        resolve: {
            device: NonAuthenticationDeviceResolver,
            taxes: TaxesResolver,
        },
    },
    {
        path: 'parks-kiosk',
        component: ParksKioskComponent,
        canActivate: [NonLoginAuthenticationGuard, RemovedarkthemeGuard],
        data: { type: 'parks_kiosk' },
        resolve: {
            device: NonAuthenticationDeviceResolver,
        },
    },
    {
        path: 'screen',
        component: ScreenComponent,
        canActivate: [RemovedarkthemeGuard],
    },
    {
        path: 'ripleys-employee-screening',
        component: RipleysEmployeeScreeningComponent,
        canActivate: [RemovedarkthemeGuard],
    },
    {
        path: 'rnf-employee-screening',
        component: RNFEmployeeScreeningComponent,
        canActivate: [RemovedarkthemeGuard],
    },
    {
        path: '**',
        component: PagenotfoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [
        UserResolver,
        EmployeesResolver,
        EmployeeResolver,
        CorporateInvoiceResolver,
        CorporateInvoiceVouchersResolver,
        EmployeeDocumentResolver,
        EmployeeDocumentsResolver,
        ParksLocationsResolver,
        NonAuthenticationDeviceResolver,
        TaxesResolver,
        PartnerPayResolver,
    ],
})
export class AppRoutingModule {}
