import { Injectable } from '@angular/core';

/**
 * This service contains helpful formatting functions. This
 * could range from phone number formatting to regex functions.
 */
@Injectable({
    providedIn: 'root'
})
export class FormattingService {
    constructor() {}

    /**
     * This function turns a formatted phone number into numbers.
     * For example, (111) 111-1111 would turn into 1111111111.
     *
     *
     * @param formatted The formatted phone number.
     */
    numberifyPhone(formatted: string): string {
        return formatted.replace(/[\(\)\s\-]+/g, '').slice(0, 10);
    }

    /**
     * This function takes a string and truncates the middle of it.
     * Example `this is a long string` would turn into `this...string` if given
     * a length of 13.
     *
     *
     * @param original The string to truncate.
     * @param length The length to truncate to (including the separator).
     * @param separator The string used to separate the beginning from the end. Defaults to `...`.
     */
    truncateMiddle(
        original: string,
        length: number,
        removeTimestamp: boolean = false,
        separator: string = '...'
    ) {
        // Check if a timestamp needs to be removed.
        if (removeTimestamp) {
            original = original.replace(/^[0-9]+?-/, '');
        }

        // Return original if it does not need truncated.
        if (original.length <= length) return original;

        // Get the front and back lengths.
        let front = Math.floor(length / 2 - Math.ceil(separator.length / 2));
        let back = Math.ceil(length / 2 - Math.floor(separator.length / 2));

        return original.replace(
            new RegExp(`^(.{${front}}).*?(.{${back}})$`),
            `$1${separator}$2`
        );
    }
}
