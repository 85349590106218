import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

export interface TranslateOptions {
    id: string;
    params?: { [key: string]: string };
}

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    // Language variables.
    currentLang: string = 'en';
    loadedLanguages: Object = {};
    initialised: boolean = false;

    constructor(private _http: HttpService) {
        this.init();
    }

    get isReady() {
        return (
            this.initialised &&
            this.loadedLanguages.hasOwnProperty(this.currentLang) &&
            this.loadedLanguages.hasOwnProperty('en')
        );
    }

    async setLang(lang: string) {
        // Set/load the language.
        if (!this.loadedLanguages.hasOwnProperty(lang)) {
            await this.loadLanguage(lang);
        }
        this.currentLang = lang;

        // Dispatch language change event.
        window.dispatchEvent(
            new CustomEvent('languageChange', { detail: { language: lang } })
        );
    }

    async translate(id: string | TranslateOptions): Promise<string> {
        // Wait for initialised.
        if (!this.initialised) await this.waitForInit();

        // Get/load the language.
        let language = this.currentLang;
        if (!language) return '';

        // Make sure the current language is loaded.
        if (!this.loadedLanguages.hasOwnProperty(language)) {
            await this.loadLanguage(language);
        }

        // Make sure English is loaded.
        if (!this.loadedLanguages.hasOwnProperty('en')) {
            await this.loadLanguage('en');
        }

        // Get the ID based on the type of the id parameter.
        let translateID: string = id as string;
        if (id instanceof Object) {
            translateID = id.id;
        }

        // Check if the ID exists. If not, check for English existence. If English exists, use that, else, error.
        if (
            !this.loadedLanguages[language].hasOwnProperty(translateID) &&
            !this.loadedLanguages['en'].hasOwnProperty(translateID)
        ) {
            throw new Error(`ID: ${translateID} not found.`);
        } else if (
            !this.loadedLanguages[language].hasOwnProperty(translateID)
        ) {
            language = 'en';
        }

        return this._translate(language, translateID, id);
    }

    translateSync(id: string | TranslateOptions): string {
        // Wait for initialised.
        if (!this.initialised) {
            throw new Error(
                'translateSync has to be called after initialised.'
            );
        }

        // Get/load the language.
        let language = this.currentLang;
        if (!language) return '';

        // Make sure the current language is loaded.
        if (!this.loadedLanguages.hasOwnProperty(language)) {
            throw new Error(
                'translateSync needs the current language to be loaded prior to being called.'
            );
        }

        // Make sure English is loaded.
        if (!this.loadedLanguages.hasOwnProperty('en')) {
            throw new Error(
                'translateSync needs the English language to be loaded prior to being called.'
            );
        }

        // Get the ID based on the type of the id parameter.
        let translateID: string = id as string;
        if (id instanceof Object) {
            translateID = id.id;
        }

        // Check if the ID exists. If not, check for English existence. If English exists, use that, else, error.
        if (
            !this.loadedLanguages[language].hasOwnProperty(translateID) &&
            !this.loadedLanguages['en'].hasOwnProperty(translateID)
        ) {
            throw new Error(`ID: ${translateID} not found.`);
        } else if (
            !this.loadedLanguages[language].hasOwnProperty(translateID)
        ) {
            language = 'en';
        }

        return this._translate(language, translateID, id);
    }

    getCurrentLang() {
        return this.currentLang;
    }

    private _translate(
        language: string,
        translateID: string,
        id: string | TranslateOptions
    ) {
        // Check if we have any parameters.
        let translation: string = this.loadedLanguages[language][translateID];
        if (id instanceof Object && id.params) {
            // Create the regex to match.
            const regex = /{{([^{][ ]?.+?[ ]?)}}/g;
            let match: RegExpMatchArray;

            // Loop through and get all matching handlebar matches.
            while (
                (match = regex.exec(
                    this.loadedLanguages[language][translateID]
                ))
            ) {
                // Trim and see if the property exists.
                const col = match[1].trim();
                if (!id.params.hasOwnProperty(col)) continue;

                // If the property exists in the translation options, replace it with that.
                translation = translation.replace(match[0], id.params[col]);
            }
        }

        return translation;
    }

    waitForLang() {
        return new Promise((resolve, reject) => {
            const wait = () => {
                if (this.currentLang) return resolve(null);
                setTimeout(wait, 50);
            };
            wait();
        });
    }

    private waitForInit() {
        return new Promise((resolve, reject) => {
            const wait = () => {
                if (this.initialised) return resolve(null);
                setTimeout(wait, 50);
            };
            wait();
        });
    }

    private async init() {
        await this.loadLanguage('en');
        this.initialised = true;
    }

    private async loadLanguage(lang: string) {
        this.loadedLanguages[lang] = (
            await this._http.getLocal(
                `translations/?website=fluiddynamics.ca&lang=${lang}`
            )
        ).body;
    }
}
