<h1 mat-dialog-title>
    {{
        data.type === 'Adding'
            ? data.type + ' Member'
            : data.type + ' ' + data.replacing['pass_id']
    }}
</h1>
<div mat-dialog-content class="scrollbar">
    <div *ngIf="data.type === 'Replacing'">
        <mat-list>
            <mat-list-item
                class="pointer"
                (click)="choosePass(pass)"
                *ngFor="let pass of data.passes"
                [ngClass]="{
                    'background-radial':
                        selectedPass['pass_id'] === pass['pass_id']
                }"
            >
                {{ pass['pass_id'] }}
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="data.type === 'Adding'">
        <div class="inputs">
            <mat-form-field>
                <mat-select placeholder="Pass" [(ngModel)]="selectedPass">
                    <mat-option *ngFor="let pass of data.passes" [value]="pass">
                        {{ pass['pass_id'] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="selectedPass['first_name']"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="selectedPass['last_name']"
                    required
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-radio-group
                class="center flex col aic"
                [(ngModel)]="selectedPass['duration']"
                color="primary"
            >
                <mat-radio-button value="3">
                    3 Day Pass
                </mat-radio-button>
                <mat-radio-button value="5">
                    5 Day Pass
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="dialogRef.close(selectedPass)"
        color="primary"
    >
        Save
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
