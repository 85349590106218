<loading [opacity]="0.5" *ngIf="loading"></loading>

<h1 mat-dialog-title>Choose Employee</h1>
<div mat-dialog-content class="w65vh">
    <div class="flex">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Employee ID"
                [(ngModel)]="searchValue"
                (keyup.enter)="search()"
                pattern="^[0-9]+$"
            />
        </mat-form-field>
        <button
            mat-raised-button
            class="center-btn mb15 w25"
            color="primary"
            (click)="search()"
        >
            Search
        </button>
    </div>

    <div class="scrollbar overflow" *ngIf="employee">
        <div
            class="pass scrollbar padding-15"
            [ngClass]="{
                'background-radial-red':
                    employee['suspended'] > 0 ||
                    employee['status'] === 'Inactive' ||
                    !employee['photo']
            }"
        >
            <img
                class="center-btn"
                [src]="
                    employee['photo'] ||
                    this._sanitizer.bypassSecurityTrustResourceUrl(
                        _misc.defaultPhoto
                    )
                "
            />
            <div class="pass-description flex col center jcc center-btn">
                <p>
                    {{ employee['first_name'] }}
                    {{ employee['last_name'] }}
                </p>
                <p
                    *ngIf="
                        employee['suspended'] > 0 ||
                        employee['status'] === 'Inactive' ||
                        !employee['photo']
                    "
                >
                    Employee is
                    {{
                        employee['suspended'] > 0
                            ? 'suspended'
                            : employee['status'] === 'Inactive'
                            ? 'inactive'
                            : 'missing a photo'
                    }}
                    and cannot be printed a pass.
                </p>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="choose()"
        color="primary"
        *ngIf="
            employee &&
            employee['suspended'] < 1 &&
            employee['status'] === 'Active' &&
            employee['photo']
        "
    >
        Choose Employee
    </button>
    <button mat-raised-button [mat-dialog-close]="undefined" color="warn">
        Cancel
    </button>
</div>
