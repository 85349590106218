<h1 mat-dialog-title>Add Ticket Event</h1>
<div mat-dialog-content>
    <mat-form-field class="w100">
        <mat-select placeholder="Type" [(ngModel)]="event.type" disabled>
            <mat-option *ngFor="let type of types" [value]="type.value">
                {{ type.display }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w100">
        <mat-label>Content</mat-label>
        <textarea matInput rows="5" [(ngModel)]="event.content"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        (click)="confirm()"
        color="primary"
        [disabled]="!event.content || !event.type"
    >
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
