import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ChooseOptionData {
    title: string;
    content?: string;
    options: ChooseOptionOptions[];
}

export interface ChooseOptionOptions {
    content: string;
    color: string;
    tooltip: TooltipOption;
    disabled?: boolean;
    callback?: () => any;
    icon?: string;
}

export interface TooltipOption {
    content: string;
    onlyOnDisabled: boolean;
}

@Component({
    selector: 'app-chooseoption',
    templateUrl: './chooseoption.component.html',
    styleUrls: ['./chooseoption.component.scss']
})
export class ChooseoptionComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ChooseoptionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChooseOptionData
    ) {}

    ngOnInit() {}

    async choose(option: ChooseOptionOptions) {
        if (option.disabled || !option.callback) {
            this.dialogRef.close(undefined);
            return;
        }

        this.dialogRef.close(await Promise.resolve(option.callback()));
    }
}
