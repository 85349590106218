<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin', false)"
            >
                <mat-icon>home</mat-icon> Home
            </a>
            <!-- Reporting Extension Panel -->
            <mat-expansion-panel>
                <mat-expansion-panel-header class="select-none">
                    <mat-panel-title class="dark-sidenav-text">
                        <mat-icon>assessment</mat-icon> &nbsp;Reporting
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Employee Reporting Extension Panel -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>account_circle</mat-icon> &nbsp;Employee
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="_loading.navigate('/admin/punch', false)"
                    >
                        <mat-icon>dialpad</mat-icon> Punch
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="_loading.navigate('/admin/payroll', false)"
                    >
                        <mat-icon>attach_money</mat-icon> Payroll
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/referral-report', false)
                        "
                    >
                        <mat-icon>people</mat-icon> Referral
                    </a>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>money</mat-icon> &nbsp;Sales
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/online-sales', false)
                        "
                    >
                        <mat-icon>wifi</mat-icon> Online
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/onsite-sales', false)
                        "
                    >
                        <mat-icon>room</mat-icon> On-site
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/ripleys-sales', false)
                        "
                    >
                        <mat-icon svgIcon="ripleys"></mat-icon> Ripleys
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/daily-reconciliation',
                                false
                            )
                        "
                    >
                        <mat-icon>people</mat-icon> Daily Reconciliation
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="_loading.navigate('/admin/daily-entry', false)"
                    >
                        <mat-icon>create</mat-icon> Daily Entry
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="_loading.navigate('/admin/drawers', false)"
                    >
                        <mat-icon>open_in_browser</mat-icon> Drawers
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/giftcard-report', false)
                        "
                    >
                        <mat-icon>card_giftcard</mat-icon> Gift Card
                    </a>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>shopping_cart</mat-icon> &nbsp;Customer
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="_loading.navigate('/admin/feedback', false)"
                    >
                        <mat-icon>feedback</mat-icon> Feedback
                    </a>
                </mat-expansion-panel>
            </mat-expansion-panel>

            <!-- Products Extension Panel -->
            <mat-expansion-panel>
                <mat-expansion-panel-header class="select-none">
                    <mat-panel-title class="dark-sidenav-text">
                        <mat-icon>local_offer</mat-icon> &nbsp;Products
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- POS Extension Panel -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>local_atm</mat-icon> &nbsp;POS
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/pos/products',
                                false
                            )
                        "
                    >
                        <mat-icon>local_offer</mat-icon> Products
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/pos/locations',
                                false
                            )
                        "
                    >
                        <mat-icon>location_searching</mat-icon> Locations
                    </a>
                </mat-expansion-panel>

                <!-- Web Expansion Panel -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>cloud</mat-icon> &nbsp;Web
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate('/admin/products/web', false)
                        "
                    >
                        <mat-icon>local_offer</mat-icon> Products
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/categories',
                                false
                            )
                        "
                    >
                        <mat-icon>category</mat-icon> Categories
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/templates',
                                false
                            )
                        "
                    >
                        <mat-icon>crop_landscape</mat-icon> Templates
                    </a>
                </mat-expansion-panel>

                <!-- Kiosk Extension Panel -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="select-none">
                        <mat-panel-title class="dark-sidenav-text">
                            <mat-icon>computer</mat-icon> &nbsp;Kiosk
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/kiosk/products',
                                false
                            )
                        "
                    >
                        <mat-icon>local_offer</mat-icon> Products
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/kiosk/locations',
                                false
                            )
                        "
                    >
                        <mat-icon>location_searching</mat-icon> Locations
                    </a>
                    <a
                        class="dark-sidenav-text"
                        mat-raised-button
                        (click)="
                            _loading.navigate(
                                '/admin/products/kiosk/categories',
                                false
                            )
                        "
                    >
                        <mat-icon>category</mat-icon> Categories
                    </a>
                </mat-expansion-panel>

                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/products/consignment', false)
                    "
                >
                    <mat-icon>local_activity</mat-icon> Consignment
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/products/bogo', false)"
                >
                    <mat-icon>payment</mat-icon> BOGOs
                </a>
            </mat-expansion-panel>

            <!-- Screens Extension Panel -->
            <mat-expansion-panel>
                <mat-expansion-panel-header class="select-none">
                    <mat-panel-title class="dark-sidenav-text">
                        <mat-icon>tv</mat-icon> &nbsp;Screens
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/screens/locations', false)
                    "
                >
                    <mat-icon>location_searching</mat-icon> Locations
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/screens/screens', false)"
                >
                    <mat-icon>tv</mat-icon> Screens
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/screens/media', false)"
                >
                    <mat-icon>perm_media</mat-icon> Media
                </a>
            </mat-expansion-panel>

            <!-- Cart Extension Panel -->
            <mat-expansion-panel>
                <mat-expansion-panel-header class="select-none">
                    <mat-panel-title class="dark-sidenav-text">
                        <mat-icon>store</mat-icon> &nbsp;Store
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/store/stores', false)"
                >
                    <mat-icon>business</mat-icon> Stores
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/store/translations', false)
                    "
                >
                    <mat-icon>translate</mat-icon> Translations
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/store/carts', false)"
                >
                    <mat-icon>shopping_cart</mat-icon> Carts
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/store/promo-codes', false)
                    "
                >
                    <mat-icon>local_offer</mat-icon> Promo Codes
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/store/logs', false)"
                >
                    <mat-icon>article</mat-icon> Logs
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/products/web', false)"
                >
                    <mat-icon>cloud</mat-icon> Products
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/store/categories', false)
                    "
                >
                    <mat-icon>category</mat-icon> Categories
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="
                        _loading.navigate('/admin/store/transactions', false)
                    "
                >
                    <mat-icon>receipt_long</mat-icon> Transactions
                </a>
                <a
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="_loading.navigate('/admin/store/shipping', false)"
                >
                    <mat-icon>local_shipping</mat-icon> Shipping
                </a>
            </mat-expansion-panel>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/message', false)"
            >
                <mat-icon>chat_bubble</mat-icon> Send Message
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/users', false)"
            >
                <mat-icon>person</mat-icon> Users
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/documents', false)"
            >
                <mat-icon>description</mat-icon> Documents
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/voucher-search', false)"
            >
                <mat-icon>search</mat-icon> Voucher Search
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/giftcards', false)"
            >
                <mat-icon>card_giftcard</mat-icon> Gift Cards
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/bookings', false)"
            >
                <mat-icon>event</mat-icon> Bookings
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/reservations', false)"
            >
                <mat-icon>book_online</mat-icon> Reservations
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/non-fluid-locations', false)"
            >
                <mat-icon>location_searching</mat-icon> Non Fluid Locations
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/templates', false)"
            >
                <mat-icon>note</mat-icon> Templates
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/checklists', false)"
            >
                <mat-icon>checklist</mat-icon> Checklists
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate('/admin/customer-facing-screens', false)
                "
            >
                <mat-icon>tablet</mat-icon> Customer Facing Screens
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/printing', false)"
            >
                <mat-icon>print</mat-icon> Printing
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/nonlogin-devices', false)"
            >
                <mat-icon>computer</mat-icon> Non-Login Devices
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/phone-numbers', false)"
            >
                <mat-icon>call</mat-icon> Phone Numbers
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/admin/sd-card-images', false)"
            >
                <mat-icon>sd_card</mat-icon> SD Card Images
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
