import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    partner: Object;
    users: Object[];
}

@Component({
    selector: 'app-editpartner',
    templateUrl: './editpartner.component.html',
    styleUrls: ['./editpartner.component.scss']
})
export class EditpartnerComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    emailFormControl = new FormControl('', [
        Validators.email,
        Validators.required
    ]);
    filteredUsers: Object[];

    @ViewChild('partnerForm', { static: true })
    partnerForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditpartnerComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe(_ => this.askClose());
    }

    ngOnInit() {
        // Set the email value to the partners email.
        this.emailFormControl.setValue(this.data.partner['email']);

        this.filteredUsers = cloneDeep(this.data.users);
    }

    hasChanges() {
        return (
            this.partnerForm.dirty ||
            this.emailFormControl.value !== this.data.partner['email']
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.'
                }
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.data.partner['email'] = this.emailFormControl.value;
            this.dialogRef.close(this.data.partner);
        } else {
            this.dialogRef.close(undefined);
        }
    }

    filterUsers() {
        this.filteredUsers = this.data.users.filter(user => {
            let joined = `${user['id']}${user['first_name']}${user['last_name']}${user['username']}`.toLowerCase();
            return joined.includes(this.data.partner['master_user']);
        });
    }
}
