<loading *ngIf="loading" [opacity]="0.5"></loading>

<div class="print border-box" *ngIf="!showSuccess">
    <div
        class="print large-margin"
        *ngIf="!payment['invalid'] && !payment['completed']"
    >
        <h1 class="print blue center bbb2">Payment Details</h1>

        <div class="print bs1 mb15">
            <mat-table
                [dataSource]="invoicesDataSource"
                class="mat-elevation-z8 w100 mb15 print"
            >
                <!-- ID -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef class="print blue">
                        ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let invoice" class="print">
                        {{
                            invoice['type'] === 'corporate'
                                ? invoice['invoice']['id']
                                : invoice['invoice']['o_id']
                        }}
                    </mat-cell>
                </ng-container>

                <!-- Price -->
                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef class="print blue">
                        Price
                    </mat-header-cell>
                    <mat-cell *matCellDef="let invoice" class="print">
                        {{ invoice['invoice']['total'] | currency }}
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    class="print btb2"
                    *matHeaderRowDef="['id', 'price']"
                ></mat-header-row>
                <mat-row
                    class="print row"
                    *matRowDef="let row; columns: ['id', 'price']"
                ></mat-row>
            </mat-table>

            <h2 class="print center mb15">
                <span class="print blue">Total:</span>
                {{ total | currency }}
            </h2>
        </div>

        <h1 class="print center blue bbb2">Contact Information</h1>
        <div class="print bs1 padding-15 mb15">
            <div class="inputs print">
                <mat-form-field class="print">
                    <input
                        matInput
                        type="email"
                        autocomplete="email"
                        placeholder="Email"
                        [(ngModel)]="paymentInfo.email"
                        class="print"
                        required
                    />
                </mat-form-field>
            </div>
            <div class="inputs print">
                <mat-form-field class="print">
                    <input
                        matInput
                        placeholder="First Name"
                        autocomplete="given-name"
                        [(ngModel)]="paymentInfo.first_name"
                        class="print"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="print">
                    <input
                        matInput
                        placeholder="Last Name"
                        autocomplete="family-name"
                        [(ngModel)]="paymentInfo.last_name"
                        class="print"
                        required
                    />
                </mat-form-field>
            </div>
        </div>

        <h1 class="print center blue bbb2">Card Information</h1>
        <div class="print pay-container bs1">
            <div class="pay-header aic flex padding-15 print">
                <p class="print">Credit Card</p>
                <div class="flex aic print">
                    <div class="print">
                        <img
                            class="print"
                            src="https://store.maritimefun.com/assets/logos/payment/amex.png"
                            alt="American Express Logo"
                        />
                    </div>
                    <div class="print">
                        <img
                            class="print"
                            src="https://store.maritimefun.com/assets/logos/payment/discover.png"
                            alt="Discover Logo"
                        />
                    </div>
                    <div class="print">
                        <img
                            class="print"
                            src="https://store.maritimefun.com/assets/logos/payment/mastercard.png"
                            alt="Mastercard Logo"
                        />
                    </div>
                    <div class="print">
                        <img
                            class="print"
                            src="https://store.maritimefun.com/assets/logos/payment/visa.png"
                            alt="Visa Logo"
                        />
                    </div>
                </div>
            </div>
            <div class="pay-body padding-15 print">
                <div class="inputs print">
                    <mat-form-field class="print">
                        <input
                            matInput
                            creditCard
                            placeholder="Card Number"
                            autocomplete="cc-number"
                            [(ngModel)]="paymentInfo.cardNumber"
                            class="print"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field class="print">
                        <input
                            matInput
                            placeholder="Name On Card"
                            autocomplete="cc-name"
                            [(ngModel)]="paymentInfo.nameOnCard"
                            class="print"
                            required
                        />
                    </mat-form-field>
                </div>
                <div class="inputs print">
                    <mat-form-field class="print">
                        <input
                            matInput
                            creditCardExpiry
                            placeholder="Expiration Date (MM/YY)"
                            autocomplete="cc-exp"
                            [(ngModel)]="paymentInfo.expirationDate"
                            class="print"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field class="print">
                        <input
                            matInput
                            placeholder="Security Code"
                            autocomplete="cc-csc"
                            [(ngModel)]="paymentInfo.securityCode"
                            class="print"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <button
            mat-raised-button
            class="print center-btn mb15 mt15"
            color="primary"
            [disabled]="!canPay"
            (click)="pay()"
        >
            <mat-icon>attach_money</mat-icon>
            Pay
        </button>
    </div>

    <!-- Invalid payment. -->
    <div class="print center-screen" *ngIf="payment['invalid']">
        <h1 class="print center red-text">INVALID PAYMENT</h1>
        <p class="print center">
            The payment has been invalidated. Please contact your account
            administrator for more information.
        </p>
        <button
            mat-raised-button
            class="print center-btn mt15"
            color="primary"
            onclick="window.location.assign('/');"
        >
            Back to FluidDynamics
        </button>
    </div>

    <!-- Completed payment. -->
    <div class="print-container completed-payment" *ngIf="payment['completed']">
        <div>
            <div class="completed-payment-img">
                <div class="no-print">
                    <button mat-icon-button onclick="window.print()">
                        <mat-icon class="white">print</mat-icon>
                    </button>
                </div>
                <img src="https://api.fluiddynamics.ca/img/mfg-logo.png" />
            </div>

            <div class="completed-payment-header mb15">
                <h1>Thank-You for your Payment</h1>
                <h2>{{ payment['first_name'] }},</h2>
                <p>Please retain this receipt for your records.</p>
            </div>

            <div class="completed-payment-info">
                <div class="flex mb15">
                    <div class="w100 center">
                        <h3 class="underline m0">Order Information</h3>
                        <p class="m0">
                            <span class="bold">Date:</span>
                            {{
                                payment['completed'] * 1000
                                    | date: 'yyyy-MM-dd HH:mm:ss zz'
                            }}
                        </p>
                        <p class="m0">
                            <span class="bold">Order ID:</span>
                            {{ payment['id'] }}
                        </p>
                    </div>
                </div>
                <div class="flex mb15">
                    <div class="w100 center">
                        <h3 class="m0 underline">Customer Information</h3>
                        <p class="m0">
                            <span class="bold">Name:</span>
                            {{ payment['first_name'] }}
                            {{ payment['last_name'] }}
                        </p>
                        <p class="m0">
                            <span class="bold">Email:</span>
                            {{ payment['email'] }}
                        </p>
                    </div>
                    <div class="w100 center">
                        <h3 class="m0 underline">Payment Information</h3>

                        <p class="m0">
                            <span class="bold">Card Type:</span>
                            {{ payment['card_name'] }}
                        </p>
                        <p class="m0">
                            <span class="bold">Card Number:</span>
                            {{ payment['pan'] }}
                        </p>
                    </div>
                </div>
            </div>

            <h2 class="completed-payment-invoices-header">Invoices Paid For</h2>
            <div class="completed-payment-invoices-body">
                <div *ngFor="let invoice of payment['invoices']">
                    <p class="w100 bold">
                        {{
                            invoice['type'] === 'corporate'
                                ? invoice['invoice']['id']
                                : invoice['invoice']['o_id']
                        }}
                    </p>

                    <p class="bold w100 right">
                        {{ invoice['invoice']['total'] | currency }}
                    </p>
                </div>
            </div>

            <div class="completed-payment-total">
                <div>
                    <div>
                        <p>Total</p>
                    </div>

                    <div class="right bold">
                        <p>
                            {{ total | currency }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="completed-payment-tac">
                <h1>
                    All purchases are subject to our updated
                    <a
                        target="_blank"
                        href="https://maritimefun.com/TermsConditions.pdf"
                        >Terms and Conditions</a
                    >
                </h1>
            </div>

            <div class="completed-payment-footer">
                <img src="https://api.fluiddynamics.ca/img/mfgWebLogo.png" />
            </div>
        </div>
    </div>
</div>

<div class="print center-screen" *ngIf="showSuccess">
    <svg
        class="print center mb15"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
    >
        <circle
            class="print checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
        />
        <path
            class="print checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
    </svg>
    <h1 class="print center">Payment Success!</h1>
    <p class="print center">
        In 10 seconds, the page will refresh showing you your receipt. Also, you
        should get an email confirmation shortly.
    </p>
</div>
