/// <reference types="@types/googlemaps" />

import {
    Component,
    EventEmitter,
    Output,
    AfterViewInit,
    ViewChild,
    ElementRef,
    Input
} from '@angular/core';

@Component({
    selector: 'google-autocomplete',
    template: `
        <mat-form-field [class]="class ? class : ''">
            <input
                #addressInput
                matInput
                type="text"
                [placeholder]="placeholder"
                [name]="placeholder"
            />
        </mat-form-field>
    `
})
export class GoogleAutocompleteComponent implements AfterViewInit {
    @Input() placeholder: string;
    @Input() class?: string;
    @Output() updated: EventEmitter<
        google.maps.places.PlaceResult
    > = new EventEmitter();
    @ViewChild('addressInput', { static: true }) addressInput: ElementRef;

    constructor() {}

    ngAfterViewInit() {
        let autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
            this.addressInput.nativeElement,
            { componentRestrictions: { country: 'CA' } }
        );

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.updated.emit(place);
        });
    }
}
