<div class="background-color">
    <div *ngIf="online">
        <img
            class="logo"
            src="assets/parks-canada/parksLogo.png"
            alt="Parks canada logo"
        />

        <header class="pick-language">
            <button
                class="btn-language"
                (click)="_translate.setLang('fr')"
                *ngIf="_translate.currentLang === 'en'"
            >
                Français
            </button>
            <button
                class="btn-language"
                (click)="_translate.setLang('en')"
                *ngIf="_translate.currentLang === 'fr'"
            >
                English
            </button>
        </header>

        <div *ngIf="state === 'initialization'">
            <h1 class="initialization">Initializing</h1>
        </div>
        <div *ngIf="state === 'selectFacilityLocation'">
            <h1 class="initialization mb15">Select Facility and Location</h1>
            <form>
                <mat-form-field class="flex jcc">
                    <mat-label>List Of Facilities</mat-label>
                    <mat-select
                        [(ngModel)]="selectedFacility"
                        name="facility"
                        (selectionChange)="filterLocation()"
                    >
                        <mat-option
                            *ngFor="let facility of parksFacilities"
                            [value]="facility"
                        >
                            {{ facility }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="flex jcc">
                    <mat-label>List Of Facility Locations</mat-label>
                    <mat-select [(ngModel)]="selectedLocation" name="location">
                        <mat-option
                            *ngFor="let location of filteredLocation"
                            [value]="location"
                        >
                            {{ location.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <button
                class="center-btn"
                mat-raised-button
                color="primary"
                [disabled]="!selectedLocation"
                (click)="startApp()"
            >
                Start App
            </button>
        </div>

        <div *ngIf="state === 'welcome'">
            <h1 class="headerWelcome">
                Welcome To {{ selectedLocation.facility_name }}
            </h1>
            <h2 class="scanPass" translation="parksKioskScan"></h2>
            <img
                class="barcode-reader"
                src="assets/parks-canada/barcodeReaderFinal.png"
                alt="Barcode Reader img"
            />
            <p>{{ finalKeypress }}</p>
        </div>

        <div *ngIf="state === 'pleaseWait'">
            <h2 class="pleaseWait" translation="pleaseWait"></h2>
            <div class="loader"></div>
        </div>

        <div *ngIf="state === 'printReceipt'">
            <img
                class="print-gif"
                loading="lazy"
                src="/assets/parks-canada/print.gif"
                alt="printing gif"
            />
            <h2 class="printing" translation="printing"></h2>
        </div>
        <div *ngIf="state === 'thankYou'">
            <h2 class="thank-you" translation="thankYou"></h2>
            <h2 class="take-pass" translation="takePass"></h2>
        </div>

        <div *ngIf="state === 'msg400'">
            <img
                class="animated-gif-error"
                src="assets/parks-canada/warningSign.png"
                alt="Warning Sign"
            />
            <h1 class="notValidHeader">{{ errorMsg }}</h1>
            <h1 class="notValidHeader" translation="proceedStaffedGate"></h1>
        </div>

        <div *ngIf="state === 'error'">
            <img
                class="animated-gif-error"
                src="assets/parks-canada/warningSign.png"
                alt="Warning Sign"
            />
            <h1 class="notValidHeader">{{ errorMsg }}</h1>
            <h1 class="notValidHeader" translation="proceedStaffedGate"></h1>
        </div>
    </div>

    <div *ngIf="!online">
        <h1 translation="offlinePage"></h1>
    </div>
</div>
