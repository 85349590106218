import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import DOMPurify from 'dompurify';
import marked from 'marked';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/markdown/markdown.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/fullscreen.js';
import 'codemirror/addon/fold/comment-fold.js';
import 'codemirror/addon/comment/comment.js';

interface Data {
    markdown: string;
}

@Component({
    selector: 'app-editmarkdown',
    templateUrl: './editmarkdown.component.html',
    styleUrls: ['./editmarkdown.component.scss'],
})
export class EditMarkdownComponent implements OnInit {
    // Variables.
    editor: CodeMirror.Editor;
    changed: Subject<string> = new Subject<string>();
    changedSubscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<EditMarkdownComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        // Setup listeners on the RXJS subjects.
        this.changedSubscription = this.changed
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((newValue) => {
                this.data.markdown = newValue;

                // Update preview.
                const element = document.getElementById('markdownPreview');
                if (element) {
                    element.innerHTML = DOMPurify.sanitize(
                        marked(this.data.markdown)
                    );
                }
            });

        this.setupCodeMirror();
    }

    setupCodeMirror() {
        // Setup CodeMirror editor.
        const textArea = <HTMLTextAreaElement>(
            document.getElementById('markdown')
        );
        this.editor = CodeMirror.fromTextArea(textArea, {
            mode: 'markdown',
            lineNumbers: true,
            indentUnit: 4,
            indentWithTabs: true,
            lineWrapping: false,
            styleActiveLine: true,
            foldGutter: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
            extraKeys: {
                F11: (cm: any) =>
                    cm.setOption('fullScreen', !cm.getOption('fullScreen')),
                Esc: (cm: any) => {
                    if (cm.getOption('fullScreen')) {
                        cm.setOption('fullScreen', false);
                    }
                },
                'Ctrl-/': 'toggleComment',
                'Cmd-/': 'toggleComment',
                'Ctrl-Space': 'autocomplete',
                'Cmd-Space': 'autocomplete',
            },
        });
        this.editor.on('change', () => this.cmChanged(this.editor.getValue()));

        // Add classes.
        this.addScrollbarClasses();

        // Set values of the editors.
        if (this.data.markdown) this.editor.setValue(this.data.markdown);
    }

    addScrollbarClasses() {
        // Add Scrollbar to all elements that have hscrollbar and vscrollbar.
        let addScrollbar = document.querySelectorAll(
            '.CodeMirror-hscrollbar, .CodeMirror-vscrollbar'
        );
        for (let i = 0; i < addScrollbar.length; i++) {
            addScrollbar[i].classList.add('scrollbar');
            addScrollbar[i].classList.remove('scrollbar');
            addScrollbar[i].classList.add('scrollbar');
        }
    }

    cmChanged(text: string) {
        this.changed.next(text);
    }
}
