import { Injectable } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from './auth.service';

/**
 * This service provides functions for handling context menus.
 * Context menus are popup menus instantiated by a right click.
 */
@Injectable({
    providedIn: 'root',
})
export class ContextmenuService {
    constructor(private _auth: AuthService) {}

    /**
     * This function opens a context menu along with positioning the menu.
     * This function should also allow right clicking outside to close the menu.
     *
     *
     * @param event The right click mouse event.
     * @param menu The menu trigger used to open the menu.
     */
    openMenu(event: MouseEvent, menu: MatMenuTrigger) {
        // Open the menu and move it to the current mouse position.
        menu.openMenu();
        let openedMenu = <HTMLElement>(
            document.getElementsByClassName('mat-menu-panel')[0]
        );
        let offsetElement = openedMenu.parentElement.parentElement;

        // Set menu style 10 times.
        let amt = 0;
        let timeout = setTimeout(() => {
            if (amt >= 10) {
                clearTimeout(timeout);
                return;
            }

            this._setMenuStyle(openedMenu, offsetElement, event);
            amt++;
        }, 10);

        // Add event listener to context menu overlay. Delay to prevent the event listener from failing.
        if (!this._auth.isIOS()) {
            setTimeout(() => {
                let removeRightClick = document.getElementsByClassName(
                    'cdk-overlay-backdrop'
                )[0];

                removeRightClick.addEventListener('contextmenu', (evt) => {
                    evt.preventDefault();
                    menu.closeMenu();
                    return false;
                });
            }, 200);
        }
    }

    private async _setMenuStyle(
        openedMenu: HTMLElement,
        offsetElement: HTMLElement,
        event: MouseEvent
    ) {
        // Put the menu to the correct position.
        openedMenu.style.position = 'absolute';
        openedMenu.style.top = `calc(${event.clientY}px - ${offsetElement.style.top})`;
        openedMenu.style.left = `calc(${event.clientX}px - ${offsetElement.style.left})`;
        openedMenu.style.transition = 'all 150ms';

        // Remove right click on mat-menu-content.
        openedMenu.children[0].setAttribute('oncontextmenu', 'return false;');

        // Fix the position.
        const parentBounding = openedMenu.parentElement.parentElement.getBoundingClientRect();
        const topParent = parentBounding.height;
        const leftParent = parentBounding.width;
        await this._keepAdjusting(openedMenu);

        // Get offscreen amount.
        const bounding = openedMenu.getBoundingClientRect();
        const topOff = window.innerHeight - (bounding.top + bounding.height);
        const leftOff = window.innerWidth - (bounding.left + bounding.width);

        // If off screen.
        if (topOff < 0) {
            openedMenu.style.top = `${topParent - bounding.height}px`;
        }
        if (leftOff < 0) {
            openedMenu.style.left = `${leftParent - bounding.width}px`;
        }
    }

    private _keepAdjusting(
        openedMenu: HTMLElement,
        oldHeight: number = 0,
        oldWidth: number = 0
    ) {
        return new Promise((resolve, reject) => {
            const wait = (oldHeight, oldWidth) => {
                const bounding = openedMenu.getBoundingClientRect();
                if (
                    bounding.height === oldHeight &&
                    bounding.width === oldWidth
                ) {
                    return resolve();
                }

                // Re-call.
                setTimeout(() => {
                    wait(bounding.height, bounding.width);
                }, 25);
            };

            wait(oldHeight, oldWidth);
        });
    }
}
