import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import {
    TranslateOptions,
    TranslateService,
} from '../services/translate.service';

@Directive({
    selector: '[translation]',
})
export class TranslationDirective implements OnInit, OnDestroy {
    // Translation variables.
    currentTranslation: string;
    translateEvent = this.translateE.bind(this);

    // Input variables.
    @Input('translation') id: string | TranslateOptions;
    @Input('translationParams') params?: { [key: string]: string };

    constructor(private el: ElementRef, private _translate: TranslateService) {}

    async ngOnInit() {
        await this.translate();

        window.addEventListener('languageChange', this.translateEvent);
    }

    ngOnDestroy() {
        window.removeEventListener('languageChange', this.translateEvent);
    }

    async translate() {
        // If given translation params instead, use that.
        if (this.params) {
            this.id = {
                id: this.id instanceof Object ? this.id.id : this.id,
                params: this.params,
            };
        }

        // Call the translate method on the translate service.
        this.el.nativeElement.innerText = await this._translate.translate(
            this.id
        );

        // Set new lang.
        this.currentTranslation = this._translate.getCurrentLang();
    }

    async translateE(e: CustomEvent) {
        if (e.detail.language === this.currentTranslation) return;

        await this.translate();
    }
}
