import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MiscService } from '../services/misc.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpService } from '../services/http.service';

import cloneDeep from 'lodash-es/cloneDeep';
import { SnackbarService } from '../services/snackbar.service';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
    // Other variables.
    loading: boolean = false;
    partner: Object;

    // Resolve variables.
    order: Object;
    vouchers: Object[];

    // Data Table.
    vouchersDataSource: MatTableDataSource<Object>;
    reportDisplayedColumns: string[] = [
        'description',
        'price',
        'quantity',
        'amount',
    ];

    constructor(
        public _misc: MiscService,
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {
        this.order = this._route.snapshot.data.order;
        this.vouchers = this._route.snapshot.data.vouchers;
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Invoice - FluidDynamics');

        this.loading = true;

        let uniqueVouchers = [];

        // Loop through the vouchers and see if the items have the same ID to append quantity.
        for (let i = 0; i < this.vouchers.length; i++) {
            let index: number = uniqueVouchers.findIndex(
                (voucher) =>
                    voucher['prod_id'] === this.vouchers[i]['prod_id'] &&
                    voucher['price'] === this.vouchers[i]['price']
            );
            if (index < 0) {
                this.vouchers[i]['quantity'] = 1;
                uniqueVouchers.push(this.vouchers[i]);
            } else {
                uniqueVouchers[index]['quantity']++;
            }
        }

        // Clone back into vouchers.
        this.vouchers = cloneDeep(uniqueVouchers);

        // Create the data source.
        this.vouchersDataSource = new MatTableDataSource(this.vouchers);

        // Get the partner.
        this.partner = (
            await this._http.getLocal(
                `partners/?id=${this.order['partner_id']}`
            )
        ).body;

        this.loading = false;
    }

    async payInvoice() {
        // If already paid.
        if (this.order['paid']) return;

        this.loading = true;

        // Call API to a partner_payment for the invoice.
        const resp = await this._http.postLocal(
            'partners/invoice/generate-payment/',
            { invoices: [{ id: this.order['hash'], type: 'corporate' }] }
        );

        this.loading = false;

        // If not 200.
        if (resp.status !== 200) {
            this._snackbar.durationSnackbar(
                'An error has occurred while attempting to create the payment. Please contact your account administrator for help.',
                null,
                10000
            );
            return;
        }

        // If 200, redirect them to the partner-pay page.
        window.location.assign(`/partner-pay/${resp.body['id']}`);
    }
}
