import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalerrorhandlerService implements ErrorHandler {
    constructor() {}

    handleError(error) {
        // Check if the error is a chunk loading error.
        if (/Loading chunk [\d]+ failed/.test(error.message)) {
            // If it is, refresh the page.
            window.location.reload();
        }

        throw error;
    }
}
