import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MiscService } from 'src/app/services/misc.service';

@Component({
    selector: 'app-giftcarddetailedandhistory',
    templateUrl: './giftcarddetailedandhistory.component.html',
    styleUrls: ['./giftcarddetailedandhistory.component.scss'],
})
export class GiftcarddetailedandhistoryComponent implements OnInit {
    historyDataSource: MatTableDataSource<Object>;
    contextLocation: Object;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        public dialogRef: MatDialogRef<GiftcarddetailedandhistoryComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object
    ) {}

    ngOnInit() {
        // Setup the table.
        this.historyDataSource = new MatTableDataSource(this.data['history']);
        this.historyDataSource.sort = this.sort;
        this.historyDataSource.paginator = this.paginator;
    }

    openOrder() {
        if (this.data['giftcard']['sales_vector'] === 'web') {
            window.open(
                `/admin/voucher-search/?search=${this.data['giftcard']['order_id']}`
            );
        }
    }
}
