<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr', false)"
            >
                <mat-icon>home</mat-icon> Home
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr/schedule', false)"
            >
                <mat-icon>schedule</mat-icon> Schedule
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr/employees', false)"
            >
                <mat-icon>supervisor_account</mat-icon> Employees
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr/applications', false)"
            >
                <mat-icon>menu_book</mat-icon> Applications
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr/auditions', false)"
            >
                <mat-icon>event_note</mat-icon> Interview Schedule
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/hr/drawers', false)"
            >
                <mat-icon>open_in_browser</mat-icon> Drawers
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
