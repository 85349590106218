<loading *ngIf="loading" [opacity]="0.5"></loading>

<div mat-dialog-content>
    <h1 class="center mb15">New Deposit</h1>

    <div class="flex jcc aic">
        <h2 class="m-0-10 underline">Active Drawers</h2>
        <mat-icon class="blue-text pointer" (click)="fetchDrawers()">
            refresh
        </mat-icon>
    </div>

    <!-- Loading Drawers -->
    <div class="flex-panel-entry aic jcc" *ngIf="drawerState === -1">
        <div class="mini-spinner flex jcc">
            <mat-icon [inline]="true" color="primary">sync</mat-icon>
        </div>
        Loading Active Drawers
    </div>

    <!-- No Drawers -->
    <h3 class="center" *ngIf="drawerState === 0">No Active Drawers</h3>

    <!-- Active Drawers -->
    <div *ngIf="drawerState === 1">
        <div class="mb15 flex jcc aic">
            <button
                mat-raised-button
                color="primary"
                class="m-0-10"
                (click)="selectAllDrawers()"
            >
                Select All Drawers
            </button>
            <button
                mat-raised-button
                color="warn"
                class="m-0-10"
                (click)="deselectAllDrawers()"
            >
                Deselect All Drawers
            </button>
        </div>

        <mat-selection-list
            class="list-w100"
            color="primary"
            [(ngModel)]="selectedDrawers"
        >
            <mat-list-item class="flex">
                <p class="header scrollbar">ID</p>
                <p class="header scrollbar">Location</p>
                <p class="header scrollbar">Clerk</p>
                <p class="header scrollbar">Rung</p>
                <p class="header scrollbar">Cash Actual</p>
                <p class="header padding-right-15 scrollbar">O/S</p>
            </mat-list-item>
            <mat-list-option
                class="flex hover"
                *ngFor="let drawer of activeDrawers"
                [value]="drawer"
            >
                <p class="scrollbar mh2r">
                    {{ drawer['id'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['location_name'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['first_name'] }} {{ drawer['last_name'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['total'] | currency }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['cash_actual'] | currency }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['os'] | currency }}
                </p>
            </mat-list-option>
        </mat-selection-list>
        <mat-selection-list
            class="list-w100 p-0"
            color="primary"
            [(ngModel)]="selectedSquareDrawers"
        >
            <mat-list-option
                class="flex hover"
                *ngFor="let drawer of activeSquareDrawers"
                [value]="drawer"
            >
                <p class="scrollbar mh2r">
                    {{ drawer['id'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['location_name'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['first_name'] }} {{ drawer['last_name'] }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['net_sales'] | currency }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['cash_actual'] | currency }}
                </p>
                <p class="scrollbar mh2r">
                    {{ drawer['os'] | currency }}
                </p>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <!-- Other Revenue -->
    <div class="mt15 other-revenue" *ngIf="hasOtherRevenue">
        <h2>Other Revenue</h2>
        <div class="flex aic" *ngFor="let revenue of otherRevenueNames">
            <p class="padding-right-15 w200">{{ revenue }}</p>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    [(ngModel)]="otherRevenue[revenue]"
                />
            </mat-form-field>
        </div>
    </div>

    <!-- Bank O/S -->
    <div class="mt15">
        <div class="flex aic">
            <p class="padding-right-15 w200">Bank O/S</p>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    [(ngModel)]="bankOS"
                />
            </mat-form-field>
        </div>
    </div>

    <!-- Cash Expected -->
    <div class="mt15">
        <div class="flex aic">
            <p class="padding-right-15 w200">Cash Expected</p>
            <p class="w200 bb">{{ cashExpected | currency }}</p>
        </div>
    </div>

    <!-- Deposit Amount -->
    <div class="mt15">
        <div class="flex aic">
            <p class="padding-right-15 w200">Deposit Amount</p>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    [(ngModel)]="depositAmount"
                />
            </mat-form-field>
        </div>
    </div>

    <!-- Slip ID -->
    <div class="mt15">
        <div class="flex aic">
            <p class="padding-right-15 w200">Slip ID</p>
            <mat-form-field>
                <input matInput [(ngModel)]="slipID" />
            </mat-form-field>
        </div>
    </div>

    <div class="mt30 fr">
        <button
            mat-raised-button
            (click)="save()"
            class="m-0-10"
            color="primary"
            [disabled]="!valid()"
        >
            Save
        </button>
        <button
            mat-raised-button
            (click)="dialogRef.close(undefined)"
            class="m-0-10"
            color="warn"
        >
            Cancel
        </button>
    </div>
</div>
