<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <p>{{ data.content ? data.content : '' }}</p>
</div>
<div mat-dialog-actions class="fr">
    <div
        *ngFor="let option of data.options"
        class="btn-div"
        [matTooltip]="option.tooltip.content"
        [matTooltipDisabled]="!option.disabled && option.tooltip.onlyOnDisabled"
    >
        <button
            mat-raised-button
            (click)="choose(option)"
            [color]="option.color ? option.color : 'primary'"
            class="btn-1"
            [disabled]="option.disabled"
        >
            <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
            {{ option.content }}
        </button>
    </div>
</div>
