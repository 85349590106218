import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Data {
    response: boolean;
    result: string;
    item: Object;
    barcode: string;
}

@Component({
    selector: 'app-posshowemployee',
    templateUrl: './posshowemployee.component.html',
    styleUrls: ['./posshowemployee.component.scss']
})
export class PosshowemployeeComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PosshowemployeeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        public _sanitizer: DomSanitizer,
        public _misc: MiscService
    ) {}

    ngOnInit() {}
}
