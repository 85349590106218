<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Choose Image</h1>
<div mat-dialog-content class="scrollbar">
    <h2 class="center">Upload Image</h2>
    <input
        #files
        type="file"
        [hidden]="true"
        accept="image/jpeg, image/png, image/gif"
        (change)="addMedia(files.files)"
    />
    <button
        mat-raised-button
        color="primary"
        class="center-btn mb15"
        (click)="files.click()"
    >
        Upload
    </button>

    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Filter"
            [(ngModel)]="filterValue"
            (input)="filter()"
        />
    </mat-form-field>

    <div class="uploaded-screen-media choose-screen-media">
        <div
            class="pointer"
            *ngFor="let image of filteredImages"
            (click)="save(image)"
        >
            <div class="center padding-15 flex aic">
                <h1>{{ image }}</h1>
            </div>
            <img
                src="https://api.fluiddynamics.ca/v1/pos/vouchers/products/images/?file={{
                    image
                }}"
                [alt]="image"
            />
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
