export default {
    row: `
        height: calc(20vh / 5);
        align-items: center;
    `,
    key: `
        font-size: 50px;
        border: 1px solid grey;
        text-align: center;
        flex-grow: 1;
        padding: 0 10px;
        user-select: none;
        height: 100%;
        cursor: pointer;
    `,
    keyCenter: `
        flex-grow: 0;
    `,
    keyDisabled: `
        background: black;
    `,
    keySelected: `
        background: rgb(80, 80, 80);
    `,
    space: `
        width: 400px;
    `,
    flex: `
        display: -webkit-box !important;
        display: flex !important;
    `,
    center: `
        justify-content: center;
    `
};
