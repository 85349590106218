import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

interface Data {
    clerks: string[];
}

@Component({
    selector: 'app-unknownsclerks',
    templateUrl: './unknownsclerks.component.html',
    styleUrls: ['./unknownsclerks.component.scss'],
})
export class UnknownsClerkComponent implements OnInit {
    // Data Table.
    dataSource: MatTableDataSource<Object>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<UnknownsClerkComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {}
}
