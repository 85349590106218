<loading *ngIf="loading" [opacity]="0.5"></loading>

<div mat-dialog-content>
    <h1 class="center">Activate Gift Card</h1>
    <h2 class="center mb15">Barcode: {{ giftcard['barcode'] }}</h2>

    <p class="center mb15">
        The sales vector is for how the gift card was purchased. If this gift
        card was not an online purchase from our store or a sale from our POS
        system, leave it at other. The order ID is if the gift card was
        purchased from a known Fluid order. For example, transaction_id for the
        POS and o_id for online sales. The item is if the gift card was
        purchased from a known Fluid order as well as for a specific item in the
        order. Campaign is if the gift card was purchased for a specific
        campaign/charity event/etc.
    </p>

    <div class="inputs">
        <mat-form-field>
            <mat-select
                placeholder="Sales Vector"
                [(ngModel)]="giftcard['sales_vector']"
                required
            >
                <mat-option value="other">Other</mat-option>
                <mat-option value="web">Web/Online</mat-option>
                <mat-option value="pos">POS</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                type="email"
                placeholder="Email"
                [(ngModel)]="giftcard['email']"
                required
            />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="Order ID"
                [(ngModel)]="giftcard['order_id']"
            />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Item" [(ngModel)]="giftcard['item']" />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="Campaign"
                [(ngModel)]="giftcard['campaign']"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Starting Balance"
                [(ngModel)]="startingBalance"
            />
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="activate()" color="primary">
        Activate
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
