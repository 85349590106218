<h1 mat-dialog-title>Group Information</h1>
<div mat-dialog-content>
    <p class="pos-group-description">
        Enter the name of the person to book and the amount of people that are
        in the group. Also, select the product associated with the booking.
    </p>

    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="First Name"
                [(ngModel)]="first_name"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Last Name"
                [(ngModel)]="last_name"
                required
            />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                type="number"
                placeholder="People"
                [(ngModel)]="people"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="POS Product" [(ngModel)]="item">
                <mat-option *ngFor="let p of data['products']" [value]="p">
                    {{ p['name'] }} ({{ p['id'] }})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
