import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface Data {
    facility: string;
    products: Object[];
    content: string;
    datePlaceholder?: string;
    hideDate?: boolean;
}

@Component({
    selector: 'app-choosedateandproduct',
    templateUrl: './choosedateandproduct.component.html',
    styleUrls: ['./choosedateandproduct.component.scss'],
})
export class ChoosedateandproductComponent implements OnInit {
    // Component variables.
    filteredProducts: Object[];
    product: string;
    date: Date;

    constructor(
        public dialogRef: MatDialogRef<ChoosedateandproductComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {
        this.filteredProducts = this.data.products.slice(0);
    }

    filterProducts() {
        // Filter by the description.
        this.filteredProducts = this.data.products.filter((product) => {
            return product['description']
                .toLowerCase()
                .includes(this.product.toLowerCase());
        });
    }

    confirm() {
        // If not date or product.
        if ((!this.date && !this.data.hideDate) || !this.product) {
            this._snackbar.defaultSnackbar('Missing date and/or product.');
            return;
        }

        // Get the product.
        const product = this.data.products.find(
            (p) => `${p['description']} (${p['id']})` == this.product
        );
        if (!product) {
            this._snackbar.defaultSnackbar('Invalid product.');
            return;
        }

        this.dialogRef.close({ date: this.date, product });
    }
}
