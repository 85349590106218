import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmComponent } from '../confirm/confirm.component';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface Data {
    name: string;
}

@Component({
    selector: 'app-changeticketname',
    templateUrl: './changeticketname.component.html',
    styleUrls: ['./changeticketname.component.scss'],
})
export class ChangeticketnameComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    newName: string;

    constructor(
        public dialogRef: MatDialogRef<ChangeticketnameComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());

        // Default new name to old name.
        this.newName = this.data.name;
    }

    ngOnInit() {}

    save() {
        // Check for missing information.
        if (!this.newName) {
            this._snackbar.defaultSnackbar('Missing new name.');
            return;
        }

        this.dialogRef.close(this.newName);
    }

    hasChanges() {
        return this.newName !== this.data.name;
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }
}
