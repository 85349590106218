<h1 mat-dialog-title>Editing Kiosk Category</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data['category']['name']"
                name="name"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Title"
                [(ngModel)]="data['category']['title']"
                name="title"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <textarea
                matInput
                placeholder="Description"
                [(ngModel)]="data['category']['description']"
                class="scrollbar"
                name="description"
                required
            ></textarea>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Facility"
                [(ngModel)]="data['category']['facility']"
                name="facility"
            />
        </mat-form-field>
        <h2 class="center">
            Categories
        </h2>
        <p class="center">
            You should not have subcategories if the category will have
            products. Note, double click a selection box to select/deselect all
            subcategories.
        </p>
        <div class="inputs mb15">
            <mat-selection-list
                #categoryList
                color="primary"
                class="list-w100 scrollbar max-250"
                [(ngModel)]="data['category']['subcategories']"
                (selectionChange)="_misc.selectionHandler($event, categoryList)"
                name="categories"
            >
                <mat-list-option
                    #categoryListOption
                    *ngFor="let category of data['categories']"
                    [value]="category['id']"
                    [ngClass]="{
                        'background-radial': categoryListOption.selected
                    }"
                >
                    {{ category['title']
                    }}{{
                        category['facility']
                            ? ' (' + category['facility'] + ')'
                            : ''
                    }}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
