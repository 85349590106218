import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    transform(number: string, ...args: any[]): any {
        // If number is null.
        if (!number) return '';

        let val: string = number
            .toString()
            .trim()
            .replace(/[-+ \.]/g, '');

        // If there are letters in the number.
        if (val.match(/[^0-9]/)) return number;

        // If more than 10, slice off the start.
        if (val.length > 10) {
            val = val.slice(val.length - 10);
        }

        // Get the city of the phone number.
        let city: string, formattedNumber: string;
        if (val.length === 3) {
            city = val;
        } else if (val.length > 3) {
            city = val.slice(0, 3);
            formattedNumber = val.slice(3);
        }

        if (formattedNumber) {
            if (formattedNumber.length > 3) {
                formattedNumber = `${formattedNumber.slice(
                    0,
                    3
                )}-${formattedNumber.slice(3, 7)}`;
            }

            return `(${city}) ${formattedNumber}`.trim();
        } else {
            return `(${city})`;
        }
    }
}
