import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as dayjs from 'dayjs';

interface Data {
    reservations: Object[];
    order: Object;
}

@Component({
    selector: 'app-reschedulereservation',
    templateUrl: './reschedulereservation.component.html',
    styleUrls: ['./reschedulereservation.component.scss'],
})
export class ReschedulereservationComponent implements OnInit {
    date: Date;
    reservation: Object;
    timeSlot: string;
    taken: number;
    dateFilter = this.rDateFilter.bind(this);
    lastNight: dayjs.Dayjs = dayjs().subtract(1, 'day').endOf('day');
    facilityMap = {
        SEL: 'Sandspit Amusement Park',
        SW: 'Shining Waters Family Fun Park',
        FZ: 'Magic Mountain FunZone',
        SZ: 'Magic Mountain SplashZone',
    };

    constructor(
        public dialogRef: MatDialogRef<ReschedulereservationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        this.taken = this.data.order['vouchers'].filter(
            (v) => v['reservation'] && v['time_slot']
        ).length;
    }

    confirm() {
        this.dialogRef.close({
            reservation: this.reservation,
            timeSlot: this.timeSlot,
        });
    }

    dateChange() {
        this.reservation = this.getReservation(this.date);
        this.timeSlot = undefined;
    }

    rDateFilter(date: Date) {
        // Check if the date is less than the current date.
        if (dayjs(date).isBefore(this.lastNight)) return false;

        // Get the reservation.
        const reservation = this.getReservation(date);

        // If no reservation, it is not open, wrong facility, or slots are taken.
        return !(
            !reservation ||
            reservation['slots'].every((slot) => {
                return (
                    (slot['open'] == '0' &&
                        slot['partner_accessible'] == '0') ||
                    slot['taken'] + this.taken >=
                        slot['max_people'] + slot['partner_inventory']
                );
            }) ||
            reservation['facility'] !==
                this.data.order['vouchers'][0]['facility']
        );
    }

    private getReservation(date: Date): Object | undefined {
        // Get date.
        const d = dayjs(date).format('YYYY-MM-DD');

        // Find the reservation from selected store.
        return this.data.reservations.find((r) => r['date'] === d);
    }
}
