<loading *ngIf="loading" [opacity]="0.5"></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <h1 class="center">Scanning</h1>

        <mat-card>
            <mat-card-content>
                <p class="center mb15">
                    To scan a voucher, just focus the input field below and scan
                    the voucher.
                </p>

                <mat-form-field class="center-btn half">
                    <input
                        matInput
                        id="scan"
                        placeholder="Scan Input"
                        [(ngModel)]="scanValue"
                        (keyup.enter)="scan()"
                    />
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
</div>
