import {
    Component,
    OnInit,
    HostListener,
    OnDestroy,
    AfterContentInit,
} from '@angular/core';
import { HttpService } from '../services/http.service';
import { WebcamUtil, WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SnackbarService } from '../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { PunchclockdayComponent } from '../dialogues/punchclockday/punchclockday.component';
import { HttpResponse } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { MiscService } from '../services/misc.service';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-punch-clock',
    templateUrl: './punch-clock.component.html',
    styleUrls: ['./punch-clock.component.scss'],
})
export class PunchClockComponent
    implements OnInit, OnDestroy, AfterContentInit {
    // Other variables.
    loading: boolean = false;
    checkAuthTimer: NodeJS.Timer;

    // Webcam variables.
    private trigger: Subject<void> = new Subject<void>();
    webcamOptions: MediaTrackConstraints = {
        facingMode: { ideal: 'user' },
    };

    // Punch variables.
    currentTime: string = dayjs().format('hh:mm:ss A');
    timer: NodeJS.Timer;
    empNum: string = '';
    readonly allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'Backspace',
    ];

    // Event listener for keyup.
    @HostListener('document:keyup', ['$event']) keyUp(event: KeyboardEvent) {
        if (!this.allowedKeys.includes(event.key)) return;

        switch (event.key) {
            case 'Backspace':
                this.remove();
                break;
            default:
                this.add(event.key);
                break;
        }
    }

    constructor(
        private _http: HttpService,
        private _title: Title,
        private _location: Location,
        private _snackbar: SnackbarService,
        private _dialog: MatDialog,
        private _loading: LoadingService,
        private _misc: MiscService
    ) {}

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Punch Clock - FluidDynamics');

        // Setup the webcam.
        await WebcamUtil.getAvailableVideoInputs();

        this.darkTheme(0);

        // Set timer to count up.
        this.timer = setInterval(
            () => (this.currentTime = dayjs().format('hh:mm:ss A')),
            1000
        );

        // Set timer (5 minutes) for checking if authenticated.
        this.checkAuthTimer = setInterval(async () => {
            // Set the cookie.
            this._misc.setCookie(
                `PUNCH_FLUIDDYNAMICS`,
                window.localStorage.getItem(`PUNCH_FLUIDDYNAMICS`)
            );

            let resp: HttpResponse<Object> = await this._http.getLocal(
                'admin/nonlogin-devices/validate/?type=punch'
            );

            // If not authenticated.
            if (resp.status !== 204) {
                this._loading.navigate('/auth/login', true);
            }
        }, 300000);
    }

    ngOnDestroy() {
        clearInterval(this.timer);
        clearInterval(this.checkAuthTimer);
    }

    ngAfterContentInit() {
        const webcam: HTMLElement = document.querySelector('.webcam');
        const title: HTMLParagraphElement = document.querySelector(
            '.numpad-value-title'
        );
        const val: HTMLParagraphElement = document.querySelector(
            '.numpad-value'
        );
        const button = <HTMLButtonElement>(
            document.querySelector('.numpad').firstChild
        );

        // Set height of video.
        (webcam.firstChild.firstChild as HTMLVideoElement).style.height = `${
            title.getBoundingClientRect().height +
            val.getBoundingClientRect().height
        }px`;

        // Set left for webcam.
        webcam.style.left = `${button.getBoundingClientRect().width / 4 + 5}px`;
    }

    async punch(photo: string) {
        // Check if the number is set.
        if (!this.empNum) return;

        // Check if employee exists.
        if (!(await this.employeeExists())) {
            this._snackbar.defaultSnackbar('Employee not found.');
            this.loading = false;
            return;
        }

        // Add the punch.
        let resp: Object = (
            await this._http.postLocal('hr/employees/punches/', {
                e_id: this.empNum,
                photo,
            })
        ).body;

        // If not successful.
        if (!resp['success']) {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to punch in.'
            );
        } else {
            this._snackbar.defaultSnackbar('Successfully punched in.');
        }

        await this.getPunches(3000);
        this.empNum = '';
        this.loading = false;
    }

    async getPunches(timer?: number) {
        console.log();
        this.loading = true;

        // Check if employee exists.
        let employee = await this.employeeExists();
        if (!employee) {
            this._snackbar.defaultSnackbar('Employee not found.');
            this.loading = false;
            return;
        }

        // Get start/end from the date.
        let start = dayjs().startOf('day').add(2, 'hour').unix();
        let end = dayjs().endOf('day').add(1, 'second').add(2, 'hour').unix();

        // Call the API to get the punches for the day for the employee.
        let resp: Object = (
            await this._http.getLocal(
                `hr/employees/punches/?start=${start}&end=${end}&emp=${this.empNum}&report`
            )
        ).body;

        // Get the punches or set to empty array.
        let punches: Object[] =
            resp.hasOwnProperty('success') && !resp['success']
                ? []
                : <Object[]>resp;

        // Get the segments.
        let segments: Object[] = [];
        let totalHours: number = 0;
        for (let i = 0; i < punches.length; i += 2) {
            // Set the in.
            let segment = {};
            segment['in'] = punches[i]['time'];

            // Check if the out exists.
            if (i + 1 < punches.length) {
                // Set out, hours and add to totalHours.
                segment['out'] = punches[i + 1]['time'];
                segment['hours'] = (segment['out'] - segment['in']) / 3600;
                totalHours += segment['hours'];
            } else {
                // Set out and hours to null.
                segment['out'] = null;
                segment['hours'] = null;
            }

            segments.push(segment);
        }

        this.loading = false;

        // Ask if they are sure.
        const dialogRef = this._dialog.open(PunchclockdayComponent, {
            data: {
                employee,
                segments,
                totalHours,
                timer: timer ? timer : null,
            },
        });

        await dialogRef.afterClosed().toPromise();

        this.empNum = '';
    }

    async employeeExists() {
        // Check if the employee exists.
        let resp: Object = (
            await this._http.getLocal(`hr/employees/?id=${this.empNum}`)
        ).body;

        return !resp.hasOwnProperty('success') || resp['success'] ? resp : null;
    }

    add(val: string) {
        this.empNum += val;
    }

    clear() {
        this.empNum = '';
    }

    remove() {
        this.empNum = this.empNum.slice(0, -1);
    }

    async darkTheme(count: number) {
        if (!document.documentElement.classList.contains('fluid-dark-theme')) {
            document.documentElement.classList.add('fluid-dark-theme');
        }

        count++;

        if (count < 10) {
            setTimeout(this.darkTheme.bind(this, count), 100);
        }
    }

    takeScreenshot() {
        this.trigger.next();
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    webcamInitError(error: WebcamInitError) {
        console.error('Webcam Error: ', error);

        // this._location.back();
    }

    handleImage(webcamImage: WebcamImage) {
        this.loading = true;
        this.punch(webcamImage.imageAsDataUrl);
    }
}
