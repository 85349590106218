import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '../services/snackbar.service';
import { HttpService } from '../services/http.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ChooselocationComponent } from '../dialogues/chooselocation/chooselocation.component';
import { ConfirmComponent } from '../dialogues/confirm/confirm.component';

@Component({
    selector: 'app-parkscanada',
    templateUrl: './parkscanada.component.html',
    styleUrls: ['./parkscanada.component.scss'],
})
export class ParkscanadaComponent implements OnInit {
    // Other variables.
    loading: boolean = false;
    location: Object;

    // Resolve variables.
    user: Object;
    locations: Object[];

    // Scanning variables.
    scanValue: string = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _title: Title,
        private _http: HttpService,
        private _cdr: ChangeDetectorRef,
        private _snackbar: SnackbarService,
        private _dialog: MatDialog
    ) {
        this.user = this._route.snapshot.data.user;
        this.locations = this._route.snapshot.data.locations;
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Parks Canada - FluidDynamics');

        // If location not set, call set function.
        let location = window.localStorage.getItem(
            'fluiddynamics_parks_location'
        );
        if (!location) {
            await this.setLocation();
        } else {
            // Set the location variable.
            let locationObject = this.locations.find(
                (l) => l['id'] === location
            );
            if (locationObject) this.location = locationObject;
        }
    }

    ngAfterViewInit() {
        this.focusInput();
    }

    async scan() {
        // Make sure the input has something in it and that a location is set.
        if (!this.scanValue || !this.location) return;

        this.loading = true;

        // Call the API.
        const resp = await this._http.postLocal('parks-canada/scan/', {
            scan: this.scanValue,
            location: this.location['id'],
        });

        // If not logged in.
        if (resp.status === 400) {
            await this._http.redirectHome(true, this._router.url);
            this.loading = false;
            return;
        }

        // Set the content of the popup based on the response.
        const body = resp.body;
        let content: string;
        let changeLocation: boolean = false;
        if (body['success']) {
            content = 'Successfully scanned the voucher.';
        } else {
            switch (body['response']) {
                case 'INEXISTENT_LOCATION':
                    content =
                        'The current location does not exist. Once the dialog closes, you will be prompt to select a new one.';
                    changeLocation = true;
                    break;
                case 'INEXISTENT_VOUCHER':
                    content =
                        'The vouchers that has been scanned does not exist.';
                    break;
                case 'UPDATE_ERROR':
                    content =
                        'There was an error scanning the voucher, please try again.';
                    break;
                case 'INSERT_ERROR':
                    content = `Successfully scanned the voucher. Although this was successful, please keep note of the voucher "${this.scanValue}" so that a minor fix can be assigned at a later time.`;
                    break;
                default:
                    content = body['response'];
            }
        }

        this.loading = false;

        // Show the popup.
        const dialogRef = this._dialog.open(ConfirmComponent, {
            data: {
                title: `Scan result for ${this.scanValue}.`,
                content,
            },
        });

        await dialogRef.afterClosed().toPromise();

        // If changeLocation set a location.
        if (changeLocation) await this.setLocation();

        this.scanValue = '';

        // Focus the input.
        this.focusInput();
    }

    async setLocation() {
        // Popup dialog.
        const dialogRef = this._dialog.open(ChooselocationComponent, {
            data: { locations: this.locations, parks: true },
        });

        await dialogRef.afterClosed().toPromise();

        // If still not set, re-call this function.
        let location = window.localStorage.getItem(
            'fluiddynamics_parks_location'
        );
        if (!location) {
            return await this.setLocation();
        } else {
            // Set the location variable.
            let locationObject = this.locations.find(
                (l) => l['id'] === location
            );
            if (locationObject) this.location = locationObject;
        }
    }

    focusInput() {
        // Focus the scanning input.
        document.getElementById('scan').focus();

        // Detect changes.
        this._cdr.detectChanges();
    }
}
