import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-adminbookinginfo',
    templateUrl: './adminbookinginfo.component.html',
    styleUrls: ['./adminbookinginfo.component.scss'],
})
export class AdminbookinginfoComponent implements OnInit {
    first_name: string;
    last_name: string;
    item: Object;
    people: number;

    constructor(
        public dialogRef: MatDialogRef<AdminbookinginfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object
    ) {}

    ngOnInit() {}

    confirm() {
        // Check if not all fields are filled.
        if (!this.first_name || !this.last_name || !this.item || !this.people) {
            return;
        }

        this.dialogRef.close({
            first_name: this.first_name,
            last_name: this.last_name,
            item: this.item,
            people: this.people,
        });
    }
}
