import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import * as dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
import { CurrencyPipe } from '@angular/common';
import { MiscService } from 'src/app/services/misc.service';

interface Data {
    facility: Object;
    date: Date;
}

@Component({
    selector: 'app-addcashdeposit',
    templateUrl: './addcashdeposit.component.html',
    styleUrls: ['./addcashdeposit.component.scss'],
})
export class AddCashDepositComponent implements OnInit {
    // Other variables.
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    formattedDate: string;
    loading: boolean = false;

    // Revenue variables.
    hasOtherRevenue: boolean = false;
    otherRevenueNames: string[] = [];
    otherRevenue: { [key: string]: string } = {};

    // Deposit variables.
    activeDrawers: Object[];
    activeSquareDrawers: Object[];
    selectedDrawers: Object[] = [];
    selectedSquareDrawers: Object[] = [];
    bankOS: string = '0';
    depositAmount: string = '';
    slipID: string = '';

    constructor(
        public dialogRef: MatDialogRef<AddCashDepositComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _snackbar: SnackbarService,
        private _http: HttpService,
        private _cp: CurrencyPipe,
        private _misc: MiscService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        // Get the other revenue.
        if (this.data.facility['entry_fields']) {
            this.hasOtherRevenue = true;
            this.otherRevenueNames = cloneDeep(
                this.data.facility['entry_fields']
            );
            for (let i = 0; i < this.otherRevenueNames.length; i++) {
                const otherRevenue = this.otherRevenueNames[i];
                this.otherRevenue[otherRevenue] = '0';
            }
        }

        // Set the formatted date and fetch the drawers initially.
        this.formattedDate = dayjs(this.data.date).format('YYYY-MM-DD');
        this.fetchDrawers();
    }

    get drawerState() {
        // If loading.
        if (!this.activeDrawers || !this.activeSquareDrawers) return -1;

        // If no drawers.
        if (
            this.activeDrawers.length < 1 &&
            this.activeSquareDrawers.length < 1
        )
            return 0;

        // If drawers.
        return 1;
    }

    get cashExpected() {
        let value: number = 0;

        // Add the drawer cash actuals.
        value += this.selectedDrawers.reduce(
            (prev, curr) => prev + curr['cash_actual'],
            0
        );

        // Add the square drawer cash actuals.
        value += this.selectedSquareDrawers.reduce(
            (prev, curr) => prev + curr['cash_actual'],
            0
        );

        // Add all of the other revenue.
        for (let i = 0; i < this.otherRevenueNames.length; i++) {
            const revenue = this.otherRevenueNames[i];
            const evaluated = +this.otherRevenue[revenue];
            value += Number.isNaN(evaluated) ? 0 : evaluated;
        }

        // Add the bank o/s.
        const evaluated = +this.bankOS;
        value += Number.isNaN(evaluated) ? 0 : evaluated;

        // Round.
        return this._cp.transform(value, 'CAD', '', '1.2-2').replace(/,/g, '');
    }

    async fetchDrawers() {
        // Set drawers to null.
        this.activeDrawers = null;
        this.activeSquareDrawers = null;

        // Call the API.
        const resp = await this._http.getLocal(
            `admin/daily-entry/drawers/?facility=${this.data.facility['abbr']}&date=${this.formattedDate}`
        );

        // Check for error.
        if (resp.status !== 200 || !resp.body['success']) {
            this.activeDrawers = [];
            this.activeSquareDrawers = [];
            return;
        }

        this.activeDrawers = resp.body['drawers'];
        this.activeSquareDrawers = resp.body['squareDrawers'];
    }

    valid() {
        // If no drawers.
        if (
            !this.selectedDrawers ||
            !this.selectedSquareDrawers ||
            this.selectedDrawers.length + this.selectedSquareDrawers.length < 1
        ) {
            return false;
        }

        // If no slip ID given.
        if (!this.slipID) return false;

        // Make sure all the inputted amounts match regex.
        if (!this._misc.currencyRegex.test(this.bankOS)) return false;
        if (!this._misc.currencyRegex.test(this.depositAmount)) return false;
        for (let i = 0; i < this.otherRevenueNames.length; i++) {
            const revenue = this.otherRevenueNames[i];
            if (!this._misc.currencyRegex.test(this.otherRevenue[revenue])) {
                return false;
            }
        }

        // If cash expected does not match deposit amount.
        if (+this.cashExpected !== +this.depositAmount) return false;

        // Else, return true.
        return true;
    }

    selectAllDrawers() {
        this.selectedDrawers = [...this.activeDrawers];
        this.selectedSquareDrawers = [...this.activeSquareDrawers];
    }

    deselectAllDrawers() {
        this.selectedDrawers = [];
        this.selectedSquareDrawers = [];
    }

    async save() {
        // If not valid.
        if (!this.valid()) return;

        this.loading = true;

        // Call the API.
        const resp = await this._http.postLocal('admin/daily-entry/deposits/', {
            slip_id: this.slipID,
            date: this.formattedDate,
            facility: this.data.facility['abbr'],
            bank_os: this.bankOS,
            other_revenue: this.otherRevenue,
            deposit_amount: this.depositAmount,
            drawers: this.selectedDrawers.map((d) => d['id']),
            squareDrawers: this.selectedSquareDrawers.map((d) => d['id']),
        });

        this.loading = false;

        // If error.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'An error has occurred while attempting to save the deposit.'
            );
            return;
        }

        // If success, close the dialog with the deposit.
        this.dialogRef.close(resp.body);
    }
}
