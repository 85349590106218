import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { createCustomElement } from '@angular/elements';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AdminsidenavComponent } from './adminsidenav/adminsidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { HrsidenavComponent } from './hrsidenav/hrsidenav.component';
import { LoadingComponent } from './loading/loading.component';
import { GoogleAutocompleteComponent } from './google-autocomplete/google-autocomplete.component';
import { PartnersmanagesidenavComponent } from './partnersmanagesidenav/partnersmanagesidenav.component';
import { VoucherproductsbaseComponent } from './voucherproductsbase/voucherproductsbase.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PartnersidenavComponent } from './partnersidenav/partnersidenav.component';
import { CrmsidenavComponent } from './crmsidenav/crmsidenav.component';
import { PossidenavComponent } from './possidenav/possidenav.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CustomdirectivesModule } from '../customdirectives/customdirectives.module';
import { SoftkeyboardComponent } from './softkeyboard/softkeyboard.component';
import { StoregroupsidenavComponent } from './storegroupssidenav/storegroupssidenav.component';

@NgModule({
    declarations: [
        NavbarComponent,
        AdminsidenavComponent,
        HrsidenavComponent,
        LoadingComponent,
        GoogleAutocompleteComponent,
        PartnersmanagesidenavComponent,
        VoucherproductsbaseComponent,
        PartnersidenavComponent,
        CrmsidenavComponent,
        PossidenavComponent,
        SoftkeyboardComponent,
        StoregroupsidenavComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        RouterModule,
        MatSidenavModule,
        MatButtonModule,
        MatExpansionModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatListModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatBadgeModule,
        CustomdirectivesModule,
    ],
    exports: [
        NavbarComponent,
        AdminsidenavComponent,
        HrsidenavComponent,
        LoadingComponent,
        GoogleAutocompleteComponent,
        PartnersmanagesidenavComponent,
        VoucherproductsbaseComponent,
        PartnersidenavComponent,
        CrmsidenavComponent,
        PossidenavComponent,
        SoftkeyboardComponent,
        StoregroupsidenavComponent,
    ],
})
export class CustomelementsModule {
    constructor(private injector: Injector) {
        const navbar = createCustomElement(NavbarComponent, { injector });
        const adminSidenav = createCustomElement(AdminsidenavComponent, {
            injector,
        });
        const hrSidenav = createCustomElement(HrsidenavComponent, {
            injector,
        });
        const loading = createCustomElement(LoadingComponent, { injector });
        const googleAutocomplete = createCustomElement(
            GoogleAutocompleteComponent,
            { injector }
        );
        const partnersManageSidenav = createCustomElement(
            PartnersmanagesidenavComponent,
            { injector }
        );
        const voucherProductsBase = createCustomElement(
            VoucherproductsbaseComponent,
            { injector }
        );
        const partnerSidenav = createCustomElement(PartnersidenavComponent, {
            injector,
        });
        const crmSidenav = createCustomElement(CrmsidenavComponent, {
            injector,
        });
        const posSidenav = createCustomElement(PossidenavComponent, {
            injector,
        });
        const storeGroupSidenav = createCustomElement(
            StoregroupsidenavComponent,
            {
                injector,
            }
        );
        const softKeyboard = createCustomElement(SoftkeyboardComponent, {
            injector,
        });

        /* For some reason, you have to define with a different name
        than the actual components selector.
        This prevents firing of ngOnInit twice. */
        try {
            customElements.define('custom-navv', navbar);
            customElements.define('admin-sidenavv', adminSidenav);
            customElements.define('hr-sidenavv', hrSidenav);
            customElements.define('custom-loading', loading);
            customElements.define('google-autocompletee', googleAutocomplete);
            customElements.define(
                'partners-manage-sidenavv',
                partnersManageSidenav
            );
            customElements.define('voucherproductsbasee', voucherProductsBase);
            customElements.define('partner-sidenavv', partnerSidenav);
            customElements.define('crm-sidenavv', crmSidenav);
            customElements.define('pos-sidenavv', posSidenav);
            customElements.define('storegroup-sidenavv', storeGroupSidenav);
            customElements.define('soft-keyboardd', softKeyboard);
        } catch (_) {}
    }
}
