<h1 mat-dialog-title>Confirm Delete</h1>
<div mat-dialog-content>
    <p>
        Are you sure you want to delete this template?
        {{
            data['changedFacilities'].length == 1
                ? 'There is 1 facility'
                : 'There are ' +
                  data['changedFacilities'].length +
                  ' facilities '
        }}
        using this template and
        {{
            data['changedProducts'].length == 1
                ? '1 product'
                : data['changedProducts'].length + ' products '
        }}
        using this template.
    </p>
    <p>
        This means that you
        {{
            !data['changedFacilities'].length && !data['changedProducts'].length
                ? 'do not'
                : ''
        }}
        need to select a new template for the facilities and products using this
        one.
    </p>
</div>
<div mat-dialog-actions class="fr">
    <mat-slide-toggle
        [(ngModel)]="deleteFromServer"
        color="primary"
        class="margin-15"
    >
        Delete File on Server?
    </mat-slide-toggle>
    <mat-form-field
        *ngIf="
            data['changedFacilities'].length || data['changedFacilities'].length
        "
    >
        <mat-select
            placeholder="New Template"
            [(ngModel)]="newTemplate"
            required
        >
            <mat-option
                *ngFor="let template of data['templates']"
                [value]="template['id']"
            >
                {{ template['file'] }} ({{ template['id'] }})
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button (click)="confirm()" color="primary" class="btn-1">
        Confirm
    </button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">
        Cancel
    </button>
</div>
