import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import * as JsBarcode from 'jsbarcode';

@Component({
    selector: 'app-staff-pass',
    templateUrl: './staff-pass.component.html',
    styleUrls: ['./staff-pass.component.scss'],
})
export class StaffPassComponent implements OnInit {
    // Other variables.
    loading: boolean = false;
    photo: string;

    // Resolve variables.
    user: Object;
    employee: Object;

    // Getters.
    get barcode() {
        return `EMP-MFG-${this.employee['id']}`;
    }

    constructor(
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _router: Router,
        private _cdr: ChangeDetectorRef
    ) {
        this.user = this._route.snapshot.data.user;
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Staff Pass - FluidDynamics');

        // If no employee for the user.
        if (!this.user['emp_id']) {
            this._router.navigate(['/', 'account']);
            return;
        }

        this.loading = true;

        // Get the employee for the user.
        let resp: Object = (
            await this._http.getLocal(`hr/employees/?id=${this.user['emp_id']}`)
        ).body;

        // Check if this employee does not exist.
        if (resp.hasOwnProperty('success') && !resp['success']) {
            this._router.navigate(['/', 'account']);
            return;
        }

        this.employee = resp;

        // If the employee does not have a photo.
        if (!this.employee['photo']) {
            this._router.navigate(['/', 'account']);
        } else {
            this.photo = this.employee['photo'];
        }

        this._cdr.detectChanges();

        // Init barcode.
        JsBarcode('#staff-pass-barcode').init();

        this.loading = false;
    }
}
