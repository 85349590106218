import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-sacoaaddremovetickets',
    templateUrl: './sacoaaddremovetickets.component.html',
    styleUrls: ['./sacoaaddremovetickets.component.scss'],
})
export class SacoaaddremoveticketsComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string;
    amount: string;

    constructor(
        public dialogRef: MatDialogRef<SacoaaddremoveticketsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    async addOrRemove() {
        // Check if the card is set.
        if (!this.card || !/^[0-9]{6,8}$/.test(this.card)) return;

        // Check if the amount is set.
        if (!this.amount || !/^-?[^-0][0-9]+$/.test(this.amount)) return;

        this.loading = true;

        // Add or remove the amount.
        const resp = await this._http.postLocal('pos/sacoa/add-tickets/', {
            card: this.card,
            amount: this.amount,
            posId: this.data['posId'],
        });

        // If not 200. Bad client request.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'A client error has occurred. Make sure the card number is valid.'
            );
            this.loading = false;
            return;
        }

        // If not successful, just stop the loading, show snackbar, and return.
        if (resp.body.hasOwnProperty('success') && !resp.body['success']) {
            this._snackbar.durationSnackbar(
                `The following Sacoa error has occurred: ${resp.body['response']}.`,
                null,
                10000
            );
            this.loading = false;
            return;
        }

        // If successful, clear inputs and stop loading and show snackbar.
        this._snackbar.defaultSnackbar(
            'Successfully added/removed tickets from the card.'
        );
        this.card = '';
        this.amount = '';
        this.loading = false;
    }
}
