import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
    selector: 'app-manageshipping',
    templateUrl: './manageshipping.component.html',
    styleUrls: ['./manageshipping.component.scss'],
})
export class ManageshippingComponent implements OnInit {
    loading: boolean = false;
    changes: boolean = false;
    editingAddress: boolean = false;
    newAddress: Object;

    constructor(
        public dialogRef: MatDialogRef<ManageshippingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService,
        private _http: HttpService
    ) {}

    ngOnInit() {
        this.data['transaction']['vouchers'] = this.data['transaction'][
            'vouchers'
        ].map((v) => {
            return { ...v, checked: false, gc: null, gca: '0' };
        });
    }

    toggleEditAddress() {
        this.editingAddress = !this.editingAddress;
        if (this.editingAddress) {
            this.newAddress = {
                o_id: this.data['transaction']['o_id'],
                address1: this.data['transaction']['shipping_address1'],
                address2: this.data['transaction']['shipping_address2'],
                city: this.data['transaction']['shipping_city'],
                province: this.data['transaction']['shipping_province'],
                postal_code: this.data['transaction']['shipping_postal_code'],
                country: this.data['transaction']['shipping_country'],
            };
        } else {
            this.newAddress = undefined;
        }
    }

    async changeAddress() {
        // If not editing or not newAddress.
        if (!this.editingAddress || !this.newAddress) return;

        this.loading = true;

        // Send to the API.
        const resp = await this._http.putLocal(
            'admin/transactions/shipping/',
            this.newAddress
        );

        // Based on response code show snackbar.
        if (resp.status === 204) {
            // Set the transaction address to the new one.
            this.data['transaction']['shipping_address1'] = this.newAddress[
                'address1'
            ];
            this.data['transaction']['shipping_address2'] = this.newAddress[
                'address2'
            ];
            this.data['transaction']['shipping_city'] = this.newAddress['city'];
            this.data['transaction']['shipping_province'] = this.newAddress[
                'province'
            ];
            this.data['transaction']['shipping_postal_code'] = this.newAddress[
                'postal_code'
            ];
            this.data['transaction']['shipping_country'] = this.newAddress[
                'country'
            ];

            this.toggleEditAddress();
            this._snackbar.defaultSnackbar('Successfully edited the address.');
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to edit the address. Did the address get edited prior to saving?'
            );
        }

        this.loading = false;
    }

    async confirm() {
        // Get the selected vouchers.
        const selected: Object[] = this.data['transaction']['vouchers'].filter(
            (v) => v['checked']
        );
        if (selected.length < 1) {
            this._snackbar.defaultSnackbar(
                'Cannot confirm with no items selected.'
            );
            return;
        }

        // If there are selected gift card vouchers without the gift card input filled.
        for (let i = 0; i < selected.length; i++) {
            // If not a gift card, just continue.
            if (selected[i]['giftcard'] === '0') continue;

            // If a gift card, check.
            if (
                !selected[i]['gc'] ||
                !/^GC[0-9]{12}$/.test(selected[i]['gc'])
            ) {
                this._snackbar.defaultSnackbar(
                    'There is a gift card item selected without a correct gift card in the input. The gift card input should be in the format "GC000000000000".'
                );
                return;
            }

            // Check for gift card amount.
            if (
                !/^(?:[1-9][0-9]*?|0)(?:\.[0-9]{1,2})?$/.test(
                    selected[i]['gca']
                )
            ) {
                this._snackbar.defaultSnackbar(
                    'Invalid amount for the gift card. Must be a number with optionally up to 2 decimal places. Examples: "10" and "1.34".'
                );
                return;
            }
        }

        // Ask if they are sure they want to dispatch.
        const dialogRef = this._dialog.open(ConfirmComponent, {
            data: {
                title: 'Confirm Dispatch',
                content: `Are you sure you want to dispatch ${
                    selected.length
                } item${
                    selected.length === 1 ? '' : 's'
                }? This will activate all gift cards associated with the selected items to dispatch. Note, the customer will also get an email notifying them of the newly dispatched items.`,
            },
        });

        // If not confirmed.
        if (!(await dialogRef.afterClosed().toPromise())) return;

        this.loading = true;

        // Call the API for dispatching.
        const resp = await this._http.postLocal(
            'admin/transactions/shipping/',
            {
                transaction: this.data['transaction'],
                items: selected.map((v) => {
                    return {
                        v_id: v['v_id'],
                        giftcard: v['gc'],
                    };
                }),
            }
        );

        // If not 200.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar('An unexpected error has occurred.');
            this.loading = false;
            return;
        }

        // Loop through the vouchers and call the API for recharging the gift card.
        this.changes = true;
        let success: number = 0;
        let errors: number = 0;
        for (let i = 0; i < selected.length; i++) {
            // If not a gift card.
            if (!selected[i]['gc']) continue;

            // Call the recharge API.
            const resp = await this._http.postLocal(
                'admin/giftcards/charge-recharge/',
                {
                    barcode: selected[i]['gc'],
                    amount: selected[i]['gca'],
                    type: 'recharge',
                }
            );

            // Add to errors/success.
            if (resp.status === 201) {
                success++;
            } else {
                errors++;
            }
        }

        // Get the message.
        let message = 'Successfully dispatched.';
        if (errors > 0 || success > 0) {
            message += ` Also, successfully recharged ${success} gift card${
                success === 0 ? '' : 's'
            } and failed recharging ${errors} gift card${
                errors === 0 ? '' : 's'
            }.`;
        }
        this._snackbar.defaultSnackbar(message);

        this.loading = false;
        this.dialogRef.close(true);
    }

    printShippingLabel() {
        (document.location as any) = `mfg://?shipping-label=${this.data['transaction']['o_id']}&printer=DYMO LabelWriter 450`;
    }
}
