import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-sacoaconsolidate',
    templateUrl: './sacoaconsolidate.component.html',
    styleUrls: ['./sacoaconsolidate.component.scss'],
})
export class SacoaconsolidateComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string = '';
    targetCard: string;
    cards: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<SacoaconsolidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    addCard() {
        // If card is not set.
        if (!this.card || !/^[0-9]{6,8}$/.test(this.card)) return;

        // If there is no target card, set that.
        if (!this.targetCard) {
            this.targetCard = this.card;
            this.card = '';
            return;
        }

        // If adding to cards check if already in cards or already at max cards (4).
        if (this.cards.includes(this.card) || this.cards.length >= 4) return;

        // Add the card.
        this.cards.push(this.card);
        this.card = '';
    }

    removeCard(card: string) {
        // If in cards.
        if (this.cards.includes(card)) {
            const index = this.cards.findIndex((c) => c === card);
            if (index > -1) {
                this.cards.splice(index, 1);
            }
        }

        // If target card.
        if (this.targetCard === card) this.targetCard = '';
    }

    async consolidate() {
        // Make sure there are cards to consolidate and target is set.
        if (this.cards.length < 1 || !this.targetCard) return;

        this.loading = true;

        // Send the cards to the backend to consolidate.
        const resp = await this._http.postLocal('pos/sacoa/consolidate/', {
            card: this.targetCard,
            sourceCards: this.cards,
            posId: this.data['posId'],
        });

        // If error.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'An unexpected error has occurred while attempting to consolidate cards.'
            );
            this.loading = false;
            return;
        }

        // If not 200. Bad client request.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'A client error has occurred. Make sure the card numbers are valid.'
            );
            this.loading = false;
            return;
        }

        // If not successful, just stop the loading, show snackbar, and return.
        if (resp.body.hasOwnProperty('success') && !resp.body['success']) {
            this._snackbar.durationSnackbar(
                `The following Sacoa error has occurred: ${resp.body['response']}.`,
                null,
                10000
            );
            this.loading = false;
            return;
        }

        // If successful, clear inputs and stop loading and show snackbar.
        this._snackbar.defaultSnackbar(
            'Successfully consolidated card(s) to new card.'
        );
        this.card = '';
        this.targetCard = '';
        this.cards = [];
        this.loading = false;
    }
}
