import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    // Resolve variables.
    user: Object;

    constructor(private _route: ActivatedRoute, private _title: Title) {
        this.user = this._route.snapshot.data.user;
    }

    ngOnInit() {
        // Set the title.
        this._title.setTitle('Help - FluidDynamics');
    }
}
