import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../services/http.service';
import { TranslateService } from '../services/translate.service';

type State =
    | 'initialization'
    | 'selectFacilityLocation'
    | 'welcome'
    | 'pleaseWait'
    | 'printReceipt'
    | 'thankYou'
    | 'error'
    | 'connectionError'
    | 'msg400';

interface locations {
    id: string;
    name: string;
    facility: string;
    facility_name: string;
}

@Component({
    selector: 'app-parks-kiosk',
    templateUrl: './parks-kiosk.component.html',
    styleUrls: ['./parks-kiosk.component.scss'],
})
export class ParksKioskComponent implements OnInit {
    // Resolve variables.

    device: Object;

    readonly localStorageKey: string = 'fluiddynamics_parks_kiosk_location';

    //parksFacilities stores all unique Facility names
    parksFacilities = [];

    //These variables stores selected facility and location's name
    selectedFacility: string = '';
    selectedLocation: locations;

    //after selecting facility, these variables helps to store filtered location of the selected facility
    parksLocation: locations[] = [];
    filteredLocation: locations[] = [];

    //initialKeypress stores each key that was pressed
    initialKeypress: string = '';

    //finalKeypress stores all the keypresses all together so it can be sent to the database
    finalKeypress: string = '';

    errorMsg: string = '';

    //The application will start with the initial state
    state: State = 'initialization';

    online: boolean = true;
    isConnected = true;

    constructor(
        private _title: Title,
        private _route: ActivatedRoute,
        private _http: HttpService,
        public _translate: TranslateService
    ) {
        this.device = this._route.snapshot.data.device;
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Self Serve Kiosk - FluidDynamics');

        this.initializingApp();
    }

    async initializingApp() {
        this.state = 'initialization';
        await this._translate.setLang('fr');
        await this._translate.setLang('en');

        const storedLocation = localStorage.getItem(this.localStorageKey);

        if (storedLocation) {
            const response = await this._http.getLocal(
                `parks-canada/locations/?id=${storedLocation}`
            );
            if (!response.body.hasOwnProperty('success')) {
                this.selectedLocation = response.body as locations;
                this.state = 'welcome';
                return;
            }
        }

        //Getting data from API
        const init = await this._http.getLocal(`parks-canada/locations/`);

        const locations = init.body as locations[];

        this.parksLocation = locations;
        this.state = 'selectFacilityLocation';

        //going through the objects that was fetched from API
        this.parksFacilities = this.parksLocation.reduce<string[]>(
            (prev, curr) => {
                //Storing unique facility_name to parksFacilities

                if (!prev.includes(curr.facility_name)) {
                    prev.push(curr.facility_name);
                }
                return prev;
            },
            []
        );

        // for (let i = 0; i < locations.length; i++) {
        //     const element = locations[i];

        //     //Storing unique facility_name to parksFacilities
        //     if (!this.parksFacilities.includes(element.facility_name)) {
        //         this.parksFacilities.push(element.facility_name);
        //     }
        // }
    }

    filterLocation() {
        //Resetting the filtered location
        this.filteredLocation = [];

        //This loop is going through the parksLocation object array
        // and filtering specific Objects if selectedFacility and facility_name  are same

        this.filteredLocation = this.parksLocation.filter(
            (location) => location.facility_name === this.selectedFacility
        );

        /* Following code works exactly same thing like above the filter method using for loop */

        // for (let i = 0; i < this.parksLocation.length; i++) {
        //     const location = this.parksLocation[i];
        //     if (location.facility_name === this.selectedFacility) {
        //         this.filteredLocation.push(location);
        //     }
        // }
    }

    startApp() {
        localStorage.setItem(this.localStorageKey, this.selectedLocation.id);
        this.state = 'welcome';
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyBoardEvent(event: KeyboardEvent) {
        if (this.state !== 'welcome') {
            return;
        }
        this.initialKeypress = event.key;

        if (this.initialKeypress === 'Enter') {
            if (this.finalKeypress) {
                this.processRequest();
            }

            event.preventDefault();
            return;
        }
        if (this.initialKeypress === 'Backspace') {
            this.finalKeypress = this.finalKeypress.substring(
                0,
                this.finalKeypress.length - 1
            );
            return;
        }
        this.finalKeypress += this.initialKeypress;
    }

    async processRequest() {
        this.state = 'pleaseWait';
        const currentTime = +new Date();

        const response = await this._http.postLocal('parks-canada/scan/', {
            scan: this.finalKeypress,
            location: this.selectedLocation.id,
            noVouchers: true,
        });

        const endTime = +new Date();
        const milliseconds = endTime - currentTime;

        const timeOut = milliseconds > 750 ? 0 : 750 - milliseconds;
        setTimeout(() => {
            if (response.status === 400) {
                this.errorMsg = 'This station is CLOSED for maintenance';
                this.state = 'msg400';
                return;
            }
            if (response.body['success']) {
                this.printReceipt();
                return;
            }

            switch (response.body['response']) {
                case 'INEXISTENT_LOCATION':
                    this.state = 'msg400';
                    return;

                case 'INEXISTENT_VOUCHER':
                    this.errorMsgState(
                        'The vouchers that has been scanned does not exist.'
                    );
                    break;
                case 'UPDATE_ERROR':
                    this.errorMsgState('Server error. Please try again.');
                    break;

                default:
                    this.errorMsgState(response.body['response']);
            }
        }, timeOut);
    }

    errorMsgState(msg: string) {
        this.state = 'error';
        this.errorMsg = msg;
        this.welcomeState();
    }

    printReceipt() {
        this.state = 'printReceipt';
        (window.location as any) = `mfg://?parks-parking-pass={"lang":"${this._translate.currentLang}", "facility":"${this.selectedLocation.facility_name}"}&printer=Star Micronics`;
        this.thankYouState();
    }

    thankYouState() {
        setTimeout(() => {
            this.state = 'thankYou';
            this.welcomeState();
        }, 2000);
    }

    welcomeState() {
        setTimeout(() => {
            this.state = 'welcome';
            this.finalKeypress = '';
        }, 6000);
    }

    @HostListener('window:online')
    onlineHandler() {
        this.online = true;
    }
    @HostListener('window:offline')
    offlineHander() {
        this.online = false;
    }
}
