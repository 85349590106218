import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from 'src/app/services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'crm-sidenav',
    templateUrl: './crmsidenav.component.html',
    styleUrls: [
        './crmsidenav.component.scss',
        '../customelements.component.scss'
    ]
})
export class CrmsidenavComponent implements OnInit {
    @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

    constructor(
        public _loading: LoadingService,
        private _sidenav: SidenavService
    ) {}

    ngOnInit() {
        this._sidenav.setSidenav(this.sidenav);
    }
}
