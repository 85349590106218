import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

export interface PurchaseCreditsCard {
    number: string;
    credits: number;
    bonusCredits: number;
    loading: boolean;
    status?: string;
}

@Component({
    selector: 'app-sacoapurchasecredits',
    templateUrl: './sacoapurchasecredits.component.html',
    styleUrls: ['./sacoapurchasecredits.component.scss'],
})
export class SacoapurchasecreditsComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string = '';
    cards: PurchaseCreditsCard[] = [];

    constructor(
        public dialogRef: MatDialogRef<SacoapurchasecreditsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    get canContinue() {
        return (
            this.cards.length > 0 &&
            this.cards.every((c) => c.loading === false)
        );
    }

    addCard() {
        // If card is not set or already in cards.
        if (
            !this.card ||
            this.hasCard(this.card) ||
            !/^[0-9]{6,8}$/.test(this.card)
        ) {
            return;
        }

        // Add the card.
        const card = {
            number: this.card,
            credits: 0,
            bonusCredits: 0,
            loading: true,
        };
        this.cards.push(card);
        this.card = '';
        this.spreadCredits();

        // Fetch the card status from the database.
        this.getCardStatus(card);
    }

    removeCard(card: PurchaseCreditsCard) {
        // If in cards, remove.
        const index = this.cards.findIndex((c) => c.number === card.number);
        if (index > -1) this.cards.splice(index, 1);

        this.spreadCredits();
    }

    hasCard(card: string) {
        return this.cards.findIndex((c) => c.number === card) > 0;
    }

    spreadCredits() {
        const spread = (type: 'credits' | 'bonusCredits') => {
            const base = Math.floor(this.data[type] / this.cards.length);
            const extra = this.data[type] % this.cards.length;
            for (let i = 0; i < this.cards.length; i++) {
                this.cards[i][type] = base + (i < extra ? 1 : 0);
            }
        };

        spread('credits');
        spread('bonusCredits');
    }

    taken(type: 'credits' | 'bonusCredits') {
        return this.cards.reduce((prev, curr) => prev + curr[type], 0);
    }

    async getCardStatus(card: PurchaseCreditsCard) {
        // Call the balance API.
        const resp = await this._http.getLocal(
            `pos/sacoa/balance/?posId=${this.data['posId']}&card=${card.number}`
        );

        // If not 200. Bad client request.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                `An error has occurred while attempting to get the status of the card: ${card.number}. Make sure the card number is valid.`
            );
            this.removeCard(card);
            return;
        }

        // If 200 but not successful.
        if (resp.body.hasOwnProperty('success') && !resp.body['success']) {
            this._snackbar.durationSnackbar(
                `The following Sacoa error has occurred: ${resp.body['response']}.`,
                null,
                10000
            );
            this.removeCard(card);
            return;
        }

        card.status = resp.body['body']['status'];
        card.loading = false;
    }

    continue() {
        this.spreadCredits();
        this.dialogRef.close(this.cards);
    }
}
