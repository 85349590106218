import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';

/**
 * This service contains easy-to-use methods that will
 * help other components in sending HTTP requests to a server.
 * Along with providing wrapped [HttpClient](https://angular.io/api/common/http/HttpClient)
 * methods that return a promise instead of an Observable, this service also
 * contains methods that make it even easier to send local API calls by providing
 * just the endpoint.
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    API_URL: string = 'https://api.fluiddynamics.ca';
    API_VER: string = 'v1';
    EPSON_URL: string = '';

    constructor(private _http: HttpClient, private _router: Router) {
        this.EPSON_URL = `${this.API_URL}/${this.API_VER}/epson`;
    }

    private async isForbidden(resp: Promise<HttpResponse<Object>>) {
        let awaitedResp = await resp;
        if (awaitedResp.status === 403) {
            if (!this._router.url.match(/\/?magic.+/)) {
                this.redirectHome(true, this._router.url);
            } else {
                this.redirectHomeMagic(true, this._router.url);
            }
        }
    }

    /**
     * Performs a [`GET` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#get) and turns it into a promise.
     *
     *
     * @param url The url to perform the get request on.
     * @param options The optional options to send to the get request.
     * @returns A promise containing the information received.
     *
     */
    get(url: string, options?: Object): Promise<Object> {
        // Set the response option.
        if (!options) {
            options = { observe: 'response' };
        } else {
            options['observe'] = 'response';
        }

        return <Promise<HttpResponse<Object>>>this._http
            .get(url, options)
            .toPromise()
            .catch((t: HttpErrorResponse) => {
                return new HttpResponse({ status: t.status });
            });
    }

    /**
     * Performs a [`POST` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#post) and turns it into a promise.
     *
     *
     * @param url The url to perform the post request on.
     * @param body The optional POST request data to send to the server.
     * @param options The optional options to send to the post request.
     * @returns A promise containing the information received.
     *
     */
    post(url: string, body?: Object, options?: Object): Promise<Object> {
        // Set the response option.
        if (!options) {
            options = { observe: 'response' };
        } else {
            options['observe'] = 'response';
        }

        return <Promise<HttpResponse<Object>>>this._http
            .post(url, body, options)
            .toPromise()
            .catch((t: HttpErrorResponse) => {
                return new HttpResponse({ status: t.status });
            });
    }

    /**
     * Performs a [`PUT` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#put) and turns it into a promise.
     *
     *
     * @param url The url to perform the put request on.
     * @param body The optional request data to send to the server.
     * @param options The optional options to send to the put request.
     * @returns A promise containing the information received.
     *
     */
    put(url: string, body?: Object, options?: Object): Promise<Object> {
        // Set the response option.
        if (!options) {
            options = { observe: 'response' };
        } else {
            options['observe'] = 'response';
        }

        return <Promise<HttpResponse<Object>>>this._http
            .put(url, body, options)
            .toPromise()
            .catch((t: HttpErrorResponse) => {
                return new HttpResponse({ status: t.status });
            });
    }

    /**
     * Performs a [`DELETE` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#delete) and turns it into a promise.
     *
     *
     * @param url The url to perform the delete request on.
     * @param options The optional options to send to the delete request.
     * @returns A promise containing the information received.
     *
     */
    delete(url: string, options?: Object): Promise<Object> {
        // Set the response option.
        if (!options) {
            options = { observe: 'response' };
        } else {
            options['observe'] = 'response';
        }

        return <Promise<HttpResponse<Object>>>this._http
            .delete(url, options)
            .toPromise()
            .catch((err: HttpErrorResponse) => {
                return new HttpResponse({ status: err.status });
            });
    }

    /**
     * Performs a [`PATCH` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#patch) and turns it into a promise.
     *
     *
     * @param url The url to perform the patch request on.
     * @param body The PATCH request data to send to the server.
     * @param options The optional options to send to the patch request.
     * @returns A promise containing the information received.
     *
     */
    patch(
        url: string,
        body: Object,
        options?: Object
    ): Promise<HttpResponse<Object>> {
        // Set the response option.
        if (!options) {
            options = { observe: 'response' };
        } else {
            options['observe'] = 'response';
        }

        return <Promise<HttpResponse<Object>>>this._http
            .patch(url, body, options)
            .toPromise()
            .catch((err: HttpErrorResponse) => {
                return new HttpResponse({ status: err.status });
            });
    }

    /**
     * Performs a local (api.fluiddynamics.ca) [`GET` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#get) and turns it into a promise.
     *
     *
     * @param endpoint The endpoint to request (example: `auth/session/`).
     * @param options The optional options to send to the get request.
     * @returns A promise containing the information received.
     *
     */
    getLocal(
        endpoint: string,
        options: Object = { withCredentials: true }
    ): Promise<HttpResponse<Object>> {
        let resp = <Promise<HttpResponse<Object>>>(
            this.get(`${this.API_URL}/${this.API_VER}/${endpoint}`, options)
        );

        this.isForbidden(resp);

        return resp;
    }

    /**
     * Performs a local (api.fluiddynamics.ca) [`POST` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#post) and turns it into a promise.
     *
     *
     * @param endpoint The endpoint to request (example: `auth/login/`).
     * @param body The optional POST request data to send to the server.
     * @param options The optional options to send to the post request.
     * @returns A promise containing the information received.
     *
     */
    postLocal(
        endpoint: string,
        body?: Object,
        options: Object = {
            withCredentials: true,
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
    ): Promise<HttpResponse<Object>> {
        let resp = <Promise<HttpResponse<Object>>>(
            this.post(
                `${this.API_URL}/${this.API_VER}/${endpoint}`,
                body,
                options
            )
        );

        this.isForbidden(resp);

        return resp;
    }

    /**
     * Performs a local (api.fluiddynamics.ca) [`PUT` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#put) and turns it into a promise.
     *
     *
     * @param endpoint The endpoint to request (example: `auth/login/`).
     * @param body The optional request data to send to the server.
     * @param options The optional options to send to the put request.
     * @returns A promise containing the information received.
     *
     */
    putLocal(
        endpoint: string,
        body?: Object,
        options: Object = {
            withCredentials: true,
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
    ): Promise<HttpResponse<Object>> {
        let resp = <Promise<HttpResponse<Object>>>(
            this.put(
                `${this.API_URL}/${this.API_VER}/${endpoint}`,
                body,
                options
            )
        );

        this.isForbidden(resp);

        return resp;
    }

    /**
     * Performs a local (api.fluiddynamics.ca) [`DELETE` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#delete) and turns it into a promise.
     *
     *
     * @param endpoint The endpoint to request (example: `auth/session/`).
     * @param options The optional options to send to the delete request.
     * @returns A promise containing the information received.
     *
     */
    deleteLocal(
        endpoint: string,
        options: Object = { withCredentials: true }
    ): Promise<HttpResponse<Object>> {
        let resp = <Promise<HttpResponse<Object>>>(
            this.delete(`${this.API_URL}/${this.API_VER}/${endpoint}`, options)
        );

        this.isForbidden(resp);

        return resp;
    }

    /**
     * Performs a local (api.fluiddynamics.ca) [`PATCH` request with Angular's HttpClient](https://angular.io/api/common/http/HttpClient#patch) and turns it into a promise.
     *
     *
     * @param endpoint The endpoint to request (example: `auth/login/`).
     * @param body The PATCH request data to send to the server.
     * @param options The optional options to send to the patch request.
     * @returns A promise containing the information received.
     *
     */
    patchLocal(
        endpoint: string,
        body: Object,
        options: Object = {
            withCredentials: true,
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
    ): Promise<HttpResponse<Object>> {
        let resp = <Promise<HttpResponse<Object>>>(
            this.patch(
                `${this.API_URL}/${this.API_VER}/${endpoint}`,
                body,
                options
            )
        );

        this.isForbidden(resp);

        return resp;
    }

    /**
     * This function is for redirecting the current user to their home page.
     * Depending on the value of redirectLogin, this can also redirect to the login
     * page if not logged in.
     *
     *
     * @param redirectLogin Determines whether this should also redirect to the login page if not logged in.
     * @param attemptedLocation An optional parameter that contains where the user was attempting to go if this was called on a failed route guard check.
     * @returns A `Promise` containing `void`.
     */
    async redirectHome(
        redirectLogin: boolean = true,
        attemptedLocation?: string
    ): Promise<void> {
        let session: Object = (await this.getLocal('auth/session/')).body;

        // If logged in, redirect home.
        if (!session.hasOwnProperty('success')) {
            let home = session['default_home'];

            // Check if POS user but no localStorage location.
            if (
                session['type'] === 'pos_user' &&
                !window.localStorage.getItem('fluiddynamics_location')
            ) {
                home = '/account';
            }

            // Navigate.
            await this._router.navigate([home]);
            return;
        }

        // If not logged in, redirect to login if needed.
        if (redirectLogin) {
            if (attemptedLocation) {
                sessionStorage.setItem('loginRedirect', attemptedLocation);
            }
            await this._router.navigate(['auth']);
        }
    }

    /**
     * This function is the exact same as the above function but for Magic Mountain logins.
     *
     *
     * @param redirectLogin Determines whether this should also redirect to the login page if not logged in.
     * @param attemptedLocation An optional parameter that contains where the user was attempting to go if this was called on a failed route guard check.
     * @returns A `Promise` containing `void`.
     */
    async redirectHomeMagic(
        redirectLogin: boolean = true,
        attemptedLocation?: string
    ): Promise<void> {
        let session: Object = (await this.getLocal('magic/auth/session/')).body;

        // If logged in, redirect home.
        if (!session.hasOwnProperty('success')) {
            await this._router.navigate([`magic/${session['default_home']}`]);
            return;
        }

        // If not logged in, redirect to login if needed.
        if (redirectLogin) {
            if (attemptedLocation) {
                sessionStorage.setItem('magicLoginRedirect', attemptedLocation);
            }
            await this._router.navigate(['magic/auth']);
        }
    }
}
