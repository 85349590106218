import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    product: Object;
    products: Object[];
    taxes: Object[];
    type: string;
    facilities?: Object[];
    locations?: Object[];
    categories?: Object[];
    categoryFacility?: Object;
    categoryLocation?: Object;
    category?: Object;
    sellAt?: Object[];
    bookingTypes?: Object[];
}

@Component({
    selector: 'app-editproduct',
    templateUrl: './editproduct.component.html',
    styleUrls: ['./editproduct.component.scss'],
})
export class EditproductComponent implements OnInit {
    // Dialog variables.
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;

    // Original variables.
    originalTaxes: number[];
    originalColour: string;
    originalProducts: Object[];

    // Button product variables.
    filteredLocations: Object[];
    filteredCategories: Object[];
    displayImages: boolean = false;

    // Suggested product variables.
    filteredProducts: Object[];
    filter: string = '';
    filterFacility: Object;

    @ViewChild('productForm', { static: true })
    productForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditproductComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService,
        private _cdr: ChangeDetectorRef
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());
    }

    ngOnInit() {
        this.originalTaxes = cloneDeep(this.data.product['taxes']);
        this.originalColour = this.data.product['button_colour'];
        this.filteredProducts = cloneDeep(this.data.products);

        // Set the text_colour if needed.
        if (this.data.type === 'button') {
            this.data.product['text_colour'] =
                this.data.product['text_colour'] === '#000000' ? true : false;
        }

        // Set the multiscan.
        this.data.product['multiscan'] =
            this.data.product['multiscan'] === '1' ||
            this.data.product['multiscan'] === true;

        // Set the create_pass.
        this.data.product['create_pass'] =
            this.data.product['create_pass'] === '1' ||
            this.data.product['create_pass'] === true;

        // Set the suggested products if needed.
        if (!this.data.product['suggested_products']) {
            this.data.product['suggested_products'] = [];
        } else {
            this.data.product['suggested_products'] = this.data.product[
                'suggested_products'
            ]
                .split(',')
                .map((product) =>
                    this.data.products.find((p) => p['id'] === product)
                );
        }

        this.originalProducts = cloneDeep(
            this.data.product['suggested_products']
        );

        this.facilityChange();
        this.locationChange();
    }

    facilityChange() {
        if (this.data.type === 'button') {
            this.filteredLocations = this.data.locations.filter(
                (location) =>
                    location['facility'] ===
                    this.data.categoryFacility['fluid_id']
            );
        }
    }

    locationChange() {
        if (this.data.type === 'button') {
            this.filteredCategories = this.data.categories.filter((category) =>
                this.data.categoryLocation['categories']
                    .split(',')
                    .includes(category['id'])
            );
        }
    }

    filterSuggestedProducts() {
        // Filter by the facility if needed.
        if (this.filterFacility) {
            let categories = [
                ...new Set(
                    Array.prototype.concat.apply(
                        [],
                        this.data.locations
                            .filter(
                                (location) =>
                                    location['facility'] ===
                                    this.filterFacility['fluid_id']
                            )
                            .map((location) =>
                                location['categories'].split(',')
                            )
                    )
                ),
            ];

            // Filter by the facility and the name.
            this.filteredProducts = this.data.products.filter(
                (product) =>
                    categories.includes(product['category']) &&
                    product['name']
                        .toLowerCase()
                        .includes(this.filter.toLowerCase())
            );
        } else {
            // Filter by the name.
            this.filteredProducts = this.data.products.filter((product) => {
                return product['name']
                    .toLowerCase()
                    .includes(this.filter.toLowerCase());
            });
        }
    }

    removeSuggestedProduct(product: Object) {
        this.data.product['suggested_products'].splice(
            this.data.product['suggested_products'].indexOf(product),
            1
        );
    }

    addSuggestedProduct() {
        if (!this.filter) return;

        // Check if the product exists.
        let product = this.filteredProducts.find(
            (product) => product === this.filter
        );

        // If it exists, add to suggested products.
        if (product) {
            this.data.product['suggested_products'].push(product);
            this.resetFilter();
        }
    }

    resetFilter() {
        // Set the filter back to nothing.
        this.filter = '';
        // Need this line for it to update correctly.
        (<HTMLInputElement>document.getElementById('filterChoices')).value = '';
        this.filterSuggestedProducts();

        this._cdr.markForCheck();
    }

    hasChanges() {
        return (
            this.productForm.dirty ||
            JSON.stringify(this.originalTaxes) !==
                JSON.stringify(this.data.product['taxes']) ||
            JSON.stringify(this.originalProducts) !==
                JSON.stringify(this.data.product['suggested_products']) ||
            (this.data.type === 'button' &&
                this.data.product['button_colour'] !== this.originalColour)
        );
    }

    async addButtonImage(file: File) {
        let base64 = await this._misc.fileToBase64(file).catch((e) => e);

        // Show snackbar based on if the above includes an error.
        if (base64 instanceof Error) {
            this._snackbar.defaultSnackbar(
                'An error occurred while uploading the file.'
            );
        } else {
            this.data.product['button_image'] = base64;
            this._snackbar.defaultSnackbar('Successfully uploaded the file.');
        }
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            // If it is a button product, set the category to the one chosen.
            if (this.data.type === 'button') {
                this.data.product['category'] = this.data.category['id'];
                this.data.product['valid_at'] = this.data.categoryFacility[
                    'fluid_id'
                ];
                this.data.product['text_colour'] = this.data.product[
                    'text_colour'
                ]
                    ? '#000000'
                    : '#ffffff';
            }

            this.dialogRef.close(this.data.product);
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
