<h1 mat-dialog-title>Choose Location</h1>
<div mat-dialog-content class="scrollbar">
    <mat-form-field class="w100">
        <mat-select placeholder="Location" [(ngModel)]="location">
            <mat-option
                *ngFor="let location of data.locations"
                [value]="location"
            >
                {{ location['name'] }} ({{ location['id'] }})
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="save()" color="primary">
        Save Choice
    </button>
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
