<mat-sidenav-container
    class="pos"
    [ngClass]="{
        'pos-queue-manager': queueManager,
        'pos-opened': opened
    }"
    [hasBackdrop]="true"
>
    <mat-sidenav
        #sidenav
        mode="side"
        color="accent"
        class="scrollbar-div pos-sidenav"
        (openedStart)="opened = true"
        (closedStart)="opened = false"
    >
        <div class="pos-sidenav-content">
            <div
                class="flex col m-0-10"
                *ngIf="location && location['sacoa'] === '1'"
            >
                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('sacoaDialog', 'consolidate')"
                    *ngIf="isPos"
                >
                    <mat-icon>call_merge</mat-icon> Consolidate Cards
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('sacoaDialog', 'transfer')"
                    *ngIf="isPos"
                >
                    <mat-icon>compare_arrows</mat-icon> Transfer
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('sacoaDialog', 'history/analyze')"
                    *ngIf="isPos"
                >
                    <mat-icon>info</mat-icon> History/Analyze
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('sacoaDialog', 'zero')"
                    *ngIf="isPos"
                >
                    <mat-icon>exposure_zero</mat-icon> Zero Cards
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('addRemoveTickets')"
                    *ngIf="isPos"
                >
                    <mat-icon>local_activity</mat-icon> Add/Remove Tickets
                </button>
            </div>

            <div class="flex col m-0-10">
                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    [routerLink]="'/pos'"
                >
                    <mat-icon>attach_money</mat-icon> Point of Sale
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    [routerLink]="'/pos/manage-passes'"
                >
                    <mat-icon>local_activity</mat-icon> Manage Passes
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    [routerLink]="'/pos/queue-manager'"
                >
                    <mat-icon>list</mat-icon> Queue Manager
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('viewBookings')"
                    *ngIf="isPos"
                >
                    <mat-icon>event</mat-icon> Bookings
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('openCashDrawer')"
                    *ngIf="isPos"
                >
                    <mat-icon class="flipped">open_in_browser</mat-icon> Open
                    Cash Drawer
                </button>
            </div>

            <div class="flex col m-0-10" *ngIf="isPos">
                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('refundSelected')"
                >
                    <mat-icon>arrow_back</mat-icon> Refund Selected
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('journal')"
                >
                    <mat-icon>menu_book</mat-icon> View Journal
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('discountSelected')"
                >
                    <mat-icon>local_offer</mat-icon> Discount Selected
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('giftCardBalance')"
                >
                    <mat-icon>card_giftcard</mat-icon> Gift Card Balance
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('printReport')"
                >
                    <mat-icon>print</mat-icon> Print Report
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('closeDrawer')"
                >
                    <mat-icon>open_in_browser</mat-icon> Close Drawer
                </button>
            </div>

            <div class="flex col m-0-10">
                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('refreshProducts')"
                    *ngIf="isPos"
                >
                    <mat-icon>refresh</mat-icon> Refresh Products
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('changeLocation')"
                    *ngIf="isPos"
                >
                    <mat-icon>my_location</mat-icon> Change Location
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('printEmployeePass')"
                    *ngIf="isPos"
                >
                    <mat-icon>print</mat-icon> Print Employee Pass
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    [routerLink]="'/account'"
                    *ngIf="!queueManager"
                >
                    <mat-icon>person</mat-icon> My Account
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('logout')"
                    *ngIf="isPos"
                >
                    <mat-icon>double_arrow</mat-icon> Logout
                </button>
            </div>

            <div class="flex col m-0-10" *ngIf="isPos && pos.cfsEnabled">
                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('cfsReFetchTemplates')"
                >
                    <mat-icon>refresh</mat-icon> Refresh CFS Templates
                </button>

                <button
                    class="dark-sidenav-text"
                    mat-raised-button
                    (click)="callFunction('cfsResetLocation')"
                >
                    <mat-icon>highlight_off</mat-icon> Reset CFS Location
                </button>
            </div>
        </div>

        <div class="flex center">
            <div class="w100" *ngIf="!queueManager">
                <h2 class="center">Your Theme</h2>
                <div class="w100 flex theme-pos-div">
                    <mat-icon
                        class="dark-mode-icon pointer select-none"
                        (click)="toggleTheme()"
                    >
                        {{ darkTheme ? 'brightness_3' : 'brightness_5' }}
                    </mat-icon>
                    <mat-slide-toggle
                        class="m-0-10"
                        [(ngModel)]="darkTheme"
                        (change)="toggleTheme(true)"
                        color="primary"
                    ></mat-slide-toggle>
                </div>
            </div>
            <div class="w100" *ngIf="isPos">
                <h2 class="center">Photos Enabled</h2>
                <div class="w100 flex theme-pos-div">
                    <mat-slide-toggle
                        class="m-0-10"
                        [(ngModel)]="pos.photosEnabled"
                        color="primary"
                    ></mat-slide-toggle>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
