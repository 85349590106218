import { Directive, HostListener, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[creditCardExpiry]',
})
export class CreditCardExpiryDirective implements AfterViewInit {
    constructor(public ngControl: NgControl) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value);
    }

    ngAfterViewInit() {
        // Timeout needed since the value was null.
        setTimeout(() => {
            this.onInputChange(this.ngControl.value);
        }, 100);
    }

    onInputChange(event: string) {
        if (!event) return;

        let newVal = event.replace(/[^\d\/]/g, '');

        // If more than 5, trim the end.
        newVal = newVal.slice(0, 5);

        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length > 2 && newVal[2] !== '/') {
            // If the value is more than 2 and theres no slash, add it.
            newVal = `${newVal.slice(0, 2)}/${newVal.slice(2)}`;
        }

        this.ngControl.valueAccessor.writeValue(newVal);
    }
}
