import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Data {
    passes: Object[];
}

@Component({
    selector: 'app-posscangroup',
    templateUrl: './posscangroup.component.html',
    styleUrls: ['./posscangroup.component.scss']
})
export class PosscangroupComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PosscangroupComponent>,
        public _misc: MiscService,
        public _sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {}

    save() {
        // Get the selected ones.
        let selected = this.data.passes.filter(
            pass => pass['selected'] && pass['valid']
        );

        // Remove the selected property.
        for (let i = 0; i < selected.length; i++) {
            delete selected[i]['selected'];
        }

        // Return the selected passes.
        this.dialogRef.close(selected);
    }
}
