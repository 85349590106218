import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    phoneNumber: Object;
    users: Object[];
    facilities: Object[];
}

@Component({
    selector: 'app-editphonenumber',
    templateUrl: './editphonenumber.component.html',
    styleUrls: ['./editphonenumber.component.scss'],
})
export class EditphonenumberComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    originalUsersNotified: Object[];

    // Phone number variables.
    usersNotified: Object[];
    usersNotifiedSearch: string = '';
    usersNotifiedFiltered: Object[];

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditphonenumberComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog,
        private _cdr: ChangeDetectorRef
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());

        // Store original users notified.
        this.originalUsersNotified = this.data.phoneNumber['users_notified'];
        this.usersNotified = cloneDeep(this.originalUsersNotified);
    }

    ngOnInit() {}

    filterUsersNotified() {
        this.usersNotifiedFiltered = this.data.users
            .filter((u) =>
                `${u['first_name']} ${u['last_name']} (${u['id']})`
                    .toLowerCase()
                    .includes(this.usersNotifiedSearch.toLowerCase())
            )
            .slice(0, 25);
    }

    removeUserNotified(user: Object) {
        this.usersNotified.splice(this.usersNotified.indexOf(user), 1);
    }

    addUserNotified() {
        if (!this.usersNotifiedSearch) return;

        // Check if the choice exists.
        let choice = this.data.users.find(
            (u) => u['id'] === this.usersNotifiedSearch
        );

        // If it exists and not already in notified, add to notified.
        if (
            choice &&
            !this.usersNotified.find((u) => u['id'] === choice['id'])
        ) {
            this.usersNotified.push(choice);
            this.resetFilter();
        }
    }

    resetFilter() {
        // Set the filter back to nothing.
        this.usersNotifiedSearch = '';

        // Need this line for it to update correctly.
        (<HTMLInputElement>(
            document.getElementById('usersNotifiedInput')
        )).value = '';
        this.filterUsersNotified();

        this._cdr.markForCheck();
    }

    hasChanges() {
        return (
            this.form.dirty ||
            JSON.stringify(this.originalUsersNotified) !==
                JSON.stringify(this.usersNotified)
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.data.phoneNumber['users_notified'] = this.usersNotified
                .map((un) => un['id'])
                .join();
            this.dialogRef.close({
                phone_number: this.data.phoneNumber,
                users_notified: this.usersNotified,
            });
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
