import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface YesNoButtons {
    first: string;
    second: string;
}

interface Data {
    kiosk?: boolean;
    title: string;
    content: string;
    buttons?: YesNoButtons;
}

@Component({
    selector: 'app-yesno',
    templateUrl: './yesno.component.html',
    styleUrls: ['./yesno.component.scss']
})
export class YesnoComponent {
    constructor(
        public dialogRef: MatDialogRef<YesnoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {
        if (!this.data.buttons) {
            this.data.buttons = { first: 'Yes', second: 'No' };
        }
    }

    first() {
        this.dialogRef.close(true);
    }

    second() {
        this.dialogRef.close(undefined);
    }
}
