import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

interface Data {
    employee: Object;
    totalHours: number;
    segments: Object[];
    timer?: number;
}

@Component({
    selector: 'app-punchclockday',
    templateUrl: './punchclockday.component.html',
    styleUrls: ['./punchclockday.component.scss']
})
export class PunchclockdayComponent implements OnInit, OnDestroy {
    // Other variables.
    timer: NodeJS.Timer;

    // Data Table.
    dataSource: MatTableDataSource<Object>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<PunchclockdayComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        // Setup data table.
        this.dataSource = new MatTableDataSource(this.data.segments);
        this.dataSource.sort = this.sort;

        // Set timer to close the dialog.
        this.timer = setTimeout(
            () => this.dialogRef.close(),
            this.data.timer || 10000
        );
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
    }
}
