<h1 mat-dialog-title>Create Customer</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    type="email"
                    placeholder="Email"
                    [(ngModel)]="customer.email"
                    name="email"
                    required
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="customer.first_name"
                    name="first_name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="customer.last_name"
                    name="last_name"
                    required
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    phoneMask
                    placeholder="Phone"
                    [(ngModel)]="customer.phone"
                    name="phone"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    phoneMask
                    placeholder="Mobile"
                    [(ngModel)]="customer.mobile"
                    name="mobile"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Address 1"
                    [(ngModel)]="customer.address1"
                    name="address1"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Address 2"
                    [(ngModel)]="customer.address2"
                    name="address2"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Postal Code"
                    [(ngModel)]="customer.postal_code"
                    name="postal_code"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Country (e.g CA)"
                    [(ngModel)]="customer.country"
                    name="country"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Province (e.g PE)"
                    [(ngModel)]="customer.province"
                    name="province"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="City"
                    [(ngModel)]="customer.city"
                    name="city"
                />
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Create
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
