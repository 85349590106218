<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>{{ data.title ? data.title : 'Confirm Dialog' }}</h1>
<div mat-dialog-content>
    <p>{{ data.content }}</p>
</div>
<div mat-dialog-actions class="fr">
    <mat-form-field *ngIf="data.pin">
        <input
            matInput
            type="password"
            placeholder="Pin"
            [formControl]="pinFormControl"
            (keydown.enter)="confirm()"
        />
        <mat-error *ngIf="pinFormControl.hasError('pattern')">
            Invalid Pin.
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="data.password">
        <input
            matInput
            type="password"
            placeholder="Password"
            (keydown.enter)="confirm()"
            [(ngModel)]="password"
        />
    </mat-form-field>
    <button mat-raised-button (click)="confirm()" color="primary" class="btn-1">
        Confirm
    </button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">
        Cancel
    </button>
</div>
