<mat-menu #menu="matMenu">
    <a
        mat-menu-item
        class="notification-button flex"
        *ngFor="let notification of notifications"
        [href]="notification.path"
    >
        <p class="center-btn">{{ notification.content }}</p>
    </a>
    <div *ngIf="!notifications.length">
        <img
            class="w100"
            [src]="
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    _misc.defaultPhoto
                )
            "
            alt="No Notifications"
        />
        <h1 class="center padding-15">No Notifications!</h1>
    </div>
</mat-menu>

<mat-toolbar>
    <mat-toolbar-row>
        <mat-icon
            *ngIf="!disableSidenav"
            (click)="toggleSidenav()"
            class="pointer select-none"
        >
            menu
        </mat-icon>
        <img
            src="../../../assets/logo.svg"
            alt="FluidDynamics Logo"
            class="pointer select-none"
            (click)="redirectHome()"
        />
        <span></span>
        <mat-icon
            class="dark-mode-icon pointer select-none margin-15"
            [matMenuTriggerFor]="menu"
            [matBadge]="notifications.length"
            matBadgeColor="warn"
            [matBadgeHidden]="!notifications.length"
        >
            notifications
        </mat-icon>
        <mat-icon
            class="dark-mode-icon pointer select-none"
            (click)="toggleDark(true)"
        >
            {{ darkTheme ? 'brightness_3' : 'brightness_5' }}
        </mat-icon>
        <mat-slide-toggle
            [(ngModel)]="darkTheme"
            (change)="toggleDark()"
            color="primary"
        ></mat-slide-toggle>
        <a href="javascript:void(0)" [matMenuTriggerFor]="userMenu">
            {{ user['first_name'] }}
            <mat-icon class="pointer select-none">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #userMenu="matMenu">
            <a mat-menu-item href="javascript:void(0)" (click)="redirectHome()">
                Home
            </a>
            <a
                mat-menu-item
                href="javascript:void(0)"
                (click)="_loading.navigate('/account', false)"
            >
                Account
            </a>

            <mat-menu #navigateMenu="matMenu">
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/admin', false)"
                    *ngIf="allowed['admin']"
                >
                    Admin
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/hr', false)"
                    *ngIf="allowed['hr']"
                >
                    HR
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/crm', false)"
                    *ngIf="allowed['crm']"
                >
                    CRM
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/pos', false)"
                    *ngIf="allowed['pos']"
                >
                    POS
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/partners', false)"
                    *ngIf="allowed['partners']"
                >
                    Partners
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/partners/manage', false)"
                    *ngIf="allowed['partnersmanage']"
                >
                    Partners Manage
                </a>
                <a
                    mat-menu-item
                    href="javascript:void(0)"
                    (click)="_loading.navigate('/store-groups', false)"
                    *ngIf="allowed['store-groups']"
                >
                    Store Group
                </a>
            </mat-menu>
            <a mat-menu-item [matMenuTriggerFor]="navigateMenu"> Navigate </a>

            <a
                mat-menu-item
                href="javascript:void(0)"
                (click)="_loading.navigate('/contact', false)"
            >
                Contact
            </a>
            <a
                mat-menu-item
                href="javascript:void(0)"
                (click)="_loading.navigate('/help', false)"
            >
                Help
            </a>
            <a mat-menu-item href="javascript:void(0)" (click)="_auth.logout()">
                Logout
            </a>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<div class="fluid-offline-nav" *ngIf="offline">
    <p>
        <mat-icon>cloud_off</mat-icon> Your device is offline, a majority of
        this website will not work properly.
    </p>
</div>
