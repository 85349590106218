<div mat-dialog-content class="scrollbar">
    <h2 class="center">
        {{ reservation['facility'] }} {{ reservation['date'] }}
    </h2>
    <p class="center mb15">
        The partners flag is for partners ordering reservation products in
        Fluid. This flag is ignored if slot is open. Also, the capacity is
        showing in the format of taken/capacity. Note, you can remove slots that
        have 0 taken. If you want to remove a slot that has more than 0 taken,
        contact admins.
    </p>

    <div class="flex-panel-entry flex-panel-header jcc">
        <p></p>
        <p>Open</p>
        <p>Close</p>
        <p>Capacity</p>
        <p>Open</p>
        <p>Partners</p>
    </div>
    <div class="flex col">
        <div
            *ngFor="let slot of reservation['slots']; let i = index"
            class="flex-panel-entry jcc"
        >
            <p>Slot {{ i + 1 }}</p>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Open"
                    [(ngModel)]="slot['start']"
                    [format]="24"
                    [ngxTimepicker]="startTimePicker"
                    readonly
                    required
                />
                <ngx-material-timepicker
                    #startTimePicker
                    [theme]="timePickerTheme"
                ></ngx-material-timepicker>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Close"
                    [(ngModel)]="slot['end']"
                    [format]="24"
                    [ngxTimepicker]="endTimePicker"
                    readonly
                    required
                />
                <ngx-material-timepicker
                    #endTimePicker
                    [theme]="timePickerTheme"
                ></ngx-material-timepicker>
            </mat-form-field>
            <div>
                <mat-form-field class="flex">
                    <span matPrefix>{{ slot['web_taken'] }}/</span>
                    <input
                        matInput
                        type="number"
                        placeholder="Public"
                        [(ngModel)]="slot['max_people']"
                    />
                </mat-form-field>
                <mat-form-field class="flex">
                    <span matPrefix>{{ slot['partner_taken'] }}/</span>
                    <input
                        matInput
                        type="number"
                        placeholder="Partner"
                        [(ngModel)]="slot['partner_inventory']"
                    />
                </mat-form-field>
            </div>
            <mat-checkbox
                color="primary"
                [(ngModel)]="slot['open']"
            ></mat-checkbox>
            <mat-checkbox
                color="primary"
                [(ngModel)]="slot['partner_accessible']"
            ></mat-checkbox>
            <button
                mat-icon-button
                (click)="removeSlot(i)"
                color="warn"
                [disabled]="slot['taken'] > 0"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <button
        mat-raised-button
        color="primary"
        class="center-btn"
        (click)="addSlot()"
    >
        Add Slot
    </button>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
