<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <p>{{ data.content ? data.content : '' }}</p>

    <!-- If not range. -->
    <mat-form-field class="w100" *ngIf="!data.range">
        <input
            matInput
            [placeholder]="data.placeholder"
            [matDatepicker]="picker"
            [(ngModel)]="date"
            (focus)="picker.open()"
            (click)="picker.open()"
            required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <!-- If range. -->
    <mat-form-field class="w100" *ngIf="data.range">
        <mat-label>{{ data.placeholder }}</mat-label>
        <mat-date-range-input
            [rangePicker]="rPicker"
            [formGroup]="dateRange"
            (focus)="rPicker.open()"
            (click)="rPicker.open()"
        >
            <input matStartDate formControlName="start" required />
            <input matEndDate formControlName="end" required />
        </mat-date-range-input>
        <mat-datepicker-toggle
            matSuffix
            [for]="rPicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rPicker></mat-date-range-picker>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        (click)="dialogRef.close(data.range ? dateRange.value : date)"
        color="primary"
    >
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
