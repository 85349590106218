import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import cloneDeep from 'lodash-es/cloneDeep';
import { MiscService } from 'src/app/services/misc.service';

@Component({
    selector: 'app-activategiftcard',
    templateUrl: './activategiftcard.component.html',
    styleUrls: ['./activategiftcard.component.scss'],
})
export class ActivategiftcardComponent implements OnInit {
    loading: boolean = false;
    giftcard: Object;
    startingBalance: string = '0';

    constructor(
        public dialogRef: MatDialogRef<ActivategiftcardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _snackbar: SnackbarService,
        private _http: HttpService,
        private _misc: MiscService
    ) {
        // Get the gift card and set the sales vector to other by default.
        this.giftcard = cloneDeep(this.data['giftcard']);
        this.giftcard['sales_vector'] = 'other';
    }

    ngOnInit() {}

    async activate() {
        // Check for missing fields.
        if (!this.giftcard['email'] || !this.giftcard['sales_vector']) {
            this._snackbar.defaultSnackbar(
                'Missing email and/or sales vector for the gift card.'
            );
            return;
        }

        // Check for invalid email.
        if (!this._misc.emailRegex.test(this.giftcard['email'])) {
            this._snackbar.defaultSnackbar('Invalid email.');
            return;
        }

        // Check for invalid balance.
        if (
            !/^(?:[1-9][0-9]*?|0)(?:\.[0-9]{1,2})?$/.test(this.startingBalance)
        ) {
            this._snackbar.defaultSnackbar('Invalid balance.');
            return;
        }

        this.loading = true;

        // Call the API to activate the gift card.
        const resp = await this._http.postLocal(
            'admin/giftcards/activate/',
            this.giftcard
        );

        // If failed.
        if (resp.status !== 204) {
            this._snackbar.defaultSnackbar(
                'An error has occurred while attempting to activate the gift card.'
            );
            return;
        }

        // If success, call the charge/recharge API.
        const balanceResp = await this._http.postLocal(
            'admin/giftcards/charge-recharge/',
            {
                barcode: this.giftcard['barcode'],
                amount: +this.startingBalance,
                type: 'recharge',
            }
        );

        // Based on this response.
        if (balanceResp.status !== 201) {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to recharge the gift card to the starting balance.'
            );
        } else {
            this._snackbar.defaultSnackbar(
                'Successfully activated the gift card.'
            );
        }

        this.loading = false;
        this.dialogRef.close({
            giftcard: this.giftcard,
            balance: this.startingBalance,
        });
    }
}
