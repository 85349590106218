<loading *ngIf="loading" [opacity]="0.5"></loading>

<div mat-dialog-content>
    <h1 class="center">Managing Shipping</h1>
    <h2 class="center mb15">Order ID: {{ data['transaction']['o_id'] }}</h2>

    <h2 class="center flex jcc aic">
        Shipping Address
        <mat-icon
            color="primary"
            class="pointer m-0-10"
            (click)="toggleEditAddress()"
        >
            edit
        </mat-icon>
    </h2>
    <div *ngIf="!editingAddress">
        <p class="center">
            {{ data['transaction']['first_name'] }}
            {{ data['transaction']['last_name'] }}
        </p>
        <p class="center">
            {{ data['transaction']['shipping_address1'] }}
            {{ data['transaction']['shipping_address2'] }}
        </p>
        <p class="center">
            {{ data['transaction']['shipping_city'] }},
            {{ data['transaction']['shipping_province'] }}
            {{ data['transaction']['shipping_postal_code'] }}
        </p>
        <p class="center mb15">{{ data['transaction']['shipping_country'] }}</p>
        <button
            mat-raised-button
            color="primary"
            class="center-btn"
            (click)="printShippingLabel()"
        >
            Print Shipping Label
        </button>
    </div>
    <div *ngIf="editingAddress">
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Address 1"
                    [(ngModel)]="newAddress['address1']"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Address 2"
                    [(ngModel)]="newAddress['address2']"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="City"
                    [(ngModel)]="newAddress['city']"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Province/State (use abbreviation)"
                    [(ngModel)]="newAddress['province']"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Postal Code"
                    [(ngModel)]="newAddress['postal_code']"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Country (use abbreviation)"
                    [(ngModel)]="newAddress['country']"
                />
            </mat-form-field>
        </div>
        <div class="flex phone-block jcc center">
            <button
                mat-raised-button
                color="primary"
                class="m-0-10"
                (click)="changeAddress()"
            >
                Save
            </button>
            <button
                mat-raised-button
                color="warn"
                class="m-0-10"
                (click)="toggleEditAddress()"
            >
                Cancel
            </button>
        </div>
    </div>

    <h2 class="center mt15">Shipping Items</h2>
    <div class="shipping-items">
        <div
            class="shipping-item"
            *ngFor="let voucher of data['transaction']['vouchers']"
        >
            <div class="flex aic">
                <mat-checkbox
                    *ngIf="voucher['shipping_status'] === 'not-dispatched'"
                    [(ngModel)]="voucher['checked']"
                    color="primary"
                ></mat-checkbox>
                <p>
                    {{ voucher['description'] }}
                    <span *ngIf="voucher['shipping_status'] === 'dispatched'">
                        (Dispatched:
                        {{
                            voucher['shipped_on'] * 1000
                                | date: 'yyyy-MM-dd HH:mm:ss'
                        }})
                    </span>
                </p>
                <p *ngIf="voucher['shipping_status'] === 'dispatched'"></p>
            </div>
            <div
                class="inputs"
                *ngIf="voucher['checked'] && voucher['giftcard'] === '1'"
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Gift Card"
                        [(ngModel)]="voucher['gc']"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Gift Card Amount"
                        [(ngModel)]="voucher['gca']"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(changes)" color="warn">
        Cancel
    </button>
</div>
