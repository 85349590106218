<h1 mat-dialog-title>
    {{ !data['drawer']['id'] ? 'New' : 'Editing' }} Square Drawer
</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <div class="inputs">
            <mat-form-field>
                <mat-select
                    placeholder="Location"
                    [(ngModel)]="drawer['location']"
                    name="location"
                >
                    <mat-option
                        *ngFor="let location of data.locations"
                        [value]="location['id']"
                    >
                        {{ location['name'] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Date"
                    [matDatepicker]="datePicker"
                    (click)="datePicker.open()"
                    (focus)="datePicker.open()"
                    [(ngModel)]="drawer['date']"
                    name="date"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="datePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Clerk"
                    [matAutocomplete]="autoClerk"
                    (input)="filterUsers()"
                    [(ngModel)]="drawer['clerk']"
                    name="clerk"
                />
                <mat-autocomplete
                    #autoClerk="matAutocomplete"
                    class="scrollbar"
                >
                    <mat-option
                        *ngFor="let user of filteredUsers"
                        value="{{ user['first_name'] }} {{
                            user['last_name']
                        }} ({{ user['id'] }})"
                    >
                        {{ user['first_name'] }}
                        {{ user['last_name'] }} ({{ user['id'] }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Net Sales"
                    [(ngModel)]="drawer['net_sales']"
                    name="net_sales"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Tax"
                    [(ngModel)]="drawer['tax']"
                    name="tax"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Rounding"
                    [(ngModel)]="drawer['rounding']"
                    name="rounding"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Cash Actual"
                    [(ngModel)]="drawer['cash_actual']"
                    name="cash_actual"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="CC"
                    [(ngModel)]="drawer['cc']"
                    name="cc"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Other (MFG Gift Card)"
                    [(ngModel)]="drawer['gc_redeemed']"
                    name="gc_redeemed"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    step="0.01"
                    placeholder="Square Fees"
                    [(ngModel)]="drawer['square_fees']"
                    name="square_fees"
                />
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <p class="red-text m-0-10" *ngIf="errorMessage">{{ errorMessage }}</p>
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        {{ !data['drawer']['id'] ? 'Create' : 'Save' }}
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
