import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '../services/auth.service';

interface InputValue {
    function: Function;
    trigger: MatMenuTrigger;
    row: Object;
    params?: any[];
    component: any;
}

@Directive({
    selector: '[phoneContextMenu]'
})
export class PhonecontextmenuDirective {
    @Input() phoneContextMenu: InputValue;
    touchTimeout: NodeJS.Timeout;
    touchTime: number = 500;

    constructor(private el: ElementRef, private _auth: AuthService) {}

    @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
        if (!this._auth.isIOS()) return;

        this.touchTimeout = setTimeout(() => {
            // Create the mouse event.
            let mouseEvent = document.createEvent('MouseEvent');
            mouseEvent.initMouseEvent(
                'contextmenu',
                true,
                true,
                window,
                1,
                event.touches[0].screenX,
                event.touches[0].screenY,
                event.touches[0].clientX,
                event.touches[0].clientY,
                false,
                false,
                false,
                false,
                0,
                null
            );

            // Set params to empty array if not set.
            if (!this.phoneContextMenu.params) {
                this.phoneContextMenu.params = [];
            }

            // Call the function.
            this.phoneContextMenu.function(
                mouseEvent,
                this.phoneContextMenu.trigger,
                this.phoneContextMenu.row,
                ...this.phoneContextMenu.params,
                this.phoneContextMenu.component
            );
        }, this.touchTime);
    }

    @HostListener('touchmove') onTouchMove() {
        this.onTouchEnd();
    }

    @HostListener('touchend') onTouchEnd() {
        // If there is a timeout, then it hasn't been a right click.
        if (this.touchTimeout) {
            clearTimeout(this.touchTimeout);
            this.touchTimeout = undefined;
        }
    }
}
