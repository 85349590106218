import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Data {
    type: string;
    passes: Object[];
    replacing?: Object;
}

@Component({
    selector: 'app-addreplacepass',
    templateUrl: './addreplacepass.component.html',
    styleUrls: ['./addreplacepass.component.scss']
})
export class AddreplacepassComponent implements OnInit {
    // Dialog variables.
    selectedPass: Object;

    constructor(
        public dialogRef: MatDialogRef<AddreplacepassComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        // Order the passes.
        this.data.passes = this.data.passes.sort((a, b) => {
            return a['pass_id'] > b['pass_id'] ? 1 : -1;
        });

        // Set the selectedPass to the first pass.
        this.selectedPass = this.data.passes[0];
    }

    choosePass(pass: Object) {
        this.selectedPass = pass;
    }
}
