import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

import * as dayjs from 'dayjs';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/amchartsdark';
import am4themes_default from '@amcharts/amcharts4/themes/amcharts';

am4core.options.onlyShowOnViewport = true;
am4core.useTheme(am4themes_animated);

export interface Chart {
    data: any;
    chart: am4charts.Chart;
}

interface Queue {
    function: Function;
    time: number;
    data: any[];
}

/**
 * This service contains all the helper functions to deal with charts.
 * This will deal with the creation of charts, the queue of the charts to help performance,
 * disposing of charts, along with running these functions outside of the Angular zone.
 */
@Injectable({
    providedIn: 'root',
})
export class ChartService implements OnDestroy {
    private charts: Chart[] = [];
    private queue: Queue[] = [];
    private defaultTime: number;

    private routerEventsSubscription: Subscription;
    private themeChangesSubscription: Subscription;

    constructor(
        private _zone: NgZone,
        private _router: Router,
        private _auth: AuthService
    ) {
        this.setup();
        this.defaultTime = 500;
    }

    ngOnDestroy() {
        this.routerEventsSubscription.unsubscribe();
        this.themeChangesSubscription.unsubscribe();
        this.disposeCharts();
    }

    private async setup() {
        // Subscribe to router events and if it's navigating, dispose of charts and queue.
        this.routerEventsSubscription = this._router.events.subscribe(
            (event: RouterEvent) => {
                if (event instanceof NavigationStart) {
                    this.disposeCharts();
                }
            }
        );

        // Subscribe to theme changes.
        this.themeChangesSubscription = this._auth.themeChange.subscribe(
            (dark) => {
                if (dark) {
                    am4core.unuseTheme(am4themes_default);
                    am4core.useTheme(am4themes_dark);
                } else {
                    am4core.unuseTheme(am4themes_dark);
                    am4core.useTheme(am4themes_default);
                }
            }
        );

        // Setup the themes based on website theme.
        if ((await this._auth.getTheme()) === 'dark') {
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.useTheme(am4themes_default);
        }

        // Start the queue.
        this.processQueue();
    }

    private processQueue() {
        let chart: Queue;

        // Check if the queue has a length.
        if (this.queue.length) {
            // If they do, shift the chart of the queue and run the function outside Angular.
            chart = this.queue.shift();
            this._zone.runOutsideAngular(() => {
                this.charts.push(chart.function(...chart.data));
            });
        }

        // Set the timeout for the shifted chart if given, or the default time if not.
        setTimeout(
            () => {
                this.processQueue();
            },
            chart && chart.time ? chart.time : this.defaultTime
        );
    }

    /**
     * This function is used to create charts for the website. Does all the chart handling,
     * for example, adding the chart to the queue, or creating it instantly.
     *
     *
     * @param fn The function name to call for when the chart is being created.
     * @param data The data to send to the function.
     * @param time The amount of time the queue should wait until the next chart is created.
     * @param id The ID of the HTML element for the chart to be created on.
     * @param queue A `boolean` determining if the chart should be added to the queue, or created instantly.
     */
    createCharts(
        fn: string,
        data: any[],
        time: number,
        id: string,
        queue: boolean = true
    ) {
        for (let i = 0; i < data.length; i++) {
            if (queue) {
                this.queue.push({
                    function: this[fn],
                    time,
                    data: [data[i], id],
                });
            } else {
                this._zone.runOutsideAngular(() => {
                    this.charts.push(this[fn](data[i], id));
                });
            }
        }
    }

    private createSalesTrendChart(trend: Object, id: string): Chart {
        // Create the chart.
        let chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);

        // Add the data.
        let numberFormat = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'CAD',
        });
        chart.data = Object.keys(trend['data']['first']).map((key) => {
            return {
                key,
                first: numberFormat.format(trend['data']['first'][key]),
                second: numberFormat.format(trend['data']['second'][key]),
            };
        });

        // Create x axis.
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'key';

        // Create y axis.
        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.formatLabel = (val: number) => numberFormat.format(val);

        let secondSeries: am4charts.LineSeries | am4charts.ColumnSeries;
        let firstSeries: am4charts.LineSeries | am4charts.ColumnSeries;

        // If it is daily, create line series, else create column series.
        if (trend['type'] === 'daily') {
            // Create the line series'.
            secondSeries = chart.series.push(new am4charts.LineSeries());
            secondSeries.name = dayjs
                .unix(trend['data']['dates']['second']['start'])
                .format('MMM D, YYYY');

            firstSeries = chart.series.push(new am4charts.LineSeries());
            firstSeries.name = dayjs
                .unix(trend['data']['dates']['first']['start'])
                .format('MMM D, YYYY');
        } else {
            secondSeries = chart.series.push(new am4charts.ColumnSeries());
            firstSeries = chart.series.push(new am4charts.ColumnSeries());

            // Set the names based on the type of trend.
            if (trend['type'] === 'weekly') {
                firstSeries.name = `${dayjs
                    .unix(trend['data']['dates']['first']['start'])
                    .format('MMM D, YYYY')} - ${dayjs
                    .unix(trend['data']['dates']['first']['end'])
                    .format('MMM D, YYYY')}`;
                secondSeries.name = `${dayjs
                    .unix(trend['data']['dates']['second']['start'])
                    .format('MMM D, YYYY')} - ${dayjs
                    .unix(trend['data']['dates']['second']['end'])
                    .format('MMM D, YYYY')}`;
            } else {
                firstSeries.name = dayjs
                    .unix(trend['data']['dates']['first']['start'])
                    .format('YYYY');
                secondSeries.name = dayjs
                    .unix(trend['data']['dates']['second']['start'])
                    .format('YYYY');
            }
        }

        secondSeries.dataFields.valueY = 'second';
        secondSeries.dataFields.categoryX = 'key';
        secondSeries.tooltipText = '{categoryX}: ${valueY}';
        firstSeries.dataFields.valueY = 'first';
        firstSeries.dataFields.categoryX = 'key';
        firstSeries.tooltipText = '{categoryX}: ${valueY}';

        // Add cursor.
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'zoomX';

        // Add legend.
        chart.legend = new am4charts.Legend();

        return { data: { selector: id }, chart };
    }

    private createAttendanceChart(location: Object, idFormat: string): Chart {
        // Create the chart.
        const facility =
            location['facility'] instanceof Object
                ? location['facility'].id
                : location['facility'];
        let chart = am4core.create(
            `${idFormat}${facility}`,
            am4charts.PieChart
        );
        chart.hiddenState.properties.opacity = 0;

        // Set the chart data dependant on the location given.
        chart.data = [];
        if (location.hasOwnProperty('weekly'))
            chart.data.push({
                name: 'Weekly',
                value: location['weekly'],
            });
        if (location.hasOwnProperty('season'))
            chart.data.push({
                name: 'Season',
                value: location['season'],
            });
        if (location.hasOwnProperty('online'))
            chart.data.push({
                name: 'Online',
                value: location['online'],
            });
        if (location.hasOwnProperty('gate'))
            chart.data.push({
                name: 'Gate',
                value: location['gate'],
            });
        if (location.hasOwnProperty('vtkto'))
            chart.data.push({
                name: 'vtkto',
                value: location['vtkto'],
            });

        // Push the chart data fields and properties of the pie chart.
        let series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = 'value';
        series.dataFields.category = 'name';
        series.slices.template.cornerRadius = 6;
        series.colors.step = 3;

        series.hiddenState.properties.endAngle = -90;

        return { data: { selector: `${idFormat}${facility}` }, chart };
    }

    private createEmployeeStatsChart(stats: Object, idFormat: string): Chart {
        // Create the chart.
        let chart = am4core.create(
            `${idFormat}${stats['id']}`,
            am4charts.XYChart
        );

        // Get the chart data.
        chart.data = (<Array<Object>>stats['departments']).map((department) => {
            let mapped = {
                department: department['name'],
                target: department['amount']['targets']['total'],
                current: department['amount']['total'],
            };

            if (department['name'] === 'Attractions Operations') {
                mapped['target_u16'] = department['amount']['targets']['u16'];
                mapped['current_u16'] = department['amount']['u16'];
                mapped['target_o16'] = department['amount']['targets']['o16'];
                mapped['current_o16'] = department['amount']['o16'];
            }

            return mapped;
        });

        // Create the axes.
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'department';
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.minGridDistance = 30;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.title.text = 'Amount of Employees';

        // Create the series'.
        // Total Current
        let totalCurrentSeries = chart.series.push(
            new am4charts.ColumnSeries()
        );
        totalCurrentSeries.dataFields.valueY = 'current';
        totalCurrentSeries.dataFields.categoryX = 'department';
        totalCurrentSeries.clustered = false;
        totalCurrentSeries.tooltipText = 'Total Current: [bold]{valueY}[/]';

        // Total Target
        let totalTargetSeries = chart.series.push(new am4charts.ColumnSeries());
        totalTargetSeries.dataFields.valueY = 'target';
        totalTargetSeries.dataFields.categoryX = 'department';
        totalTargetSeries.clustered = false;
        totalCurrentSeries.columns.template.width = am4core.percent(83);
        totalTargetSeries.tooltipText = 'Total Target: [bold]{valueY}[/]';

        // Over 16 Current
        let o16CurrentSeries = chart.series.push(new am4charts.ColumnSeries());
        o16CurrentSeries.dataFields.valueY = 'current_o16';
        o16CurrentSeries.dataFields.categoryX = 'department';
        o16CurrentSeries.clustered = false;
        o16CurrentSeries.columns.template.width = am4core.percent(67);
        o16CurrentSeries.tooltipText = 'Over 16 Current: [bold]{valueY}[/]';

        // Over 16 Target
        let o16TargetSeries = chart.series.push(new am4charts.ColumnSeries());
        o16TargetSeries.dataFields.valueY = 'target_o16';
        o16TargetSeries.dataFields.categoryX = 'department';
        o16TargetSeries.clustered = false;
        o16TargetSeries.columns.template.width = am4core.percent(50);
        o16TargetSeries.tooltipText = 'Over 16 Target: [bold]{valueY}[/]';

        // Under 16 Total
        let u16CurrentSeries = chart.series.push(new am4charts.ColumnSeries());
        u16CurrentSeries.dataFields.valueY = 'current_u16';
        u16CurrentSeries.dataFields.categoryX = 'department';
        u16CurrentSeries.clustered = false;
        u16CurrentSeries.columns.template.width = am4core.percent(33);
        u16CurrentSeries.tooltipText = 'Under 16 Current: [bold]{valueY}[/]';

        // Under 16 Target
        let u16TargetSeries = chart.series.push(new am4charts.ColumnSeries());
        u16TargetSeries.dataFields.valueY = 'target_u16';
        u16TargetSeries.dataFields.categoryX = 'department';
        u16TargetSeries.clustered = false;
        u16TargetSeries.columns.template.width = am4core.percent(16);
        u16TargetSeries.tooltipText = 'Under 16 Target: [bold]{valueY}[/]';

        // Create chart cursor.
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        return { data: { id: stats['id'] }, chart };
    }

    disposeChart(selector: string) {
        this._zone.runOutsideAngular(() => {
            for (let i = 0; i < this.charts.length; i++) {
                if (
                    this.charts[i].data.hasOwnProperty('selector') &&
                    this.charts[i].data['selector'] === selector
                ) {
                    this.charts[i].chart.dispose();
                    this.charts.splice(i, 1);
                    i--;
                }
            }
        });
    }

    private disposeCharts() {
        this._zone.runOutsideAngular(() => {
            for (let i = 0; i < this.charts.length; i++) {
                this.charts[i].chart.dispose();
            }
        });

        this.charts = [];
        this.queue = [];
    }
}
