<h1 mat-dialog-title>Choose Media</h1>
<div mat-dialog-content class="scrollbar">
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Filter"
            [(ngModel)]="filterValue"
            (input)="filter()"
        />
    </mat-form-field>

    <div class="uploaded-screen-media choose-screen-media">
        <div
            class="pointer"
            *ngFor="let m of filteredMedia; let i = index"
            (click)="save(m)"
        >
            <div class="center padding-15 flex aic">
                <h1>{{ m }}</h1>
            </div>
            <img
                src="https://api.fluiddynamics.ca/v1/screens/media/?file={{
                    m
                }}"
                [alt]="m"
                (error)="_misc.loadVideo(m, i, 'choose-screen-media')"
            />
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
