import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myjson',
})
export class MyjsonPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string {
        return JSON.stringify(value, null, args.length ? args[0] : 0);
    }
}
