import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';

import * as dayjs from 'dayjs';
import { MiscService } from 'src/app/services/misc.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-editaudition',
    templateUrl: './editaudition.component.html',
    styleUrls: ['./editaudition.component.scss']
})
export class EditauditionComponent implements OnInit, OnDestroy {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    originalDate: Date;
    originalTime: string;
    timePickerTheme: NgxMaterialTimepickerTheme;
    themeChangeSubscription: Subscription;

    @ViewChild('auditionForm', { static: true })
    auditionForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditauditionComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public audition: Object,
        private _dialog: MatDialog,
        private _auth: AuthService
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe(_ => this.askClose());

        // Transform unix to date and time.
        let normal = dayjs.unix(this.audition['date']);
        this.audition['date'] = normal.startOf('day').toDate();
        this.audition['time'] = normal.format('HH:mm');

        // Store original date and time.
        this.originalDate = normal.startOf('day').toDate();
        this.originalTime = normal.format('HH:mm');
    }

    async ngOnInit() {
        // Set the theme.
        this.timePickerTheme = await this._misc.getTimePickerTheme();
        this.themeChangeSubscription = this._auth.themeChange.subscribe(
            async () => {
                this.timePickerTheme = await this._misc.getTimePickerTheme();
            }
        );
    }

    ngOnDestroy() {
        this.themeChangeSubscription.unsubscribe();
    }

    hasChanges() {
        return (
            this.auditionForm.dirty ||
            this.audition.hasOwnProperty('gmLocation') ||
            JSON.stringify(this.audition['date']) !==
                JSON.stringify(this.originalDate) ||
            this.audition['time'] !== this.originalTime
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.'
                }
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close(this.audition);
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
