<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>View/Edit Cart</h1>
<div mat-dialog-content class="scrollbar">
    <mat-tab-group mat-stretch-tabs class="transaction-items">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">visibility</mat-icon>
                Overview
            </ng-template>

            <h2 class="center">General Info</h2>
            <p class="center">
                Cart ID:
                <span class="bold">
                    {{ data['cart']['id'] }}
                </span>
            </p>
            <p class="center">
                Created:
                <span class="bold">
                    {{
                        data['cart']['created'] * 1000
                            | date: 'yyyy-MM-dd HH:mm:ss'
                    }}
                </span>
            </p>
            <p class="center">
                Expires:
                <span class="bold">
                    {{
                        data['cart']['expires'] * 1000
                            | date: 'yyyy-MM-dd HH:mm:ss'
                    }}
                </span>
            </p>
            <p class="center mb15">
                Has Shipping:
                <span class="bold">
                    {{ hasShipping ? 'Yes' : 'No' }}
                </span>
            </p>

            <h2 class="center">Totals</h2>
            <p class="center">
                Subtotal:
                <span class="bold">{{
                    data['cart']['subtotal'] | currency
                }}</span>
            </p>
            <p class="center" *ngFor="let tax of data['cart']['taxes']">
                {{ tax['name'] }}:
                <span class="bold">{{ tax['amount'] | currency }}</span>
            </p>
            <p class="center mb15">
                Total:
                <span class="bold">{{ data['cart']['total'] | currency }}</span>
            </p>

            <div
                *ngIf="
                    data['cart']['giftcards'] &&
                    data['cart']['giftcards'].length > 0
                "
            >
                <h2 class="center">Gift Cards</h2>
                <p class="center" *ngFor="let gc of data['cart']['giftcards']">
                    {{ gc['digits'] }}:
                    <span class="bold">-{{ gc['amount'] | currency }}</span>
                </p>
            </div>

            <h2
                class="center mt15"
                *ngIf="
                    data['cart']['discounts'] > 0 || data['cart']['promo_code']
                "
            >
                Savings
            </h2>
            <p class="center" *ngIf="data['cart']['promo_code']">
                Promo Code: {{ data['cart']['promo_code']['code'] }} ({{
                    data['cart']['promo_code']['type']
                }}) at
                <span class="bold">
                    {{ data['cart']['promo_code']['percentage'] }}</span
                >%:
                <span class="bold">
                    {{ data['cart']['promo_code']['amount'] | currency }}
                </span>
            </p>
            <span
                *ngIf="data['cart']['promo_code']"
                class="small-orange-cursor center center-btn"
                (click)="removePromoCode()"
            >
                Remove Promo Code
            </span>
            <p class="center" *ngIf="data['cart']['discounts'] > 0">
                Other Discounts (category):
                <span class="bold">
                    {{ data['cart']['discounts'] | currency }}
                </span>
            </p>
            <p
                class="center"
                *ngIf="
                    data['cart']['discounts'] > 0 && data['cart']['promo_code']
                "
            >
                Total Savings:
                <span class="bold">
                    {{
                        data['cart']['discounts'] +
                            data['cart']['promo_code']['amount'] | currency
                    }}
                </span>
            </p>

            <div *ngIf="!data['cart']['promo_code']">
                <h2 class="center">Add Promo Code</h2>
                <p class="center">
                    Note, if checked, bypass validation will add the promo code
                    to the cart even if the promo code does not exist or is not
                    valid.
                </p>
                <div class="inputs">
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="bypassValidation"
                            >
                                Bypass Validation
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>

                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Promo Code"
                            [(ngModel)]="promoCode"
                        />
                    </mat-form-field>
                </div>

                <button
                    mat-raised-button
                    class="center-btn"
                    color="primary"
                    (click)="addPromoCode()"
                >
                    Add Promo Code
                </button>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">shopping_basket</mat-icon>
                Items
            </ng-template>

            <div class="cart-items">
                <div
                    class="cart-item"
                    *ngFor="let item of data['cart']['items']"
                >
                    <div>
                        <div>
                            <img
                                [src]="
                                    item['icon'] ||
                                    'https://store.maritimefun.com/assets/logos/' +
                                        item['facility'].toLowerCase() +
                                        '.png'
                                "
                                alt="Item Icon"
                            />
                        </div>
                        <p>
                            {{ item['description']
                            }}{{
                                item['discount_percentage']
                                    ? ' (-' + item['discount_percentage'] + '%)'
                                    : ''
                            }}
                        </p>
                    </div>
                    <div>
                        <div>
                            <div *ngFor="let attr of item['attributes']">
                                <p *ngIf="attr.display">
                                    <span>{{ attr['name'] }}:</span>
                                    {{ attr['value'] }}
                                </p>
                            </div>
                        </div>
                        <div class="center">
                            <!-- Quantity -->
                            <div>
                                <input
                                    class="same-color"
                                    type="number"
                                    [value]="item['quantity']"
                                    (change)="updateQuantity(item, $event)"
                                />
                            </div>

                            <!-- Remove -->
                            <span
                                class="small-orange-cursor"
                                (click)="removeItem(item)"
                            >
                                Remove Item
                            </span>

                            <!-- Price -->
                            <p>
                                <span
                                    *ngIf="item['oldPrice'] !== item['price']"
                                    >{{ item['oldPrice'] | currency }}</span
                                >
                                {{ item['price'] | currency }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">text_fields</mat-icon>
                Fields
            </ng-template>

            <h3 class="center">Contact Information</h3>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Email"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['email']
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Confirm Email"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['confirmEmail']
                        "
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="First Name"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['firstName']
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Last Name"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['lastName']
                        "
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Phone"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['phone']
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Mobile"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['mobile']
                        "
                    />
                </mat-form-field>
            </div>
            <h3 class="center">Billing Address</h3>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Address 1"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['address1']
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Address 2"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['address2']
                        "
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="City"
                        [(ngModel)]="data['cart']['fields']['customer']['city']"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Province/State (2 character abbreviation)"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['province']
                        "
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Postal Code"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['postalCode']
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Country (2 character abbreviation)"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['country']
                        "
                    />
                </mat-form-field>
            </div>
            <h3 class="center" *ngIf="hasShipping">Shipping Address</h3>
            <div class="inputs" *ngIf="hasShipping">
                <div class="form-field">
                    <div class="form-field-div jcc w100">
                        <mat-slide-toggle
                            color="primary"
                            [(ngModel)]="
                                data['cart']['fields']['customer'][
                                    'shippingSame'
                                ]
                            "
                        >
                            Same as Billing
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div
                class="inputs"
                *ngIf="
                    hasShipping &&
                    !data['cart']['fields']['customer']['shippingSame']
                "
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Address 1"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'address1'
                            ]
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Address 2"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'address2'
                            ]
                        "
                    />
                </mat-form-field>
            </div>
            <div
                class="inputs"
                *ngIf="
                    hasShipping &&
                    !data['cart']['fields']['customer']['shippingSame']
                "
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="City"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'city'
                            ]
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Province/State (2 character abbreviation)"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'province'
                            ]
                        "
                    />
                </mat-form-field>
            </div>
            <div
                class="inputs"
                *ngIf="
                    hasShipping &&
                    !data['cart']['fields']['customer']['shippingSame']
                "
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Postal Code"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'postalCode'
                            ]
                        "
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Country (2 character abbreviation)"
                        [(ngModel)]="
                            data['cart']['fields']['customer']['shipping'][
                                'country'
                            ]
                        "
                    />
                </mat-form-field>
            </div>

            <h3 class="center">Review Fields</h3>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Gift Message"
                        [(ngModel)]="
                            data['cart']['fields']['review']['giftMessage']
                        "
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <div class="form-field">
                    <div class="form-field-div jcc w100">
                        <mat-slide-toggle
                            color="primary"
                            [(ngModel)]="
                                data['cart']['fields']['review']['newsletter']
                            "
                        >
                            Subscribe to Newsletter
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>

            <button
                mat-raised-button
                class="center-btn"
                color="primary"
                (click)="saveFields()"
            >
                Save Fields
            </button>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">article</mat-icon>
                Log
            </ng-template>

            <h2 class="center" *ngIf="!logs">Loading Log</h2>
            <div class="log-list" *ngIf="logs">
                <div class="log-list-header log-list-header-cols">
                    <p class="center bold">Time</p>
                    <p class="center bold">Description</p>
                    <p class="center bold small-hide">Content</p>
                </div>
                <div
                    #logDiv
                    data-collapsed="true"
                    class="log"
                    *ngFor="let log of logs"
                >
                    <div class="log-header log-list-header hover">
                        <p
                            class="center"
                            [ngClass]="{ 'red-text': log['type'] === 'error' }"
                        >
                            {{
                                log['time'] * 1000 | date: 'yyyy-MM-dd HH:mm:ss'
                            }}
                        </p>
                        <p
                            class="center"
                            [ngClass]="{ 'red-text': log['type'] === 'error' }"
                        >
                            {{ log['description'] }}
                        </p>
                        <p
                            class="center scrollbar small-hide"
                            [ngClass]="{ 'red-text': log['type'] === 'error' }"
                        >
                            {{ log['content'] }}
                        </p>
                        <mat-icon
                            class="pointer center"
                            color="primary"
                            (click)="_misc.toggleCollapse(logDiv)"
                        >
                            {{
                                logDiv.dataset.collapsed === 'true'
                                    ? 'keyboard_arrow_down'
                                    : 'keyboard_arrow_up'
                            }}
                        </mat-icon>
                    </div>
                    <div class="log-body">
                        <h3 class="center">Full Content</h3>
                        <p class="center mb15">{{ log['content'] }}</p>

                        <h3 class="center">User Agent</h3>
                        <p class="center mb15">{{ log['user_agent'] }}</p>
                        <button
                            mat-raised-button
                            class="center-btn mb15"
                            color="primary"
                            *ngIf="!log['user_agent_detailed']"
                            (click)="getBrowserInformation(log, logDiv)"
                        >
                            Get Browser Information
                        </button>
                        <mat-icon
                            class="detailed-x pointer"
                            color="warn"
                            *ngIf="log['user_agent_detailed']"
                            (click)="getBrowserInformation(log, logDiv, true)"
                        >
                            close
                        </mat-icon>
                        <pre
                            class="mb15 mat-tooltip padding-15 zero-nine-rem"
                            *ngIf="log['user_agent_detailed']"
                            >{{ log['user_agent_detailed'] | myjson: 4 }}</pre
                        >

                        <h3 class="center">User Request Body</h3>
                        <pre class="center overflow scrollbar mb15">{{
                            log['data'] | myjson
                        }}</pre>

                        <h2 class="center">Cart State</h2>
                        <h3 class="center">Items</h3>
                        <div>
                            <div
                                *ngFor="let item of log['cart_state']['items']"
                                class="center mat-elevation-z3 padding-15"
                            >
                                <p>
                                    ({{ item['quantity'] }})
                                    {{ item['description'] }}
                                </p>
                                <p>
                                    Item ID:
                                    <span class="bold">{{ item['id'] }}</span>
                                    | Product ID:
                                    <span class="bold">
                                        {{ item['product'] }}
                                    </span>
                                </p>
                                <p class="mb15">
                                    Price:
                                    <span
                                        *ngIf="
                                            item['oldPrice'] !== item['price']
                                        "
                                        class="strike-through"
                                    >
                                        {{ item['oldPrice'] | currency }}
                                    </span>
                                    <span class="bold">
                                        {{ item['price'] | currency }}
                                    </span>
                                </p>
                                <div
                                    *ngIf="
                                        item['attributes'] &&
                                        item['attributes'].length > 0
                                    "
                                >
                                    <p class="underline">Attributes</p>
                                    <p
                                        *ngFor="
                                            let attribute of item['attributes']
                                        "
                                    >
                                        {{ attribute['name'] }}:
                                        {{ attribute['value'] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h3 class="center mt15">Totals</h3>
                        <p class="center">
                            Subtotal:
                            <span class="bold">{{
                                log['cart_state']['subtotal'] | currency
                            }}</span>
                        </p>
                        <p
                            class="center"
                            *ngFor="let tax of log['cart_state']['taxes']"
                        >
                            {{ tax['name'] }}:
                            <span class="bold">{{
                                tax['amount'] | currency
                            }}</span>
                        </p>
                        <p class="center mb15">
                            Total:
                            <span class="bold">{{
                                log['cart_state']['total'] | currency
                            }}</span>
                        </p>

                        <div
                            *ngIf="
                                log['cart_state']['giftcards'] &&
                                log['cart_state']['giftcards'].length > 0
                            "
                        >
                            <h2 class="center">Gift Cards</h2>
                            <p
                                class="center"
                                *ngFor="
                                    let gc of log['cart_state']['giftcards']
                                "
                            >
                                {{ gc['digits'] }}:
                                <span class="bold"
                                    >-{{ gc['amount'] | currency }}</span
                                >
                            </p>
                        </div>

                        <h2
                            class="center mt15"
                            *ngIf="
                                log['cart_state']['discounts'] > 0 ||
                                log['cart_state']['promo_code']
                            "
                        >
                            Savings
                        </h2>
                        <p
                            class="center"
                            *ngIf="log['cart_state']['promo_code']"
                        >
                            Promo Code:
                            {{ log['cart_state']['promo_code']['code'] }} ({{
                                log['cart_state']['promo_code']['type']
                            }}) at
                            <span class="bold">
                                {{
                                    log['cart_state']['promo_code'][
                                        'percentage'
                                    ]
                                }}</span
                            >%:
                            <span class="bold">
                                {{
                                    log['cart_state']['promo_code']['amount']
                                        | currency
                                }}
                            </span>
                        </p>
                        <p
                            class="center"
                            *ngIf="log['cart_state']['discounts'] > 0"
                        >
                            Other Discounts (category):
                            <span class="bold">
                                {{ log['cart_state']['discounts'] | currency }}
                            </span>
                        </p>
                        <p
                            class="center"
                            *ngIf="
                                log['cart_state']['discounts'] > 0 &&
                                log['cart_state']['promo_code']
                            "
                        >
                            Total Savings:
                            <span class="bold">
                                {{
                                    log['cart_state']['discounts'] +
                                        log['cart_state']['promo_code'][
                                            'amount'
                                        ] | currency
                                }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
