import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { MyjsonPipe } from './myjson.pipe';

@NgModule({
    declarations: [PhonePipe, MyjsonPipe],
    imports: [CommonModule],
    exports: [PhonePipe, MyjsonPipe],
})
export class PipesModule {}
