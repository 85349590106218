import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DialoguesComponent } from './dialogues.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChooseoptionComponent } from './chooseoption/chooseoption.component';
import { EditauditionComponent } from './editaudition/editaudition.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomelementsModule } from '../customelements/customelements.module';
import { EditpartnerComponent } from './editpartner/editpartner.component';
import { CustomdirectivesModule } from '../customdirectives/customdirectives.module';
import { MatSelectModule } from '@angular/material/select';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { EditproductComponent } from './editproduct/editproduct.component';
import { MatListModule } from '@angular/material/list';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditcategoryortaxComponent } from './editcategoryortax/editcategoryortax.component';
import { EditlocationComponent } from './editlocation/editlocation.component';
import { EditvoucherproductComponent } from './editvoucherproduct/editvoucherproduct.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditproductcategoryComponent } from './editproductcategory/editproductcategory.component';
import { ConfirmtemplatedeleteComponent } from './confirmtemplatedelete/confirmtemplatedelete.component';
import { EdittemplateComponent } from './edittemplate/edittemplate.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AddreplacepassComponent } from './addreplacepass/addreplacepass.component';
import { MatRadioModule } from '@angular/material/radio';
import { ChooseemailattachmentComponent } from './chooseemailattachment/chooseemailattachment.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ChooselocationComponent } from './chooselocation/chooselocation.component';
import { NumberpadComponent } from './numberpad/numberpad.component';
import { CompletetransactionComponent } from './completetransaction/completetransaction.component';
import { PickattractionComponent } from './pickattraction/pickattraction.component';
import { MatChipsModule } from '@angular/material/chips';
import { PosscangroupComponent } from './posscangroup/posscangroup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PosrefundComponent } from './posrefund/posrefund.component';
import { ChoosegroupComponent } from './choosegroup/choosegroup.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from '../pipes/pipes.module';
import { WebcamModule } from 'ngx-webcam';
import { TakephotoComponent } from './takephoto/takephoto.component';
import { PasshistoryComponent } from './passhistory/passhistory.component';
import { MatTableModule } from '@angular/material/table';
import { EditmemberComponent } from './editmember/editmember.component';
import { PosshowemployeeComponent } from './posshowemployee/posshowemployee.component';
import { ChooseemployeeidComponent } from './chooseemployeeid/chooseemployeeid.component';
import { PosjournalComponent } from './posjournal/posjournal.component';
import { MatMenuModule } from '@angular/material/menu';
import { EdituserComponent } from './edituser/edituser.component';
import { ChoosedocumentComponent } from './choosedocument/choosedocument.component';
import { AddgroupComponent } from './addgroup/addgroup.component';
import { PunchclockdayComponent } from './punchclockday/punchclockday.component';
import { EditkiosklocationComponent } from './editkiosklocation/editkiosklocation.component';
import { EditkioskcategoryComponent } from './editkioskcategory/editkioskcategory.component';
import { IdleComponent } from './idle/idle.component';
import { YesnoComponent } from './yesno/yesno.component';
import { CreatecustomerComponent } from './createcustomer/createcustomer.component';
import { ReschedulereservationComponent } from './reschedulereservation/reschedulereservation.component';
import { ChangegroupinfoComponent } from './changegroupinfo/changegroupinfo.component';
import { PosgroupinfoComponent } from './posgroupinfo/posgroupinfo.component';
import { PosbookingsComponent } from './posbookings/posbookings.component';
import { ReschedulereservationadminComponent } from './reschedulereservationadmin/reschedulereservationadmin.component';
import { PosscanorderComponent } from './posscanorder/posscanorder.component';
import { RebookComponent } from './rebook/rebook.component';
import { ChangevouchernameComponent } from './changevouchername/changevouchername.component';
import { ChoosedateandproductComponent } from './choosedateandproduct/choosedateandproduct.component';
import { SacoahistoryanalyzecardComponent } from './sacoahistoryanalyzecard/sacoahistoryanalyzecard.component';
import { SacoazerocardsComponent } from './sacoazerocards/sacoazerocards.component';
import { SacoaaddremoveticketsComponent } from './sacoaaddremovetickets/sacoaaddremovetickets.component';
import { SacoatransferComponent } from './sacoatransfer/sacoatransfer.component';
import { SacoaconsolidateComponent } from './sacoaconsolidate/sacoaconsolidate.component';
import { EditscreenlocationComponent } from './editscreenlocation/editscreenlocation.component';
import { ChoosescreenmediaComponent } from './choosescreenmedia/choosescreenmedia.component';
import { EditscreenComponent } from './editscreen/editscreen.component';
import { EditcartcategoryComponent } from './editcartcategory/editcartcategory.component';
import { MatSliderModule } from '@angular/material/slider';
import { EdittranslationComponent } from './edittranslation/edittranslation.component';
import { AddstoretableproductComponent } from './addstoretableproduct/addstoretableproduct.component';
import { AdminbookinginfoComponent } from './adminbookinginfo/adminbookinginfo.component';
import { VieweditcartComponent } from './vieweditcart/vieweditcart.component';
import { ChoosevoucherproductimageComponent } from './choosevoucherproductimage/choosevoucherproductimage.component';
import { BatchactivatedeactivategiftcardsComponent } from './batchactivatedeactivategiftcards/batchactivatedeactivategiftcards.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GiftcarddetailedandhistoryComponent } from './giftcarddetailedandhistory/giftcarddetailedandhistory.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { EditnonfluidlocationComponent } from './editnonfluidlocation/editnonfluidlocation.component';
import { ManageshippingComponent } from './manageshipping/manageshipping.component';
import { CreateeditreservationsComponent } from './createeditreservations/createeditreservations.component';
import { ActivategiftcardComponent } from './activategiftcard/activategiftcard.component';
import { EditphonenumberComponent } from './editphonenumber/editphonenumber.component';
import { ChangeticketnameComponent } from './changeticketname/changeticketname.component';
import { ConfirmviewimageComponent } from './confirmviewimage/confirmviewimage.component';
import { AddticketeventComponent } from './addticketevent/addticketevent.component';
import { PosGiftCardInfoComponent } from './posgiftcardinfo/posgiftcardinfo.component';
import { UploadfilesComponent } from './uploadfiles/uploadfiles.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ViewDrawerComponent } from './viewdrawer/viewdrawer.component';
import { EditMarkdownComponent } from './editmarkdown/editmarkdown.component';
import { EditSquareDrawerComponent } from './editsquaredrawer/editsquaredrawer.component';
import { AddCashDepositComponent } from './addcashdeposit/addcashdeposit.component';
import { VouchermodifiersattributesComponent } from './vouchermodifiersattributes/vouchermodifiersattributes.component';
import { SacoapurchasecreditsComponent } from './sacoapurchasecredits/sacoapurchasecredits.component';
import { UnknownsClerkComponent } from './unknownsclerks/unknownsclerks.component';

@NgModule({
    declarations: [
        ConfirmComponent,
        DialoguesComponent,
        ChooseoptionComponent,
        EditauditionComponent,
        EditpartnerComponent,
        DatepickerComponent,
        EditproductComponent,
        EditcategoryortaxComponent,
        EditlocationComponent,
        EditvoucherproductComponent,
        EditproductcategoryComponent,
        ConfirmtemplatedeleteComponent,
        EdittemplateComponent,
        AddreplacepassComponent,
        ChooseemailattachmentComponent,
        ChooselocationComponent,
        NumberpadComponent,
        CompletetransactionComponent,
        PickattractionComponent,
        PosscangroupComponent,
        PosrefundComponent,
        ChoosegroupComponent,
        TakephotoComponent,
        PasshistoryComponent,
        EditmemberComponent,
        PosshowemployeeComponent,
        ChooseemployeeidComponent,
        PosjournalComponent,
        EdituserComponent,
        ChoosedocumentComponent,
        AddgroupComponent,
        PunchclockdayComponent,
        EditkiosklocationComponent,
        EditkioskcategoryComponent,
        IdleComponent,
        YesnoComponent,
        CreatecustomerComponent,
        ReschedulereservationComponent,
        ChangegroupinfoComponent,
        PosgroupinfoComponent,
        PosbookingsComponent,
        ReschedulereservationadminComponent,
        PosscanorderComponent,
        RebookComponent,
        ChangevouchernameComponent,
        ChoosedateandproductComponent,
        SacoahistoryanalyzecardComponent,
        SacoazerocardsComponent,
        SacoaaddremoveticketsComponent,
        SacoatransferComponent,
        SacoaconsolidateComponent,
        SacoapurchasecreditsComponent,
        EditscreenlocationComponent,
        ChoosescreenmediaComponent,
        EditscreenComponent,
        EditcartcategoryComponent,
        EdittranslationComponent,
        AddstoretableproductComponent,
        AdminbookinginfoComponent,
        VieweditcartComponent,
        ChoosevoucherproductimageComponent,
        BatchactivatedeactivategiftcardsComponent,
        GiftcarddetailedandhistoryComponent,
        EditnonfluidlocationComponent,
        ManageshippingComponent,
        CreateeditreservationsComponent,
        ActivategiftcardComponent,
        EditphonenumberComponent,
        ChangeticketnameComponent,
        ConfirmviewimageComponent,
        AddticketeventComponent,
        PosGiftCardInfoComponent,
        UploadfilesComponent,
        ViewDrawerComponent,
        EditMarkdownComponent,
        EditSquareDrawerComponent,
        AddCashDepositComponent,
        VouchermodifiersattributesComponent,
        UnknownsClerkComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        NgxMaterialTimepickerModule,
        CustomelementsModule,
        CustomdirectivesModule,
        MatSelectModule,
        MatListModule,
        ColorPickerModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatRadioModule,
        LazyLoadImageModule,
        MatChipsModule,
        MatCheckboxModule,
        MatExpansionModule,
        PipesModule,
        WebcamModule,
        MatTableModule,
        MatMenuModule,
        MatSliderModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressBarModule,
    ],
    exports: [
        ConfirmComponent,
        ChooseoptionComponent,
        EditauditionComponent,
        EditpartnerComponent,
        DatepickerComponent,
        EditproductComponent,
        EditcategoryortaxComponent,
        EditlocationComponent,
        EditvoucherproductComponent,
        EditproductcategoryComponent,
        ConfirmtemplatedeleteComponent,
        EdittemplateComponent,
        AddreplacepassComponent,
        ChooseemailattachmentComponent,
        ChooselocationComponent,
        NumberpadComponent,
        CompletetransactionComponent,
        PickattractionComponent,
        PosscangroupComponent,
        PosrefundComponent,
        ChoosegroupComponent,
        TakephotoComponent,
        PasshistoryComponent,
        EditmemberComponent,
        PosshowemployeeComponent,
        ChooseemployeeidComponent,
        PosjournalComponent,
        EdituserComponent,
        ChoosedocumentComponent,
        AddgroupComponent,
        PunchclockdayComponent,
        EditkiosklocationComponent,
        EditkioskcategoryComponent,
        IdleComponent,
        YesnoComponent,
        CreatecustomerComponent,
        ReschedulereservationComponent,
        ChangegroupinfoComponent,
        PosgroupinfoComponent,
        PosbookingsComponent,
        ReschedulereservationadminComponent,
        PosscanorderComponent,
        RebookComponent,
        ChangevouchernameComponent,
        ChoosedateandproductComponent,
        SacoahistoryanalyzecardComponent,
        SacoazerocardsComponent,
        SacoaaddremoveticketsComponent,
        SacoatransferComponent,
        SacoaconsolidateComponent,
        SacoapurchasecreditsComponent,
        EditscreenlocationComponent,
        ChoosescreenmediaComponent,
        EditscreenComponent,
        EditcartcategoryComponent,
        EdittranslationComponent,
        AddstoretableproductComponent,
        AdminbookinginfoComponent,
        VieweditcartComponent,
        ChoosevoucherproductimageComponent,
        BatchactivatedeactivategiftcardsComponent,
        GiftcarddetailedandhistoryComponent,
        EditnonfluidlocationComponent,
        ManageshippingComponent,
        CreateeditreservationsComponent,
        ActivategiftcardComponent,
        EditphonenumberComponent,
        ChangeticketnameComponent,
        ConfirmviewimageComponent,
        AddticketeventComponent,
        PosGiftCardInfoComponent,
        UploadfilesComponent,
        ViewDrawerComponent,
        EditMarkdownComponent,
        EditSquareDrawerComponent,
        AddCashDepositComponent,
        VouchermodifiersattributesComponent,
        UnknownsClerkComponent,
    ],
    providers: [CurrencyPipe],
})
export class DialoguesModule {}
