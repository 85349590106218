<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/partners/manage', false)"
            >
                <mat-icon>home</mat-icon> Home
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/partners/manage/vouchers', false)"
            >
                <mat-icon>receipt</mat-icon> Vouchers
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/partners/manage/vtkto', false)"
            >
                <mat-icon>face</mat-icon> VTKTO
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/partners/manage/partners', false)"
            >
                <mat-icon>people</mat-icon> Manage Partners
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate('/partners/manage/consignment', false)
                "
            >
                <mat-icon>local_activity</mat-icon> Consignment Orders
            </a>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate('/partners/manage/outstanding', false)
                "
            >
                <mat-icon>payments</mat-icon> Oustanding Orders
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
