import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SidenavService } from 'src/app/services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'storegroup-sidenav',
    templateUrl: './storegroupssidenav.component.html',
    styleUrls: [
        './storegroupssidenav.component.scss',
        '../customelements.component.scss',
    ],
})
export class StoregroupsidenavComponent implements OnInit {
    @Input() user: Object;
    @Input() storeGroup: Object;

    @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

    constructor(
        public _loading: LoadingService,
        private _sidenav: SidenavService
    ) {}

    ngOnInit() {
        this._sidenav.setSidenav(this.sidenav);
    }
}
