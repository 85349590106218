<h1 mat-dialog-title class="center pick-title">Employee</h1>
<div mat-dialog-content class="pick-content">
    <div class="pass scrollbar mb15 center-btn">
        <img
            [src]="
                data.item['photo'] ||
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    _misc.defaultPhoto
                )
            "
        />
        <div class="pass-description flex col center jcc">
            <p>{{ data.item['first_name'] }} {{ data.item['last_name'] }}</p>
            <p class="scrollbar invalid-pass" *ngIf="!data.response">
                {{ data.result }}
            </p>
        </div>
    </div>
    <div class="pick-buttons flex">
        <button
            *ngIf="data.response"
            class="pick-finish-button w100 m-0-10"
            mat-raised-button
            (click)="dialogRef.close('friend')"
            color="primary"
        >
            Add Friend
        </button>
        <button
            *ngIf="data.response"
            class="pick-finish-button w100 m-0-10"
            mat-raised-button
            (click)="dialogRef.close('scan')"
            color="primary"
        >
            Scan Employee
        </button>
        <button
            class="pick-finish-button w100 m-0-10"
            mat-raised-button
            (click)="dialogRef.close(undefined)"
            color="warn"
        >
            Cancel Scan
        </button>
    </div>
</div>
