<loading *ngIf="loading" [opacity]="0.5"></loading>

<mat-card>
    <mat-card-content class="punch-top flex">
        <h1>Punch Clock</h1>
        <h1>{{ currentTime }}</h1>
    </mat-card-content>
</mat-card>

<webcam
    [height]="125"
    [width]="125"
    [videoOptions]="webcamOptions"
    [trigger]="triggerObservable"
    [allowCameraSwitch]="false"
    (imageCapture)="handleImage($event)"
    (initError)="webcamInitError($event)"
    class="webcam"
></webcam>

<div class="numpad-content center-btn mb15">
    <p class="numpad-value-title center">Punch Number</p>
    <p class="numpad-value center">
        {{ empNum }}
    </p>

    <div class="numpad pcc">
        <button mat-raised-button color="primary" (click)="add('1')">1</button>
        <button mat-raised-button color="primary" (click)="add('2')">2</button>
        <button mat-raised-button color="primary" (click)="add('3')">3</button>
        <button mat-raised-button color="primary" (click)="add('4')">4</button>
        <button mat-raised-button color="primary" (click)="add('5')">5</button>
        <button mat-raised-button color="primary" (click)="add('6')">6</button>
        <button mat-raised-button color="primary" (click)="add('7')">7</button>
        <button mat-raised-button color="primary" (click)="add('8')">8</button>
        <button mat-raised-button color="primary" (click)="add('9')">9</button>
        <button mat-raised-button color="primary" (click)="clear()">C</button>
        <button mat-raised-button color="primary" (click)="add('0')">0</button>
        <button
            mat-raised-button
            color="primary"
            (click)="remove()"
            class="numpad-icon"
        >
            <mat-icon>backspace</mat-icon>
        </button>
    </div>
</div>

<div class="flex jcc center actions">
    <button
        mat-raised-button
        class="green-btn m-0-10"
        (click)="takeScreenshot()"
    >
        Punch!
    </button>
    <button mat-raised-button class="orange-btn m-0-10" (click)="getPunches()">
        View Day
    </button>
</div>
