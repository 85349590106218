<loading *ngIf="loading" [opacity]="0.5"></loading>

<button mat-icon-button class="ar" onclick="window.print()">
    <mat-icon class="white">print</mat-icon>
</button>

<div class="background-radial flex top-bar print">
    <div class="flex col w40 print">
        <img
            src="assets/mfg-logo.png"
            alt="Maritime Fun Group Logo"
            class="mfg-logo print"
        />
        <h1 class="print invoice-text center white" id="report-header">
            INVOICE
        </h1>
    </div>

    <div class="flex w60 print">
        <div class="print flex col top-bar-mfg">
            <p class="print white">902-963-3939</p>
            <p class="print white">info@maritimefun.com</p>
            <p class="print white">maritimefun.com</p>
        </div>
        <div class="print flex col top-bar-mfg">
            <p class="print white">8885 Cavendish Rd</p>
            <p class="print white">Cavendish, PE</p>
            <p class="print white">C0A 1N0</p>
        </div>
    </div>
</div>

<div class="large-margin print">
    <div class="flex print middle-info">
        <div class="flex half print small-70">
            <div class="flex col half print mb15">
                <p class="print p-label">Billed To</p>
                <p class="print">
                    {{ partner ? partner['partner_name'] : '' }}
                </p>
                <p class="print">
                    {{ partner ? partner['contact_name'] : '' }}
                </p>
                <p class="print">
                    {{ partner ? partner['phone'] : '' }}
                </p>
                <p class="print">
                    {{ partner ? partner['email'] : '' }}
                </p>
            </div>
            <div class="flex col half print">
                <div class="print mb15">
                    <p class="print p-label">Invoice Number</p>
                    <p class="print">{{ order['id'] }}</p>
                </div>
                <div class="print mb15">
                    <p class="print p-label">Date Of Issue</p>
                    <p class="print">
                        {{ order['created_on'] * 1000 | date: 'yyyy-MM-dd' }}
                    </p>
                </div>
            </div>
        </div>

        <div class="half right print small-30">
            <p class="print">Total</p>
            <h1 class="blue print">
                {{ order['total'] || 0.0 | currency }}
            </h1>
        </div>
    </div>

    <mat-table
        #vouchersTable
        [dataSource]="vouchersDataSource"
        class="mat-elevation-z8 w100 mb15 print"
    >
        <!-- Description -->
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef class="print blue">
                Description
            </mat-header-cell>
            <mat-cell *matCellDef="let voucher" class="print">
                {{ voucher['description'] }}
            </mat-cell>
        </ng-container>

        <!-- Price -->
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef class="print blue jfe">
                Price
            </mat-header-cell>
            <mat-cell *matCellDef="let voucher" class="print jfe">
                {{ voucher['price'] || 0.0 | currency }}
            </mat-cell>
        </ng-container>

        <!-- Quantity -->
        <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef class="print blue jfe">
                Quantity
            </mat-header-cell>
            <mat-cell *matCellDef="let voucher" class="print jfe">
                {{ voucher['quantity'] || 1 }}
            </mat-cell>
        </ng-container>

        <!-- Amount -->
        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef class="print blue jfe">
                Amount
            </mat-header-cell>
            <mat-cell *matCellDef="let voucher" class="print jfe">
                {{
                    (voucher['price'] || 0.0) * (voucher['quantity'] || 1)
                        | currency
                }}
            </mat-cell>
        </ng-container>

        <mat-header-row
            class="print header-row"
            *matHeaderRowDef="reportDisplayedColumns"
        ></mat-header-row>
        <mat-row
            class="print row"
            *matRowDef="let row; columns: reportDisplayedColumns"
        ></mat-row>
    </mat-table>

    <div class="flex print end">
        <div class="flex col print first">
            <p class="print blue">Subtotal</p>
            <p class="print blue">Tax</p>
            <p class="print blue">Total</p>
        </div>
        <div class="flex col print">
            <p class="print">
                {{ order['sub_total'] || 0.0 | currency }}
            </p>
            <p class="print">
                {{ order['hst'] || 0.0 | currency }}
            </p>
            <p class="print">
                {{ order['total'] || 0.0 | currency }}
            </p>
        </div>
    </div>

    <div class="w100" *ngIf="order['paid']">
        <button
            mat-raised-button
            color="primary"
            class="center center-btn"
            disabled
        >
            <mat-icon>attach_money</mat-icon>
            Paid {{ order['paid'] * 1000 | date: 'yyyy-MM-dd HH:mm:ss' }}
        </button>
    </div>

    <div class="w100" *ngIf="!order['paid']">
        <button
            mat-raised-button
            color="primary"
            class="center center-btn"
            (click)="payInvoice()"
        >
            <mat-icon>attach_money</mat-icon>
            Pay Invoice
        </button>
    </div>
</div>
