import { Injectable } from '@angular/core';

export type Operation = {
    text: string;
    index: number;
    content?: string;
    children: Block[];
};

export type BlockType = 'each' | 'if';

export type Block = {
    type: BlockType;
    body: string;
    operations: Operation[];
};

@Injectable({
    providedIn: 'root',
})
export class CustomerFacingScreensService {
    constructor() {}

    parseTemplate(
        template: Object,
        data: Object,
        location: Object,
        facility: Object
    ) {
        const content = template['content'];

        const initialScope = {
            ...template['data'],
            ...data,
            facility,
            loc: location,
            client: false,
        };
        return this.parseBlockContentSlice(
            this.parseOperations(content, initialScope),
            initialScope
        );
    }

    parseOperations(content: string, data: Object): string {
        const START_BLOCK = /^{{ ?#/;
        const END_BLOCK = /^{{ ?\//;

        // Get all the operations.
        const operations: Operation[] = [];
        const regex = /{{ ?[#\/].+? ?}}/gs;
        let match: RegExpExecArray;
        while ((match = regex.exec(content))) {
            operations.push({
                text: match[0],
                index: match.index,
                children: [],
            });
        }

        // Split into blocks.
        const blocks: Block[] = [];
        let depth: number = 0;
        let current: Block;
        for (let i = 0; i < operations.length; i++) {
            // Get the location to put the block into.
            let loc = current;
            let operation = loc
                ? current.operations[current.operations.length - 1]
                : null;
            if (loc) {
                for (let j = 0; j < depth; j++) {
                    operation = loc.operations[loc.operations.length - 1];
                    loc = operation.children[operation.children.length - 1];
                }
            }

            // If a start block.
            if (START_BLOCK.test(operations[i].text)) {
                const typeTrimmed = operations[i].text.replace(START_BLOCK, '');
                const val: Block = {
                    operations: [operations[i]],
                    body: '',
                    type: typeTrimmed
                        .slice(0, typeTrimmed.indexOf(' '))
                        .trim() as BlockType,
                };

                // If no location to go into, its the first one.
                if (!loc) {
                    current = val;
                    continue;
                }

                // Else push to the location.
                loc.operations[loc.operations.length - 1].children.push(val);
                depth++;
                continue;
            }

            // If not start (end or continue).
            loc.operations.push(operations[i]);

            // If end block.
            if (END_BLOCK.test(operations[i].text)) {
                // If top level, push the current into the blocks and set current to null.
                if (depth === 0) {
                    blocks.push(current);
                    current = null;
                }

                if (depth > 0) depth--;
            }
        }

        // Loop through the blocks to get the body of the block and each operations content.
        for (let i = 0; i < blocks.length; i++)
            this.getBlockBody(blocks[i], content);

        // Loop through the blocks to replace.
        let offset = 0;
        for (let i = 0; i < blocks.length; i++) {
            const oldLength = content.length;
            content = content.replace(
                blocks[i].body,
                this.blockReplace(content, blocks[i], { ...data }, offset)
            );
            offset += content.length - oldLength;
        }

        return content;
    }

    getBlockBody(block: Block, content: string) {
        // Loop through the operations.
        let indices: number[] = [];
        for (let i = 0; i < block.operations.length; i++) {
            const operation = block.operations[i];

            // If start/end index.
            const last = i == block.operations.length - 1;
            if (i == 0) indices.push(operation.index);
            if (last) {
                indices.push(operation.index + operation.text.length);
                continue;
            }

            // If not last, we need to get the content between.
            operation.content = content.slice(
                operation.index + operation.text.length,
                block.operations[i + 1].index
            );

            // Recursion for all the child blocks in the operation.
            for (let j = 0; j < operation.children.length; j++) {
                this.getBlockBody(operation.children[j], content);
            }
        }

        // Set the body.
        block.body = content.slice(indices[0], indices[1]);
    }

    blockReplace(
        content: string,
        block: Block,
        existingScope: Object = {},
        offset: number = 0
    ): string {
        let scope: Object = { ...existingScope };
        let replaceValue = '';

        // Switch on the type.
        const blockOpening = block.operations[0].text;
        switch (block.type) {
            case 'each': {
                // Get the value to exec, the iterator name, and if needed, the index name.
                let [exec, ...arrIteratorAndIndex] = blockOpening
                    .slice(8, -2)
                    .split('as');
                let index = null;
                let iterator: string = '';
                let iteratorAndIndex: string = '';

                // Fix the split.
                if (arrIteratorAndIndex.length > 1) {
                    const tempExec = [exec];
                    tempExec.push(
                        ...arrIteratorAndIndex.slice(
                            0,
                            arrIteratorAndIndex.length - 1
                        )
                    );
                    exec = tempExec.join('as');
                }
                iteratorAndIndex =
                    arrIteratorAndIndex[arrIteratorAndIndex.length - 1];

                // Fix the iterator and maybe set index.
                const commaIndex = iteratorAndIndex.indexOf(',');
                if (commaIndex > -1) {
                    // If a comma, we need to set index.
                    iterator = iteratorAndIndex.slice(1, commaIndex).trim();
                    index = iteratorAndIndex.slice(commaIndex + 1).trim();
                } else {
                    // Else, we need to slice off the first space.
                    iterator = iteratorAndIndex.trim();
                }

                let arr: any[];

                // If it is an each, use functionEval() on the first param.
                this.functionEval(
                    scope,
                    [exec],
                    (e) => e,
                    (_, val) => {
                        // If errors.
                        if (!val) {
                            throw new Error(
                                `Invalid each loop in template. ${blockOpening}`
                            );
                        }
                        if (!(val instanceof Array)) {
                            throw new Error(
                                `Each executor must be an array. ${blockOpening}`
                            );
                        }

                        // Set to arr.
                        arr = val;
                    }
                );

                // If no array.
                if (!arr) {
                    throw new Error(
                        `Invalid each loop in template. ${blockOpening}`
                    );
                }

                // Loop through the array and set the scope and replace.
                for (let i = 0; i < arr.length; i++) {
                    scope[iterator] = arr[i];
                    if (index) scope[index] = i;
                    replaceValue += this.blockReplaceCore(
                        content,
                        block,
                        scope,
                        offset
                    );
                }

                break;
            }
            case 'if': {
                // Get the value to exec and then functionEval.
                const exec = blockOpening.slice(6, -2);
                let toCheck: any;
                this.functionEval(
                    scope,
                    [exec],
                    (e) => e,
                    (_, val) => (toCheck = val)
                );

                // If toCheck is not falsy.
                if (!!toCheck) {
                    replaceValue += this.blockReplaceCore(
                        content,
                        block,
                        scope,
                        offset
                    );
                }

                break;
            }
            default:
                replaceValue += this.blockReplaceCore(
                    content,
                    block,
                    scope,
                    offset
                );
                break;
        }

        return replaceValue;
    }

    blockReplaceCore(
        content: string,
        block: Block,
        scope: Object,
        offset: number
    ) {
        let replaceValue = '';

        // Loop through all the operations.
        for (let i = 0; i < block.operations.length; i++) {
            const operation = block.operations[i];

            // If the operation does not have any children, we just need to parse the body.
            if (operation.children.length < 1) {
                replaceValue += this.parseBlockContentSlice(
                    operation.content || '',
                    scope
                );
                continue;
            }

            // Get the text before the first child.
            replaceValue += this.parseBlockContentSlice(
                content.slice(
                    operation.index + offset + operation.text.length,
                    operation.children[0].operations[0].index + offset
                ),
                scope
            );

            // Loop through the children.
            for (let j = 0; j < operation.children.length; j++) {
                const child = operation.children[j];

                // If not the first, add the content between this child and the previous one.
                if (j !== 0) {
                    const lastOperation = operation.children[j - 1];
                    replaceValue += this.parseBlockContentSlice(
                        content.slice(
                            lastOperation.operations[0].index +
                                offset +
                                lastOperation.body.length,
                            child.operations[0].index + offset
                        ),
                        scope
                    );
                }

                // Call this function to recursively call this function.
                replaceValue += this.parseBlockContentSlice(
                    this.blockReplace(content, child, { ...scope }, offset),
                    scope
                );
            }

            // If there are no more blocks, we should just break.
            if (block.operations.length === i + 1) break;

            // Get the text after the last child.
            const lastChild = operation.children[operation.children.length - 1];
            const lastChildOperation =
                lastChild.operations[lastChild.operations.length - 1];

            // Slice the correct value.
            replaceValue += this.parseBlockContentSlice(
                content.slice(
                    lastChildOperation.index +
                        offset +
                        lastChildOperation.text.length,
                    operation.index +
                        offset +
                        operation.content.length +
                        operation.text.length
                ),
                scope
            );
        }

        return replaceValue;
    }

    parseBlockContentSlice(slice: string, scope: Object) {
        // Get all the {{...}}.
        const matches: RegExpExecArray[] = [];
        const regex = /{{ ?(.+?) ?}}/gs;
        let match: RegExpExecArray;
        while ((match = regex.exec(slice))) matches.push(match);

        // Eval all the matches.
        this.functionEval(
            scope,
            matches,
            (match) => match[1],
            (match, val) => {
                if (val === null) return;

                // If a val exists, replace.
                slice = slice.replace(match[0], `${val}`);
            }
        );

        return slice;
    }

    validateField(
        cfsData: Object,
        field: any,
        fieldName: any,
        value: any = undefined
    ) {
        // Get the elements that are bound to show the error.
        const errorElements = document.querySelectorAll<HTMLElement>(
            `[error="${fieldName}"]`
        );
        const updateErrorElements = (value: string) => {
            for (let i = 0; i < errorElements.length; i++) {
                errorElements[i].innerText = value;
            }
        };

        const val = value || cfsData[fieldName];

        // If not required and default.
        if (!field.required && val === field.default) {
            updateErrorElements('');
            return true;
        }

        // If no validation.
        if (
            !('validation' in field) ||
            !field.validation ||
            typeof val !== 'string'
        ) {
            updateErrorElements('');
            return true;
        }

        // Try to convert to regex and test to see if it matches.
        try {
            const regex = new RegExp(field['validation']);
            if (!regex.test(val)) {
                updateErrorElements(`${fieldName} is not valid.`);
                return false;
            }
        } catch (_) {
            updateErrorElements(`${fieldName} is not valid.`);
            return false;
        }

        updateErrorElements('');
        return true;
    }

    functionEval(
        scope: Object,
        _items_: any[],
        retCb: (item: any) => any,
        valCb: (item: any, val: any) => void
    ) {
        // If no items.
        if (_items_.length < 1) return;

        this.windowFunctions(scope);

        // If there is location in the keys.
        if ('location' in scope) {
            delete scope['location'];
        }

        // Loop through the keys of the scope. Store window.$key in another object and then set window.$key to scope.$value.
        const keys = Object.keys(scope);
        const stored: Object = {};
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const val = scope[key];

            stored[key] = key in window ? window[key] : undefined;
            window[key] = val;
        }

        // After doing that, we want to do window.Function(...) for each match.
        for (let i = 0; i < _items_.length; i++) {
            const _item_ = _items_[i];

            try {
                valCb(
                    _item_,
                    new Function(`'use strict';return (${retCb(_item_)})`)()
                );
            } catch (_) {
                valCb(_item_, null);
            }
        }

        // After evaluating all matches, we want to re-set all window.$key's with the saved original window.$key.
        for (let i = 0; i < keys.length; i++) window[keys[i]] = stored[keys[i]];
    }

    windowFunctions(scope: Object) {
        const functions = {
            currency: (num: number | string, decimals: number = 2) => {
                const rounded = +(
                    Math.round(+`${+num}e${decimals}`) + `e-${decimals}`
                );
                const fixed = rounded.toFixed(decimals);
                const thousandsSeparated = fixed.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                );
                return `$${thousandsSeparated}`;
            },
        };

        const keys = Object.keys(functions);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            // If already in the scope.
            if (key in scope) return;

            // Add to the scope.
            scope[key] = functions[key];
        }
    }
}
