<h1 mat-dialog-title>Editing User</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Username"
                    [(ngModel)]="data.user['username']"
                    name="username"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="data.user['first_name']"
                    name="first_name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="data.user['last_name']"
                    name="last_name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <mat-select
                    placeholder="Type"
                    [(ngModel)]="data.user['type']"
                    name="type"
                    required
                >
                    <mat-option value="admin">Admin</mat-option>
                    <mat-option value="supervisor">Supervisor</mat-option>
                    <mat-option value="pos_user">POS</mat-option>
                    <mat-option value="parks">Parks Canada</mat-option>
                    <mat-option value="employee">Employee</mat-option>
                    <mat-option value="maintenance">Maintenance</mat-option>
                    <mat-option value="scanning">Scanning</mat-option>
                    <mat-option value="store_group">Store Group</mat-option>
                    <mat-option value="ticketing_partner">
                        Ticketing Partner
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Employee"
                    [matAutocomplete]="autoEmployee"
                    (input)="filterEmployees()"
                    [(ngModel)]="data.user['emp_id']"
                    name="emp_id"
                />
                <mat-autocomplete
                    #autoEmployee="matAutocomplete"
                    class="scrollbar"
                >
                    <mat-option
                        *ngFor="let employee of filteredEmployees"
                        value="{{ employee['first_name'] }} {{
                            employee['last_name']
                        }} ({{ employee['id'] }})"
                    >
                        {{ employee['first_name'] }}
                        {{ employee['last_name'] }} ({{ employee['id'] }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Partner"
                    [matAutocomplete]="auto"
                    (input)="filterPartners()"
                    [(ngModel)]="data.user['partner_id']"
                    name="partner_id"
                />
                <mat-autocomplete #auto="matAutocomplete" class="scrollbar">
                    <mat-option
                        *ngFor="let partner of filteredPartners"
                        value="{{ partner['partner_name'] }} ({{
                            partner['id']
                        }})"
                    >
                        {{ partner['partner_name'] }} ({{ partner['id'] }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Sacoa User"
                    [(ngModel)]="data.user['s_user']"
                    name="s_user"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Sacoa Pass"
                    [(ngModel)]="data.user['s_pass']"
                    name="s_pass"
                />
            </mat-form-field>
            <mat-form-field>
                <mat-select
                    placeholder="Store Group"
                    [(ngModel)]="data.user['store_group']"
                    name="store_group"
                >
                    <mat-option
                        *ngFor="let store of data.storeGroups"
                        [value]="store['id']"
                    >
                        {{ store['friendly_name'] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
