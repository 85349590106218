import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfirmComponent } from '../dialogues/confirm/confirm.component';
import { SnackbarService } from '../services/snackbar.service';
import { HttpResponse } from '@angular/common/http';
import { FormattingService } from '../services/formatting.service';

import cloneDeep from 'lodash-es/cloneDeep';
import * as dayjs from 'dayjs';
import { MiscService } from '../services/misc.service';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    // Other variables.
    loading: boolean = false;
    themeChange: Subscription;
    age: number;
    dayjs = dayjs;
    selectedIndex: number;

    // Resolve variables.
    user: Object;

    // Editing variables.
    employee: Object;
    startingTheme: boolean;
    editedUser: Object;
    editedEmployee: Object;
    currentTheme: boolean;

    // Photo variables.
    editingPhoto: boolean = false;
    screenshotTaken: boolean = false;
    private trigger: Subject<void> = new Subject<void>();
    webcamImage: WebcamImage = null;

    constructor(
        public _auth: AuthService,
        public _misc: MiscService,
        public _sanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService,
        private _formatting: FormattingService,
        private _router: Router
    ) {
        this.user = this._route.snapshot.data.user;

        // Subscribe to theme changes.
        this.themeChange = this._auth.themeChange.subscribe((theme) => {
            this.currentTheme = theme;
        });
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Account - FluidDynamics');

        // Copy the user to a new editable user variable.
        this.editedUser = cloneDeep(this.user);
        this.editedUser['password'] = '';

        // Set the theme.
        this.currentTheme = this.startingTheme =
            (await this._auth.getTheme()) === 'dark' ? true : false;

        // Check if the user has an employee attached to the account.
        if (this.user['emp_id']) {
            let resp: Object = (
                await this._http.getLocal(
                    `hr/employees/?id=${this.user['emp_id']}`
                )
            ).body;

            // Check if this employee exists.
            if (!resp.hasOwnProperty('success') || resp['success']) {
                this.employee = resp;
                this.employee['dob'] = dayjs(this.employee['dob']).toDate();
                this.editedEmployee = cloneDeep(this.employee);
                this.checkAge();
                this.selectedIndex = 1;
            }
        }
    }

    async updateFluidSetting(field: string) {
        // Ask if they are sure.
        const dialogRef = this._dialog.open(ConfirmComponent, {
            data: {
                title: 'Confirm Password',
                content: 'Please type in your password to update this field.',
                password: true,
            },
        });

        let ans = await dialogRef.afterClosed().toPromise();

        if (!ans) return;

        this.loading = true;

        switch (field) {
            case 'theme':
                await this._auth.setTheme(this.currentTheme, true);
                await this._auth.setTheme(this.currentTheme, false);
                this.user['theme'] = this.currentTheme ? 'dark' : 'light';
                this.editedUser['theme'] = this.currentTheme ? 'dark' : 'light';
                break;
            case 'username':
                // Check if it is a valid email.
                if (
                    !`${this.editedUser['username']}`.match(
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    )
                ) {
                    this._snackbar.defaultSnackbar(
                        'Username must be an email and the input you gave was an invalid email.'
                    );
                    this.loading = false;
                    return;
                }

                // Check if email is already taken.
                if (
                    (
                        await this._http.getLocal(
                            `auth/users/email-taken/?email=${this.editedUser['username']}`
                        )
                    ).body['success']
                ) {
                    this._snackbar.defaultSnackbar(
                        'The username you submitted has already been taken.'
                    );
                    this.loading = false;
                    return;
                }

                // Update the username.
                let usernameResp: HttpResponse<Object> =
                    await this._http.patchLocal('auth/users/', {
                        id: this.user['id'],
                        username: this.editedUser['username'],
                    });
                if (usernameResp.status === 204) {
                    this.user['username'] = this.editedUser['username'];
                    this._snackbar.defaultSnackbar(
                        'Successfully updated your username.'
                    );
                } else {
                    this._snackbar.defaultSnackbar(
                        'An error occurred while attempting to update your username.'
                    );
                }
                break;
            case 'password':
                // Check if password is set.
                if (!this.editedUser['password']) {
                    this._snackbar.defaultSnackbar(
                        'Please fill in the new password.'
                    );
                    this.loading = false;
                    return;
                }

                let passwordResp: HttpResponse<Object> =
                    await this._http.postLocal('auth/users/password/', {
                        password: this.editedUser['password'],
                    });
                if (passwordResp.status === 204) {
                    this.editedUser['password'] = '';
                    this._snackbar.defaultSnackbar(
                        'Successfully updated your password.'
                    );
                } else {
                    this._snackbar.defaultSnackbar(
                        'An error occurred while attempting to update your password.'
                    );
                }
                break;
            case 'pin':
                // Check if it is a valid pin.
                if (!`${this.editedUser['pin']}`.match(/^[0-9]{6}$/)) {
                    this._snackbar.defaultSnackbar(
                        'Pin must be six digits long.'
                    );
                    this.loading = false;
                    return;
                }

                // Check if pin is already taken.
                if (
                    (
                        await this._http.postLocal('auth/pin/', {
                            pin: this.editedUser['pin'],
                        })
                    ).body['success']
                ) {
                    this._snackbar.defaultSnackbar(
                        'The pin you submitted has already been taken.'
                    );
                    this.loading = false;
                    return;
                }

                // Update the pin.
                let pinResp: HttpResponse<Object> = await this._http.patchLocal(
                    'auth/users/',
                    {
                        id: this.user['id'],
                        pin: this.editedUser['pin'],
                    }
                );
                if (pinResp.status === 204) {
                    this.user['pin'] = this.editedUser['pin'];
                    this._snackbar.defaultSnackbar(
                        'Successfully updated your pin.'
                    );
                } else {
                    this._snackbar.defaultSnackbar(
                        'An error occurred while attempting to update your pin.'
                    );
                }
                break;
        }

        this.loading = false;
    }

    async updateEmployeeSetting(column: string) {
        // Check if the column has a value.
        if (!this.editedEmployee[column]) {
            this._snackbar.defaultSnackbar('Please enter a valid value.');
            return;
        }

        // Check if the column is an email and if it is valid.
        if (
            column === 'email' &&
            !this.editedEmployee[column].match(
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
        ) {
            this._snackbar.defaultSnackbar(
                'The email you gave was not a valid email address.'
            );
            return;
        }

        this.loading = true;

        // Setup body.
        let body = { id: this.employee['id'] };
        body[column] = this.editedEmployee[column];

        // Check if the column is DOB.
        if (column === 'dob') {
            body[column] = dayjs(this.editedEmployee[column]).format(
                'YYYY-MM-DD'
            );
        }

        // Check if the column is SIN.
        if (column === 'sin') {
            body[column] = this.editedEmployee[column].slice(0, 11);
        }

        // Check if the column is postal code.
        if (column === 'postal_code') {
            body[column] = this.editedEmployee[column].slice(0, 7);
        }

        // Check if the column is a phone number column.
        if (
            ['phone_1', 'phone_2', 'cell', 'e_phone', 'e_phone_2'].includes(
                column
            )
        ) {
            body[column] = this._formatting.numberifyPhone(
                this.editedEmployee[column]
            );
        }

        // Call the API.
        let resp: HttpResponse<Object> = await this._http.patchLocal(
            'hr/employees/',
            body
        );

        if (resp.status === 204) {
            let addedSnackbarMessage = '';

            // Special re-formatting.
            if (column === 'dob') {
                this.editedEmployee[column] = dayjs(
                    this.editedEmployee[column]
                ).toDate();
            }

            // Check if the preferred name has changed.
            if (column === 'preferred_name') {
                // If it has, we need to edit the user name.
                let userNameSuccess: HttpResponse<Object> =
                    await this._http.postLocal(
                        'auth/users/update-preferred-name/',
                        {
                            employee: this.employee['id'],
                            name: this.editedEmployee[column],
                        }
                    );

                // If success.
                if (userNameSuccess.status !== 200) {
                    addedSnackbarMessage +=
                        ' There has been an error updating preferred name. Please contact an admin for help.';
                }
            }

            // Set the employee's column to that of the edited version.
            this.employee[column] = this.editedEmployee[column];

            this._snackbar.durationSnackbar(
                `Successfully updated the column.${addedSnackbarMessage}`,
                null,
                10000
            );
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while updating the column.'
            );
        }

        this.loading = false;
    }

    checkAge() {
        this.age = dayjs().diff(dayjs(this.editedEmployee['dob']), 'year') || 0;
    }

    generateApplicationURL() {
        return `https://www.maritimefun.com/application/?ref=${this.employee['id']}`;
    }

    copiedApplicationURL() {
        this._snackbar.defaultSnackbar('Copied application URL!');
    }

    shareButton(type: 'facebook' | 'twitter') {
        // Get the URL to open.
        const applicationURL = this.generateApplicationURL();
        const links = {
            facebook: `https://www.facebook.com/sharer.php?u=${applicationURL}`,
            twitter: `https://twitter.com/intent/tweet?url=${applicationURL}`,
        };
        const link = links[type];

        // Open the URL.
        window.open(
            link,
            'Share Dialog',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes'
        );
    }

    takeScreenshot() {
        this.trigger.next();
        this.screenshotTaken = true;
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    webcamInitError(error: WebcamInitError) {
        // Show simple snackbar.
        this._snackbar.defaultSnackbar(
            `Error opening webcam: ${error.message}.`
        );

        // Set editingPhoto back to false;
        this.editingPhoto = false;
    }

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
    }

    async updatePhoto() {
        this.loading = true;

        // Get the image from the webcam.
        const arr = this.webcamImage.imageAsDataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const file = new File([u8arr], 'photo', { type: mime });

        // Upload the file.
        const formData = new FormData();
        formData.append('photo', file, 'photo');

        // Call the POST API.
        let resp = await this._http.postLocal('hr/employees/photo/', formData, {
            withCredentials: true, // overwriting this for correct content-type.
        });

        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'Failed updating photo, please try again.'
            );
        } else {
            this._snackbar.defaultSnackbar('Successfully updated photo.');
            this.editedEmployee['photo'] = this.webcamImage.imageAsDataUrl;
            this.editedEmployee['photo_locked'] = '1';
            this.screenshotTaken = false;
            this.editingPhoto = false;
        }

        this.loading = false;
    }

    async createStaffPass() {
        if (this.loading) return;

        this.loading = true;

        // Call the API.
        const response = await this._http.postLocal(
            'hr/employees/tidal/staff-pass/'
        );

        if (response.status !== 200) {
            this._snackbar.defaultSnackbar(
                'Failed generating staff pass, please try again.'
            );
        } else {
            this._snackbar.defaultSnackbar(
                'Successfully generated staff pass.'
            );
            this.editedEmployee['tidal_staff_pass_transaction_id'] =
                response.body['transactionID'];
            this.viewStaffPass();
        }

        this.loading = false;
    }

    viewStaffPass() {
        // this._router.navigate(['/', 'account', 'staff-pass']);
        window.open(
            `https://store.maritimefun.com/pass-preregistration?t=${this.editedEmployee['tidal_staff_pass_transaction_id']}`
        );
    }
}
