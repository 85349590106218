<div class="soft-keyboard-nav">
    <select
        [(ngModel)]="template"
        (change)="changeLayout()"
        class="soft-keyboard-nav-item"
    >
        <option *ngFor="let layout of layouts" [value]="layout">
            {{ layout }}
        </option>
    </select>
    <div class="soft-keyboard-divider"></div>
    <div
        class="soft-keyboard-nav-item soft-keyboard-nav-clear"
        (click)="clearInput()"
    >
        Clear
    </div>
    <div
        class="soft-keyboard-nav-item soft-keyboard-nav-exit"
        data-key-type="enter"
    >
        &times;
    </div>
</div>

<div class="soft-keyboard-container"></div>
