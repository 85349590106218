import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import layouts from './layouts';
import css from './css';

export type Template = 'US International' | 'French';
export type KeyTypes =
    | 'shift'
    | 'caps'
    | 'tab'
    | 'backspace'
    | 'enter'
    | 'alt'
    | 'key';
export interface Layout {
    name: string;
    keys: string[][][];
}

/* Create a class that always returns true on includes (makes it so that
if it is not any other type, it will always default to a key). The static method
means that it can be used on the class and not on the instance of the class (Key.includes()). */
class Key {
    static includes(_?: string) {
        return true;
    }
}

@Component({
    selector: 'soft-keyboard',
    templateUrl: './softkeyboard.component.html',
    styleUrls: ['./softkeyboard.component.scss']
})
export class SoftkeyboardComponent implements OnInit {
    // Input/ViewChild variables.
    @Input() template?: Template;
    @Input() element: HTMLInputElement | HTMLTextAreaElement;

    // Constant/readonly variables.
    readonly centerAmount: number = 3;
    readonly keyTypes = {
        shift: ['Shift'],
        caps: ['Caps'],
        tab: ['Tab', '↹'],
        backspace: ['Backspace', 'Bksp', '←'],
        enter: ['Enter', '↵'],
        alt: ['Alt', 'AltGr'],
        key: Key
    };

    // Keyboard variables.
    layout: Layout;
    layouts: string[] = Object.keys(layouts);
    keyboardContainer: HTMLDivElement;
    keyIndex: number = 0;
    alt: boolean = false;
    shift: boolean = false;
    caps: boolean = false;

    constructor(private _cdr: ChangeDetectorRef) {
        // Replace all new lines in css file.
        let keys = Object.keys(css);
        for (let i = 0; i < keys.length; i++) {
            css[keys[i]] = css[keys[i]].replace(/\n| {4}/g, '');
        }
    }

    ngOnInit() {
        // Get the template and set the layout.
        if (!this.template) this.template = 'US International';
        this.layout = layouts[this.template];

        // Get the keyboardContainer.
        this.keyboardContainer = document.querySelector(
            '.soft-keyboard-container'
        );

        this.buildKeyboard();
    }

    changeLayout() {
        this.layout = layouts[this.template];
        this.rebuildKeyboard();
    }

    keyPressed(key: string, type: KeyTypes) {
        // Switch based on the type.
        switch (type) {
            case 'alt':
                this.alt = !this.alt;
                this.rebuildKeyboard();
                this.updateCSS();
                break;
            case 'caps':
                this.caps = !this.caps;
                this.rebuildKeyboard();
                this.updateCSS();
                break;
            case 'shift':
                this.shift = !this.shift;
                this.rebuildKeyboard();
                this.updateCSS();
                break;
            case 'key':
                this.element.value += key;

                // If the shift key is pressed, unshift.
                if (this.shift) {
                    this.shift = false;
                    this.rebuildKeyboard();
                    this.updateCSS();
                }

                break;
            case 'backspace':
                this.element.value = this.element.value.slice(
                    0,
                    this.element.value.length - 1
                );
                break;
            case 'tab':
                this.element.value += '\t';
                break;
            default:
                return;
        }

        // Focus element.
        this.focus();
    }

    clearInput() {
        this.element.value = '';
        this.focus();
    }

    private buildKeyboard() {
        this.getIndex();

        for (let i = 0; i < this.layout.keys.length; i++) {
            const row = this.layout.keys[i];

            // Create a div.
            const div = document.createElement('div');
            div.style.cssText = css.row;
            div.style.cssText += css.flex;

            if (row.length <= this.centerAmount) {
                div.style.cssText += css.center;
            }

            for (let j = 0; j < row.length; j++) {
                const key = row[j];

                const keyDiv = document.createElement('div');
                keyDiv.style.cssText = css.key;

                if (row.length <= this.centerAmount) {
                    keyDiv.style.cssText += css.keyCenter;
                }

                // If the value doesn't exist (index > length).
                if (key.length >= this.keyIndex + 1) {
                    // Make unicode HTML friendly if needed.
                    const value = key[this.keyIndex].replace(
                        /\\u([a-zA-Z0-9]+)/,
                        '&#$1;'
                    );
                    keyDiv.innerText = value;

                    // Give space CSS if a space.
                    if (value === ' ') keyDiv.style.cssText += css.space;

                    // Get the type.
                    let type: KeyTypes;
                    const types = Object.keys(this.keyTypes);
                    for (let i = 0; i < types.length; i++) {
                        if (this.keyTypes[types[i]].includes(value)) {
                            type = <KeyTypes>types[i];
                            break;
                        }
                    }

                    // Add event listener for the keyDiv.
                    if (type !== 'enter') {
                        keyDiv.addEventListener('click', () =>
                            this.keyPressed(keyDiv.innerHTML, type)
                        );
                    }

                    // Add event listeners for touch/mouse events for CSS.
                    if (['key', 'enter', 'backspace'].includes(type)) {
                        // Giving the div the selected CSS.
                        ['mousedown', 'touchstart'].forEach(e => {
                            keyDiv.addEventListener(
                                e,
                                () => (keyDiv.style.cssText += css.keySelected)
                            );
                        });

                        // Removing the selected CSS from the div.
                        [
                            'mouseup',
                            'touchend',
                            'touchcancel',
                            'mouseout'
                        ].forEach(e => {
                            keyDiv.addEventListener(e, () => {
                                keyDiv.style.cssText = keyDiv.style.cssText.replace(
                                    css.keySelected,
                                    ''
                                );
                            });
                        });
                    }

                    keyDiv.setAttribute('data-key-type', type);
                } else {
                    keyDiv.setAttribute('disabled', 'true');
                    keyDiv.style.cssText += css.keyDisabled;
                }

                div.appendChild(keyDiv);
            }

            this.keyboardContainer.appendChild(div);
        }
    }

    private rebuildKeyboard() {
        while (this.keyboardContainer.firstChild) {
            this.keyboardContainer.removeChild(
                this.keyboardContainer.firstChild
            );
        }

        this.buildKeyboard();
    }

    private getIndex() {
        if (
            // No caps no alt.
            (!this.caps && !this.shift && !this.alt) ||
            (this.caps && this.shift && !this.alt)
        ) {
            this.keyIndex = 0;
        } else if (
            // Caps no alt.
            (!this.caps && this.shift && !this.alt) ||
            (this.caps && !this.shift && !this.alt)
        ) {
            this.keyIndex = 1;
        } else if (
            // No caps alt.
            (!this.caps && !this.shift && this.alt) ||
            (this.caps && this.shift && this.alt)
        ) {
            this.keyIndex = 2;
        } else if (
            // Caps alt.
            (!this.caps && this.shift && this.alt) ||
            (this.caps && !this.shift && this.alt)
        ) {
            this.keyIndex = 3;
        }
    }

    private focus() {
        // Dispatch an input event to make sure Angular updates ngmodel.
        this.element.dispatchEvent(new Event('input'));

        // Make sure it does not open a new keyboard via focus event, so set a window variable to avoid that.
        window['softKeyboardIgnoreFocus'] = true;
        this.element.focus();
        delete window['softKeyboardIgnoreFocus'];
    }

    updateCSS() {
        // Get the elements.
        const altElements: NodeListOf<HTMLElement> = document.querySelectorAll(
            '[data-key-type=alt]'
        );
        const shiftElements: NodeListOf<HTMLElement> = document.querySelectorAll(
            '[data-key-type=shift]'
        );
        const capsElements: NodeListOf<HTMLElement> = document.querySelectorAll(
            '[data-key-type=caps]'
        );

        // Loop through alt elements.
        for (let i = 0; i < altElements.length; i++) {
            if (this.alt) {
                // If it is true, add CSS.
                altElements[i].style.cssText += css.keySelected;
            } else {
                // If false, remove CSS.
                altElements[i].style.cssText = altElements[
                    i
                ].style.cssText.replace(css.keySelected, '');
            }
        }

        // Loop through shift elements.
        for (let i = 0; i < shiftElements.length; i++) {
            if (this.shift) {
                // If it is true, add CSS.
                shiftElements[i].style.cssText += css.keySelected;
            } else {
                // If false, remove CSS.
                shiftElements[i].style.cssText = shiftElements[
                    i
                ].style.cssText.replace(css.keySelected, '');
            }
        }

        // Loop through caps elements.
        for (let i = 0; i < capsElements.length; i++) {
            if (this.caps) {
                // If it is true, add CSS.
                capsElements[i].style.cssText += css.keySelected;
            } else {
                // If false, remove CSS.
                capsElements[i].style.cssText = capsElements[
                    i
                ].style.cssText.replace(css.keySelected, '');
            }
        }
    }
}
