<h1 mat-dialog-title>Editing Partner</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #partnerForm="ngForm">
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Name"
                    [(ngModel)]="data.partner['partner_name']"
                    name="name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Contact Name"
                    [(ngModel)]="data.partner['contact_name']"
                    name="contact_name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    phoneMask
                    placeholder="Phone Number"
                    [(ngModel)]="data.partner['phone']"
                    name="phone"
                    required
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field class="w100">
                <input
                    matInput
                    type="email"
                    placeholder="Email"
                    [formControl]="emailFormControl"
                    name="email"
                    required
                />
                <mat-error
                    *ngIf="
                        emailFormControl.hasError('email') &&
                        !emailFormControl.hasError('required')
                    "
                >
                    Please enter a valid email address.
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    Email is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w100">
                <mat-select
                    placeholder="Type"
                    [(ngModel)]="data.partner['partner_type']"
                    name="type"
                    required
                >
                    <mat-option value="corporate">
                        Corporate
                    </mat-option>
                    <mat-option value="accommodation">
                        Accommodation
                    </mat-option>
                    <mat-option value="scanning">
                        Scanning
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w100">
                <textarea
                    matInput
                    placeholder="Notes"
                    [(ngModel)]="data.partner['notes']"
                    name="notes"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Address"
                    [(ngModel)]="data.partner['address']"
                    name="address"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="City"
                    [(ngModel)]="data.partner['city']"
                    name="city"
                />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Province</mat-label>
                <mat-select
                    [(ngModel)]="data.partner['province']"
                    name="province"
                >
                    <mat-option value="PE">PE</mat-option>
                    <mat-option value="NB">NB</mat-option>
                    <mat-option value="NS">NS</mat-option>
                    <mat-option value="AB">AB</mat-option>
                    <mat-option value="BC">BC</mat-option>
                    <mat-option value="MB">MB</mat-option>
                    <mat-option value="NF">NF</mat-option>
                    <mat-option value="NT">NT</mat-option>
                    <mat-option value="NU">NU</mat-option>
                    <mat-option value="ON">ON</mat-option>
                    <mat-option value="QC">QC</mat-option>
                    <mat-option value="SK">SK</mat-option>
                    <mat-option value="YT">YT</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    postalCodeMask
                    placeholder="Postal Code"
                    [(ngModel)]="data.partner['postal_code']"
                    name="postal_code"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Master User"
                    [matAutocomplete]="masterUserAuto"
                    (input)="filterUsers()"
                    [(ngModel)]="data.partner['master_user']"
                    name="master_user"
                />
                <mat-autocomplete
                    autoActiveFirstOption
                    #masterUserAuto="matAutocomplete"
                    class="scrollbar"
                >
                    <mat-option
                        *ngFor="let user of filteredUsers"
                        [value]="user['id']"
                    >
                        {{ user['first_name'] }} {{ user['last_name'] }} ({{
                            user['id']
                        }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
                *ngIf="data.partner['partner_type'] === 'accommodation'"
            >
                <input
                    matInput
                    type="number"
                    placeholder="Credit Limit"
                    [(ngModel)]="data.partner['credit_limit']"
                    name="credit_limit"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <div class="form-field single">
                <div class="form-field-div jcc w100">
                    <mat-slide-toggle
                        [(ngModel)]="data.partner['vtkto']"
                        name="vtkto"
                        color="primary"
                    >
                        VTKTO Partner
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="form-field single">
                <div class="form-field-div jcc w100">
                    <mat-slide-toggle
                        [(ngModel)]="data.partner['suspended']"
                        name="suspended"
                        color="primary"
                    >
                        Suspended
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="form-field single">
                <div class="form-field-div jcc w100">
                    <mat-slide-toggle
                        [(ngModel)]="data.partner['order_access']"
                        name="order_access"
                        color="primary"
                    >
                        Order Access
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
