<h1 mat-dialog-title>Adding Group</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Group Name"
                [(ngModel)]="group.group_name"
                name="group_name"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <mat-select
                placeholder="Party Size"
                [(ngModel)]="group.party_size"
                name="party_size"
                required
            >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                phoneMask
                placeholder="Mobile"
                [(ngModel)]="group.mobile"
                name="mobile"
                required
            />
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
