import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * This service contains helper functions for snackbars.
 * From opening a snackbar with default settings, to
 * opening a fully customizable snackbar.
 */
@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(private _snackbar: MatSnackBar) {}

    /**
     * Opens a snackbar with a given message and exit button text.
     *
     *
     * @param message The message for the snackbar.
     * @param exit An exit button message for the snackbar (optional).
     */
    defaultSnackbar(message: string, exit?: string) {
        this._snackbar.open(message, exit, {
            duration: 5000,
            panelClass: ['background-radial']
        });
    }

    /**
     * Opens a snackbar with a given message, exit button text, and duration.
     *
     *
     * @param message The message for the snackbar.
     * @param exit An exit button message for the snackbar (null for none).
     * @param duration How long the snackbar should be shown (in milliseconds).
     */
    durationSnackbar(message: string, exit: string, duration: number) {
        this._snackbar.open(message, exit, {
            duration,
            panelClass: ['background-radial']
        });
    }
}
