import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
    pinFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{6}$/),
    ]);
    password: string;
    loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _auth: AuthService
    ) {}

    ngOnInit() {}

    async confirm() {
        this.loading = true;

        // If pin and not ignoring empty pin (or not ignoring empty pin and it has a value) and there are either errors or pin doesn't exist.
        if (
            this.data.pin &&
            (!this.data.ignoreEmptyPin ||
                (this.data.ignoreEmptyPin && this.pinFormControl.value)) &&
            (this.pinFormControl.errors ||
                !(await this._auth.pinExists(this.pinFormControl.value)))
        ) {
            this.loading = false;
            return;
        }

        // If password and checkpassword is false.
        if (
            this.data.password &&
            !(await this._auth.checkPassword(this.password))
        ) {
            this.loading = false;
            return;
        }

        let result: Object;

        if (
            (!this.data.pin ||
                (this.data.pin &&
                    this.data.ignoreEmptyPin &&
                    !this.pinFormControl.value)) &&
            !this.data.password
        ) {
            result = true;
        } else if (this.data.pin && !this.data.password) {
            result = { result: true, pin: this.pinFormControl.value };
        } else if (!this.data.pin && this.data.password) {
            result = { result: true, password: this.password };
        }

        this.loading = false;
        this.dialogRef.close(result);
    }
}
