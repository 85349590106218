<h1 mat-dialog-title>Choose Attachment</h1>
<div mat-dialog-content class="scrollbar">
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Filter"
            [(ngModel)]="filterValue"
            (input)="filter()"
        />
    </mat-form-field>
    <div class="scrollbar flex col">
        <img
            *ngFor="let attachment of filteredAttachments"
            [lazyLoad]="attachment['url']"
            (click)="save(attachment)"
        />
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
