import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LoadingService } from 'src/app/services/loading.service';

import SignaturePad from 'signature_pad';
import { HttpResponse } from '@angular/common/http';
import { MiscService } from 'src/app/services/misc.service';
import {
    Attachment,
    FileviewerService,
} from 'src/app/services/fileviewer.service';

import * as dayjs from 'dayjs';
import escape from 'lodash-es/escape';

interface DocumentInput {
    name: string;
    elementID: string;
    value: string;
}

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
    // Other variables.
    loading: boolean = false;
    loadingOpacity: number = 1;
    loadingText: string = '';
    documentLoading: boolean = true;

    // Resolve variables.
    user: Object;
    employee: Object;
    employeeDocument: Object;
    inputs: DocumentInput[] = [];
    document: Object;

    // Document variables.
    signaturePad: SignaturePad;
    validateSign: boolean = false;

    constructor(
        public _loading: LoadingService,
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _snackbar: SnackbarService,
        private _misc: MiscService,
        private _fileViewer: FileviewerService
    ) {
        this.user = this._route.snapshot.data.user;
        this.employeeDocument = this._route.snapshot.data.employeeDocument;
    }

    async ngOnInit() {
        // Set the title.
        this._title.setTitle('Document - FluidDynamics');

        // Get the employee object.
        this.employee = (
            await this._http.getLocal(`hr/employees/?id=${this.user['emp_id']}`)
        ).body;

        if (!this.employeeDocument['signed']) {
            await this.getDocument();
        } else {
            await this.getSignedDocument();
        }

        this.documentLoading = false;
        this.loadingText = '';
        this.loadingOpacity = 0.5;
    }

    async getSignedDocument() {
        this.loadingText = 'Fetching Signed PDF';

        // Get the document.
        let document: Attachment = await this._fileViewer.getAttachment(
            this.employeeDocument['signed_document'],
            'signed-documents',
            `document-${this.employee['id']}-document-${this.employeeDocument['id']}`
        );

        // Load the attachment from the file viewer service.
        await this._fileViewer.loadAttachment(
            'signed',
            null,
            null,
            null,
            document
        );
    }

    async getDocument() {
        this.loadingText = 'Generating Dynamic PDF';

        // Get the actual document from the employeeDocument.
        let documentResp: Object = (
            await this._http.getLocal(
                `admin/documents/?id=${this.employeeDocument['document']}`
            )
        ).body;

        // Set document if exists, else redirect to account.
        if (
            documentResp.hasOwnProperty('success') &&
            !documentResp['success']
        ) {
            this._loading.navigate('account', true);
            return;
        } else {
            this.document = documentResp;
        }

        // Get inputs.
        if (this.document['inputs']) {
            this.inputs = JSON.parse(this.document['inputs']).map((i) => {
                return { ...i, value: '' };
            });
        }

        this.fetchHTML();
        this.insertSignaturePad();

        // Get the base64 of the temp PDF.
        let base64Resp: Object = (
            await this._http.postLocal('admin/documents/fetch/', {
                html: this.document['html'].replace(
                    this._misc.insertSignatureRegex,
                    `$1$5$7${this._misc.white1x1Image}$8$9>$10`
                ),
            })
        ).body;

        // Load the attachment from the file viewer service.
        await this._fileViewer.loadAttachment('signed', null, null, null, {
            data: base64Resp['base64'],
            name: 'test',
            type: 'pdf',
        });
    }

    async sign() {
        // Check if we validated.
        if (!this.validateSign) {
            this._snackbar.defaultSnackbar(
                'Please validate that you signed in the box.'
            );
            return;
        }

        // Check for empty signature pad.
        if (this.signaturePad.isEmpty()) {
            this._snackbar.defaultSnackbar('Missing signature.');
            return;
        }

        // Inject all of the inputs.
        for (let i = 0; i < this.inputs.length; i++) {
            const input = this.inputs[i];

            // Make sure it is filled out.
            if (!input.value) {
                this._snackbar.defaultSnackbar(
                    `Missing input with name "${input.name}".`
                );
                return;
            }
        }

        this.loading = true;

        // Call the API.
        const resp = await this._http.postLocal('admin/documents/sign/', {
            id: this.employeeDocument['id'],
            inputs: this.inputs.map((i) => {
                return { name: i.name, value: escape(i.value) };
            }),
            signature: this.signaturePad.toDataURL(),
        });

        // If success reload the page.
        if (resp.status === 204) {
            window.location.reload();
        } else {
            this.loading = false;
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to sign the document.'
            );
        }
    }

    clearSignaturePad() {
        this.signaturePad.clear();
    }

    insertSignaturePad() {
        this.signaturePad = new SignaturePad(
            <HTMLCanvasElement>document.getElementById('signature-pad')
        );
    }

    fetchHTML() {
        // Regex the HTML to replace with the actual columns.
        let regex: RegExp = /{{([^{][ ]?.+?[ ]?)}}/g;
        let text: string = this.document['html'];
        let match: RegExpExecArray;
        do {
            match = regex.exec(this.document['html']);
            if (match) {
                let replacing: string;
                let replace = match[1].trim();
                switch (replace) {
                    case 'date':
                        replacing = dayjs().format('YYYY-MM-DD');
                        break;
                    default:
                        replacing = this.employee[replace];
                        break;
                }
                text = text.replace(match[0], replacing);
            }
        } while (match);

        this.document['html'] = text;
    }
}
