import { Directive, HostListener, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[postalCodeMask]'
})
export class PostalCodeMaskDirective implements AfterViewInit {
    constructor(public ngControl: NgControl) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value);
    }

    ngAfterViewInit() {
        // Timeout needed since the value was null.
        setTimeout(() => {
            this.onInputChange(this.ngControl.value);
        }, 100);
    }

    onInputChange(event: string) {
        if (!event) return;

        let newVal: string = event.replace(/[\W_]/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(.{0,3})/, '$1');
        } else {
            newVal = newVal.substring(0, 6);
            newVal = newVal.replace(/^(.{0,3})(.{0,3})/, '$1 $2');
        }
        this.ngControl.valueAccessor.writeValue(newVal.toUpperCase());
    }
}
