<h1 mat-dialog-title class="center">
    {{ data.employee['first_name'] }} {{ data.employee['last_name'] }} ({{
        data.employee['id']
    }})
</h1>
<div mat-dialog-content class="pass-history-content scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
        <!-- In -->
        <ng-container matColumnDef="in">
            <mat-header-cell *matHeaderCellDef>
                In
            </mat-header-cell>
            <mat-cell *matCellDef="let segment" class="cell-overflow scrollbar">
                {{ segment['in'] * 1000 | date: 'HH:mm:ss' }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                Total Hours:
                {{ data.totalHours | number: '1.2-2' }}
            </mat-footer-cell>
        </ng-container>

        <!-- Out -->
        <ng-container matColumnDef="out">
            <mat-header-cell *matHeaderCellDef>
                Out
            </mat-header-cell>
            <mat-cell *matCellDef="let segment" class="cell-overflow scrollbar">
                {{
                    segment['out']
                        ? (segment['out'] * 1000 | date: 'HH:mm:ss')
                        : ''
                }}
            </mat-cell>
        </ng-container>

        <!-- Hours -->
        <ng-container matColumnDef="hours">
            <mat-header-cell *matHeaderCellDef>
                Hours
            </mat-header-cell>
            <mat-cell *matCellDef="let segment" class="cell-overflow scrollbar">
                {{
                    segment['hours'] ? (segment['hours'] | number: '1.2-2') : ''
                }}
            </mat-cell>
        </ng-container>

        <mat-header-row
            *matHeaderRowDef="['in', 'out', 'hours']"
        ></mat-header-row>
        <mat-row
            *matRowDef="let row; columns: ['in', 'out', 'hours']"
        ></mat-row>
        <mat-footer-row *matFooterRowDef="['in']"></mat-footer-row>
    </mat-table>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Close
    </button>
</div>
