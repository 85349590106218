<loading
    *ngIf="loading"
    [text]="loadingText"
    [progress]="loadingProgress"
></loading>

<div class="center theme-background front" *ngIf="theme">
    <mat-card class="rounded center-screen theme-card">
        <mat-card-content class="h100">
            <div class="explanation">
                <h1>Pick a theme!</h1>
                <p>
                    This theme will be set as a preference on your account.
                    Every time you login, you will automatically be using the
                    prefered theme chosen. Your prefered theme can be changed at
                    any time after this choice. When you hover on the theme
                    below, it will show you what that theme will look like in
                    the background. To choose a theme, just click on it!
                </p>
            </div>
            <div class="pick-theme">
                <div
                    class="half light-theme pointer"
                    (click)="chooseTheme(false)"
                >
                    <div class="w100">
                        <mat-icon>brightness_5</mat-icon>
                        <h1>Light Theme</h1>
                    </div>
                </div>
                <div
                    class="half dark-theme pointer"
                    (mouseenter)="_auth.setTheme(true)"
                    (mouseleave)="_auth.setTheme(false)"
                    (click)="chooseTheme(true)"
                >
                    <div class="w100">
                        <mat-icon>brightness_3</mat-icon>
                        <h1>Dark Theme</h1>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<router-outlet></router-outlet>
