import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import * as dayjs from 'dayjs';

interface Data {
    voucher: Object[];
    booking: string;
}

@Component({
    selector: 'app-rebook',
    templateUrl: './rebook.component.html',
    styleUrls: ['./rebook.component.scss'],
})
export class RebookComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Rebook variables.
    date: Date;
    time: string;
    times: Object[];
    bookingInfo: Object;
    formattedBookingDate: string;

    constructor(
        public dialogRef: MatDialogRef<RebookComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    confirm() {
        this.dialogRef.close(this.time);
    }

    async dateChange() {
        this.loading = true;
        this.time = null;

        // Load the dates.
        this.formattedBookingDate = dayjs(this.date).format('YYYY-MM-DD');
        this.bookingInfo = (
            await this._http.getLocal(
                `pos/bookings/?booking=${this.data.booking}&date=${this.formattedBookingDate}`
            )
        ).body;

        // Load the correct dates.
        const date = this.bookingInfo['dates'].find(
            (d) => d['date'] === this.formattedBookingDate && d['open'] == '1'
        );

        // If no date found.
        if (!date) {
            this.date = null;
            this.loading = false;
            this._snackbar.defaultSnackbar(
                'This booking type is not open for the date given.'
            );
            return;
        }

        // Get starting values.
        this.times = [];
        let start: number = dayjs(
            `${date['date']} ${date['open_time']}`
        ).unix();
        const end: number = dayjs(
            `${date['date']} ${date['close_time']}`
        ).unix();

        // Loop to get the values.
        while (start < end) {
            // Get the dayjs object.
            const d = dayjs.unix(start);
            const timeInterval = +this.bookingInfo['type']['time_interval'];
            const timeBreak = +this.bookingInfo['type']['time_break'];
            const totalTime = timeInterval + timeBreak;

            // Check if valid.
            let blocked: boolean = false;
            for (let i = 0; i < this.bookingInfo['blocking'].length; i++) {
                if (
                    start + totalTime >
                        this.bookingInfo['blocking'][i]['start'] &&
                    start < this.bookingInfo['blocking'][i]['end']
                ) {
                    blocked = true;
                }
            }

            // Check if taken is already set.
            let taken: boolean = false;
            const dateTime = d.format('YYYY-MM-DD HH:mm:ss');
            const max =
                this.bookingInfo['type']['max_bookings'] != null
                    ? +this.bookingInfo['type']['max_bookings']
                    : +this.bookingInfo['type']['max_attendance'];

            // If has taken and taken has this date and if max_bookings then get the length, else get the reduced party_size.
            if (
                this.bookingInfo.hasOwnProperty('taken') &&
                this.bookingInfo['taken'].hasOwnProperty(dateTime) &&
                (this.bookingInfo['type']['max_bookings'] != null
                    ? this.bookingInfo['taken'][dateTime].length
                    : this.bookingInfo['taken'][dateTime].reduce(
                          (prev, curr) => prev + +curr['party_size'],
                          0
                      ) >= max)
            ) {
                taken = true;
            }

            // Push the time to the date.
            const endSlot = d.add(totalTime, 'minute');
            const time = `${d.format('HH:mm')} - ${endSlot
                .subtract(timeBreak, 'minute')
                .format('HH:mm')}`;
            const timeTranslate = time
                .match(/(\d{1,2}):(\d{1,2}) \- (\d{1,2}):(\d{1,2})/)
                .slice(1)
                .reduce((prev, curr, i) => {
                    prev[i] = curr;
                    return prev;
                }, {});
            this.times.push({
                time,
                timeTranslate,
                timeTaken: `${this.formattedBookingDate} ${timeTranslate[0]}:${timeTranslate[1]}:00`,
                valid: !blocked && !taken,
            });

            start = endSlot.unix();
        }

        this.loading = false;
    }
}
