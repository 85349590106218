import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Directive({
    selector: '[dragAndDrop]',
})
export class DragAndDropDirective {
    @HostBinding('class.dragging') dragging: boolean = false;
    @Input('input') input: HTMLInputElement;
    @Output() files = new EventEmitter<File[]>();

    constructor() {}

    dragEvent(e: DragEvent, dragging: boolean) {
        e.preventDefault();
        e.stopPropagation();
        this.dragging = dragging;
    }

    @HostListener('dragover', ['$event']) onDragOver(e: DragEvent) {
        this.dragEvent(e, true);
    }

    @HostListener('dragenter', ['$event']) onDragEnter(e: DragEvent) {
        this.dragEvent(e, true);
    }

    @HostListener('dragleave', ['$event']) onDragLeave(e: DragEvent) {
        this.dragEvent(e, false);
    }

    @HostListener('dragend', ['$event']) onDragEnd(e: DragEvent) {
        this.dragEvent(e, false);
    }

    @HostListener('drag', ['$event']) onDrag(e: DragEvent) {
        this.dragEvent(e, this.dragging);
    }

    @HostListener('dragstart', ['$event']) onDragStart(e: DragEvent) {
        this.dragEvent(e, this.dragging);
    }

    @HostListener('drop', ['$event']) onDrop(e: DragEvent) {
        this.dragEvent(e, false);

        // Get the files.
        const droppedFiles =
            e.dataTransfer.files && e.dataTransfer.files.length
                ? Array.from(e.dataTransfer.files)
                : Array.from(e.dataTransfer.items)
                      .filter((i) => i.type === 'file')
                      .map((i) => i.getAsFile());

        // Emit.
        if (droppedFiles.length > 0) this.files.emit(droppedFiles);
    }

    @HostListener('click') onClick() {
        this.input.click();
    }
}
