<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Activate/Deactivate Gift Cards</h1>
<div mat-dialog-content class="scrollbar">
    <h2 class="center red-text mb15">
        Note, this will only set the active flag and nothing else will be
        changed. To activate this card for a shipping order, you have to do that
        through the shipping component.
    </h2>
    <p class="mb15">
        To add a gift card, either scan with a barcode scanner or type in the
        barcode and hit enter. To remove a gift card, hit the
        <mat-icon color="warn">remove_circle</mat-icon> beside the barcode of
        the gift card.
    </p>

    <mat-button-toggle-group
        class="flex-col-2 background-radial w100 mb15"
        [(ngModel)]="type"
    >
        <mat-button-toggle
            [ngClass]="{ 'background-radial': type === 'Activate' }"
            value="Activate"
        >
            Activate
        </mat-button-toggle>
        <mat-button-toggle
            [ngClass]="{ 'background-radial': type === 'Deactivate' }"
            value="Deactivate"
        >
            Deactivate
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Gift Card Barcode"
            [(ngModel)]="giftcard"
            (keyup.enter)="addGiftcard()"
            pattern="^GC[0-9]{12}$"
            required
        />
    </mat-form-field>

    <h2 class="center">List of Gift Cards</h2>
    <div class="mb15">
        <div
            class="w100 h100 center card-background"
            *ngFor="let gc of giftcards"
        >
            <p class="center card">
                {{ gc }}
                <mat-icon
                    color="warn"
                    class="pointer"
                    (click)="removeGiftcard(gc)"
                >
                    remove_circle
                </mat-icon>
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        [disabled]="giftcards.length < 1"
        (click)="batch()"
        color="primary"
    >
        Batch {{ type }}
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
