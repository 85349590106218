<h1 mat-dialog-title>Group Information</h1>
<div mat-dialog-content>
    <p class="pos-group-description">
        Please enter the following details for one person in the group. Note,
        only skip if they are not purchasing admission products.
    </p>

    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="First Name"
                [(ngModel)]="first_name"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Last Name"
                [(ngModel)]="last_name"
                required
            />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                #emailInput
                matInput
                type="email"
                placeholder="Email"
                [(ngModel)]="email"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                phoneMask
                placeholder="Phone"
                [(ngModel)]="phone"
                required
            />
        </mat-form-field>
    </div>

    <p class="red-text" *ngIf="error">{{ error }}</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
    <button mat-raised-button (click)="dialogRef.close(false)" color="warn">
        Skip
    </button>
</div>
