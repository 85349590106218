import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-chooseemployeeid',
    templateUrl: './chooseemployeeid.component.html',
    styleUrls: ['./chooseemployeeid.component.scss']
})
export class ChooseemployeeidComponent implements OnInit {
    // Variables.
    searchValue: string = '';
    loading: boolean = false;
    employee: Object;

    constructor(
        public dialogRef: MatDialogRef<ChooseemployeeidComponent>,
        public _sanitizer: DomSanitizer,
        public _misc: MiscService,
        private _http: HttpService
    ) {}

    ngOnInit() {}

    async search() {
        // Check if searchValue is set or is not a number.
        if (!this.searchValue || !/^[0-9]+$/.test(this.searchValue)) return;

        this.loading = true;

        // Call the API.
        let resp: Object = (
            await this._http.getLocal(`hr/employees/?id=${this.searchValue}`)
        ).body;

        // Set employee based on response.
        if (!resp.hasOwnProperty('success') || resp['success']) {
            this.employee = resp;
        }

        this.loading = false;
    }

    choose() {
        if (this.employee) {
            this.dialogRef.close(this.employee['id']);
        }
    }
}
