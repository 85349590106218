import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Data {
    order: Object;
}

@Component({
    selector: 'app-posscanorder',
    templateUrl: './posscanorder.component.html',
    styleUrls: ['./posscanorder.component.scss'],
})
export class PosscanorderComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PosscanorderComponent>,
        public _misc: MiscService,
        public _sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {
        this.data.order['vouchers'].map((v) => {
            return { ...v, selected: false };
        });
    }

    ngOnInit() {}

    save() {
        // Get the selected ones.
        let selected = this.data.order['vouchers'].filter((v) => v['selected']);

        // Remove the selected property.
        for (let i = 0; i < selected.length; i++) {
            delete selected[i]['selected'];
        }

        // Return the selected passes.
        this.dialogRef.close(selected);
    }
}
