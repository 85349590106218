import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-batchactivatedeactivategiftcards',
    templateUrl: './batchactivatedeactivategiftcards.component.html',
    styleUrls: ['./batchactivatedeactivategiftcards.component.scss'],
})
export class BatchactivatedeactivategiftcardsComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Gift card variables.
    type: 'Activate' | 'Deactivate' = 'Activate';
    giftcard: string = '';
    giftcards: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<BatchactivatedeactivategiftcardsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    addGiftcard() {
        // If card is not set.
        if (!this.giftcard || !/^GC[0-9]{12}$/.test(this.giftcard)) return;

        // Check if already in giftcards.
        if (this.giftcards.includes(this.giftcard)) return;

        // Add the card.
        this.giftcards.push(this.giftcard);
        this.giftcard = '';
    }

    removeGiftcard(giftcard: string) {
        // If not in giftcards.
        if (!this.giftcards.includes(giftcard)) return;

        // Remove.
        const index = this.giftcards.findIndex((gc) => gc === giftcard);
        if (index > -1) this.giftcards.splice(index, 1);
    }

    async batch() {
        // Make sure there are giftcards.
        if (this.giftcards.length < 1) return;

        this.loading = true;

        const errors: string[] = [];
        const success: string[] = [];
        const active = this.type === 'Activate';
        for (let i = 0; i < this.giftcards.length; i++) {
            const resp = await this._http.patchLocal('admin/giftcards/', {
                barcode: this.giftcards[i],
                active,
            });

            // Push to correct array based on success.
            if (resp.status === 204) {
                success.push(this.giftcards[i]);

                // Set active on given giftcards.
                if (this.data['giftcards']) {
                    const index = this.data['giftcards'].findIndex(
                        (gc) => gc['barcode'] === this.giftcards[i]
                    );
                    if (index > -1) {
                        this.data['giftcards'][index]['active'] = active;
                    }
                }
            } else {
                errors.push(this.giftcards[i]);
            }
        }

        // Make the giftcards array the errors array.
        this.giftcards = errors;

        // Snackbar.
        const action = `${this.type.toLowerCase()}d`;
        let message = `Successfully ${action} ${success.length} gift cards and failed ${action} ${errors.length} gift cards.`;
        let time = 10000;
        if (errors.length > 0) {
            message += ` The errors may have occurred due to a few factors: gift card not existant, already ${action}, or a database error. The final factor is very rare. The gift cards that had an error are kept in the array of gift cards.`;
            time += 15000;
        }
        this._snackbar.durationSnackbar(message, null, time);

        this.loading = false;
    }
}
