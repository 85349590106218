<h1 mat-dialog-title>Editing Cart Category</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data['category']['name']"
                name="name"
                required
            />
        </mat-form-field>
        <div class="form-field">
            <p>Discount</p>
            <mat-slider
                thumbLabel
                color="primary"
                min="0"
                max="100"
                name="discount"
                [(ngModel)]="data['category']['discount']"
            >
            </mat-slider>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
