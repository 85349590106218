import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-posrefund',
    templateUrl: './posrefund.component.html',
    styleUrls: ['./posrefund.component.scss']
})
export class PosrefundComponent implements OnInit {
    pinFormControl: FormControl = new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{6}$/)
    ]);
    loading: boolean = false;
    reason: string;

    constructor(
        public dialogRef: MatDialogRef<PosrefundComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _auth: AuthService
    ) {}

    ngOnInit() {}

    async confirm() {
        this.loading = true;

        // Get the user.
        let user = await this._auth.userFromPin(this.pinFormControl.value);

        // If there are errors or pin in incorrect.
        if (!user || this.pinFormControl.errors) {
            this.loading = false;
            return;
        }

        this.loading = false;
        this.dialogRef.close({ user, reason: this.reason });
    }
}
