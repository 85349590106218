<h1 mat-dialog-title>Editing Product</h1>
<div mat-dialog-content class="scrollbar">
    <!-- If barcoded. -->

    <ng-form #productForm="ngForm">
        <div *ngIf="data.type === 'barcoded'">
            <mat-tab-group mat-stretch-tabs class="transaction-items">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">info</mat-icon>
                        Information
                    </ng-template>

                    <mat-form-field class="w100">
                        <input
                            matInput
                            placeholder="Barcode"
                            [(ngModel)]="data.product['barcode']"
                            pattern="^[0-9]+$"
                            name="barcode"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field class="w100">
                        <input
                            matInput
                            placeholder="Description (name)"
                            [(ngModel)]="data.product['name']"
                            name="description"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field class="w100">
                        <input
                            matInput
                            type="number"
                            placeholder="Price"
                            [(ngModel)]="data.product['price']"
                            min="0"
                            step="0.0001"
                            pattern="^[0-9]+(?:\.[0-9]{1,4})?$"
                            name="price"
                            required
                        />
                    </mat-form-field>

                    <div class="flex w100">
                        <div class="center phone-mb15">
                            <h3>Multi Scan</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['multiscan']"
                                name="multiscan"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Create Pass</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['create_pass']"
                                name="create_pass"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Active</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['active']"
                                name="active"
                            ></mat-slide-toggle>
                        </div>
                    </div>

                    <!-- Taxes -->
                    <h2 class="center">Taxes</h2>
                    <p class="center">
                        Double click a selection box to select/deselect all
                        taxes.
                    </p>
                    <div class="inputs mb15">
                        <mat-selection-list
                            #taxList
                            color="primary"
                            class="list-w100 scrollbar max-250"
                            [(ngModel)]="data.product['taxes']"
                            (selectionChange)="
                                _misc.selectionHandler($event, taxList)
                            "
                            name="taxlist"
                        >
                            <mat-list-option
                                #taxListOption
                                *ngFor="let tax of data.taxes"
                                [value]="tax['id']"
                                [ngClass]="{
                                    'background-radial': taxListOption.selected
                                }"
                            >
                                {{ tax['name'] }} ({{ tax['amount'] * 100 }}%)
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">thumb_up</mat-icon>
                        Suggested Products
                    </ng-template>

                    <!-- Suggested Products -->
                    <h2 class="center">Suggested Products</h2>

                    <mat-form-field class="w100">
                        <mat-select
                            placeholder="Facility"
                            [(ngModel)]="filterFacility"
                            name="filterfacility"
                            (selectionChange)="filterSuggestedProducts()"
                        >
                            <mat-option
                                *ngFor="let facility of data.facilities"
                                [value]="facility"
                            >
                                {{ facility['name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="w100">
                        <mat-chip-list #sendToChipList>
                            <mat-chip
                                *ngFor="
                                    let product of data.product[
                                        'suggested_products'
                                    ]
                                "
                                [removable]="true"
                                (removed)="removeSuggestedProduct(product)"
                            >
                                {{ product['name'] }} ({{ product['id'] }})
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input
                                matInput
                                id="filterChoices"
                                placeholder="Filter"
                                [matAutocomplete]="sendToAuto"
                                (input)="filterSuggestedProducts()"
                                [(ngModel)]="filter"
                                [matChipInputFor]="sendToChipList"
                                name="filteringsuggestedproducts"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #sendToAuto="matAutocomplete"
                                (optionSelected)="addSuggestedProduct()"
                                class="scrollbar"
                            >
                                <mat-option
                                    *ngFor="let product of filteredProducts"
                                    [value]="product"
                                >
                                    {{ product['name'] }} ({{ product['id'] }})
                                </mat-option>
                            </mat-autocomplete>
                        </mat-chip-list>
                        <mat-hint
                            matTooltip="To add suggested products, type anything and then choose an option.
                            To remove suggested products, either press backspace while in
                            the input, or press the X button on the chip."
                        >
                            Hover for instructions.
                        </mat-hint>
                    </mat-form-field>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="data.type === 'button'">
            <mat-tab-group mat-stretch-tabs class="transaction-items">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">info</mat-icon>
                        Information
                    </ng-template>

                    <div class="inputs">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder="Description (name)"
                                [(ngModel)]="data.product['name']"
                                name="description"
                                required
                            />
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                matInput
                                type="number"
                                placeholder="Price"
                                [(ngModel)]="data.product['price']"
                                min="0"
                                step="0.0001"
                                pattern="^[0-9]+(?:\.[0-9]{1,4})?$"
                                name="price"
                                required
                            />
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                matInput
                                type="number"
                                placeholder="Party Size"
                                [(ngModel)]="data.product['party_size']"
                                min="0"
                                name="party_size"
                                required
                            />
                        </mat-form-field>
                    </div>
                    <div class="inputs">
                        <mat-form-field>
                            <mat-select
                                placeholder="Category Facility"
                                [(ngModel)]="data.categoryFacility"
                                (selectionChange)="facilityChange()"
                                name="categoryFacility"
                                required
                            >
                                <mat-option
                                    *ngFor="let facility of data.facilities"
                                    [value]="facility"
                                >
                                    {{ facility['name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="data.categoryFacility">
                            <mat-select
                                placeholder="Location"
                                [(ngModel)]="data.categoryLocation"
                                (selectionChange)="locationChange()"
                                name="categoryLocation"
                                required
                            >
                                <mat-option
                                    *ngFor="let location of filteredLocations"
                                    [value]="location"
                                >
                                    {{ location['name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="data.categoryLocation">
                            <mat-select
                                placeholder="Category"
                                [(ngModel)]="data.category"
                                name="category"
                                required
                            >
                                <mat-option
                                    *ngFor="let category of filteredCategories"
                                    [value]="category"
                                >
                                    {{ category['name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="inputs">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder="Voucher Type (Season, Weekly, double, triple, grandslam, etc)"
                                [(ngModel)]="data.product['voucher_type']"
                                name="voucher_type"
                            />
                        </mat-form-field>

                        <mat-form-field>
                            <input
                                matInput
                                placeholder="Facility (SEL, SW, SNR, SNRXL, etc)"
                                [(ngModel)]="data.product['facility']"
                                name="facility"
                            />
                        </mat-form-field>
                    </div>

                    <div class="inputs">
                        <mat-form-field>
                            <mat-select
                                placeholder="Sell At (ex: CBAZ Laser Product)"
                                [(ngModel)]="data.product['sell_at']"
                                name="sell_at"
                            >
                                <mat-option
                                    *ngFor="let at of data.sellAt"
                                    [value]="at['abbr']"
                                >
                                    {{ at['full'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="flex w100">
                        <div class="center phone-mb15">
                            <h3>Multi Scan</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['multiscan']"
                                name="multiscan"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Create Pass</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['create_pass']"
                                name="create_pass"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Active</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['active']"
                                name="active"
                            ></mat-slide-toggle>
                        </div>
                    </div>

                    <div class="flex w100">
                        <div class="center phone-mb15">
                            <h3>Print Voucher</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['print_voucher']"
                                name="print_voucher"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Reservation</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['reservation']"
                                name="reservation"
                            ></mat-slide-toggle>
                        </div>
                        <div class="center phone-mb15">
                            <h3>Gatelog Entry</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['gatelog_entry']"
                                name="gatelog_entry"
                            ></mat-slide-toggle>
                        </div>
                    </div>

                    <div class="flex w100">
                        <div class="center phone-mb15">
                            <h3>Gift Card</h3>
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data.product['giftcard']"
                                name="giftcard"
                            ></mat-slide-toggle>
                        </div>
                    </div>

                    <div class="inputs">
                        <mat-form-field *ngIf="data.product['print_voucher']">
                            <input
                                matInput
                                placeholder="Print Voucher Expiry (PHP strtotime)"
                                [(ngModel)]="
                                    data.product['print_voucher_expiry']
                                "
                                name="print_voucher_expiry"
                            />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select
                                placeholder="Booking Type"
                                [(ngModel)]="data.product['booking']"
                                name="booking"
                            >
                                <mat-option
                                    *ngFor="let bType of data.bookingTypes"
                                    [value]="bType['id']"
                                >
                                    {{ bType['name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Taxes -->
                    <h2 class="center">Taxes</h2>
                    <p class="center">
                        Double click a selection box to select/deselect all
                        taxes.
                    </p>
                    <div class="inputs mb15">
                        <mat-selection-list
                            #taxList
                            color="primary"
                            class="list-w100 scrollbar max-250"
                            [(ngModel)]="data.product['taxes']"
                            (selectionChange)="
                                _misc.selectionHandler($event, taxList)
                            "
                            name="taxlist"
                        >
                            <mat-list-option
                                #taxListOption
                                *ngFor="let tax of data.taxes"
                                [value]="tax['id']"
                                [ngClass]="{
                                    'background-radial': taxListOption.selected
                                }"
                            >
                                {{ tax['name'] }} ({{ tax['amount'] * 100 }}%)
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">visibility</mat-icon>
                        Button Appearance
                    </ng-template>

                    <div class="flex phone-block">
                        <div class="w100 phone-mb15">
                            <h2 class="center">Button Colour</h2>
                            <p class="center">
                                This is the colour that the button in the POS
                                will be.
                            </p>
                            <div class="w100 flex jcc">
                                <span
                                    [cpToggle]="true"
                                    [cpDialogDisplay]="'inline'"
                                    [cpAlphaChannel]="'disabled'"
                                    [(colorPicker)]="
                                        data.product['button_colour']
                                    "
                                ></span>
                            </div>
                        </div>
                        <div class="w100 flex col">
                            <div class="center phone-mb15">
                                <h3>Text Colour</h3>
                                <mat-label
                                    class="mat-slide-toggle-content double-toggle-text"
                                >
                                    White&nbsp;
                                </mat-label>
                                <mat-slide-toggle
                                    color="primary"
                                    [(ngModel)]="data.product['text_colour']"
                                    name="text_colour"
                                ></mat-slide-toggle>
                                <mat-label
                                    class="mat-slide-toggle-content double-toggle-text"
                                >
                                    &nbsp;Black
                                </mat-label>
                            </div>

                            <div class="center flex phone-block phone-mb15">
                                <div class="m-0-10 phone-mb15">
                                    <input
                                        #buttonImage
                                        type="file"
                                        [hidden]="true"
                                        accept="image/png, image/jpeg, image/pjpeg"
                                        (change)="
                                            addButtonImage(buttonImage.files[0])
                                        "
                                    />
                                    <button
                                        mat-raised-button
                                        color="primary"
                                        (click)="buttonImage.click()"
                                    >
                                        Upload Image
                                    </button>
                                </div>
                                <div class="m-0-10">
                                    <button
                                        mat-raised-button
                                        color="warn"
                                        (click)="
                                            data.product['button_image'] = null
                                        "
                                    >
                                        Remove Image
                                    </button>
                                </div>
                            </div>

                            <div class="center">
                                <h3>Button Preview</h3>
                                <div class="center phone-mb15">
                                    <h3>Display Image</h3>
                                    <mat-slide-toggle
                                        color="primary"
                                        [(ngModel)]="displayImages"
                                        name="displayImages"
                                    ></mat-slide-toggle>
                                </div>
                                <button
                                    mat-raised-button
                                    class="new-button-product-preview"
                                    [ngStyle]="{
                                        background:
                                            data.product['button_image'] &&
                                            displayImages
                                                ? 'url(' +
                                                  data.product['button_image'] +
                                                  ')'
                                                : data.product[
                                                      'button_colour'
                                                  ] || '#ffffff',
                                        color: data.product['text_colour']
                                            ? 'black'
                                            : 'white'
                                    }"
                                    [ngClass]="{
                                        'button-product-image':
                                            data.product['button_image'] &&
                                            displayImages
                                    }"
                                >
                                    {{ data.product['name'] }}
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">thumb_up</mat-icon>
                        Suggested Products
                    </ng-template>

                    <!-- Suggested Products -->
                    <h2 class="center">Suggested Products</h2>

                    <mat-form-field class="w100">
                        <mat-select
                            placeholder="Facility"
                            [(ngModel)]="filterFacility"
                            name="filterfacility"
                            (selectionChange)="filterSuggestedProducts()"
                        >
                            <mat-option
                                *ngFor="let facility of data.facilities"
                                [value]="facility"
                            >
                                {{ facility['name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="w100">
                        <mat-chip-list #sendToChipList>
                            <mat-chip
                                *ngFor="
                                    let product of data.product[
                                        'suggested_products'
                                    ]
                                "
                                [removable]="true"
                                (removed)="removeSuggestedProduct(product)"
                            >
                                {{ product['name'] }} ({{ product['id'] }})
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input
                                matInput
                                id="filterChoices"
                                placeholder="Filter"
                                [matAutocomplete]="sendToAuto"
                                (input)="filterSuggestedProducts()"
                                [(ngModel)]="filter"
                                [matChipInputFor]="sendToChipList"
                                name="filteringsuggestedproducts"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #sendToAuto="matAutocomplete"
                                (optionSelected)="addSuggestedProduct()"
                                class="scrollbar"
                            >
                                <mat-option
                                    *ngFor="let product of filteredProducts"
                                    [value]="product"
                                >
                                    {{ product['name'] }} ({{ product['id'] }})
                                </mat-option>
                            </mat-autocomplete>
                        </mat-chip-list>
                        <mat-hint
                            matTooltip="To add suggested products, type anything and then choose an option.
                            To remove suggested products, either press backspace while in
                            the input, or press the X button on the chip."
                        >
                            Hover for instructions.
                        </mat-hint>
                    </mat-form-field>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
