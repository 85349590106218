import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';
import { TakephotoComponent } from '../takephoto/takephoto.component';
import { DomSanitizer } from '@angular/platform-browser';

interface Data {
    member: Object;
}

@Component({
    selector: 'app-editmember',
    templateUrl: './editmember.component.html',
    styleUrls: ['./editmember.component.scss']
})
export class EditmemberComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    originalPhoto: string;
    originalType: string;

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditmemberComponent>,
        public _misc: MiscService,
        public _sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe(_ => this.askClose());

        // Store original photo.
        this.originalPhoto = this.data.member['photo'];
        this.originalType = this.data.member['pass_type'];
    }

    ngOnInit() {}

    hasChanges() {
        return (
            this.form.dirty || this.originalPhoto !== this.data.member['photo']
        );
    }

    async changePhoto() {
        // Open the photo dialog.
        const dialogRef = this._dialog.open(TakephotoComponent);

        let ans = await dialogRef.afterClosed().toPromise();

        // If ans is set, then overwrite the member's image.
        if (ans) {
            this.data.member['photo'] = ans;
        }
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.'
                }
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close({
                member: this.data.member,
                typeChanged: this.data.member['pass_type'] !== this.originalType
            });
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
