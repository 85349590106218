<h1 mat-dialog-title>Choose Date and Product</h1>
<div mat-dialog-content>
    <p class="mb15">{{ data.content }}</p>

    <mat-form-field class="w100" *ngIf="!data.hideDate">
        <input
            matInput
            [placeholder]="data.datePlaceholder || 'Date'"
            [matDatepicker]="picker"
            (click)="picker.open()"
            (focus)="picker.open()"
            [(ngModel)]="date"
            required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Product"
            [matAutocomplete]="auto"
            (input)="filterProducts()"
            [(ngModel)]="product"
            required
        />
        <mat-autocomplete #auto="matAutocomplete" class="scrollbar">
            <mat-option
                *ngFor="let product of filteredProducts"
                [value]="product['description'] + ' (' + product['id'] + ')'"
            >
                {{ product['description'] }} ({{ product['id'] }})
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
