import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    voucher: Object;
}

@Component({
    selector: 'app-vouchermodifiersattributes',
    templateUrl: './vouchermodifiersattributes.component.html',
    styleUrls: ['./vouchermodifiersattributes.component.scss'],
})
export class VouchermodifiersattributesComponent implements OnInit {
    per_person_modifiers: Object[];
    attributes: Object[];
    initialised: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<VouchermodifiersattributesComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        // Initialise.
        this.initialise('attributes');
        this.initialise('per_person_modifiers');
        this.initialised = true;
    }

    initialise(type: 'per_person_modifiers' | 'attributes') {
        // If not there.
        if (
            !this.data.voucher.hasOwnProperty(type) ||
            !this.data.voucher[type]
        ) {
            this[type] = null;
            return;
        }

        // If string, turn into object[].
        if (typeof this.data.voucher[type] === 'string') {
            this[type] = JSON.parse(this.data.voucher[type]);
        }

        // If object, clone it.
        if (typeof this.data.voucher[type] === 'object') {
            this[type] = cloneDeep(this.data.voucher[type]);
        }

        // If single object, make it an array.
        if (typeof this[type] === 'object' && !Array.isArray(this[type])) {
            this[type] = [this[type]];
        }

        // Make sure it is not empty.
        if (!this[type] || this[type].length < 1) {
            this[type] = null;
        }
    }
}
