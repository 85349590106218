<div mat-dialog-content class="scrollbar">
    <h2 class="center mb15">Voucher Attributes/Modifiers</h2>
    <h3 class="center mb15">{{ data.voucher['v_id'] }}</h3>

    <p *ngIf="!initialised">Initialising...</p>

    <div *ngIf="initialised">
        <div class="b1px mb15">
            <h3 class="center underline">Attributes</h3>
            <div *ngIf="attributes">
                <p class="center" *ngFor="let attribute of attributes">
                    <span class="bold">{{ attribute['name'] }}&nbsp;</span>
                    {{ attribute['value'] }}
                </p>
            </div>
            <p class="center" *ngIf="!attributes">No attributes.</p>
        </div>

        <div class="b1px">
            <h3 class="center underline">Per Person Modifiers</h3>
            <div *ngIf="per_person_modifiers">
                <div
                    [ngClass]="{ mb15: per_person_modifiers.length !== i + 1 }"
                    *ngFor="let person of per_person_modifiers; let i = index"
                >
                    <h3 class="center">Guest {{ i + 1 }}</h3>
                    <p class="center" *ngFor="let modifier of person">
                        <span class="bold">
                            {{
                                modifier['attr_name'] || modifier['name']
                            }}&nbsp;
                        </span>
                        {{ modifier['val'] }}
                    </p>
                </div>
            </div>
            <p class="center" *ngIf="!per_person_modifiers">
                No per person modifiers.
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Close
    </button>
</div>
