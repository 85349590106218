import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import cloneDeep from 'lodash-es/cloneDeep';
import { HttpService } from 'src/app/services/http.service';

interface Data {
    images: string[];
}

@Component({
    selector: 'app-choosevoucherproductimage',
    templateUrl: './choosevoucherproductimage.component.html',
    styleUrls: ['./choosevoucherproductimage.component.scss'],
})
export class ChoosevoucherproductimageComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Filter variables.
    filterValue: string;
    filteredImages: string[];

    constructor(
        public dialogRef: MatDialogRef<ChoosevoucherproductimageComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _snackbar: SnackbarService,
        private _http: HttpService
    ) {}

    ngOnInit() {
        this.filteredImages = cloneDeep(this.data.images);
    }

    save(image: string) {
        this.dialogRef.close(image);
    }

    filter() {
        // If no filter, clone all.
        if (!this.filterValue) {
            this.filteredImages = cloneDeep(this.data.images);
            return;
        }

        // If filter, filter.
        this.filteredImages = this.data.images.filter((image) =>
            image.toLowerCase().includes(this.filterValue.toLowerCase())
        );
    }

    async addMedia(files: FileList | File[]) {
        if (!files || files.length < 1) return;
        const file = files[0];

        // Get allowed files.
        let allowedFiles = ['image/jpeg', 'image/png', 'image/gif'];

        // Check if file type is allowed.
        if (!allowedFiles.includes(file.type)) return;

        // Check if the file name is already taken.
        if (this.data.images.find((m) => m === file.name)) {
            this._snackbar.defaultSnackbar(
                `File ${file.name} is already taken in the uploaded media. Please rename it and try again.`
            );
            return;
        }

        this.loading = true;

        // Create the formdata.
        const formData = new FormData();
        formData.append('image', file);

        // Upload the files.
        let resp = await this._http.postLocal(
            'pos/vouchers/products/images/',
            formData,
            {
                withCredentials: true, // Overwriting this for correct content-type.
            }
        );

        // If there are errors, show them.
        if (resp.status === 200) {
            const body = resp.body;

            if (body['success']) {
                this._snackbar.defaultSnackbar(
                    'Successfully uploaded the image.'
                );
                this.data.images.unshift(body['file']);
                this.filter();
            } else {
                this._snackbar.defaultSnackbar(body['response']);
            }
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to upload the file(s).'
            );
        }

        this.loading = false;
    }
}
