<loading *ngIf="loading" [opacity]="0.5"></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <h1 class="center">Contact Us</h1>
        <p class="center">
            Please note, this is mainly for feedback, bug reporting and general
            inquiries about the website. This is not for employment questions or
            any non-website related inquiries.
        </p>

        <mat-card>
            <mat-card-content>
                <mat-form-field class="w100">
                    <input
                        matInput
                        placeholder="Subject"
                        [(ngModel)]="email.subject"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="w100">
                    <textarea
                        matInput
                        placeholder="Body"
                        [(ngModel)]="email.body"
                        class="scrollbar"
                        rows="15"
                        required
                    ></textarea>
                </mat-form-field>
                <mat-form-field class="w100 mb15">
                    <div class="w100 pb15">
                        <input
                            #attachmentInput
                            type="file"
                            multiple
                            [hidden]="true"
                            accept="application/pdf, image/png, image/jpeg, image/pjpeg"
                            (change)="addAttachments(attachmentInput.files)"
                        />
                        <button
                            mat-raised-button
                            color="primary"
                            (click)="attachmentInput.click()"
                        >
                            Upload Files/Screenshots
                        </button>
                    </div>
                    <mat-chip-list #chipList>
                        <mat-chip
                            *ngFor="let file of attachments"
                            [removable]="true"
                            (removed)="removedChip(file)"
                        >
                            {{ file.name }}
                            <mat-icon matChipRemove class="pointer">
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </mat-form-field>

                <button mat-raised-button color="primary" (click)="contact()">
                    Contact
                </button>
            </mat-card-content>
        </mat-card>
    </div>
</div>
