import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-confirmtemplatedelete',
    templateUrl: './confirmtemplatedelete.component.html',
    styleUrls: ['./confirmtemplatedelete.component.scss']
})
export class ConfirmtemplatedeleteComponent implements OnInit {
    newTemplate: number;
    deleteFromServer: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ConfirmtemplatedeleteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _auth: AuthService
    ) {}

    ngOnInit() {}

    async confirm() {
        // Check if there are any facilities or products using this template.
        if (
            this.data['changedFacilities'].length ||
            this.data['changedProducts'].length
        ) {
            // If there are, but they have not chosen a new template, just return.
            if (this.newTemplate) {
                this.dialogRef.close({
                    template: this.newTemplate,
                    deleteFromServer: this.deleteFromServer
                });
            }
        } else {
            this.dialogRef.close({ deleteFromServer: this.deleteFromServer });
        }
    }
}
