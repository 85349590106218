<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id']"
            >
                <mat-icon>home</mat-icon> Home
            </a>

            <!-- <a
                *ngIf="partner['vtkto'] > 0"
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id'] + '/activation'"
            >
                <mat-icon>power_settings_new</mat-icon> Activation
            </a>

            <a
                *ngIf="partner['vtkto'] > 0"
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="
                    '/partners/' + partner['id'] + '/activation-history'
                "
            >
                <mat-icon>history</mat-icon> Activation History
            </a> -->

            <a
                *ngIf="partner['order_access'] > 0"
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id'] + '/order'"
            >
                <mat-icon>receipt</mat-icon> Order Vouchers
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id'] + '/order-history'"
            >
                <mat-icon>change_history</mat-icon> Order History
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id'] + '/statement'"
            >
                <mat-icon>description</mat-icon> Statement
            </a>

            <a
                *ngIf="
                    partner['master_user'] === user['id'] ||
                    user['type'] === 'admin'
                "
                class="dark-sidenav-text"
                mat-raised-button
                [routerLink]="'/partners/' + partner['id'] + '/profile'"
            >
                <mat-icon>person</mat-icon> Profile and Users
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
