<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate(
                        '/store-groups/' + storeGroup['id'],
                        false
                    )
                "
            >
                <mat-icon>home</mat-icon> Home
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate(
                        '/store-groups/' + storeGroup['id'] + '/bookings',
                        false
                    )
                "
            >
                <mat-icon>event</mat-icon> Bookings
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate(
                        '/store-groups/' + storeGroup['id'] + '/voucher-search',
                        false
                    )
                "
            >
                <mat-icon>search</mat-icon> Voucher Search
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate(
                        '/store-groups/' + storeGroup['id'] + '/transactions',
                        false
                    )
                "
            >
                <mat-icon>receipt_long</mat-icon> Transactions
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="
                    _loading.navigate(
                        '/store-groups/' + storeGroup['id'] + '/promo-codes',
                        false
                    )
                "
            >
                <mat-icon>local_offer</mat-icon> Promo Codes
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
