<h1 mat-dialog-title class="center">
    Pass History for {{ data.member['first_name'] }}
    {{ data.member['last_name'] }}
</h1>
<div mat-dialog-content class="pass-history-content scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
        <!-- Facility -->
        <ng-container matColumnDef="facility">
            <mat-header-cell *matHeaderCellDef>
                Facility
            </mat-header-cell>
            <mat-cell *matCellDef="let entry" class="cell-overflow scrollbar">
                {{ entry['location']['facility']['name'] }}
            </mat-cell>
        </ng-container>

        <!-- Location -->
        <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef>
                Location
            </mat-header-cell>
            <mat-cell *matCellDef="let entry" class="cell-overflow scrollbar">
                {{ entry['location']['name'] }} ({{ entry['location']['id'] }})
            </mat-cell>
        </ng-container>

        <!-- Result -->
        <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef>
                Result
            </mat-header-cell>
            <mat-cell *matCellDef="let entry" class="cell-overflow scrollbar">
                {{ entry['result'] }}
            </mat-cell>
        </ng-container>

        <!-- Time -->
        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Time
            </mat-header-cell>
            <mat-cell *matCellDef="let entry" class="cell-overflow scrollbar">
                {{ entry['time'] * 1000 | date: 'yyyy-MM-dd HH:mm:ss' }}
            </mat-cell>
        </ng-container>

        <mat-header-row
            *matHeaderRowDef="
                ['facility', 'location', 'result', 'time'];
                sticky: true
            "
        ></mat-header-row>
        <mat-row
            *matRowDef="
                let row;
                columns: ['facility', 'location', 'result', 'time']
            "
        ></mat-row>
    </mat-table>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Close
    </button>
</div>
