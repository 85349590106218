<h1 mat-dialog-title [ngClass]="{ 'kiosk-picture-title': data && data.kiosk }">
    Take Photo
</h1>
<div
    mat-dialog-content
    [ngClass]="{ 'kiosk-picture-container': data && data.kiosk }"
>
    <div class="relative">
        <webcam
            *ngIf="!screenshotTaken"
            class="max100"
            [ngClass]="{ 'rotate-270': data && data.kiosk }"
            [height]="640"
            [width]="380"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
            (initError)="webcamInitError($event)"
            (cameraSwitched)="cameraSwitched($event)"
        ></webcam>
        <div class="snapshot" *ngIf="webcamImage && screenshotTaken">
            <img [src]="webcamImage.imageAsDataUrl" class="center-btn" />
        </div>

        <div *ngIf="!currentDevice" class="loading-take-photo">
            <mat-icon class="select-none" [inline]="true" color="primary">
                sync
            </mat-icon>
        </div>
    </div>
    <div class="center center-btn">
        <button
            mat-raised-button
            color="primary"
            [disabled]="!currentDevice"
            (click)="takeScreenshot()"
            *ngIf="!screenshotTaken"
        >
            Take Photo
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="screenshotTaken = !screenshotTaken"
            *ngIf="screenshotTaken && (!data || !data.kiosk)"
        >
            Take Another
        </button>
    </div>
</div>
<div mat-dialog-actions class="fr" *ngIf="!data || !data.kiosk">
    <button mat-raised-button (click)="save()" color="primary">
        Save Choice
    </button>
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
