import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { HttpService } from 'src/app/services/http.service';

interface Data {
    location: Object;
}

@Component({
    selector: 'app-pickattraction',
    templateUrl: './pickattraction.component.html',
    styleUrls: ['./pickattraction.component.scss']
})
export class PickattractionComponent implements OnInit {
    attractions: string[];

    constructor(
        public dialogRef: MatDialogRef<PickattractionComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        public _http: HttpService
    ) {}

    ngOnInit() {
        this.attractions = this.data.location['attractions'].split(',');
    }

    choose(attraction: string) {
        this.dialogRef.close(attraction);
    }
}
