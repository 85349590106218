import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
    constructor(private _http: HttpService) {}

    ngOnInit() {
        this._http.redirectHome();
    }
}
