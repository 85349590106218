import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpService } from '../services/http.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { MiscService } from '../services/misc.service';

type QuestionType =
    | 'yesNo'
    | 'multi'
    | 'name'
    | 'companyName'
    | 'phone'
    | 'covidAlertMsg'
    | 'success'
    | 'acknowledgeDisagree';

type UserType =
    | 'Employee'
    | 'Guest'
    | 'Third Party/Contractor'
    | 'Camp/Programming';

interface Question {
    type: QuestionType;
    id: string;
    body: string;
    safeBody?: SafeHtml;
    answers?: string[];
    answer?: string;
    cb: (answer: string) => void;
}

@Component({
    selector: 'app-ripleys-employee-screening',
    templateUrl: './ripleys-employee-screening.component.html',
    styleUrls: ['./ripleys-employee-screening.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RipleysEmployeeScreeningComponent implements OnInit {
    timeOut: any;
    questions: Question[] = [
        {
            type: 'multi',
            id: 'Screening Type',
            body: 'Are you a (select one)',
            answers: [
                'Employee',
                'Guest',
                'Third Party/Contractor',
                'Camp/Programming',
            ],
            cb: (answer) => {
                // Switch based on the answer of the question (for multi, it's the answers array above).
                switch (answer as UserType) {
                    case 'Employee':
                    case 'Guest':
                    case 'Third Party/Contractor':
                        // Go to the first question for the contractor/employee/guest.
                        this.goToQuestion('Name', answer);
                        break;
                    case 'Camp/Programming':
                        // Go to the first question for the camp/programming answer.
                        this.goToQuestion('Guardian Name', answer);
                        break;
                }
            },
        },
        //Entering name as an employee or Contractor
        {
            type: 'name',
            id: 'Name',
            body: 'Enter the following:',
            answers: [],
            cb: (answer) => {
                // If no answer.
                if (!answer) return;

                // If guest or third party/contractor.
                const userType = this.getUserType();
                if (userType === 'Guest') {
                    this.goToQuestion('Phone Number', answer);
                    return;
                }

                // If third party/contractor.
                if (userType === 'Third Party/Contractor') {
                    this.goToQuestion('Company Name', answer);
                    return;
                }

                // Else.
                this.goToQuestion('Worsening Symptoms', answer);
            },
        },

        // Entering phone.
        {
            type: 'phone',
            id: 'Phone Number',
            body: 'Enter the following:',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                if (answer) {
                    this.goToQuestion('Worsening Symptoms', answer);
                }
            },
        },

        //Entering name as a Guardian
        {
            type: 'name',
            id: 'Guardian Name',
            body: 'Name of Parent/Guardian',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                if (answer) {
                    this.goToQuestion('Student/Camper Name', answer);
                }
            },
        },

        {
            type: 'name',
            id: 'Student/Camper Name',
            body: 'Name of Student/Camper',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                if (answer) {
                    this.goToQuestion(
                        'Worsening Symptoms Student/Camp',
                        answer
                    );
                }
            },
        },

        /*
         ********************************************************************************
         * Answering Yes or No question as an employee, Guest or Third Party/Contractor *
         ********************************************************************************
         * */

        {
            type: 'yesNo',
            id: 'Worsening Symptoms',
            body: `<span><strong>Do I have any of the following new or worsening symptoms or
                    signs? Symptoms should not be chronic or related to other known
                    causes or conditions.</strong></span>

                <li>Fever or Chills</li>
                <li>Difficulty breathing or shortness of breath</li>
                <li>Cough</li>
                <li>Sore throat, trouble swallowing</li>
                <li>Runny nose/stuffy nose or nasal congestion</li>
                <li>Decrease or loss of smell or taste</li>
                <li>Nausea, vomiting, diarrhoea, abdominal pain</li>
                <li>Not feeling well, extreme tiredness, sore muscles</li>
                <li>Pink Eye</li>
            `,
            answers: [],
            cb: (answer) => {
                // If the answer is no.
                if (answer === 'No') {
                    this.goToQuestion('Travelled Outside Canada', answer);
                    return;
                }

                // If yes and employee.
                if (this.getUserType() === 'Employee') {
                    this.goToQuestion('Symptoms Alert Message', answer);
                } else {
                    this.goToQuestion(
                        'Symptoms Alert Message non-employee',
                        answer
                    );
                }

                this.backToFirstQuestion();
            },
        },

        {
            type: 'covidAlertMsg',
            id: 'Symptoms Alert Message',
            body: 'Please leave the property, contact manager and seek further medical screening.',
            answers: [],
            cb: (answer) => {
                switch (answer) {
                    case 'StartOver':
                        // If Back To Bigging button is clicked the clear the timeout or wait 10s and go to home page (first question) which is basically Screening Type.
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },

        {
            type: 'covidAlertMsg',
            id: 'DisagreeMsg Type',
            body: 'Please contact Security or your Ripley’s Contact.',
            answers: [],
            cb: (answer) => {
                // If Back To Bigging button is clicked the clear the timeout or wait 10s and go to home page (first question) which is basically Screening Type.
                switch (answer) {
                    case 'StartOver':
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },
        {
            type: 'covidAlertMsg',
            id: 'Symptoms Alert Message non-employee',
            body: 'Please return home and follow Public Health Advice.',
            answers: [],
            cb: (answer) => {
                switch (answer) {
                    case 'StartOver':
                        // If Back To Bigging button is clicked the clear the timeout or wait 10s and go to home page (first question) which is basically Screening Type.
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },

        {
            type: 'yesNo',
            id: 'Travelled Outside Canada',
            body: 'In the last 14 days have travelled outside of Canada AND been advised to quarantine per the federal quarantine requirements?',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'Yes':
                        // After displaying the Covid alert msg, Go to the home page to start over.
                        if (this.getUserType() === 'Employee') {
                            //This alert msg is only for employee
                            this.goToQuestion('Symptoms Alert Message', answer);
                            this.backToFirstQuestion();
                            break;
                        }
                        //Following msg is for other such as, guest, third party or camp/programmer
                        else {
                            this.goToQuestion(
                                'Symptoms Alert Message non-employee',
                                answer
                            );
                            this.backToFirstQuestion();
                            break;
                        }
                    case 'No':
                        // Go to the next question for the employee, guest or third party.
                        this.goToQuestion('Close Contact', answer);
                        break;
                }
            },
        },

        {
            type: 'yesNo',
            id: 'Close Contact',
            body: `
                    <li>Does someone in your household have Covid-19 symptoms or are awaiting results of a Covid-19 test?</li><br>
                    <li>Have you been notified as a close contact of someone with COVID -19 or been told to stay home and self-isolate?</li><br>
                    <li>In the last 10 days have you tested positive on a rapid antigen test or a home-based self-testing kit?</li>`,

            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'Yes':
                        // After displaying the Covid alert msg, Go to the home page to start over.

                        if (this.getUserType() === 'Employee') {
                            this.goToQuestion('Symptoms Alert Message', answer);
                            this.backToFirstQuestion();
                            break;
                        } else {
                            this.goToQuestion(
                                'Symptoms Alert Message non-employee',
                                answer
                            );
                            this.backToFirstQuestion();
                            break;
                        }

                    case 'No':
                        // We know that the first question is always going to be the type of the person who is there (tree).
                        // With that information, we know that we want to go to the following question (which needs to be refactored to a different way)
                        // if we are an employee or guest.
                        if (this.getUserType() === 'Employee') {
                            this.goToQuestion(
                                'YesNo Success Msg Employee',
                                answer
                            );
                            this.backToFirstQuestion();
                            break;
                        } else if (this.getUserType() === 'Guest') {
                            this.goToQuestion(
                                'YesNo Success Msg Guest',
                                answer
                            );
                            this.backToFirstQuestion();
                            break;
                        } else {
                            this.goToQuestion('Company Name', answer);
                            break;
                        }
                }
            },
        },

        {
            type: 'success',
            id: 'YesNo Success Msg Employee',
            body: ' You may enter. In the event you develop any symptoms throughout your time at the Aquarium, please leave the premises immediately and contact your manager. ',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'StartOver':
                        // After printing an success msg, go to home page to start over
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },

        {
            type: 'success',
            id: 'YesNo Success Msg Guest',
            body: 'You may enter. In the event you develop any symptoms throughout your time at the Aquarium, please leave the premises immediately and follow Public Health Advice.',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'StartOver':
                        // After printing an success msg, go to home page to start over
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },

        /*
         ******************************************************************
         * Ending Yes No type Question for employee, Guest or Third Party *
         ******************************************************************
         */

        /*
         **************************************************************
         * Starting Acknowledge Disagree type question for Contractor *
         * ************************************************************
         */

        {
            type: 'companyName',
            id: 'Company Name',
            body: 'Company Name',
            answers: [],
            cb: (answer) => {
                if (answer) {
                    this.goToQuestion('General', answer);
                }
            },
        },

        {
            type: 'acknowledgeDisagree',
            id: 'General',
            body: `<span><strong>General</strong></span>
            
                <li>Report unsafe work conditions to Security or Ripley’s Contact.</li>
                <li>Only perform work/duties agreed upon with Ripley’s.</li>
                <li>No use of any chemicals as they can be harmful to the animals.</li>
                <li>Be conscious of Employees, animals, exhibits, acrylic & ticket holders.</li>
                <li>Follow Employee direction and PA messages during emergencies.</li>
                <li>Evacuation Muster Point is at Gate 5 of the Rogers Centre.</li>
            `,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'Disagree':
                        // After displaying alert msg, go to home page to start over
                        this.goToQuestion('DisagreeMsg Type', answer);
                        this.backToFirstQuestion();
                        break;
                    case 'Acknowledge':
                        //or go to next question to Acknowledge or Disagree (Contractor)
                        this.goToQuestion('Access', answer);
                        break;
                }
            },
        },

        {
            type: 'acknowledgeDisagree',
            id: 'Access',
            body: `<span><strong>Access</strong></span>
              
                <li>Sign in/out with Security upon arrival and departure and wear ID badge.</li>
                <li>Agree to a voluntary bag check upon request at arrival/departure by Security. </li>
                <li>Surrender photo ID for keys/access card/radio. Immediately report lost items
                to Security.</li>
                <li>Use of vehicles on property is prohibited (unless approved by Management).</li> 
             `,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'Disagree':
                        // After displaying alert msg, go to home page to start over
                        this.goToQuestion('DisagreeMsg Type', answer);
                        this.backToFirstQuestion();
                        break;

                    case 'Acknowledge':
                        //or go to next question to Acknowledge or Disagree (Contractor)
                        this.goToQuestion('Equipment', answer);

                        break;
                }
            },
        },

        {
            type: 'acknowledgeDisagree',
            id: 'Equipment',
            body: `<span><strong>Equipment</strong></span>
            
                <li>SDS and first aid is located at the Loading Dock/Security Desk.</li>
                <li>Use of Ripley’s equipment and tools without prior consent is prohibited.  </li>
                <li>Loading Dock procedures are indicated on Bay 1’s door and must be adhered to.</li>
                <li>Hot work and use of heavy/loud/intrusive equipment is prohibited (unless approved by Management).</li> 
            `,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'Disagree':
                        this.goToQuestion('DisagreeMsg Type', answer);
                        this.backToFirstQuestion();
                        break;

                    case 'Acknowledge':
                        this.goToQuestion('Acknowledge Success Msg', answer);
                        this.backToFirstQuestion();

                        break;
                }
            },
        },

        {
            type: 'success',
            id: 'Acknowledge Success Msg',
            body: 'You may enter the Aquarium. If you have any questions, please see Security or your Ripley’s Contact.',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question (for multi, it's the answers array above).
                switch (answer) {
                    case 'StartOver':
                        // After displaying success message go to the home page, clicking button or wait 10s
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        break;
                }
            },
        },

        /*
         ************************************************************
         * Ending Acknowledge Disagree type question for Contractor *
         * **********************************************************
         */

        /*
         *****************************************************
         * Start Answering Yes or No Question as a Guardian  *
         * ***************************************************
         */
        {
            type: 'yesNo',
            id: 'Worsening Symptoms Student/Camp',
            body: `<span><strong>Do I / someone in my household / or camper/student have any of the
             following new or worsening symptoms or signs? Symptoms should not be chronic or 
             related to other known causes or conditions.</strong></span>
            
                <li>Fever or Chills</li>
                <li>Difficulty breathing or shortness of breath</li>
                <li>Cough</li>
                <li>Sore throat, trouble swallowing</li>
                <li>Runny nose/stuffy nose or nasal congestion</li>
                <li>Decrease or loss of smell or taste</li>
                <li>Nausea, vomiting, diarrhoea, abdominal pain</li>
                <li>Not feeling well, extreme tiredness, sore muscles</li>
                <li>Pink Eye</li>
            `,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question (for multi, it's the answers array above).
                switch (answer) {
                    case 'Yes':
                        //
                        this.goToQuestion(
                            'Symptoms Alert Message non-employee',
                            answer
                        );
                        this.backToFirstQuestion();
                        break;
                    case 'No':
                        // Go to the first question for the contractor.
                        this.goToQuestion(
                            'Travelled Outside Canada Student/Camp',
                            answer
                        );
                        break;
                }
            },
        },

        {
            type: 'yesNo',
            id: 'Travelled Outside Canada Student/Camp',
            body: 'In the last 14 days have I / someone in my household / or camper/student travelled outside of Canada AND been advised to quarantine per the federal quarantine requirements?',
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question (for multi, it's the answers array above).
                switch (answer) {
                    case 'Yes':
                        // Go to the first question for the employee.
                        this.goToQuestion(
                            'Symptoms Alert Message non-employee',
                            answer
                        );
                        this.backToFirstQuestion();
                        break;
                    case 'No':
                        // Go to the first question for the contractor.
                        this.goToQuestion('Close Contact Student/Camp', answer);
                        break;
                }
            },
        },

        {
            type: 'yesNo',
            id: 'Close Contact Student/Camp',
            body: `<li>Do I / camper/student in your household have Covid-19 symptoms or are awaiting results of a Covid-19 test?</li><br>
                    <li>Have I / someone in my household / or camper/student been notified as a close contact of someone with COVID-19 or been told to stay home and self-isolate?</li><br>
                    <li>In the last 10 days have I / someone in my household / or camper/student tested positive on a rapid antigen test or a home-based self-testing kit?</li>`,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question (for multi, it's the answers array above).
                switch (answer) {
                    case 'Yes':
                        this.goToQuestion(
                            'Symptoms Alert Message non-employee',
                            answer
                        );
                        this.backToFirstQuestion();
                        break;
                    case 'No':
                        // Go to the first question for the contractor.
                        this.goToQuestion('YesNo Success Msg Guardian', answer);
                        this.backToFirstQuestion();
                        break;
                }
            },
        },

        {
            type: 'success',
            id: 'YesNo Success Msg Guardian',
            body: `<h5 style="text-align: center;">     Welcome to the Aquarium!</h5> </br>
                   <h5 style="text-align: center;">Please proceed to the Camp Check-In.</h5>`,
            answers: [],
            cb: (answer) => {
                // Switch based on the answer of the question.
                switch (answer) {
                    case 'StartOver':
                        clearTimeout(this.timeOut);
                        this.goToQuestion('Screening Type');
                        // this.backToFirstQuestion(true);
                        break;
                }
            },
        },
        /*
         ********************************************
         * Ending Yes or No Question as a Guardian  *
         * ******************************************
         */
    ];

    currentQuestion: Question;
    survey: Question[] = [];

    constructor(
        private _sanitizer: DomSanitizer,
        private _misc: MiscService,
        private _http: HttpService
    ) {}

    ngOnInit(): void {
        this.goToQuestion('Screening Type');
    }

    getUserType(): UserType {
        return this.survey[0].answer as UserType;
    }

    async goToQuestion(id: string, answer: string = null) {
        // Find the question by the ID.
        const question = this.questions.find((q) => q.id === id);

        if (!question) {
            // console.log('Question does not exist');
            return;
        }
        // Set the answer for the current question and push this current question to the survey.
        if (this.currentQuestion && answer) {
            this.currentQuestion.answer = answer;
            this.survey.push(cloneDeep(this.currentQuestion));
        }
        // Animate this correctly with the currently existing animations.
        const questionElement =
            document.querySelector<HTMLDivElement>('.r-question');
        if (questionElement) {
            questionElement.classList.add('r-question-hidden');
            await this._misc.wait(500);
            questionElement.classList.remove('r-question-hidden');
        }

        // Set the current question to the new question that we passed by ID.
        this.currentQuestion = cloneDeep(question);

        // Inject the currentQuestion body (as HTML) into the div with question-text as a class.
        this.currentQuestion.safeBody = this._sanitizer.bypassSecurityTrustHtml(
            this.currentQuestion.body
        );
    }

    backToHome() {
        this.goToQuestion('Screening Type');
        this.survey = [];
    }

    async backToFirstQuestion() {
        this.timeOut = setTimeout(() => {
            this.goToQuestion('Screening Type');
        }, 10000);

        // console.log({ testSurvey: this.survey });
        await this._http.postLocal('ripleys-employee-screening-v2/', {
            data: this.survey.map((q) => {
                return {
                    type: q.type,
                    id: q.id,
                    body: q.body,
                    answer: q.answer,
                };
            }),
        });
        this.survey = [];
    }

    inputCB(input: HTMLInputElement) {
        this.currentQuestion.cb(input.value);
        input.value = '';
    }
}
