import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';
import { AuthService } from 'src/app/services/auth.service';
import { NewTemplate } from 'src/app/admin/products/templates/templates.component';

@Component({
    selector: 'app-edittemplate',
    templateUrl: './edittemplate.component.html',
    styleUrls: ['./edittemplate.component.scss']
})
export class EdittemplateComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    newTemplate: NewTemplate = <NewTemplate>{};

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EdittemplateComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _dialog: MatDialog,
        private _auth: AuthService
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe(_ => this.askClose());
    }

    ngOnInit() {}

    fileInputChange(files: FileList | File[]) {
        // Since it is a single input, it should only get a single file.
        let file: File = files[0];

        // Check if the file is a PNG file.
        if (file.type !== 'image/png') return;

        // Set new file to this file.
        this.newTemplate.file = file;
        this.newTemplate.fileName = file.name;
    }

    hasChanges() {
        return (
            this.form.dirty ||
            this.newTemplate.file ||
            this.newTemplate.fileName
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.'
                }
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close({
                template: this.data['template'],
                newFile: Object.keys(this.newTemplate).length
                    ? this.newTemplate
                    : null
            });
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
