<h1 mat-dialog-title>Analyze Card</h1>
<div mat-dialog-content>
    <p class="mb15">
        Scan a card to show the details of it as well as the history of the
        card.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            (keyup.enter)="search()"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>

    <div
        class="flex-panel-entry jcc aic mb15"
        *ngIf="balanceLoading === 'loading'"
    >
        <div class="mini-spinner flex jcc">
            <mat-icon [inline]="true" color="primary">sync</mat-icon>
        </div>
        Loading Balance
    </div>

    <div class="mb15" *ngIf="balanceLoading === 'loaded'">
        <h2 class="center">Card #: {{ balance['body']['card'] }}</h2>
        <p class="center">
            Status: {{ balance['body']['statusName'] }} ({{
                balance['body']['status']
            }}) | Tickets: {{ balance['body']['tickets'] }} | Credits:
            {{ balance['body']['credits'] }} | Bonus:
            {{ balance['body']['bonus'] }} | Courtesy:
            {{ balance['body']['courtesy'] }} | Minutes:
            {{ balance['body']['minutes'] }}
        </p>
    </div>

    <div class="flex-panel-entry jcc aic" *ngIf="historyLoading === 'loading'">
        <div class="mini-spinner flex jcc">
            <mat-icon [inline]="true" color="primary">sync</mat-icon>
        </div>
        Loading History
    </div>

    <div *ngIf="historyLoading === 'loaded'">
        <div
            *ngIf="
                history['body'].hasOwnProperty('error') &&
                history['body'].constructor.name === 'Object'
            "
        >
            <h2 class="center">{{ history['body']['error'] }}</h2>
        </div>
        <div
            *ngIf="history['body'].constructor.name === 'Array'"
            class="card-history"
        >
            <h2 class="center">Card History</h2>
            <div class="w100 scrollbar overflow">
                <div class="flex aic m-10-0">
                    <p class="bold">Date</p>
                    <p class="bold">Operation</p>
                    <p class="bold">Quality and Concept</p>
                    <p class="bold">Amount</p>
                    <p class="bold">Counter</p>
                </div>
                <div
                    class="flex aic"
                    *ngFor="let entry of history['body']; let i = index"
                    [ngStyle]="{
                        background:
                            entry['groupIndex'] % 2 === 1
                                ? 'rgba(0, 0, 0, 0.25)'
                                : 'transparent'
                    }"
                >
                    <p>{{ entry['date'] }}</p>
                    <p>{{ entry['opname'] }}</p>
                    <p>{{ entry['quantity'] }} x {{ entry['concept'] }}</p>
                    <p>{{ entry['amount'] }}.00</p>
                    <p>{{ entry['countername'] }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
