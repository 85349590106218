<div class="background full center main-card jcc">
    <mat-card class="rounded">
        <mat-card-title class="large-font">Page Not Found</mat-card-title>
        <mat-card-content>
            <p class="medium-font">
                The page you were looking for does not exist.
            </p>
            <div>
                <button
                    mat-raised-button
                    class="margin-15"
                    color="primary"
                    (click)="_location.back()"
                >
                    Go Back
                </button>
                <button
                    mat-raised-button
                    class="margin-15"
                    color="primary"
                    (click)="_http.redirectHome()"
                >
                    Home
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
