<h1 mat-dialog-title>Editing Member</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <img
            class="center-btn mb15"
            [src]="
                data.member['photo'] ||
                this._sanitizer.bypassSecurityTrustResourceUrl(
                    _misc.defaultPhoto
                )
            "
        />
        <button
            mat-raised-button
            class="center-btn mb15"
            color="primary"
            (click)="changePhoto()"
        >
            Change Photo
        </button>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="data.member['first_name']"
                    name="first_name"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="data.member['last_name']"
                    name="last_name"
                    required
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <mat-select
                    placeholder="Pass Type"
                    [(ngModel)]="data.member['pass_type']"
                    name="pass_type"
                    required
                >
                    <mat-option value="SnRXL"> Slide and Ride XL </mat-option>
                    <mat-option value="SnR"> Slide and Ride </mat-option>
                    <mat-option value="SEL"> Sandspit </mat-option>
                    <mat-option value="SW"> Shining Waters </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select
                    placeholder="Height Code"
                    [(ngModel)]="data.member['height_code']"
                    name="height_code"
                    required
                >
                    <ng-container *ngIf="data.member['pass_type']">
                        <!-- Only for SW -->
                        <mat-option
                            *ngIf="data.member['pass_type'] === 'SW'"
                            value='Over48"'
                        >
                            Over 48"
                        </mat-option>

                        <!-- Only for non-SW -->
                        <ng-container *ngIf="data.member['pass_type'] !== 'SW'">
                            <mat-option value='Over 57"'> Over 57" </mat-option>
                            <mat-option value='48" - 57"'>
                                48" - 57"
                            </mat-option>
                        </ng-container>

                        <!-- Default values. -->
                        <mat-option value='42" - 48"'> 42" - 48" </mat-option>
                        <mat-option value='36" - 42"'> 36" - 42" </mat-option>
                        <mat-option value='Under 36"'> Under 36" </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="inputs" *ngIf="data.member['pass_type'] === 'SnRXL'">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Yippees Card"
                    [(ngModel)]="data.member['yippees_card']"
                    name="yippees_card"
                />
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
