import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MiscService } from 'src/app/services/misc.service';
import { HttpService } from 'src/app/services/http.service';
import { ContextmenuService } from 'src/app/services/contextmenu.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/dialogues/confirm/confirm.component';
import { HttpResponse } from '@angular/common/http';
import { EditvoucherproductComponent } from 'src/app/dialogues/editvoucherproduct/editvoucherproduct.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ChoosevoucherproductimageComponent } from 'src/app/dialogues/choosevoucherproductimage/choosevoucherproductimage.component';

import cloneDeep from 'lodash-es/cloneDeep';
import * as JsBarcode from 'jsbarcode';

interface NewProduct {
    prefix: 'VOUCH';
    price: number;
    taxes: number[] | string;
    template: number;
    description: string;
    facility: string;
    product_type: string;
    height_code: string;
    sales_vector: 'Consignment' | 'Web';
    valid_at: number[] | string;
    sell_at: string;
    redeem_at: string;
    quick_1: string;
    quick_2: string;
    quick_3: string;
    party_size: number;
    redeem_for: string;
    max_scans: number;
    gatelog_entry: boolean;
    foxy_cat: string;
    active: boolean;
    partner_accessible: boolean;
    create_pass: boolean;
    multiscan: boolean;
    category: string;
    allowed_attributes: string;
    required_attributes: string;
    can_change_quantity: boolean;
    shipping: boolean;
    giftcard: boolean;
    image?: string;
}

@Component({
    selector: 'voucherproductsbase',
    templateUrl: './voucherproductsbase.component.html',
    styleUrls: ['./voucherproductsbase.component.scss'],
})
export class VoucherproductsbaseComponent implements OnInit {
    // Other variables.
    this = this;
    foxyCategories: string[];
    foxyCategoriesFiltered: string[];

    // New product variables.
    newProduct: NewProduct = <NewProduct>{
        foxy_cat: 'DEFAULT',
        prefix: 'VOUCH',
        sales_vector: 'Consignment',
        gatelog_entry: true,
        quick_1: '',
        quick_2: '',
        quick_3: '',
        facility: null,
        product_type: '',
        height_code: '',
        active: true,
        partner_accessible: true,
        create_pass: false,
        multiscan: false,
        category: 'default',
        can_change_quantity: true,
        shipping: false,
        giftcard: false,
    };
    template: string;

    // Table variables.
    filter: string;
    productsDataSource: MatTableDataSource<Object>;
    contextProduct: Object;

    @Input() user: Object;
    @Input() products: Object[];
    @Input() facilities: Object[];
    @Input() taxes: Object[];
    @Input() templates: Object[];
    @Input() sellAt?: Object[];
    @Input() categories?: Object[];
    @Input() type: string;
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        public _misc: MiscService,
        public _http: HttpService,
        private _snackbar: SnackbarService,
        private _context: ContextmenuService,
        private _dialog: MatDialog
    ) {}

    async ngOnInit() {
        // Set the newProduct sales_vector.
        this.newProduct.sales_vector = <'Consignment' | 'Web'>this.type;

        // Set the template to mfg.
        let mfgTemplate = this.templates.find((template) =>
            template['file'].includes('mfg')
        );
        this.template = mfgTemplate['file'];

        // Split the product taxes and valid_at fields. Also remove the whitespace on the prices.
        for (let i = 0; i < this.products.length; i++) {
            this.products[i]['taxes'] = this.products[i]['taxes'].split(',');
            this.products[i]['valid_at'] =
                this.products[i]['valid_at'].split(', ');
            this.products[i]['price'] = this.products[i]['price'].trim();
            this.products[i]['post_tax'] = this.products[i]['post_tax'].trim();
            this.products[i]['active'] = this.products[i]['active'] == '1';
            this.products[i]['partner_accessible'] =
                this.products[i]['partner_accessible'] == '1';
            this.products[i]['gatelog_entry'] =
                this.products[i]['gatelog_entry'] == '1';
            this.products[i]['create_pass'] =
                this.products[i]['create_pass'] == '1';
            this.products[i]['multiscan'] =
                this.products[i]['multiscan'] == '1';
            this.products[i]['can_change_quantity'] =
                this.products[i]['can_change_quantity'] == '1';
            this.products[i]['shipping'] = this.products[i]['shipping'] == '1';
            this.products[i]['giftcard'] = this.products[i]['giftcard'] == '1';
        }

        // Filter the facilities without fluid_id's.
        this.facilities = this.facilities.filter(
            (facility) => facility['fluid_id']
        );

        // Add SnR and SnRXL.
        this.facilities.push(
            {
                name: 'Slide and Ride',
                abbr: this.type === 'Consignment' ? 'SNR' : 'SnR',
            },
            {
                name: 'Slide and Ride XL',
                abbr: this.type === 'Consignment' ? 'SNRXL' : 'SnRXL',
            }
        );

        // Get the foxy categories and filter out blank value.
        this.foxyCategories = (<string[]>(
            (await this._http.getLocal('pos/vouchers/products/categories/'))
                .body
        )).filter((category) => category);

        this.foxyCategoriesFiltered = cloneDeep(this.foxyCategories);

        this.filterFoxyCategories();

        // Setup the table.
        this.productsDataSource = new MatTableDataSource(this.products);
        this.productsDataSource.sort = this.sort;
        this.productsDataSource.paginator = this.paginator;
        this.productsDataSource.filterPredicate = (
            data: any,
            _: string
        ): boolean => {
            let joined =
                `${data.id}${data.description}${data.facility}${data.product_type}${data.height_code}${data.redeem_at}${data.quick_1}-${data.quick_2}-${data.quick_3}${data.redeem_for}${data.price}`.toLowerCase();
            return !this.filter || joined.includes(this.filter.toLowerCase());
        };

        // Get the filter if there is one.
        const filter = window.sessionStorage.getItem(
            'fluiddynamics_voucher_product_filter'
        );

        // Filter the table.
        if (filter) {
            this.filter = filter;
            this.applyFilter();
            window.sessionStorage.removeItem(
                'fluiddynamics_voucher_product_filter'
            );
        }

        // Initialise the barcodes.
        JsBarcode('#consignment-barcode').init();
        JsBarcode('#web-barcode').init();
    }

    async createProduct() {
        // Check for missing fields.
        if (
            this.newProduct.price === null ||
            !this.newProduct.description ||
            !this.newProduct.redeem_at ||
            this.newProduct.party_size === null ||
            !this.newProduct.redeem_for ||
            !this.newProduct.sell_at ||
            this.newProduct.max_scans === null ||
            (this.newProduct.sales_vector !== 'Consignment' &&
                !this.newProduct.foxy_cat) ||
            !this.newProduct.facility
        ) {
            this._snackbar.defaultSnackbar(
                'Please fill out all required fields.'
            );
            return;
        }

        // Check for no valid at locations.
        if (!this.newProduct.valid_at || !this.newProduct.valid_at.length) {
            this._snackbar.defaultSnackbar(
                'You must select at least one valid at location.'
            );
            return;
        }

        // Check for invalid price.
        if (
            !`${this.newProduct.price}`.match(/^[0-9]+(?:\.[0-9]{1,4})?$/) ||
            this.newProduct.price < 0
        ) {
            this._snackbar.defaultSnackbar(
                'Price must be a number with at most four decimals and greater than 0.'
            );
            return;
        }

        // Check for invalid party_size.
        if (
            !`${this.newProduct.party_size}`.match(/^[0-9]+$/) ||
            this.newProduct.party_size < 1
        ) {
            this._snackbar.defaultSnackbar(
                'Party size must be a whole number and greater than 0.'
            );
            return;
        }

        // Check for invalid max_scans.
        if (
            !`${this.newProduct.max_scans}`.match(/^[0-9]+$/) ||
            this.newProduct.max_scans < 0
        ) {
            this._snackbar.defaultSnackbar(
                'Max scans must be a whole number and greater than or equal to 0.'
            );
            return;
        }

        this.loadingChange.emit(true);

        // Set valid_at to a comma-space separated array.
        this.newProduct.valid_at = (<number[]>this.newProduct.valid_at).join(
            ', '
        );

        // Set taxes to a CSV.
        this.newProduct.taxes = this.newProduct.taxes
            ? (<number[]>this.newProduct.taxes).join()
            : '';

        // Call the POST API.
        let resp: Object = (
            await this._http.postLocal(
                'pos/vouchers/products/',
                this.newProduct
            )
        ).body;

        // Show snackbar based on success and if product was fetched correctly.
        if (resp['success']) {
            let snackbarMessage = '';

            if (resp['product'] !== false) {
                snackbarMessage = 'Successfully created the product.';

                // Split taxes.
                resp['product']['taxes'] = resp['product']['taxes'].split(',');

                // Split valid_at.
                resp['product']['valid_at'] =
                    resp['product']['valid_at'].split(', ');
                resp['product']['price'] = resp['product']['price'].trim();
                resp['product']['post_tax'] =
                    resp['product']['post_tax'].trim();
                resp['product']['active'] = resp['product']['active'] == '1';
                resp['product']['partner_accessible'] =
                    resp['product']['partner_accessible'] == '1';
                resp['product']['gatelog_entry'] =
                    resp['product']['gatelog_entry'] == '1';
                resp['product']['create_pass'] =
                    resp['product']['create_pass'] == '1';
                resp['product']['multiscan'] =
                    resp['product']['multiscan'] == '1';
                resp['product']['can_change_quantity'] =
                    resp['product']['can_change_quantity'] == '1';
                resp['product']['shipping'] =
                    resp['product']['shipping'] == '1';
                resp['product']['giftcard'] =
                    resp['product']['giftcard'] == '1';

                // Add the product to products and filter to refresh.
                this.products.unshift(resp['product']);
                this.applyFilter();
            } else {
                snackbarMessage =
                    'Successfully inserted the product. An error occurred while fetching it.';
            }

            this._snackbar.defaultSnackbar(snackbarMessage);

            // Reset newProduct.
            this.newProduct = <NewProduct>{
                foxy_cat: 'DEFAULT',
                prefix: 'VOUCH',
                sales_vector: 'Consignment',
                gatelog_entry: true,
                quick_1: '',
                quick_2: '',
                quick_3: '',
                facility: null,
                product_type: '',
                height_code: '',
                active: true,
                partner_accessible: true,
                create_pass: false,
                multiscan: false,
                category: 'default',
                can_change_quantity: true,
                shipping: false,
                giftcard: false,
            };
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while adding the product.'
            );
        }

        this.loadingChange.emit(false);
    }

    async deleteProduct() {
        // Ask if they are sure they want to delete the product.
        const dialogRef = this._dialog.open(ConfirmComponent, {
            data: {
                title: 'Confirm Delete',
                content: 'Are you sure you want to delete this product?',
            },
        });

        let ans = await dialogRef.afterClosed().toPromise();

        if (!ans) return;

        this.loadingChange.emit(true);

        // Call the DELETE API.
        let resp: HttpResponse<Object> = await this._http.deleteLocal(
            `pos/vouchers/products/?id=${this.contextProduct['id']}`
        );

        if (resp.status === 204) {
            // Delete from the array.
            let index = this.products.findIndex(
                (item) => item['id'] === this.contextProduct['id']
            );
            if (index > -1) {
                this.products.splice(index, 1);
            }

            this.applyFilter();

            this._snackbar.defaultSnackbar('Successfully deleted the product.');
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to delete the product.'
            );
        }

        this.loadingChange.emit(false);
    }

    async editProduct() {
        // Open the edit dialog.
        const data = {
            type: this.type,
            product: cloneDeep(this.contextProduct),
            facilities: this.facilities,
            taxes: this.taxes,
            foxyCategories: this.foxyCategories,
            categories: this.categories,
            templates: this.templates,
            sellAt: this.sellAt,
        };
        const dialogRef = this._dialog.open(EditvoucherproductComponent, {
            data,
            autoFocus: false,
        });

        let ans = await dialogRef.afterClosed().toPromise();

        if (!ans) return;

        this.loadingChange.emit(true);

        // Set valid_at to a comma-space separated array.
        ans.valid_at = (<number[]>ans.valid_at).join(', ');

        // Set taxes to a CSV.
        ans.taxes = (<number[]>ans.taxes).join();

        // Call the PUT API.
        let resp: HttpResponse<Object> = await this._http.putLocal(
            'pos/vouchers/products/',
            ans
        );

        if (resp.status === 204) {
            // Split the valid_at and taxes.
            ans.valid_at = ans.valid_at.split(', ');
            ans.taxes = ans.taxes.split(',');

            // Set contextProduct values.
            this.contextProduct = ans;

            // Set the product values.
            let productIndex = this.products.findIndex(
                (product) => product['id'] === this.contextProduct['id']
            );
            if (productIndex > -1) this.products[productIndex] = ans;

            this.applyFilter();

            this._snackbar.defaultSnackbar('Successfully edited the product.');
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to edit the product.'
            );
        }

        this.loadingChange.emit(false);
    }

    async chooseImage() {
        this.loadingChange.emit(true);

        // Get the images.
        const images = (
            await this._http.getLocal('pos/vouchers/products/images/')
        ).body as string[];

        this.loadingChange.emit(false);
        if (!images || !images.length) return;

        // Open the choose dialog.
        const dialogRef = this._dialog.open(
            ChoosevoucherproductimageComponent,
            {
                data: { images },
            }
        );

        let ans = await dialogRef.afterClosed().toPromise();

        if (!ans) return;

        this.newProduct.image = ans;
    }

    templateChange() {
        // Get the template.
        let template = this.templates.find(
            (template) => template['id'] === this.newProduct.template
        );

        // Set the template to the file.
        this.template = template['file'];
    }

    facilityChange() {
        // Get the facility from the abbreviation.
        let facility = this.facilities.find(
            (facility) => facility['abbr'] === this.newProduct.facility
        );

        // Set redeem_at.
        if (!this.newProduct.redeem_at) {
            this.newProduct.redeem_at = facility['name'];
        }

        // Set quick_1.
        if (!this.newProduct.quick_1) {
            this.newProduct.quick_1 = facility['abbr'];
        }
    }

    partySizeChange() {
        // Set quick_3.
        if (!this.newProduct.quick_3) {
            this.newProduct.quick_3 = `${this.newProduct.party_size}`;
        }
    }

    applyFilter() {
        // Apply a random filter (unix time in ms) so that it always fires even if filter is blank.
        this.productsDataSource.filter = `${+new Date()}`;
    }

    filterFoxyCategories() {
        this.foxyCategoriesFiltered = this.foxyCategories.filter((category) =>
            category['name']
                .toLowerCase()
                .includes(this.newProduct.foxy_cat.toLowerCase())
        );
    }

    rightClick(
        event: MouseEvent,
        menu: MatMenuTrigger,
        obj: Object,
        component?: VoucherproductsbaseComponent
    ) {
        let t = component || this;

        t.contextProduct = obj;
        t._context.openMenu(event, menu);
        return false;
    }
}
