<h1 mat-dialog-title>Reschedule Reservation</h1>
<div mat-dialog-content>
    <p class="mb15">
        You will be rescheduling from
        {{ data.order['reservation']['date'] }}
        {{ data.order['timeSlot'] }}. This reservation is for
        {{ facilityMap[data.order['vouchers'][0]['facility']] }}.
    </p>

    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Pick a Date"
            [matDatepicker]="picker"
            [matDatepickerFilter]="dateFilter"
            [(ngModel)]="date"
            (dateChange)="dateChange()"
            (focus)="picker.open()"
            (click)="picker.open()"
            required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="w100" *ngIf="reservation">
        <mat-select placeholder="Time Slot" [(ngModel)]="timeSlot">
            <mat-option
                *ngFor="let slot of reservation['slots']; let i = index"
                [value]="i + 1"
                [disabled]="
                    (slot['open'] == '0' &&
                        slot['partner_accessible'] == '0') ||
                    taken + slot['taken'] >=
                        slot['max_people'] + slot['partner_inventory']
                "
            >
                {{ slot['time']
                }}{{
                    slot['open'] == '0' && slot['partner_accessible'] == '0'
                        ? ' (closed)'
                        : taken + slot['taken'] >=
                          slot['max_people'] + slot['partner_inventory']
                        ? ' (full)'
                        : ''
                }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
