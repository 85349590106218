<h1 mat-dialog-title>Change Group Information</h1>
<div mat-dialog-content>
    <ng-form #form="ngForm">
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="data.order['first_name']"
                    required
                    name="first_name"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="data.order['last_name']"
                    required
                    name="last_name"
                />
            </mat-form-field>
        </div>
        <div class="inputs">
            <mat-form-field>
                <input
                    matInput
                    type="email"
                    placeholder="Email"
                    [(ngModel)]="data.order['email']"
                    required
                    name="email"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    phoneMask
                    placeholder="Phone"
                    [(ngModel)]="data.order['phone']"
                    required
                    name="phone"
                />
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="save()" color="primary">
        Save
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
