<h1 mat-dialog-title>{{ data.title || 'Confirm Image' }}</h1>
<div mat-dialog-content>
    <p *ngIf="data.body" class="mb15">{{ data.body }}</p>
    <img *ngIf="fileURL" [src]="fileURL" alt="Image" />
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
