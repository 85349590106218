<h1 mat-dialog-title>Editing Non Fluid Location</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data['location']['name']"
                name="name"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Square ID"
                [(ngModel)]="data['location']['square_id']"
                name="square_id"
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <mat-select
                placeholder="Square Credentials"
                [(ngModel)]="data['location']['square_credentials']"
                name="square_credentials"
            >
                <mat-option value="beaver"> beaver@maritimefun.com </mat-option>
                <mat-option value="shining">
                    shining@maritimefun.com
                </mat-option>
                <mat-option value="teddybears">
                    teddybears@maritimefun.com
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
