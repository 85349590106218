<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Zero Cards</h1>
<div mat-dialog-content>
    <p class="mb15">
        Scan a card to add to the list to zero. Click/tap the
        <mat-icon color="warn">remove_circle</mat-icon> beside the card number
        to remove from list.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            (keyup.enter)="addCard()"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>

    <div class="zero-cards mb15 overflow scrollbar">
        <p class="center" *ngFor="let c of cards">
            {{ c }}
            <mat-icon color="warn" class="pointer" (click)="removeCard(c)">
                remove_circle
            </mat-icon>
        </p>
    </div>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        [disabled]="cards.length < 1"
        (click)="zero()"
        color="primary"
    >
        Zero Cards
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
