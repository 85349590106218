<loading *ngIf="loading" [opacity]="0.5"></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <h1 class="center">Assigned Documents</h1>
        <p class="center">
            To sign/view a document, click the button on the right of the entry
            or right click the entry in the table below and then click the
            Sign/View button.
        </p>

        <!-- Contextmenu -->
        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
        <mat-menu #menu="matMenu">
            <button
                mat-menu-item
                oncontextmenu="return false;"
                (click)="signDocument()"
            >
                {{
                    contextDocument && contextDocument['signed']
                        ? 'View'
                        : 'View/Sign'
                }}
            </button>
        </mat-menu>

        <!-- Sort -->
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Filter"
                [(ngModel)]="filter"
                (input)="applyFilter()"
            />
        </mat-form-field>

        <!-- Table -->
        <mat-table
            #table
            [dataSource]="documentsDataSource"
            matSort
            class="mat-elevation-z8 w100"
        >
            <!-- Name -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let document"
                    [ngClass]="{ 'red-text': !document['signed'] }"
                >
                    {{ document['name'] }}
                </mat-cell>
            </ng-container>

            <!-- Signed -->
            <ng-container matColumnDef="signed">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Signed
                </mat-header-cell>
                <mat-cell
                    *matCellDef="let document"
                    [ngClass]="{ 'red-text': !document['signed'] }"
                >
                    {{ document['signed'] || 'Not Signed' }}
                </mat-cell>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Actions
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    <div>
                        <button
                            mat-raised-button
                            (click)="signDocument(document)"
                            color="primary"
                        >
                            {{ document['signed'] ? 'View' : 'View/Sign' }}
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="['name', 'signed', 'actions']"
            ></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: ['name', 'signed', 'actions']"
                [phoneContextMenu]="{
                    function: rightClick,
                    trigger: menuTrigger,
                    row: row,
                    component: this
                }"
                (contextmenu)="rightClick($event, menuTrigger, row)"
                [ngClass]="{
                    'background-radial':
                        contextDocument && contextDocument['id'] === row['id']
                }"
            ></mat-row>
        </mat-table>
        <mat-paginator
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
        ></mat-paginator>
    </div>
</div>
