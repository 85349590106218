<h1 mat-dialog-title>Editing Phone Number</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                type="tel"
                placeholder="Number"
                [(ngModel)]="data.phoneNumber['number']"
                name="number"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data.phoneNumber['name']"
                name="name"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <mat-select
                placeholder="Facility"
                [(ngModel)]="data.phoneNumber['facility']"
                name="facility"
            >
                <mat-option
                    *ngFor="let facility of data.facilities"
                    [value]="facility['abbr']"
                >
                    {{ facility['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Users notified. -->
        <mat-form-field class="w100">
            <mat-chip-list #usersNotifiedChipList>
                <mat-chip
                    *ngFor="let user of usersNotified"
                    [removable]="true"
                    (removed)="removeUserNotified(user)"
                >
                    {{ user['first_name'] }} {{ user['last_name'] }} ({{
                        user['id']
                    }})
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                    matInput
                    id="usersNotifiedInput"
                    placeholder="Users Notified"
                    [matAutocomplete]="usersNotifiedAuto"
                    (input)="filterUsersNotified()"
                    [(ngModel)]="usersNotifiedSearch"
                    [matChipInputFor]="usersNotifiedChipList"
                    name="users_notified"
                />
                <mat-autocomplete
                    autoActiveFirstOption
                    #usersNotifiedAuto="matAutocomplete"
                    (optionSelected)="addUserNotified()"
                    class="scrollbar"
                >
                    <mat-option
                        *ngFor="let u of usersNotifiedFiltered"
                        [value]="u['id']"
                    >
                        {{ u['first_name'] }} {{ u['last_name'] }} ({{
                            u['id']
                        }})
                    </mat-option>
                </mat-autocomplete>
            </mat-chip-list>
            <mat-hint
                matTooltip="To add senders, type anything and then choose an option.
                    To remove senders, either press backspace while in
                    the input, or press the X button on the chip."
            >
                Hover for instructions.
            </mat-hint>
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
