import { Injectable, isDevMode } from '@angular/core';
import * as dayjs from 'dayjs';

export interface CachedObject {
    data: any;
    expires: number;
}

/**
 * This service provides a simple caching system for caching anything.
 * Can cache anything from a base64 encoded string for images, to a simple object.
 * Note, this cache expires on page refresh and browser close.
 */
@Injectable({
    providedIn: 'root',
})
export class CachingService {
    private cache: Map<string, CachedObject>;

    constructor() {
        this.cache = new Map();
    }

    /**
     * This function gets a value from a given key from the cache Map.
     *
     *
     * @param key The key to get.
     * @returns A `CachedObject` on success, or `false` if none found.
     */
    get(key: string): CachedObject | boolean {
        let cachedObject = this.cache.get(key);

        // Check if exists.
        if (!cachedObject) {
            this.log('Inexistent cache.', key);
            return false;
        }

        // Check if expired.
        if (cachedObject.expires < dayjs().unix()) {
            this.cache.delete(key);
            this.log('Cache expired.', key);
            return false;
        }

        this.log('Cache retrieved.', cachedObject);
        return cachedObject;
    }

    /**
     * This function sets a new key/value pair in the cache map.
     *
     *
     * @param key The key to set for the map.
     * @param val The value to set for the map.
     * @param expires An optional number containing the amount of time, in seconds, until the cache should expire.
     * @returns A `boolean` of whether the setting was successful.
     */
    set(key: string, val: any, expires: number = 3600): boolean {
        if (this.cache.has(key)) {
            this.log('Error setting, existing key.');
            return false;
        }

        this.cache.set(key, {
            data: val,
            expires: dayjs().add(expires, 'second').unix(),
        });
        return true;
    }

    /**
     * This function logs caching output if Angular is in development mode.
     *
     *
     * @param info An `array` containing all the parameters.
     */
    private log(...info: any[]) {
        if (isDevMode()) {
            console.log(...info);
        }
    }
}
