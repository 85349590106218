import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

interface Data {
    documents: Object[];
}

@Component({
    selector: 'app-choosedocument',
    templateUrl: './choosedocument.component.html',
    styleUrls: ['./choosedocument.component.scss']
})
export class ChoosedocumentComponent implements OnInit {
    // Variables.
    document: Object;

    constructor(
        public dialogRef: MatDialogRef<ChoosedocumentComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {}

    save() {
        this.dialogRef.close(this.document);
    }
}
