import { Injectable, OnDestroy } from '@angular/core';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidenavService implements OnDestroy {
    private sidenav: MatSidenav;
    private openedChangeSubscription: Subscription;

    constructor() {}

    ngOnDestroy() {
        this.openedChangeSubscription.unsubscribe();
    }

    setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
        this.openedChangeSubscription = this.sidenav.openedChange.subscribe(_ =>
            this.hideElements()
        );
    }

    open(): Promise<MatDrawerToggleResult> {
        let resp = this.sidenav.open();
        this.hideElements();
        return resp;
    }

    close(): Promise<MatDrawerToggleResult> {
        let resp = this.sidenav.close();
        this.hideElements();
        return resp;
    }

    toggle(): Promise<MatDrawerToggleResult> {
        let resp = this.sidenav.toggle();
        this.hideElements();
        return resp;
    }

    private hideElements() {
        let elementsToHide: HTMLCollectionOf<
            Element
        > = document.getElementsByClassName('hide-on-sidenav');
        for (let i = 0; i < elementsToHide.length; i++) {
            if (this.sidenav.opened) {
                elementsToHide[i].classList.add('hide-on-sidenav-open');
            } else {
                elementsToHide[i].classList.remove('hide-on-sidenav-open');
            }
        }
    }
}
