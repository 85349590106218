<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <h1 class="center">Help Page</h1>
        <p class="center">
            Below are some FAQ (frequently asked questions) about the new site.
        </p>

        <mat-accordion>
            <!-- Where is the side navigation bar? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        Where is the side navigation bar?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        The side navigation menu can now be to be toggled,
                        defaulting to hidden. To show it, click the hamburger
                        icon <mat-icon>menu</mat-icon> in the upper left corner
                        near the FluidDynamics logo. Note, on some pages
                        (including this one), there is no side navigation menu.
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- Why are there no actions on any of the pages? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        Why are there no actions on any of the pages?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        All of the actions that were displayed on the page have
                        been moved to a right click menu system.
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- I cannot find a feature that was on the old site/should be there. Is it implemented? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        I cannot find a feature that was on the old site/should
                        be there. Is it implemented?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        There is almost a one hundred percent chance that the
                        feature is implemented. As the previous answer
                        mentioned, it might be in a right click menu or moved to
                        a different page. If you believe that it is not
                        implemented or are still having issues, try
                        <a class="same-color" href="/contact">contacting</a>
                        us.
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- How do I right click on my phone? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        How do I right click on my phone?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        Right clicking on your phone is as simple as holding the
                        screen in the same position for about half a second on
                        IOS and a second on Android.
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- Why is this feature not working on my phone? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        Why is this feature not working on my phone?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        We try to support IOS and Android but some features may
                        not work without some platform-specific tweaks. Please
                        click <a class="same-color" href="/contact">here</a> let
                        us know about the issue!
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- Why does the website look weird/not function on my browser? -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Q
                    </mat-panel-title>
                    <mat-panel-description class="scrollbar">
                        Why does the website look weird/not function on my
                        browser?
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="flex">
                    <p class="answer-prefix">A.</p>
                    <p class="answer scrollbar">
                        We aim to support the following major browsers: Google
                        Chrome, Firefox, Opera and New Microsoft Edge. With that
                        being said, this website should work on all
                        Chromium-based browsers including Vivaldi and Brave. If
                        you believe that we should add support for a specific
                        browser, let us know
                        <a class="same-color" href="/contact">here</a>!
                    </p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
