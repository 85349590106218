import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface Data {
    drawer: Object;
    startIndex?: number;
}

@Component({
    selector: 'app-viewdrawer',
    templateUrl: './viewdrawer.component.html',
    styleUrls: ['./viewdrawer.component.scss'],
})
export class ViewDrawerComponent implements OnInit {
    // Variables.
    report: Object;
    loading: boolean = false;
    startIndex: number = 0;

    constructor(
        public dialogRef: MatDialogRef<ViewDrawerComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {
        this.startIndex =
            this.data.startIndex !== undefined ? this.data.startIndex : 0;
        if (this.startIndex === 1) this.generateReport();
    }

    ngOnInit() {}

    async generateReport() {
        this.loading = true;

        const resp = (await this._http.get(
            `${this._http.API_URL}/mfgprint/generators/drawer-report/?drawer=${this.data.drawer['id']}`,
            {
                headers: new HttpHeaders({
                    'X-Fluid-Pwd': 'qDM$YnoUQm7J#20FC*Oy9kPO',
                }),
            }
        )) as HttpResponse<Object>;

        this.loading = false;

        // If error.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'AN error occurred while attempting to generate the report.'
            );
            return;
        }

        this.report = resp.body;
    }

    tabChange(index: number) {
        // If 2nd tab and report not already generated.
        if (index === 1 && !this.report) {
            this.generateReport();
        }
    }
}
