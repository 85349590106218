import { Directive, HostListener, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[creditCard]',
})
export class CreditCardDirective implements AfterViewInit {
    constructor(public ngControl: NgControl) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value);
    }

    ngAfterViewInit() {
        // Timeout needed since the value was null.
        setTimeout(() => {
            this.onInputChange(this.ngControl.value);
        }, 100);
    }

    onInputChange(event) {
        if (!event) return;

        let newVal = event.replace(/\D/g, '');

        // If more than 19, trim the end.
        newVal = newVal.slice(0, 19);

        if (newVal.length === 0) {
            newVal = '';
        } else {
            // Chunk into 4s.
            const numChunks = Math.ceil(newVal.length / 4);
            const chunks = new Array(numChunks);

            for (let i = 0, o = 0; i < numChunks; ++i, o += 4) {
                chunks[i] = newVal.substr(o, 4);
            }

            newVal = chunks.join(' ');
        }

        this.ngControl.valueAccessor.writeValue(newVal);
    }
}
