<loading [opacity]="0.5" *ngIf="loading"></loading>

<!-- Contextmenu -->
<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
    <button
        mat-menu-item
        oncontextmenu="return false;"
        (click)="void()"
        *ngIf="dev"
    >
        Void Transaction
    </button>
    <button
        mat-menu-item
        oncontextmenu="return false;"
        (click)="reprintReceipt()"
    >
        Reprint Receipt
    </button>
    <button
        mat-menu-item
        oncontextmenu="return false;"
        (click)="reprintVouchers()"
    >
        Reprint Vouchers
    </button>
</mat-menu>

<h1 mat-dialog-title>Journal</h1>
<div mat-dialog-content class="max-dialog">
    <mat-tab-group mat-stretch-tabs>
        <mat-tab *ngFor="let drawer of data.drawers">
            <ng-template mat-tab-label>
                {{ drawer['first_name'] }} {{ drawer['last_name'] }}
            </ng-template>

            <mat-tab-group mat-stretch-tabs>
                <mat-tab>
                    <ng-template mat-tab-label>Transactions</ng-template>

                    <h1
                        class="center padding-top-15"
                        *ngIf="!drawer['transactions'].length"
                    >
                        No Transactions
                    </h1>
                    <div
                        class="
                            flex-panel-entry
                            flex-panel-header
                            flex-panel-journal
                            jcc
                        "
                        *ngIf="drawer['transactions'].length"
                    >
                        <p class="half">ID</p>
                        <p>Time</p>
                        <p>Subtotal</p>
                        <p>Tax</p>
                        <p>Total</p>
                    </div>
                    <div
                        class="groups-div scrollbar overflow"
                        *ngIf="drawer['transactions'].length"
                    >
                        <mat-accordion class="groups-div scrollbar overflow">
                            <mat-expansion-panel
                                *ngFor="
                                    let transaction of drawer['transactions']
                                "
                                (opened)="getItems(transaction)"
                            >
                                <mat-expansion-panel-header
                                    class="mb15"
                                    [phoneContextMenu]="{
                                        function: rightClick,
                                        trigger: menuTrigger,
                                        row: transaction,
                                        params: [drawer],
                                        component: this
                                    }"
                                    (contextmenu)="
                                        rightClick(
                                            $event,
                                            menuTrigger,
                                            transaction,
                                            drawer
                                        )
                                    "
                                    [ngClass]="{
                                        'background-radial':
                                            selectedTransaction &&
                                            selectedTransaction['id'] ===
                                                transaction['id']
                                    }"
                                >
                                    <mat-panel-title>
                                        <div class="flex-panel-entry jcc">
                                            <p class="half overflow scrollbar">
                                                {{ transaction['id'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{
                                                    transaction['time'] * 1000
                                                        | date
                                                            : 'yyyy-MM-dd HH:mm:ss'
                                                }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ transaction['subtotal'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ transaction['hst'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ transaction['total'] }}
                                            </p>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="flex-panel-entry jcc">
                                    <p class="header padding-left-15">
                                        Quantity
                                    </p>
                                    <p class="header">Description</p>
                                    <p class="header padding-right-15">
                                        Subtotal
                                    </p>
                                </div>
                                <mat-list
                                    class="passes list-w100 scrollbar"
                                    *ngIf="
                                        transaction['items'] &&
                                        transaction['items'].length
                                    "
                                >
                                    <mat-list-item
                                        class="flex hover"
                                        *ngFor="
                                            let item of transaction['items']
                                        "
                                    >
                                        <p class="center">
                                            {{ item['qty'] }}
                                        </p>
                                        <p class="center">
                                            {{ item['description'] }}
                                        </p>
                                        <p class="center">
                                            {{ item['subtotal'] }}
                                        </p>
                                    </mat-list-item>
                                </mat-list>

                                <h2
                                    class="center"
                                    *ngIf="
                                        transaction['items'] &&
                                        !transaction['items'].length
                                    "
                                >
                                    No Items
                                </h2>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>Voided</ng-template>

                    <h1
                        class="center padding-top-15"
                        *ngIf="!drawer['voided'].length"
                    >
                        No Voids
                    </h1>
                    <div
                        class="
                            flex-panel-entry
                            flex-panel-header
                            flex-panel-journal
                            jcc
                        "
                        *ngIf="drawer['voided'].length"
                    >
                        <p class="half">ID</p>
                        <p>Time</p>
                        <p>Subtotal</p>
                        <p>Tax</p>
                        <p>Total</p>
                        <p>User Voided</p>
                    </div>
                    <div
                        class="groups-div scrollbar overflow"
                        *ngIf="drawer['voided'].length"
                    >
                        <mat-accordion class="groups-div scrollbar overflow">
                            <mat-expansion-panel
                                *ngFor="let void of drawer['voided']"
                                (opened)="getItems(void, true)"
                            >
                                <mat-expansion-panel-header class="mb15">
                                    <mat-panel-title>
                                        <div class="flex-panel-entry jcc">
                                            <p class="half overflow scrollbar">
                                                {{ void['id'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{
                                                    void['time'] * 1000
                                                        | date
                                                            : 'yyyy-MM-dd HH:mm:ss'
                                                }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ void['subtotal'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ void['hst'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ void['total'] }}
                                            </p>
                                            <p class="overflow scrollbar">
                                                {{ void['first_name'] }}
                                                {{ void['last_name'] }}
                                            </p>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="flex-panel-entry jcc">
                                    <p class="header padding-left-15">
                                        Quantity
                                    </p>
                                    <p class="header">Description</p>
                                    <p class="header padding-right-15">
                                        Subtotal
                                    </p>
                                </div>
                                <mat-list
                                    class="passes list-w100 scrollbar"
                                    *ngIf="
                                        void['items'] && void['items'].length
                                    "
                                >
                                    <mat-list-item
                                        class="flex hover"
                                        *ngFor="let item of void['items']"
                                    >
                                        <p class="center">
                                            {{ item['qty'] }}
                                        </p>
                                        <p class="center">
                                            {{ item['description'] }}
                                        </p>
                                        <p class="center">
                                            {{ item['subtotal'] }}
                                        </p>
                                    </mat-list-item>
                                </mat-list>

                                <h2
                                    class="center"
                                    *ngIf="
                                        void['items'] && !void['items'].length
                                    "
                                >
                                    No Items
                                </h2>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button [mat-dialog-close]="undefined" color="warn">
        Cancel
    </button>
</div>
