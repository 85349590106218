import {
    Directive,
    Input,
    ElementRef,
    OnInit,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    ViewContainerRef,
    ComponentRef
} from '@angular/core';
import {
    Template,
    SoftkeyboardComponent
} from '../customelements/softkeyboard/softkeyboard.component';

@Directive({
    selector: '[softKeyboard]'
})
export class SoftkeyboardDirective implements OnInit {
    @Input() softKeyboard: Template;
    component: ComponentRef<SoftkeyboardComponent>;
    element: HTMLInputElement | HTMLTextAreaElement;

    constructor(
        private el: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        this.element = this.el.nativeElement;

        // Add event listener for creating the keyboard.
        this.element.addEventListener('focus', () => this.createKeyboard());
    }

    async createKeyboard() {
        // If softKeyboardIgnoreFocus, then return.
        if (window['softKeyboardIgnoreFocus']) return;

        // Wait for softKeyboardClickFunction to get removed from window.
        while (window['softKeyboardClickFunction']) {
            await this.wait(25);
        }

        // Create a component reference and set values of the instance.
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(
            SoftkeyboardComponent
        );

        this.component = this.viewContainerRef.createComponent(componentRef);

        this.component.instance.element = this.element;
        this.component.instance.template = this.softKeyboard;

        // Get DOM element from component and add it to the body.
        const domElem = (this.component.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        // Add event listener for click.
        window['softKeyboardClickFunction'] = (e: Event) =>
            this.removeKeyboard(e);
        document.addEventListener('click', window['softKeyboardClickFunction']);
    }

    removeKeyboard(e: Event) {
        if (!this.element || !(<HTMLElement>e.target).offsetParent) return;

        // If not the input element and not the keyboard.
        const target: HTMLElement = <HTMLElement>e.target;
        const keyType: string | null = target.getAttribute('data-key-type');

        /* The below if is checking if the offsetParent (the parent that has a set position)
        is either the container or the nav of the soft keyboard or if it is the input 
        of this directive. If either is true, it does not close the keyboard. The other
        thing that it checks is if the keyType is enter. If it is, then it will close the keyboard. */
        if (
            !(
                (['soft-keyboard-container', 'soft-keyboard-nav'].includes(
                    target.offsetParent.className
                ) &&
                    (!keyType || keyType !== 'enter')) ||
                (this.el &&
                    this.el.nativeElement &&
                    (e.target as any) === this.el.nativeElement)
            )
        ) {
            this.component.destroy();
            document.removeEventListener(
                'click',
                window['softKeyboardClickFunction']
            );
            delete window['softKeyboardClickFunction'];
        }
    }

    wait(milli: number) {
        return new Promise((res, rej) => {
            setTimeout(() => {
                res();
            }, milli);
        });
    }
}
