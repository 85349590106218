<h1 mat-dialog-title class="center pick-title">Pass Group</h1>
<div mat-dialog-content class="pick-content">
    <div class="passes scrollbar">
        <div
            [ngClass]="{
                'background-radial': pass['selected'] && pass['valid']
            }"
            [ngStyle]="{
                background: pass['valid'] ? 'initial' : 'red',
                cursor: pass['valid'] ? 'pointer' : 'not-allowed'
            }"
            class="pass scrollbar padding-15"
            (click)="pass['selected'] = !pass['selected']"
            *ngFor="let pass of data.passes"
        >
            <img
                [src]="
                    pass['photo'] ||
                    this._sanitizer.bypassSecurityTrustResourceUrl(
                        _misc.defaultPhoto
                    )
                "
            />
            <div class="pass-description flex col center jcc">
                <p>{{ pass['first_name'] }} {{ pass['last_name'] }}</p>
                <p>
                    {{ pass['pass_type'] ? pass['pass_type'] : 'VTKTO' }}
                </p>
                <p *ngIf="pass['height_code']">
                    {{ pass['height_code'] }}
                </p>
                <p class="scrollbar invalid-pass" *ngIf="!pass['valid']">
                    {{ pass['result'] }}
                </p>
            </div>
        </div>
    </div>
    <button
        class="pick-finish-button w100"
        mat-raised-button
        (click)="save()"
        color="primary"
    >
        Finish
    </button>
</div>
