<h1 mat-dialog-title>{{ data.title || 'Upload Files' }}</h1>
<div mat-dialog-content>
    <p>{{ data.content || '' }}</p>

    <div class="files-to-upload mb15">
        <div *ngFor="let upload of uploads">
            <div class="flex mb15">
                <p class="m-0-10">
                    {{ upload.file.name }}
                </p>
                <p class="m-0-10">
                    {{ upload.progress }}% ({{ upload.bytesSent }}/{{
                        upload.bytesTotal
                    }})
                </p>
            </div>
            <p *ngIf="upload.status === 'waiting'">Waiting to upload...</p>
            <p *ngIf="upload.status === 'fail'" class="red-text">
                Failed uploading
            </p>
            <p *ngIf="upload.status === 'success'" class="green-text">
                Uploaded!
            </p>
            <mat-progress-bar
                mode="determinate"
                [value]="upload.progress"
                *ngIf="upload.status === 'uploading'"
            ></mat-progress-bar>
        </div>
    </div>

    <button
        mat-raised-button
        color="primary"
        class="center-btn"
        (click)="close()"
    >
        Close
    </button>
</div>
