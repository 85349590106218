import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

interface Data {
    value?: string;
    title: string;
    minCash?: number;
    validCash?: boolean;
    pennies?: boolean;
}

@Component({
    selector: 'app-numberpad',
    templateUrl: './numberpad.component.html',
    styleUrls: ['./numberpad.component.scss'],
})
export class NumberpadComponent implements OnInit {
    // Variables.
    value: string;
    readonly allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'Backspace',
        'Enter',
    ];

    // Event listener for keyup.
    @HostListener('document:keyup', ['$event']) keyUp(event: KeyboardEvent) {
        if (!this.allowedKeys.includes(event.key)) return;

        event.preventDefault();
        event.stopPropagation();

        switch (event.key) {
            case 'Backspace':
                this.remove();
                break;
            case 'Enter':
                this.save();
                break;
            default:
                this.add(event.key);
                break;
        }
    }

    constructor(
        public dialogRef: MatDialogRef<NumberpadComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        if (!this.data.value) this.data.value = '';
    }

    decimal() {
        // Remove the decimals.
        this.data.value = this.data.value.replace(/\./g, '');

        // Add decimals at the correct position.
        if (this.data.value.length < 3) return;

        // This should work by putting the decimal two from the end.
        this.data.value = `${this.data.value.slice(
            0,
            this.data.value.length - 2
        )}.${this.data.value.slice(
            this.data.value.length - 2,
            this.data.value.length
        )}`;
    }

    add(val: string) {
        this.data.value += val;

        // Add automatic decimal if number.
        if (this.data.validCash || this.data.pennies) {
            this.decimal();
        }

        this.blur();
    }

    clear() {
        this.data.value = '';
        this.blur();
    }

    remove() {
        this.data.value = this.data.value.slice(0, -1);

        // Add automatic decimal if number.
        if (this.data.validCash || this.data.pennies) {
            this.decimal();
        }

        this.blur();
    }

    validCash() {
        // If not supposed to be disabled, true.
        if (!this.data.validCash) return true;

        // If the value is not a number (invalid) return false.
        let val: number = parseFloat(this.data.value);
        if (Number.isNaN(val)) return false;

        // Check if the amount is larger than or equal to the min.
        if (this.data.minCash && val < this.data.minCash) return false;

        // Check if the amount is valid cash.
        if (!this._misc.validCash(val)) return false;

        // If they all passed, return true.
        return true;
    }

    save() {
        // Parse the value.
        let val: number = parseFloat(this.data.value);

        // If the value is not a number (invalid) just return out of the function.
        if (Number.isNaN(val)) return;

        // If it is valid, return that value.
        this.dialogRef.close(val);
    }

    private blur() {
        try {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        } catch (_) {}
    }
}
