<h1 mat-dialog-title>Editing Screen</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Description"
                [(ngModel)]="data['screen']['description']"
                name="description"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                type="number"
                min="0"
                placeholder="Position"
                [(ngModel)]="data['screen']['position']"
                name="position"
            />
        </mat-form-field>

        <div class="mb50 center flex jcc">
            <button
                mat-raised-button
                color="primary"
                class="m-0-10 asc phone-w100 choose-media"
                (click)="updateMedia()"
            >
                Choose Media
            </button>
            <p class="m-0-10 phone-w100">
                {{ data['screen']['media'] || 'No Media Chosen' }}
            </p>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
