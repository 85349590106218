<h1 mat-dialog-title>Editing Audition</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #auditionForm="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Date"
                [matDatepicker]="auditionPicker"
                [(ngModel)]="audition['date']"
                name="auditionPicker"
                (focus)="auditionPicker.open()"
                (click)="auditionPicker.open()"
                name="date"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="auditionPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #auditionPicker startView="year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Time"
                [(ngModel)]="audition['time']"
                [format]="24"
                [ngxTimepicker]="auditionTime"
                name="time"
                readonly
                required
            />
            <ngx-material-timepicker
                #auditionTime
                [theme]="timePickerTheme"
            ></ngx-material-timepicker>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Description"
                [(ngModel)]="audition['location']"
                name="description"
                required
            />
        </mat-form-field>
        <google-autocomplete
            class="w100"
            placeholder="Location"
            (updated)="audition['gmLocation'] = $event"
            name="location"
        ></google-autocomplete>
        <mat-form-field class="w100">
            <textarea
                matInput
                placeholder="Instructions"
                [(ngModel)]="audition['instructions']"
                name="instructions"
            ></textarea>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                type="number"
                placeholder="Max Slots"
                [(ngModel)]="audition['max_slots']"
                min="1"
                name="max_slots"
                required
            />
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
