<h1 mat-dialog-title>Editing {{ data['type'] }}</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #categoryForm="ngForm">
        <div *ngIf="data['type'] === 'Category'">
            <mat-form-field class="w100">
                <input
                    matInput
                    placeholder="Name"
                    [(ngModel)]="data['category']['name']"
                    name="name"
                    required
                />
            </mat-form-field>
            <mat-form-field class="w100">
                <input
                    matInput
                    placeholder="Facility"
                    [(ngModel)]="data['category']['facility']"
                    name="facility"
                />
            </mat-form-field>
        </div>
        <div *ngIf="data['type'] === 'Tax'">
            <mat-form-field class="w100">
                <input
                    matInput
                    placeholder="Name"
                    [(ngModel)]="data['tax']['name']"
                    name="name"
                    required
                />
            </mat-form-field>
            <mat-form-field class="w100">
                <input
                    matInput
                    placeholder="Tax (decimal)"
                    [(ngModel)]="data['tax']['amount']"
                    name="amount"
                    required
                />
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
