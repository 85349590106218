import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TusService } from 'src/app/services/tus.service';

type FileStatus = 'waiting' | 'uploading' | 'success' | 'fail';

interface Data {
    title?: string;
    content?: string;
    files: {
        file: File;
        type: string;
    }[];
}

export interface Upload {
    file: File;
    type: string;
    status: FileStatus;
    progress: string;
    bytesSent: number;
    bytesTotal: number;
}

@Component({
    selector: 'app-uploadfiles',
    templateUrl: './uploadfiles.component.html',
    styleUrls: ['./uploadfiles.component.scss'],
})
export class UploadfilesComponent implements OnInit {
    // Upload variables.
    uploads: Upload[] = [];
    index: number = 0;

    // Bound function variables.
    error = this._error.bind(this);
    success = this._success.bind(this);
    progress = this._progress.bind(this);

    constructor(
        public dialogRef: MatDialogRef<UploadfilesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _tus: TusService
    ) {
        this.dialogRef.disableClose = true;

        this.uploads = this.data.files.map((f) => {
            return {
                ...f,
                status: 'waiting',
                progress: '0',
                bytesSent: 0,
                bytesTotal: f.file.size,
            };
        });
    }

    ngOnInit() {
        this.upload();
    }

    close() {
        this.dialogRef.close(this.uploads);
    }

    async upload() {
        this._tus.upload(
            'screen-media',
            this.uploads[this.index].file,
            this.error,
            this.progress,
            this.success
        );

        this.uploads[this.index].status = 'uploading';
    }

    _error(e: Error) {
        console.log(e);
        this.uploads[this.index].status = 'fail';
    }

    _success() {
        this.uploads[this.index].status = 'success';
        this.index++;

        // If we are done.
        if (this.index >= this.uploads.length) return;

        // Start new upload.
        this.upload();
    }

    _progress(percentage: string, bytesSent: number, bytesTotal: number) {
        this.uploads[this.index].progress = percentage;
        this.uploads[this.index].bytesSent = bytesSent;
        this.uploads[this.index].bytesTotal = bytesTotal;
    }
}
