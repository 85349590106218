<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Add/Remove Tickets</h1>
<div mat-dialog-content>
    <p class="mb15">
        Scan a card and type the amount of tickets to add to the card. Note, to
        remove tickets, input a negative number.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Amount"
            [(ngModel)]="amount"
            pattern="^-?[^-0][0-9]+$"
            required
        />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="addOrRemove()" color="primary">
        Add Tickets
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
