import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { Transaction } from 'src/app/pos/pos/pos.component';
import { NumberpadComponent } from '../numberpad/numberpad.component';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CurrencyPipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { CustomerFacingScreensService } from 'src/app/services/customer-facing-screens.service';
import SignaturePad from 'signature_pad';

interface Data {
    transaction: Transaction;
    products: Object[];
    taxes: Object[];
    user: Object;
    location: Object;
    facility: Object;
    posId?: string;
    cfsEnabled: boolean;
    cfsTemplates?: Object[];
    cfsSocket: any;
    $cfsSocketMessages?: Subject<Object>;
}

interface CashTendered {
    exactDisplay: string;
    exact: number;
    nearestDollar: number;
    nearestFive: number;
    nearestTwenty: number;
}

@Component({
    selector: 'app-completetransaction',
    templateUrl: './completetransaction.component.html',
    styleUrls: ['./completetransaction.component.scss'],
})
export class CompletetransactionComponent implements OnInit, OnDestroy {
    // Loading variables.
    loading: boolean = false;
    loadingText: string = '';
    loadingOpacity: number = 0.5;

    // Transaction variables.
    hasGiftCards: boolean = false;
    purchasedGiftCards: Object[];
    state:
        | 'payment_method'
        | 'cash_tendering'
        | 'await_card'
        | 'cash_final'
        | 'gift_cards'
        | 'transaction_error'
        | 'template' = 'payment_method';
    cashTendered: CashTendered;
    suggestedProducts: Object[];
    transactionError: string;
    transactionID: string;
    groupInfo?: Object;

    // CFS variables.
    subscription: Subscription;
    cfsTemplate: Object;
    cfsTemplateType: string;
    cfsTemplateIndex: number = 0;
    cfsBindables: HTMLElement[];
    cfsData: Object;
    cfsAwaitData: { accept: boolean; data: Object };
    cfsRerender: boolean = false;
    successTimerStart: number;

    @ViewChild('cfsRendererDiv', { static: false })
    cfsRendererDiv: ElementRef<HTMLDivElement>;

    constructor(
        public dialogRef: MatDialogRef<CompletetransactionComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog,
        private _http: HttpService,
        private _snackbar: SnackbarService,
        private _cp: CurrencyPipe,
        private _cdr: ChangeDetectorRef,
        private _cfs: CustomerFacingScreensService
    ) {}

    async ngOnInit() {
        // Load the subscription.
        if (this.data.cfsEnabled) {
            this.subscription = this.data.$cfsSocketMessages.subscribe(
                (data) => {
                    switch (data['event']) {
                        case 'data':
                            this.cfsData = data['data'];
                            if (this.cfsRerender) {
                                this.renderCFSTemplate();
                            }
                            this.updateCFSBindables();
                            break;
                        case 'finish':
                            this.cfsAwaitData = data['data'] as any;
                            break;
                        case 'templates':
                            this.data.cfsTemplates = data['data']['templates'];
                            break;
                    }
                }
            );
        }

        // Check if there are any suggested products.
        if (
            this.data.transaction.total > 0 &&
            this.data.transaction.items.length &&
            !this.data.transaction.items.every(
                (item) => !item['suggested_products']
            )
        ) {
            // Customer facing screen template for suggested products.
            const sp = await this.cfsTemplates('suggestedProducts', {
                products: [
                    ...new Set(
                        Array.prototype.concat.apply(
                            [],
                            this.data.transaction.items.map((item) =>
                                item['suggested_products'] && !item['refunded']
                                    ? item['suggested_products'].split(',')
                                    : []
                            )
                        )
                    ),
                ]
                    .map((item) =>
                        this.data.products.find(
                            (product) => product['id'] === item
                        )
                    )
                    .filter((item) => !!item)
                    .map((item) => {
                        return { ...item, quantity: 0 };
                    }),
                clerk: this.data.user['first_name'],
                tax: this.data.transaction.tax,
                total: this.data.transaction.total,
            });

            // If valid.
            if (sp.length > 0 && sp[0]['accept']) {
                const products = sp[0]['data']['products'].filter(
                    (p) => p['quantity'] > 0
                );

                // Push the products.
                for (let i = 0; i < products.length; i++) {
                    this.data.transaction.items.push(products[i]);
                }

                // Refactor and recalculate.
                this._misc.refactorItems(this.data.transaction);
                this._misc.calculateTotals(
                    this.data.transaction,
                    this.data.taxes
                );
            }
        }

        // If we need to get the group information.
        // if (this.data.transaction.items.length > 0) {
        //     const groupInfo = await this.cfsTemplates('guestInformation', {
        //         clerk: this.data.user['first_name'],
        //         tax: this.data.transaction.tax,
        //         total: this.data.transaction.total,
        //     });
        //     if (groupInfo.length > 0 && groupInfo[0].accept) {
        //         const { first_name, last_name, email, phone } =
        //             groupInfo[0].data;
        //         this.groupInfo = { first_name, last_name, email, phone };
        //     }
        // }

        // Check if a refund and gift card in the cart.
        if (
            this.data.transaction.total < 0 &&
            this.data.transaction.giftcards.length > 0
        ) {
            this.goToFinish();
            return;
        }

        // If no suggested products and not refunding to a gift card, go to the payment method/gift cards state.
        if (!this.enterGiftCardState()) this.enterPaymentMethodState();
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    async cash() {
        // Get the cash numbers.
        this.cashTendered = this.getCashOptions();

        // Set the cash rounding and the new total.
        this.data.transaction.cash_rounding =
            this.data.transaction.total - this.cashTendered.exact;
        this.data.transaction.total = this.cashTendered.exact;

        // Set the payment type and the state.
        this.data.transaction.payment_type = 'cash';

        if (this.data.transaction.total > 0) {
            this.state = 'cash_tendering';
            this.sendCFSTemplate('cash', {
                total: this.data.transaction.total,
                clerk: this.data.user['first_name'],
            });
        } else {
            this.data.transaction.change_given = Math.abs(
                this.data.transaction.total
            );
            this.data.transaction.cash_tendered = 0;
            this.state = 'cash_final';
            await this.finish();

            // CFS successful transaction.
            this.dialogRef.disableClose = true;
            this.cfsSuccessfulTransaction();
        }
    }

    async card() {
        // Check if the pin pad is in localStorage.
        let pinPadID = window.localStorage.getItem('fluiddynamics_pinpad');
        if (!pinPadID) {
            this._snackbar.defaultSnackbar(
                'Card Machine is not connected properly. Please contact a supervisor.'
            );
            return;
        }

        // Set variables.
        this.dialogRef.disableClose = true;
        this.data.transaction.payment_type = 'card';
        this.state = 'await_card';
        this.loading = true;
        this.loadingOpacity = 0.75;
        this.loadingText = 'Creating the order.';

        // Tell the GFS to go to the card template.
        this.sendCFSTemplate('card', {
            total: this.data.transaction.total,
            clerk: this.data.user['first_name'],
        });

        // Call the API to create the order.
        const toPrint = await this.finish(true);

        this.loadingText = 'Sending Information to Card Machine.';

        // Call the API to send purchase to card.
        let endpoint =
            this.data.transaction.total > 0
                ? 'pos/moneris/purchase/'
                : 'pos/moneris/independant-refund/';
        let total =
            this.data.transaction.total > 0
                ? this.data.transaction.total
                : Math.abs(this.data.transaction.total);
        let resp: Object = (
            await this._http.postLocal(endpoint, {
                terminalId: pinPadID,
                o_id: this.transactionID,
                total: this._cp.transform(total, 'CAD', '').replace(/,/g, ''),
                location: this.data.location['id'],
            })
        ).body;

        // If failed.
        if (!resp['success']) {
            this.dialogRef.disableClose = false;
            this.loading = false;
            this.loadingOpacity = 0.5;
            this._snackbar.defaultSnackbar(
                'Failed sending the purchase information to the card machine.'
            );
            this.state = 'payment_method';

            // Tell the WS to go back to the transaction template.
            this.sendCFSTemplate('transaction', {
                ...this.data.transaction,
                clerk: this.data.user['first_name'],
            });
            return;
        }

        // If successful, we need to wait for the new entry in the database.
        this.loadingText = 'Awaiting the payment.';

        let cardInfo: Object = await this.waitForPayment(resp['cloudTicket']);

        // If no error, print the order.
        if (cardInfo['error'] == '0') {
            // If not skipping print, print.
            (document.location as any) = toPrint;
            this.loading = false;
            this.loadingOpacity = 0.5;

            // CFS successful transaction.
            this.dialogRef.disableClose = true;
            this.cfsSuccessfulTransaction();
            return;
        }

        // If error, we are going to delete the order/card entry from the database.
        await this._http.deleteLocal(
            `pos/transactions/card-failure/?id=${this.transactionID}&cloudTicket=${resp['cloudTicket']}`
        );
        this.dialogRef.disableClose = false;
        this.loading = false;
        this.loadingOpacity = 0.5;
        this.state = 'payment_method';

        // Tell the WS to go back to the transaction template.
        this.sendCFSTemplate('transaction', {
            ...this.data.transaction,
            clerk: this.data.user['first_name'],
        });
    }

    waitForPayment(ticket: string) {
        return new Promise((resolve, reject) => {
            let interval = setInterval(async () => {
                let resp: Object = (
                    await this._http.getLocal(
                        `pos/transactions/?card&onlyCard=${ticket}`
                    )
                ).body;

                // If successful, return the object.
                if (!resp.hasOwnProperty('success') || resp['success']) {
                    clearInterval(interval);
                    resolve(resp);
                }
            }, 1000);
        });
    }

    async tender(val: number) {
        // Check for a custom amount.
        if (val === -1) {
            // Show numpad.
            const dialogRef = this._dialog.open(NumberpadComponent, {
                data: {
                    title: `Choose Custom Tender Amount (min: ${this.cashTendered.exactDisplay})`,
                    validCash: true,
                    minCash: this.data.transaction.total,
                },
                autoFocus: false,
            });

            val = parseInt(await dialogRef.afterClosed().toPromise());

            if (!val) return;
        }

        this.data.transaction.cash_tendered = val;
        this.data.transaction.change_given =
            this.data.transaction.cash_tendered - this.data.transaction.total;
        this.state = 'cash_final';
        await this.finish();

        // CFS successful transaction.
        this.dialogRef.disableClose = true;
        this.cfsSuccessfulTransaction();
    }

    async finish(skipPrint: boolean = false) {
        this.loading = true;
        this.loadingOpacity = 0.75;

        // Check if refunds in the transaction.
        this.data.transaction.includes_refunds =
            !this.data.transaction.items.every((item) => !item['refunded']);

        let resp: Object = (
            await this._http.postLocal('pos/complete/', {
                ...this.data.transaction,
                purchasedGiftCards:
                    this.purchasedGiftCards &&
                    this.purchasedGiftCards.length > 0
                        ? this.purchasedGiftCards
                        : null,
                groupInfo: this.groupInfo,
                sold_at: 'pos',
                posId: this.data.posId,
            })
        ).body;

        // Handle the response.
        if (!resp['success']) {
            // If not successful, set transaction error message based on the error.
            switch (resp['response']) {
                case 'VOUCHER_INSERT_ERROR':
                    this.transactionError =
                        'There has been an error inserting a printable item.';
                    break;
                case 'INEXISTENT_LOCATION':
                    this.transactionError =
                        'An unexpected error occurred indicating that your location no longer exists.';
                    break;
                case 'ERROR_TRANSACTION_INSERT':
                    this.transactionError =
                        'There has been an error while attempting to insert the transaction.';
                    break;
                case 'ERROR_TRANSACTION_FETCH':
                    this.transactionError =
                        'There has been an error while attempting to fetch the inserted transaction.';
                    break;
                case 'ERROR_ITEM_INSERT':
                    this.transactionError =
                        'There has been an error while attempting to insert a transaction item.';
                    break;
                case 'BOOKING_NOT_OPEN':
                    this.transactionError =
                        'One of the items in the transaction has a booking that is no longer open.';
                    break;
                case 'BOOKING_INVALID_PEOPLE':
                    this.transactionError =
                        'One of the items in the transaction has a booking with an invalid amount of people (quantity).';
                    break;
                case 'BOOKING_TIME_INVALID':
                    this.transactionError =
                        'One of the items in the transaction has a booking with an invalid time.';
                    break;
                case 'BOOKING_BLOCKED':
                    this.transactionError =
                        'One of the items in the transaction has a booking that is blocked.';
                    break;
                case 'BOOKING_UNAVAILABLE':
                    this.transactionError =
                        'One of the items in the transaction has a booking that is no longer available.';
                    break;
                case 'SACOA_ERROR':
                    this.transactionError =
                        'A Sacoa error has occurred. The payment has been processed but the credits may not be added.';
                    break;
                default:
                    this.transactionError =
                        'An unexpected error occurred while processing the transaction.';
                    break;
            }

            // Add text to errors that are not booking errors.
            if (!resp['response'].includes('BOOKING')) {
                this.transactionError += ' Please contact a supervisor.';
            }

            this.state = 'transaction_error';
            this.loading = false;
            return;
        }

        // If the response was successful.
        this.transactionError = '';
        this.transactionID = resp['transaction'];

        // Set sessionStorage for items with create pass set to true.
        if (resp['createPasses']) {
            window.sessionStorage.setItem('createPasses', 'true');
            window.sessionStorage.setItem('passAmount', resp['passAmount']);
            window.sessionStorage.setItem(
                'createPassesTransaction',
                resp['transaction']
            );
            window.sessionStorage.setItem(
                'posPassData',
                JSON.stringify(resp['posPassData'])
            );
        }

        let toPrint: string = 'mfg://?group=[';

        // Check if we need to print a drawer kick.
        if (
            (this.data.transaction.items.length &&
                this.data.transaction.payment_type === 'cash') ||
            (this.data.transaction.scans.length &&
                this.data.transaction.scans.some((scan) =>
                    scan['barcode'].startsWith('VOUCH')
                ))
        ) {
            toPrint += `{"raw": ["drawer-kick"]},`;
        }

        // Print the receipt.
        if (resp['printReceipt'] && resp['transaction']) {
            toPrint += `{"receipt": ["${resp['transaction']}"]},`;
        }

        // Print the items that had print_voucher flag.
        for (let i = 0; i < resp['printVouchers'].length; i++) {
            toPrint += `{"voucher": ["${resp['printVouchers'][i]}"]},`;
        }

        // Finish to print URL.
        if (toPrint.charAt(toPrint.length - 1) === ',') {
            toPrint = toPrint.slice(0, -1);
        }
        toPrint += ']';

        if (!skipPrint) {
            (document.location as any) = toPrint;

            this.loading = false;
            this.loadingOpacity = 0.5;
        } else {
            return toPrint;
        }
    }

    checkIfZero() {
        // Check if the transaction total is 0 and go straight to the complete order page for cash.
        if (this.data.transaction.total === 0) this.goToFinish();
    }

    goToFinish() {
        this.data.transaction.payment_type = 'cash';
        this.data.transaction.cash_tendered = 0;
        this.data.transaction.change_given = 0;
        this.state = 'cash_final';
        this.finish();

        // CFS successful transaction.
        this.dialogRef.disableClose = true;
        this.cfsSuccessfulTransaction();
    }

    getCashOptions() {
        // Get exact (round to nearest 5c).
        let exactDisplay = (
            Math.round(this.data.transaction.total / 0.05) * 0.05
        ).toFixed(2);

        // Take the exactDisplay and turn it into a number.
        let exact = +exactDisplay;

        // Get the nearest dollar from the total (56 => 57).
        let nearestDollar = Math.ceil(this.data.transaction.total + 0.01);

        // Get the nearest five dollars from the nearest dollar.
        let nearestFive = Math.ceil((nearestDollar + 0.01) / 5) * 5;

        // Get the nearest twenty dollars from the nearest five dollars.
        let nearestTwenty = Math.ceil((nearestFive + 0.01) / 20) * 20;

        return {
            exactDisplay,
            exact,
            nearestDollar,
            nearestFive,
            nearestTwenty,
        };
    }

    enterPaymentMethodState() {
        this.state = 'payment_method';
        this.checkIfZero();
    }

    enterGiftCardState(): boolean {
        // Check if we have gift cards.
        this.checkIfHasGiftCards();
        if (!this.hasGiftCards) return false;

        // If we do, go to that state.
        this.state = 'gift_cards';
        this.purchasedGiftCards = this.data.transaction.items.reduce<Object[]>(
            (prev, curr) => {
                // If not a gift card.
                if (curr['giftcard'] !== '1' || curr['refunded']) return prev;

                // If a gift card push quantity amount.
                for (let i = 0; i < curr['quantity']; i++) {
                    prev.push({
                        itemName: curr['name'],
                        amount: this._misc.round(curr['price']),
                        giftcard: null,
                        giftCardInput: '',
                        error: '',
                    });
                }
                return prev;
            },
            []
        );

        // Focus first input.
        this._cdr.detectChanges();
        const foc = document.getElementById('gift-card-input-0');
        if (foc) foc.focus();

        return true;
    }

    async nextGiftCardInput(index: number) {
        const inputVal = this.purchasedGiftCards[index]['giftCardInput'];
        this.purchasedGiftCards[index]['giftCardInput'] = '';

        // If the gift card is already chosen in the gift cards.
        if (
            this.purchasedGiftCards.some(
                (gc, idx) =>
                    idx !== index &&
                    gc['giftcard'] &&
                    gc['giftcard']['barcode'].toLowerCase() ===
                        inputVal.toLowerCase()
            )
        ) {
            this.purchasedGiftCards[index]['error'] =
                'Gift card already chosen for another item.';
            return;
        }

        this.loading = true;

        // Call API to get the gift card.
        const giftCardResp = await this._http.getLocal(
            `pos/giftcards/?barcode=${inputVal}`
        );

        this.loading = false;

        // If no gift card.
        if (giftCardResp.status !== 200) {
            this.purchasedGiftCards[index]['error'] = 'Gift Card not found.';
            return;
        }

        // If success.
        this.purchasedGiftCards[index]['error'] = '';
        this.purchasedGiftCards[index]['giftcard'] = giftCardResp.body;

        // If last one.
        if (index === this.purchasedGiftCards.length - 1) {
            if (this.purchasedGiftCardsFilled()) this.enterPaymentMethodState();
            return;
        }

        // If not last one, go to next one.
        const nextInput = document.getElementById(
            `gift-card-input-${index + 1}`
        );
        if (nextInput) nextInput.focus();
    }

    checkIfHasGiftCards() {
        this.hasGiftCards = this.data.transaction.items.some(
            (i) => i['giftcard'] === '1' && !i['refunded']
        );
    }

    purchasedGiftCardsFilled() {
        return this.purchasedGiftCards.every((gc) => !!gc['giftcard']);
    }

    async cfsTemplates(
        type: string,
        data: Object = {},
        backToTransactionTemplate: boolean = true
    ) {
        // If no CFS.
        if (!this.data.cfsEnabled) return [];

        const tempState = this.state;

        // Reset variables.
        this.state = 'template';
        this.cfsTemplateIndex = 0;
        this.cfsTemplateType = type;
        this.cfsBindables = [];
        this.cfsData = null;
        this.cfsAwaitData = null;

        this._cdr.detectChanges();

        // Get all the templates.
        let temps = this.data.cfsTemplates.filter(
            (t) => t['type'] === this.cfsTemplateType
        );

        // If any of the templates are local, make sure they are all local.
        if (temps.some((t) => t['global'] === '0')) {
            temps = temps.filter((t) => t['global'] === '0');
        }

        // Emit.
        this.data.cfsSocket.emit('template', {
            type,
            data,
        });

        const ret: any[] = [];
        for (let i = 0; i < temps.length; i++) {
            this.cfsTemplate = temps[i];

            // Set default values.
            this.cfsData = { ...data };
            if (this.cfsTemplate['fields']) {
                const keys = Object.keys(this.cfsTemplate['fields']);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    const field = this.cfsTemplate['fields'][key];
                    if (!(key in this.cfsData)) {
                        this.cfsData[key] = field['default'];
                    }
                }

                this.data.cfsSocket.emit('data', this.cfsData);
            }

            this.renderCFSTemplate();

            // Wait for the data, push, and set to null.
            ret.push(await this.awaitCFSTemplate());
            this.cfsAwaitData = null;
        }

        // Remove all renderer div children.
        this.clearCFSTemplate();

        // If back to transaction state.
        if (backToTransactionTemplate) {
            this.sendCFSTemplate('transaction', {
                ...this.data.transaction,
                clerk: this.data.user['first_name'],
            });
        }

        this.state = tempState;
        return ret;
    }

    sendCFSTemplate(type: string, data: Object = {}) {
        if (!this.data.cfsEnabled) return;

        this.data.cfsSocket.emit('template', {
            type,
            data,
        });
    }

    renderCFSTemplate() {
        // Remove all renderer div children.
        this.clearCFSTemplate();

        this.cfsRerender = false;

        // Get the parser and parse from the content.
        const parser = new DOMParser();
        const doc = parser.parseFromString(
            this._cfs.parseTemplate(
                this.cfsTemplate,
                this.cfsData,
                this.data.location,
                this.data.facility
            ),
            'text/html'
        );

        // Move all the elements in the template into the rendererDiv.
        const children = Array.from(doc.body.children);
        for (let j = 0; j < children.length; j++) {
            this.cfsRendererDiv.nativeElement.appendChild(children[j]);
        }

        // Check if we have a custom button or rerender.
        if (
            this.cfsRendererDiv.nativeElement.querySelectorAll(
                'button[type="custom"]'
            ).length > 0 ||
            this.cfsRendererDiv.nativeElement.querySelectorAll('[rerender]')
                .length > 0
        ) {
            this.cfsRerender = true;
        }

        // Handle all the form elements to ignore submit.
        const forms =
            this.cfsRendererDiv.nativeElement.querySelectorAll('form');
        for (let i = 0; i < forms.length; i++) {
            forms[i].addEventListener('submit', (e) => {
                e.preventDefault();
            });
        }

        // Bind needed.
        this.cfsBindables = Array.from(
            this.cfsRendererDiv.nativeElement.querySelectorAll('[bind]')
        );
        for (let j = 0; j < this.cfsBindables.length; j++) {
            const bindable = this.cfsBindables[j];
            const bind = bindable.attributes.getNamedItem('bind');

            // Create callback for default behaviour for bindables.
            const defaultCB = (val: any) => {
                this._misc.stringDotNotationToObjRef(
                    this.cfsData,
                    bind.value,
                    val
                );
                this.data.cfsSocket.emit('data', this.cfsData);
            };

            switch (bindable.localName) {
                case 'input':
                    const input = bindable as HTMLInputElement;

                    // Get the type.
                    const typeAttr = bindable.attributes.getNamedItem('type');
                    const type = typeAttr == null ? 'text' : typeAttr.value;

                    // If the type is checkbox.
                    if (type === 'checkbox') {
                        bindable.addEventListener('change', () => {
                            defaultCB(input.checked);
                        });
                    } else {
                        bindable.addEventListener('input', () => {
                            defaultCB(input.value);
                        });
                    }

                    // Validate on input blur.
                    bindable.addEventListener('blur', () => {
                        // Validate the field.
                        const field = this.cfsTemplate['fields'][bind.value];
                        this._cfs.validateField(
                            this.cfsData,
                            field,
                            bind.value
                        );
                    });

                    // Check if we need to add a star for the placeholder.
                    const placeholder =
                        bindable.attributes.getNamedItem('placeholder');
                    if (placeholder) {
                        if (
                            this.cfsTemplate['fields'][bind.value]['required']
                        ) {
                            const newPlaceholder = `${placeholder.value} *`;
                            bindable.setAttribute(
                                'placeholder',
                                newPlaceholder
                            );
                        }
                    }

                    break;
                case 'textarea':
                    bindable.addEventListener('input', () => {
                        defaultCB((bindable as HTMLTextAreaElement).value);
                    });
                    break;
                case 'select':
                    const select = bindable as HTMLSelectElement;

                    // Insert a blank option.
                    const blankOption = document.createElement('option');
                    blankOption.value = '';
                    if (bindable.firstChild) {
                        bindable.insertBefore(blankOption, bindable.firstChild);
                    } else {
                        bindable.appendChild(blankOption);
                    }

                    // On change, update.
                    select.value = '';
                    bindable.addEventListener('change', () => {
                        // Validate the field.
                        const field = this.cfsTemplate['fields'][bind.value];
                        this._cfs.validateField(
                            this.cfsData,
                            field,
                            bind.value
                        );

                        defaultCB(select.value);
                    });

                    // Check if we need to add a star for the placeholder.
                    const selectPlaceholder =
                        bindable.attributes.getNamedItem('placeholder');
                    if (selectPlaceholder) {
                        if (
                            this.cfsTemplate['fields'][bind.value]['required']
                        ) {
                            const newSelectPlaceholder = `${selectPlaceholder.value} *`;
                            bindable.setAttribute(
                                'placeholder',
                                newSelectPlaceholder
                            );
                        }
                    }
                case 'signature-pad':
                    // Create the canvas.
                    const canvas = document.createElement('canvas');
                    canvas.style.background = 'white';
                    const width = bindable.attributes.getNamedItem('width');
                    if (width) canvas.width = parseInt(width.value);
                    const height = bindable.attributes.getNamedItem('height');
                    if (height) canvas.height = parseInt(height.value);
                    bindable.parentNode.insertBefore(canvas, bindable);

                    // Create the signature pad.
                    const signaturePad = new SignaturePad(canvas, {
                        onEnd: () => {
                            defaultCB({
                                data: signaturePad.toData(),
                                size: {
                                    width: canvas.width,
                                    height: canvas.height,
                                },
                            });
                        },
                    });
                    canvas['signature-pad'] = signaturePad;

                    // Create the button below the canvas.
                    const button = document.createElement('button');
                    button.textContent = 'Clear';
                    button.style.display = 'block';
                    button.addEventListener('click', () => {
                        signaturePad.clear();
                        defaultCB({
                            data: [],
                            size: {
                                width: canvas.width,
                                height: canvas.height,
                            },
                        });
                    });
                    bindable.parentNode.insertBefore(button, bindable);
            }
        }

        // Bind the buttons.
        const buttons =
            this.cfsRendererDiv.nativeElement.querySelectorAll('button');
        for (let j = 0; j < buttons.length; j++) {
            const button = buttons[j];
            button.addEventListener('click', () => {
                const typeAttr = button.attributes.getNamedItem('type');
                const type = typeAttr == null ? 'continue' : typeAttr.value;

                // If type of custom.
                if (type === 'custom') {
                    // Get the custom JS.
                    const customAttr = button.attributes.getNamedItem('custom');
                    const custom = customAttr == null ? null : customAttr.value;
                    if (!custom) return;

                    // Run the custom JS.
                    this._cfs.functionEval(
                        this.cfsData,
                        [custom],
                        (match) => match,
                        (_, __) => {}
                    );

                    // Send WS the data, then re-render the template.
                    this.data.cfsSocket.emit('data', this.cfsData);
                    this.renderCFSTemplate();
                    return;
                }

                // If decline.
                if (type === 'decline' || type === 'skip') {
                    this.cfsAwaitData = {
                        accept: false,
                        data: this.cfsData,
                    };
                    this.data.cfsSocket.emit('finish');
                    return;
                }

                // Make sure all fields are valid.
                let allValid: boolean = true;
                if (this.cfsTemplate['fields']) {
                    // Loop through the keys.
                    const fieldKeys = Object.keys(this.cfsTemplate['fields']);
                    for (let i = 0; i < fieldKeys.length; i++) {
                        // Get the field and check if it has validation.
                        const field = this.cfsTemplate['fields'][fieldKeys[i]];

                        // Validate it.
                        if (
                            !this._cfs.validateField(
                                this.cfsData,
                                field,
                                fieldKeys[i]
                            )
                        ) {
                            allValid = false;
                        }
                    }
                }

                // If not all valid.
                if (!allValid) return;

                // If we got this far, go to the next template.
                this.cfsAwaitData = { accept: true, data: this.cfsData };
                this.data.cfsSocket.emit('finish');
            });
        }
    }

    async awaitCFSTemplate() {
        return new Promise<any>((resolve, _) => {
            const wait = () => {
                // If we have data.
                if (this.cfsAwaitData) {
                    return resolve(this.cfsAwaitData);
                }

                // Else, wait another 50ms.
                setTimeout(wait, 50);
            };
            wait();
        });
    }

    updateCFSBindables() {
        // Loop through the bindables.
        for (let i = 0; i < this.cfsBindables.length; i++) {
            const bindable = this.cfsBindables[i];
            const bind = bindable.attributes.getNamedItem('bind');

            // Get the field.
            const field = this._misc.stringDotNotationToObjRef(
                this.cfsTemplate,
                `fields.${bind.value.split('.').pop()}`
            ) || { default: '' };

            // Get the value, or the default.
            let val =
                this._misc.stringDotNotationToObjRef(
                    this.cfsData,
                    bind.value
                ) || field['default'];

            // If the data is from the signature pad.
            if (val instanceof Object && 'size' in val && 'data' in val) {
                val = val['data'];
            }

            // Switch on the local name of the bindable element (note input needs to be right before default).
            switch (bindable.localName) {
                case 'signature-pad':
                    // Check if the previous sibling is a canvas.
                    const canvas = bindable.previousSibling?.previousSibling;
                    if (
                        !canvas ||
                        !(canvas instanceof HTMLCanvasElement) ||
                        !('signature-pad' in canvas)
                    ) {
                        break;
                    }

                    // Get the signature pad and set the data.
                    const signaturePad: SignaturePad = (canvas as any)[
                        'signature-pad'
                    ];
                    signaturePad.fromData(val);

                    break;
                case 'input':
                    const typeAttr = bindable.attributes.getNamedItem('type');
                    const type = typeAttr == null ? 'text' : typeAttr.value;

                    // If checkbox.
                    if (type === 'checkbox') {
                        (bindable as HTMLInputElement).checked = val;
                        break;
                    }
                default:
                    (bindable as any).value = val;
                    break;
            }
        }
    }

    clearCFSTemplate() {
        // Remove all renderer div children.
        while (this.cfsRendererDiv.nativeElement.firstChild) {
            this.cfsRendererDiv.nativeElement.firstChild.remove();
        }
    }

    cfsSuccessfulTransaction() {
        if (!this.data.cfsEnabled) return;

        // Send success, then welcome 4 seconds after.
        this.sendCFSTemplate('success', {
            paymentType: this.data.transaction.payment_type,
            changeGiven: this.data.transaction.change_given,
            clerk: this.data.user['first_name'],
        });
        this.successTimerStart = +new Date();
        setTimeout(() => {
            this.sendCFSTemplate('welcome');
        }, 4000);
    }

    signatureDataToImage(data: any): string {
        // Create canvas and signature pad
        let cv = document.createElement('canvas');
        cv.width = data.size.width;
        cv.height = data.size.height;
        let sp = new SignaturePad(cv);

        // Load data and then turn into data url.
        sp.fromData(data.data);
        const dataUrl = sp.toDataURL();

        // Try to do some de-init.
        try {
            sp.off();
            sp = null;
            cv.remove();
            cv = null;
        } catch (_) {}

        // Return the data url.
        return dataUrl;
    }
}
