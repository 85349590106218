import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-sacoahistoryanalyzecard',
    templateUrl: './sacoahistoryanalyzecard.component.html',
    styleUrls: ['./sacoahistoryanalyzecard.component.scss'],
})
export class SacoahistoryanalyzecardComponent implements OnInit {
    // Loading variables.
    balanceLoading: 'empty' | 'loading' | 'loaded' = 'empty';
    historyLoading: 'empty' | 'loading' | 'loaded' = 'empty';

    // Card variables.
    card: string;
    balance: Object;
    history: Object;

    constructor(
        public dialogRef: MatDialogRef<SacoahistoryanalyzecardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    async search() {
        // Check if the card is set.
        if (!this.card || !/^[0-9]{6,8}$/.test(this.card)) return;

        // Loading/setup.
        const card = this.card;
        this.card = '';
        this.balanceLoading = 'loading';

        const resp = await this.fetchInfo(
            `pos/sacoa/balance/?posId=${this.data['posId']}&card=${card}`
        );
        if (!resp) return;

        // If successful.
        this.balance = resp;
        this.balanceLoading = 'loaded';
        this.historyLoading = 'loading';

        // Call the history API.
        const historyResp = await this.fetchInfo(
            `pos/sacoa/history/?posId=${this.data['posId']}&card=${card}`
        );
        if (!historyResp) return;

        // Get the date from each entry into history.
        if (historyResp['body'] instanceof Array) {
            let groupIndex = 0;
            historyResp['body'] = historyResp['body'].map((entry, i, arr) => {
                // Get the date.
                const date = dayjs(entry['transdate']).format(
                    'MMM D, YYYY HH:mm:ss'
                );

                // Check if the date should be shown.
                const dateShown =
                    i === 0 || arr[i - 1]['transdate'] !== entry['transdate'];

                // Check if last in row.
                const last =
                    i === arr.length - 1 ||
                    arr[i + 1]['transdate'] !== entry['transdate'];

                // Add to groupIndex.
                if (dateShown) groupIndex++;

                return {
                    ...entry,
                    date: dateShown ? date : '',
                    first: dateShown,
                    last,
                    groupIndex,
                };
            });
        }

        // If successful.
        this.history = historyResp;
        this.historyLoading = 'loaded';
    }

    async fetchInfo(endpoint: string) {
        // Call the balance API.
        const resp = await this._http.getLocal(endpoint);

        // If not 200. Bad client request.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'A client error has occurred. Make sure the card number is valid.'
            );
            this.balance = null;
            this.history = null;
            return;
        }

        // If 200 but not successful.
        if (resp.body.hasOwnProperty('success') && !resp.body['success']) {
            this._snackbar.durationSnackbar(
                `The following Sacoa error has occurred: ${resp.body['response']}.`,
                null,
                10000
            );
            this.balance = null;
            this.history = null;
            return;
        }

        return resp.body;
    }
}
