import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface Customer {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    mobile: string;
    address1: string;
    address2?: string;
    postal_code: string;
    country: string;
    province: string;
    city: string;
}

@Component({
    selector: 'app-createcustomer',
    templateUrl: './createcustomer.component.html',
    styleUrls: ['./createcustomer.component.scss']
})
export class CreatecustomerComponent implements OnInit {
    // Dialog variables.
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    customer: Customer = <Customer>{};

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<CreatecustomerComponent>,
        public _misc: MiscService,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService,
        private _cdr: ChangeDetectorRef
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe(_ => this.askClose());
    }

    ngOnInit() {}

    hasChanges() {
        return this.form.dirty;
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.'
                }
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        this.dialogRef.close(this.customer);
    }
}
