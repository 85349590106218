import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[currencyMask]',
})
export class CurrencyMaskDirective {
    constructor(public ngControl: NgControl) {}

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    onInputChange(event) {
        if (!event) return;

        this.ngControl.valueAccessor.writeValue(event.replace(/[^0-9.]/g, ''));
    }
}
