<h1 mat-dialog-title>Change Ticket Name</h1>
<div mat-dialog-content>
    <mat-form-field class="w100">
        <input matInput placeholder="Name" [(ngModel)]="newName" required />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        (click)="save()"
        [disabled]="!hasChanges()"
        color="primary"
    >
        Save
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
