<!-- Top logo template. -->
<ng-template #topLogoTemplate>
    <div class="self-serve-top-logo center">
        <img [src]="logo" alt="Logo" />
    </div>
</ng-template>

<!-- Grow shrink button template. -->
<ng-template #growShrinkButton let-text="text" let-fn="fn" let-state="state">
    <div class="self-serve-grow-shrink">
        <button
            mat-raised-button
            color="primary"
            class="self-serve-button"
            (click)="growShrinkButtonClick(fn, state)"
        >
            {{ text }}
        </button>
        <span class="opposite-color">{{ text }}</span>
    </div>
</ng-template>

<!-- Cart Button template. -->
<ng-template #cartButton>
    <div class="self-serve-cart-button" *ngIf="transactionQuantity > 0">
        <button
            mat-raised-button
            class="self-serve-button"
            (click)="setState('cart')"
        >
            <mat-icon [matBadge]="transactionQuantity" matBadgeColor="primary">
                shopping_cart
            </mat-icon>
            &nbsp;&nbsp;&nbsp;Cart
        </button>
    </div>
</ng-template>

<loading
    *ngIf="loading"
    [opacity]="loadingOpacity"
    [text]="loadingText"
    [progress]="loadingProgress"
></loading>

<div class="self-serve-max"></div>

<!-- Error State -->
<div data-main="error" class="w100 h100" [ngClass]="{ dn: state !== 'error' }">
    <div class="center-screen">
        <h1 class="self-serve-large-text center">Errors</h1>
        <h1 class="center" *ngFor="let error of errors">{{ error }}</h1>
        <button
            class="self-serve-button self-serve-error-reload center-btn"
            mat-raised-button
            color="primary"
            onclick="window.location.reload()"
        >
            Reload Page
        </button>
    </div>
</div>

<!-- Start State -->
<div data-main="start" class="w100 h100" [ngClass]="{ dn: state !== 'start' }">
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <div class="center-screen">
        <ng-container
            *ngTemplateOutlet="
                growShrinkButton;
                context: {
                    text: 'Let\'s Have Fun!',
                    fn: 'start',
                    state: 'type'
                }
            "
        ></ng-container>
    </div>
</div>

<!-- Type State -->
<div data-main="type" class="w100 h100" [ngClass]="{ dn: state !== 'type' }">
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <h1 class="center self-serve-title">What would you like to do?</h1>

    <div class="self-serve-categories center">
        <mat-card
            class="pointer blue-text flex jcc aic"
            (click)="setState('categories')"
        >
            <mat-card-content>
                <span>Purchase</span>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="pointer blue-text flex jcc aic"
            (click)="setState('scanning')"
        >
            <mat-card-content>
                <span>Activate Passes</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Categories State -->
<div
    data-main="categories"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'categories' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="cartButton"></ng-container>

    <h1 class="center self-serve-title">
        What Type of Passes Are you Looking For?
    </h1>

    <div class="self-serve-categories center">
        <mat-card
            class="pointer blue-text flex jcc aic"
            *ngFor="let category of categories"
            (click)="chooseCategory(category)"
        >
            <mat-card-content>
                <span>{{ category['name'] }}</span>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="self-serve-exit-button">
        <button
            mat-raised-button
            color="warn"
            class="self-serve-button"
            (click)="exitPurchase()"
        >
            Cancel Purchase
        </button>
    </div>
</div>

<!-- Subcategory State -->
<div
    data-main="subcategory"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'subcategory' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="cartButton"></ng-container>

    <mat-card class="self-serve-category-card">
        <mat-card-content>
            <p class="center bold self-serve-category-title">
                {{ currentCategory ? currentCategory['title'] : '' }}
            </p>
            <p
                self-serve-category-description
                class="bold self-serve-category-description"
            ></p>
            <div class="self-serve-subcategories center">
                <button
                    mat-raised-button
                    color="primary"
                    class="self-serve-button"
                    *ngFor="let category of subCategories"
                    (click)="chooseCategory(category)"
                >
                    {{ category['name'] }}
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="self-serve-category-actions">
        <button
            mat-raised-button
            color="primary"
            class="self-serve-button"
            (click)="setState('categories')"
        >
            Go Back
        </button>
    </div>
</div>

<!-- Products State -->
<div
    data-main="products"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'products' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="cartButton"></ng-container>

    <mat-card class="self-serve-category-card">
        <mat-card-content>
            <p class="center bold self-serve-category-title">
                {{ currentCategory ? currentCategory['title'] : '' }}
            </p>
            <p
                self-serve-category-description
                class="bold self-serve-category-description"
            ></p>

            <div class="self-serve-category-products flex col h100 scrollbar">
                <div
                    class="self-serve-category-product"
                    *ngFor="let product of filteredProducts"
                >
                    <p class="self-serve-category-product-price asc bold">
                        {{ product['price'] | currency }}
                    </p>
                    <p class="self-serve-category-product-name asc w100">
                        {{ product['kiosk_name'] }}
                    </p>
                    <div class="self-serve-category-product-quantity">
                        <button
                            mat-mini-fab
                            color="primary"
                            class="asc"
                            (click)="modifyQuantity(product, false)"
                            [disabled]="product['quantity'] <= 0"
                        >
                            <mat-icon>remove</mat-icon>
                        </button>
                        <p class="asc bold">{{ product['quantity'] }}</p>
                        <button
                            mat-mini-fab
                            color="primary"
                            class="asc"
                            (click)="modifyQuantity(product, true)"
                            [disabled]="product['quantity'] >= 99"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="self-serve-cart-bottom self-serve-category-bottom"
                *ngIf="toAdd['quantity'] > 0"
            >
                <p translation="amount"></p>
                <p>{{ toAdd['total'] | currency }}</p>
                <button
                    mat-raised-button
                    color="primary"
                    class="self-serve-button"
                    (click)="addToCartOrSelectADate()"
                >
                    <span
                        [translation]="
                            anyReservationOrBooking
                                ? 'selectADate'
                                : 'addToCart'
                        "
                    ></span>
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="self-serve-category-actions">
        <button
            mat-raised-button
            color="primary"
            class="self-serve-button"
            (click)="setState('categories')"
        >
            Go Back
        </button>
    </div>
</div>

<!-- Select A Date State -->
<div
    data-main="selectADate"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'selectADate' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <mat-card class="self-serve-category-card">
        <mat-card-content>
            <div class="mfg-calendar"></div>
        </mat-card-content>
    </mat-card>
</div>

<!-- Cart State -->
<div data-main="cart" class="w100 h100" [ngClass]="{ dn: state !== 'cart' }">
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <mat-card class="self-serve-category-card">
        <mat-card-content>
            <p class="center bold self-serve-category-title">Your Cart</p>
            <p class="bold self-serve-category-description">
                Below are the items in your cart. To remove an item from your
                cart, set the quantity to 0.
            </p>

            <div
                *ngIf="transaction.items && transaction.items.length > 0"
                class="self-serve-category-products self-serve-cart-products flex col h100 scrollbar"
            >
                <div
                    class="self-serve-category-product"
                    *ngFor="let product of transaction.items"
                >
                    <p class="self-serve-category-product-price asc bold">
                        {{ product['price'] * product['quantity'] | currency }}
                    </p>
                    <p class="self-serve-category-product-name asc w100">
                        {{ product['kiosk_name'] }}
                    </p>
                    <div class="self-serve-category-product-quantity">
                        <button
                            mat-mini-fab
                            color="primary"
                            class="asc"
                            (click)="modifyQuantity(product, false, true)"
                            [disabled]="product['quantity'] <= 0"
                        >
                            <mat-icon>remove</mat-icon>
                        </button>
                        <p class="asc bold">{{ product['quantity'] }}</p>
                        <button
                            mat-mini-fab
                            color="primary"
                            class="asc"
                            (click)="modifyQuantity(product, true, true)"
                            [disabled]="product['quantity'] >= 99"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div
                *ngIf="transaction.items && transaction.items.length < 1"
                class="self-serve-cart-empty center center-screen w100"
            >
                <p>Oh No!</p>
                <p>Your cart is empty!</p>
            </div>

            <div
                *ngIf="transaction.items && transaction.items.length > 0"
                class="self-serve-cart-bottom"
            >
                <p>Total</p>
                <p>{{ transaction.total | currency }}</p>
                <button
                    mat-raised-button
                    color="primary"
                    class="self-serve-button"
                    (click)="checkout()"
                >
                    Checkout
                </button>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="self-serve-category-actions">
        <button
            mat-raised-button
            color="warn"
            class="self-serve-button"
            (click)="exitPurchase()"
        >
            Cancel Purchase
        </button>
        <button
            mat-raised-button
            color="primary"
            class="self-serve-button"
            (click)="setState('categories')"
        >
            Keep Shopping
        </button>
    </div>
</div>

<!-- Checkout State -->
<div
    data-main="checkout"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'checkout' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <p class="self-serve-checkout-main-text center center-screen">
        {{ checkoutText }}
    </p>
</div>

<!-- Final State -->
<div data-main="final" class="w100 h100" [ngClass]="{ dn: state !== 'final' }">
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <div class="self-serve-final-text center center-screen">
        <p class="self-serve-checkout-main-text w100i">Thank you!</p>
        <p>Your receipt should be printing, have a great day!</p>
    </div>
</div>

<!-- Scanning State -->
<div
    data-main="scanning"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'scanning' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <mat-card class="self-serve-category-card">
        <mat-card-content>
            <p class="center bold self-serve-category-title">
                Scanned Items{{
                    scanned && scanned.length > 0
                        ? ' (' + scanned[0]['passType'] + ')'
                        : ''
                }}
            </p>
            <p class="bold self-serve-category-description center">
                Below are the items that you have scanned. To remove an item
                from the scans, click the x beside the item.
            </p>

            <div
                *ngIf="scanned && scanned.length > 0"
                class="self-serve-scanned-items self-serve-category-products flex col h100 scrollbar"
            >
                <div
                    class="self-serve-scanned-item self-serve-category-product flex"
                    *ngFor="let scan of scanned"
                >
                    <p class="self-serve-scanned-item-barcode asc">
                        {{ scan['barcode'] || scan['v_id'] }}
                    </p>
                    <p
                        class="self-serve-scanned-item-description self-serve-category-product-name asc w100"
                    >
                        {{ scan['description'] }}
                    </p>
                    <div class="self-serve-scanned-item-remove">
                        <button
                            mat-mini-fab
                            color="primary"
                            class="asc"
                            (click)="removeScan(scan)"
                        >
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div
                *ngIf="scanned && scanned.length < 1"
                class="self-serve-cart-empty center p-10-0 w100"
            >
                <p hidden></p>
                <p>Please scan all the vouchers you wish to activate.</p>
                <img
                    [src]="scannerImage"
                    class="self-serve-scanner-image"
                    alt="Scanner Image"
                />
            </div>
        </mat-card-content>
    </mat-card>

    <div class="self-serve-category-actions">
        <button
            mat-raised-button
            color="warn"
            class="self-serve-button"
            (click)="exitPurchase()"
        >
            Cancel Activation
        </button>
        <button
            *ngIf="scanned && scanned.length > 0"
            mat-raised-button
            color="primary"
            class="self-serve-button"
            (click)="finaliseScans()"
        >
            Activate
        </button>
    </div>
</div>

<!-- Activation State -->
<div
    data-main="activation"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'activation' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <mat-card class="self-serve-category-card self-serve-activation-card">
        <mat-card-content>
            <div class="self-serve-activation-group-information">
                <h1 class="center">Group Information</h1>
                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            id="group-email-input"
                            type="email"
                            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                            placeholder="Email"
                            [(ngModel)]="group.email"
                            autocomplete="email"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            phoneMask
                            type="tel"
                            placeholder="Phone #"
                            [(ngModel)]="group.phone"
                            autocomplete="tel"
                            required
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            postalCodeMask
                            type="text"
                            placeholder="Postal Code"
                            [(ngModel)]="group.postal_code"
                            autocomplete="postal-code"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="self-serve-category-card self-serve-activation-card">
        <mat-card-content>
            <h1 class="self-serve-activation-members-h1 center">
                Group Members
            </h1>
            <div class="self-serve-activation-members scroll">
                <div
                    class="self-serve-activation-member flex jcc"
                    [ngClass]="{
                        'self-serve-activation-member-selected':
                            selectedPass === pass
                    }"
                    *ngFor="let pass of passes; let i = index"
                >
                    <img
                        [src]="pass.photo || defaultPhoto"
                        class="pointer"
                        (click)="choosePass(pass)"
                    />
                    <p *ngIf="pass.first_name && pass.last_name">
                        {{ pass.first_name }} {{ pass.last_name }}
                    </p>
                    <p *ngIf="!pass.first_name || !pass.last_name">
                        Person {{ i + 1 }}
                    </p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="self-serve-category-card self-serve-activation-card">
        <mat-card-content>
            <h1 class="self-serve-activation-members-h1 center">
                Editing Member
            </h1>
            <div class="self-serve-selected-pass" *ngIf="selectedPass">
                <div class="self-serve-pass-photo">
                    <img
                        [src]="selectedPass.photo || defaultPhoto"
                        class="w100"
                    />
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="takePhoto()"
                    >
                        Take Photo
                    </button>
                </div>
                <div class="self-serve-pass-fields center">
                    <div class="inputs">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder="First Name"
                                type="text"
                                [(ngModel)]="selectedPass.first_name"
                                autocomplete="given-name"
                                required
                            />
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                matInput
                                placeholder="Last Name"
                                type="text"
                                [(ngModel)]="selectedPass.last_name"
                                autocomplete="family-name"
                                required
                            />
                        </mat-form-field>
                    </div>
                    <div class="inputs">
                        <mat-form-field *ngIf="selectedPass.selectHeightCode">
                            <mat-select
                                placeholder="Height Code"
                                [(ngModel)]="selectedPass.height_code"
                                required
                            >
                                <!-- Only for SW -->
                                <mat-option
                                    *ngIf="selectedPass.pass_type === 'SW'"
                                    value='Over48"'
                                >
                                    Over 48"
                                </mat-option>

                                <!-- Only for non-SW -->
                                <ng-container
                                    *ngIf="selectedPass.pass_type !== 'SW'"
                                >
                                    <mat-option value='Over 57"'>
                                        Over 57"
                                    </mat-option>
                                    <mat-option value='48" - 57"'>
                                        48" - 57"
                                    </mat-option>
                                </ng-container>

                                <!-- Default values. -->
                                <mat-option value='42" - 48"'>
                                    42" - 48"
                                </mat-option>
                                <mat-option value='36" - 42"'>
                                    36" - 42"
                                </mat-option>
                                <mat-option value='Under 36"'>
                                    Under 36"
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="inputs">
                        <p
                            class="center w100"
                            *ngIf="!selectedPass.selectHeightCode"
                        >
                            {{ selectedPass.height_code }}
                        </p>
                    </div>
                </div>

                <button
                    *ngIf="passes && selectedPassIndex !== passes.length - 1"
                    mat-raised-button
                    color="primary"
                    (click)="nextPass()"
                >
                    Next
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="self-serve-category-actions">
        <button
            mat-raised-button
            color="warn"
            class="self-serve-button"
            (click)="exitPurchase()"
        >
            Cancel Activation
        </button>
        <button
            mat-raised-button
            color="primary"
            class="self-serve-button"
            [disabled]="!canActivate()"
            (click)="activate()"
        >
            Activate
        </button>
    </div>
</div>

<!-- ActivationFinal State -->
<div
    data-main="activationFinal"
    class="w100 h100"
    [ngClass]="{ dn: state !== 'activationFinal' }"
>
    <ng-container *ngTemplateOutlet="topLogoTemplate"></ng-container>

    <div class="self-serve-final-text center center-screen">
        <p class="self-serve-checkout-main-text w100i">Passes Activated!</p>
        <p *ngIf="claimCheckCreated">
            Your claim check should be printing, please go to the counter and
            give the cashier your printed claim check to finish the process.
            Have a great day!
        </p>
        <p *ngIf="!claimCheckCreated">
            Your passes have been activated, please go to the counter and give
            the cashier your existing claim check to finish the process. Have a
            great day!
        </p>
    </div>
</div>
