<h1 mat-dialog-title>Editing Translation</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Website"
                [(ngModel)]="data['translation']['website']"
                name="website"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Translation ID"
                [(ngModel)]="data['translation']['translation_id']"
                name="translation_id"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Language (2 Letter ISO)"
                [(ngModel)]="data['translation']['lang']"
                name="lang"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <textarea
                matInput
                rows="10"
                placeholder="Value"
                [(ngModel)]="data['translation']['value']"
                name="value"
                required
            ></textarea>
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
