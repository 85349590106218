import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { Group } from 'src/app/pos/managepasses/managepasses.component';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Data {
    type: 'Weekly' | 'Season';
}

@Component({
    selector: 'app-choosegroup',
    templateUrl: './choosegroup.component.html',
    styleUrls: ['./choosegroup.component.scss']
})
export class ChoosegroupComponent implements OnInit {
    // Variables.
    groups: Object[] = [];
    searchValue: string = '';
    loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ChoosegroupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        public _sanitizer: DomSanitizer,
        public _misc: MiscService,
        private _http: HttpService
    ) {}

    ngOnInit() {}

    async search() {
        // Check if searchValue is set.
        if (!this.searchValue) return;

        this.loading = true;

        // Call the API.
        let resp: Object = (
            await this._http.getLocal(
                `pos/groups/search/?type=${this.data.type}&search=${this.searchValue}`
            )
        ).body;

        // Set groups based on response.
        if (resp.hasOwnProperty('success') && !resp['success']) {
            this.groups = [];
        } else {
            this.groups = <Object[]>resp;
        }

        this.loading = false;
    }

    async getMembers(group: Object) {
        // If they already got fetched.
        if (group.hasOwnProperty('members')) return;

        this.loading = true;

        // Call the API.
        let resp: Object = (
            await this._http.getLocal(
                `pos/groups/members/?type=${this.data.type}&group_id=${group['group_id']}`
            )
        ).body;

        // Set members based on response.
        if (resp.hasOwnProperty('success') && !resp['success']) {
            group['members'] = [];
        } else {
            group['members'] = <Object[]>resp;
        }

        this.loading = false;
    }

    choose(group: Object) {
        // Only allow choosing if the members have been loaded.
        if (group.hasOwnProperty('members')) {
            this.dialogRef.close(<Group>{
                email: group['email'],
                group_id: group['group_id'],
                members: group['members'],
                phone: group['phone'],
                postal_code: group['postal_code']
            });
        }
    }
}
