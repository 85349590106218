import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';

interface Product {
    store_table?: string;
    free: boolean;
    name?: string;
    name_fr?: string;
    price?: string;
    price_fr?: string;
    id?: string;
    enabled: boolean;
}

@Component({
    selector: 'app-addstoretableproduct',
    templateUrl: './addstoretableproduct.component.html',
    styleUrls: ['./addstoretableproduct.component.scss'],
})
export class AddstoretableproductComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    product: Product = { free: false, enabled: true };
    filteredProducts: Object[] = [];

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<AddstoretableproductComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _dialog: MatDialog
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());
    }

    ngOnInit() {
        // Set filteredProducts.
        this.filteredProducts = this.data['products'].slice(0);
    }

    filterProducts() {
        this.filteredProducts = this.data['products'].filter((p) => {
            return `${p['description']} (${p['id']})`
                .toLowerCase()
                .includes(this.product.id.toLowerCase());
        });
    }

    hasChanges() {
        return this.form.dirty;
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Check if free but not the correct fields filled.
        if (this.product.free && (!this.product.name || !this.product.price)) {
            return;
        }

        // Check if not free but product not set.
        if (!this.product.free && !this.product.id) return;

        // If not free but not a valid product.
        if (!this.product.free) {
            const product = this.data['products'].find(
                (p) => `${p['description']} (${p['id']})` === this.product.id
            );
            if (!product) return;

            // Set to just ID if there is a product.
            this.product.id = product['id'];
        } else {
            this.product.id = null;
        }

        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close(this.product);
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
