<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="side" color="accent" class="scrollbar-div">
        <mat-accordion>
            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/crm', false)"
            >
                <mat-icon>home</mat-icon> Home
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/crm/customers', false)"
            >
                <mat-icon>person</mat-icon> Customers
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/crm/message', false)"
            >
                <mat-icon>chat_bubble</mat-icon> Send Message
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/crm/reservation-search', false)"
            >
                <mat-icon>search</mat-icon> Reservation Search
            </a>

            <a
                class="dark-sidenav-text"
                mat-raised-button
                (click)="_loading.navigate('/crm/text-tickets', false)"
            >
                <mat-icon>textsms</mat-icon> Text Tickets
            </a>
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
