import { Injectable, Output } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { LoadingService } from './loading.service';
import { SnackbarService } from './snackbar.service';
import { HttpResponse } from '@angular/common/http';

/**
 * This service contains simple methods used for authentication.
 * Spanning from attempting to login, to getting the current
 * user's session, use this service if you need to handle any sort
 * of authentication.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private darkTheme: boolean;
    @Output() themeChange: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _http: HttpService,
        private _router: Router,
        private _snackbar: SnackbarService,
        private _loading: LoadingService
    ) {
        this.getTheme(true);
    }

    /**
     * Performs a `POST` request to the auth/login endpoint of the local API.
     *
     *
     * @param email The email to login with.
     * @param password The password provided to attempt to login with.
     * @returns A promise containing the information received.
     *
     */
    async login(email: string, password: string): Promise<Object> {
        return (
            await this._http.postLocal('auth/login/', {
                email,
                password,
            })
        ).body;
    }

    /**
     * Performs a `POST` request to the auth/logout endpoint of the local API.
     *
     * @returns A promise containing void.
     */
    async logout(): Promise<void> {
        await this._http.postLocal('auth/logout/');
        this._http.redirectHome();
    }

    /**
     * This function provides an easy way of fetching the current user's session.
     * It can be used in a variety of ways, for example sending the user
     * to the login page if not logged in.
     *
     *
     * @returns A promise containing the session of the current user.
     */
    async getSession(): Promise<Object> {
        return (await this._http.getLocal('auth/session/')).body;
    }

    /**
     * This function wraps `getSession` into an even easier way of
     * checking if the user is logged in.
     *
     *
     * @returns A `Promise` containing a `boolean`.
     */
    async isLoggedIn(): Promise<boolean> {
        let session: Object = await this.getSession();
        return !(session.hasOwnProperty('success') && !session['success']);
    }

    /**
     * This function takes a location and checks if the user is allowed
     * access it. For better security, this calls a backend API to
     * do the checking.
     *
     *
     * @param location The location that the user is attempting to access.
     * @returns A `Promise` containing a `boolean`.
     */
    async isAllowed(location: string): Promise<boolean> {
        let resp: Object = (
            await this._http.postLocal('auth/allowed/', {
                location,
            })
        ).body;

        return resp.hasOwnProperty('success') ? resp['success'] : false;
    }

    /**
     * This function is used for redirecting to locations after a failed
     * route guard check.
     *
     *
     * @param redirect The location to redirect to.
     * @param attemptedLocation The location that the user failed the guard for.
     */
    failRedirect(redirect: string, attemptedLocation: string) {
        // Check for special redirects before calling navigate directly.
        if (redirect === 'USER_HOME') {
            this._http.redirectHome(true, attemptedLocation);
        } else {
            this._router.navigate([redirect]);
        }
    }

    /**
     * This function is for getting the theme for the currently logged in user.
     *
     *
     * @param set Whether to set the theme based on the database preference. Should not be called in most cases.
     * @returns `null` if the user is not logged in or a `string` as the theme of the current user.
     */
    async getTheme(set: boolean = false): Promise<string | null> {
        if (set) {
            // Get the user.
            let user = await this.getSession();

            // If there is no user (no session) or they need to reset their password, return out of this function.
            if (
                (user.hasOwnProperty('success') && !user['success']) ||
                user['require_reset'] > 0
            )
                return;

            /* Check if the user's theme is set.
            If it is, then set the theme, else show theme picker. */
            if (!user['theme']) {
                this._loading.setTheme(true);
                this.setTheme(false);
            } else {
                this.setTheme(user['theme'] === 'dark' ? true : false);
                return user['theme'];
            }
        } else {
            return this.darkTheme ? 'dark' : 'light';
        }
    }

    /**
     * This function is for setting the current user's theme for the site.
     * It has functionality for also updating this choice in the database.
     *
     *
     * @param dark A `boolean` indicating if the new theme is the dark theme.
     * @param update Whether to update this choice in the database.
     */
    async setTheme(dark: boolean, update: boolean = false) {
        // Check if updating.
        if (update) {
            // Call the API.
            let resp = await this._http.patchLocal('auth/users/', {
                id: (await this.getSession())['id'],
                theme: dark ? 'dark' : 'light',
            });

            // Show snackbar based on response.
            let snackbarMessage = '';
            if (resp.status === 204) {
                snackbarMessage = 'Successfully updated theme preference.';
            } else {
                snackbarMessage =
                    'An error occurred while attempting to update theme preference.';
            }
            this._snackbar.defaultSnackbar(snackbarMessage, null);

            this._loading.setTheme(false);
        }

        // Set the service variable for darkTheme.
        this.darkTheme = dark;
        this.themeChange.emit(dark);

        // Set the class for the HTML tag.
        let html: HTMLElement = document.querySelector('html');
        if (this.darkTheme) {
            html.classList.add('fluid-dark-theme');
        } else {
            if (html.classList.contains('fluid-dark-theme')) {
                html.classList.remove('fluid-dark-theme');
            }
        }
    }

    /**
     * This function toggles the user's current theme between dark and light.
     *
     *
     * @param update Whether to update this choice in the database.
     */
    async toggleTheme(update: boolean = false) {
        // Get the theme.
        let dark = !this.darkTheme;

        await this.setTheme(dark, update);
    }

    /**
     * This function checks the UA (useragent) of the device to see if they are on mobile.
     * Mainly for silencing the Chrome error of viewport width/height in px.
     * Note: this can be manipulated easily.
     *
     * @returns A `boolean` determining if the UA is that of a mobile UA.
     */
    isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            navigator.userAgent
        );
    }

    isIOS(): boolean {
        return (
            /iPad|iPhone|iPod/.test(navigator.userAgent) &&
            !(window as any).MSStream
        );
    }

    /**
     * This function checks if the current browser is Chrome (specifically Chromium).
     *
     * @returns A `boolean` of whether the current browser is Chrome.
     */
    isChrome(): boolean {
        return (
            !!(window as any).chrome &&
            (!!(window as any).chrome.webstore ||
                !!(window as any).chrome.runtime)
        );
    }

    /**
     * This function checks if the current browser is Firefox.
     *
     * @returns A `boolean` of whether the current browser is Firefox.
     */
    isFirefox(): boolean {
        return 'InstallTrigger' in window;
    }

    /**
     * This function checks if the given pin exists for a user.
     *
     * @param pin The pin to check.
     * @returns A `Promise` containing a `boolean` of whether the pin exists.
     */
    async pinExists(pin: string): Promise<boolean> {
        let exists: Object = (await this._http.postLocal('auth/pin/', { pin }))
            .body;
        return exists['success'];
    }

    /**
     * This function gets a given user from a pin.
     *
     * @param pin The pin to fetch user from.
     * @returns A `Promise` containing an `Object` of the user.
     */
    async userFromPin(pin: string): Promise<Object> {
        let resp: Object = (await this._http.postLocal('auth/pin/', { pin }))
            .body;
        return resp['user'];
    }

    /**
     * This function checks if the given password is the current password
     * for the current user.
     *
     *
     * @param password The password to check for the current user.
     * @returns A `Promise` containing a `boolean` of whether the password is correct.
     */
    async checkPassword(password: string): Promise<boolean> {
        let resp: HttpResponse<Object> = await this._http.getLocal(
            `auth/users/password/?password=${encodeURIComponent(password)}`
        );

        // Check if the response is 204, if not it was not successful.
        return resp.status === 204;
    }
}
