import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';

interface Data {
    kiosk?: boolean;
}

@Component({
    selector: 'app-takephoto',
    templateUrl: './takephoto.component.html',
    styleUrls: ['./takephoto.component.scss'],
})
export class TakephotoComponent {
    // Variables.
    location: Object;

    // Editing Data
    screenshotTaken: boolean = false;
    private trigger: Subject<void> = new Subject<void>();
    webcamImage: WebcamImage = null;
    currentDevice?: string;

    constructor(
        public dialogRef: MatDialogRef<TakephotoComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _snackbar: SnackbarService
    ) {}

    takeScreenshot() {
        this.trigger.next();
        this.screenshotTaken = true;
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    webcamInitError(error: WebcamInitError) {
        // Show simple snackbar.
        this._snackbar.defaultSnackbar(
            `Error opening webcam: ${error.message}.`
        );
    }

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;

        if (this.data && this.data.kiosk) this.save();
    }

    cameraSwitched(device: string) {
        this.currentDevice = device;
    }

    save() {
        this.dialogRef.close(
            this.data && this.data.kiosk
                ? this.rotate(270)
                : this.webcamImage.imageAsDataUrl
        );
    }

    rotate(deg: number) {
        return new Promise((resolve) => {
            const data = this.webcamImage.imageAsDataUrl;

            // If not a 90 deg angle.
            const degrees = deg % 360;
            if (degrees % 90 !== 0 || degrees === 0) {
                resolve(data);
                return;
            }

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.src = data;
            image.addEventListener('load', () => {
                if (degrees === 180) {
                    canvas.width = image.width;
                    canvas.height = image.height;
                } else {
                    canvas.width = image.height;
                    canvas.height = image.width;
                }

                ctx.rotate((degrees * Math.PI) / 180);

                if (degrees === 90) {
                    ctx.translate(0, -canvas.width);
                } else if (degrees === 180) {
                    ctx.translate(-canvas.width, -canvas.height);
                } else if (degrees === 270) {
                    ctx.translate(-canvas.height, 0);
                }

                ctx.drawImage(image, 0, 0);
                resolve(canvas.toDataURL());
            });
        });
    }
}
