<div class="the-screen w100 h100" *ngIf="screen">
    <div class="w100 h100">
        <img
            src="https://api.fluiddynamics.ca/v1/screens/media/?file={{
                screen['media']
            }}"
            [alt]="screen['media']"
            (error)="_misc.loadVideo(screen['media'], 0, 'the-screen')"
        />
    </div>
</div>
