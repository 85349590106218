import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import cloneDeep from 'lodash-es/cloneDeep';
import * as JsBarcode from 'jsbarcode';
import { ChoosevoucherproductimageComponent } from '../choosevoucherproductimage/choosevoucherproductimage.component';

@Component({
    selector: 'app-editvoucherproduct',
    templateUrl: './editvoucherproduct.component.html',
    styleUrls: ['./editvoucherproduct.component.scss'],
})
export class EditvoucherproductComponent implements OnInit {
    // Dialog variables.
    loading: boolean = false;
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;

    // Original variables.
    originalTaxes: number[];
    originalValidAt: number[];
    originalImage: string;

    // Product variables.
    filteredLocations: Object[];
    filteredCategories: Object[];
    foxyCategoriesFiltered: Object[];
    template: string;

    @ViewChild('productForm', { static: true })
    productForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditvoucherproductComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        public _http: HttpService,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());
    }

    ngOnInit() {
        this.originalTaxes = cloneDeep(this.data['product']['taxes']);
        this.originalValidAt = this.data['product']['valid_at'];
        this.originalImage = this.data['product']['image'];

        this.templateChange();

        // Initialise the barcodes.
        JsBarcode('#consignment-barcode').init();
        JsBarcode('#web-barcode').init();
    }

    async chooseImage() {
        this.loading = true;

        // Get the images.
        const images = (
            await this._http.getLocal('pos/vouchers/products/images/')
        ).body as string[];

        this.loading = false;
        if (!images || !images.length) return;

        // Open the choose dialog.
        const dialogRef = this._dialog.open(
            ChoosevoucherproductimageComponent,
            {
                data: { images },
            }
        );

        let ans = await dialogRef.afterClosed().toPromise();

        if (!ans) return;

        this.data['product']['image'] = ans;
    }

    facilityChange() {
        // Get the facility from the fluid_id.
        let facility = this.data['facilities'].find(
            (facility) => facility['abbr'] === this.data['product']['facility']
        );

        // Set redeem_at.
        if (!this.data['product'].redeem_at) {
            this.data['product'].redeem_at = facility['name'];
        }

        // Set quick_1.
        if (!this.data['product'].quick_1) {
            this.data['product'].quick_1 = facility['abbr'];
        }
    }

    partySizeChange() {
        // Set quick_3.
        if (!this.data['product']['quick_3']) {
            this.data['product'][
                'quick_3'
            ] = `${this.data['product']['party_size']}`;
        }
    }

    filterFoxyCategories() {
        this.foxyCategoriesFiltered = this.data[
            'foxyCategories'
        ].filter((category) =>
            category['name']
                .toLowerCase()
                .includes(this.data['product']['foxy_cat'].toLowerCase())
        );
    }

    templateChange() {
        // Get the template.
        let template = this.data['templates'].find(
            (template) => template['id'] === this.data['product']['template']
        );

        if (template) {
            // Set the template to the file.
            this.template = template['file'];
        }
    }

    hasChanges() {
        return (
            this.productForm.dirty ||
            JSON.stringify(this.originalTaxes) !==
                JSON.stringify(this.data['product']['taxes']) ||
            JSON.stringify(this.originalValidAt) !==
                JSON.stringify(this.data['product']['valid_at']) ||
            this.originalImage !== this.data['product']['image']
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Depending on changes, close with information.
        if (this.hasChanges()) {
            // Check for missing fields.
            if (
                !this.data['product']['price'] ||
                !this.data['product']['description'] ||
                !this.data['product']['redeem_at'] ||
                !this.data['product']['party_size'] ||
                !this.data['product']['redeem_for'] ||
                !this.data['product']['sell_at'] ||
                this.data['product']['max_scans'] === null ||
                (this.data['product']['sales_vector'] !== 'Consignment' &&
                    !this.data['product']['foxy_cat']) ||
                !this.data['product']['facility']
            ) {
                this._snackbar.defaultSnackbar(
                    'Please fill out all required fields.'
                );
                return;
            }

            // Check for no valid at locations and no taxes.
            if (!this.data)
                if (
                    !this.data['product']['valid_at'] ||
                    !this.data['product']['valid_at'].length
                ) {
                    this._snackbar.defaultSnackbar(
                        'You must select at least one valid at location.'
                    );
                    return;
                }

            // Check for invalid price.
            if (
                !`${this.data['product']['price']}`.match(
                    /^[0-9]+(?:\.[0-9]{1,4})?$/
                ) ||
                this.data['product']['price'] < 0
            ) {
                this._snackbar.defaultSnackbar(
                    'Price must be a number with at most four decimals and greater than 0.'
                );
                return;
            }

            // Check for invalid party_size.
            if (
                !`${this.data['product']['party_size']}`.match(/^[0-9]+$/) ||
                +this.data['product']['party_size'] < 1
            ) {
                this._snackbar.defaultSnackbar(
                    'Party size must be a whole number and greater than 0.'
                );
                return;
            }

            // Check for invalid max_scans.
            if (
                !`${this.data['product']['max_scans']}`.match(/^[0-9]+$/) ||
                +this.data['product']['max_scans'] < 0
            ) {
                this._snackbar.defaultSnackbar(
                    'Max scans must be a whole number and greater than or equal to 0.'
                );
                return;
            }

            // Unsubscribe from backdrop clicks.
            this.backdropSubscription.unsubscribe();
            this.dialogRef.close(this.data['product']);
        } else {
            // Unsubscribe from backdrop clicks.
            this.backdropSubscription.unsubscribe();
            this.dialogRef.close(undefined);
        }
    }
}
