import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

interface Data {
    locations: Object[];
    parks?: boolean;
}

@Component({
    selector: 'app-chooselocation',
    templateUrl: './chooselocation.component.html',
    styleUrls: ['./chooselocation.component.scss']
})
export class ChooselocationComponent implements OnInit {
    // Variables.
    location: Object;

    constructor(
        public dialogRef: MatDialogRef<ChooselocationComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {}

    save() {
        // Set the key based on if Parks Canada or not.
        let key: string;
        if (!this.data.parks) {
            key = 'fluiddynamics_location';
        } else {
            key = 'fluiddynamics_parks_location';
        }

        // Set the item in local storage.
        window.localStorage.setItem(key, this.location['id']);
        this.dialogRef.close();
    }
}
