import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root',
})
export class ResetpasswordGuard implements CanActivate {
    constructor(private _auth: AuthService, private _http: HttpService) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        let session = await this._auth.getSession();
        let requiredPasswordReset;
        if (!session.hasOwnProperty('success') || session['success']) {
            requiredPasswordReset = session['require_reset'] == 1;
        } else {
            requiredPasswordReset = null;
        }
        let failRedirect = next.data.failRedirect || 'auth/resetpassword';

        // If it is null (not logged in), set redirect to the page wanting to go.
        if (requiredPasswordReset === null) {
            failRedirect = 'USER_HOME';
        }

        let passed =
            requiredPasswordReset === (next.data.requiresReset || false);

        if (!passed) {
            this._auth.failRedirect(failRedirect, state.url);
        } else {
            if (
                !requiredPasswordReset &&
                session['emp_id'] &&
                !next.data.resetPasswordBypass
            ) {
                let employee: Object = (
                    await this._http.getLocal(
                        `hr/employees/?id=${session['emp_id']}`
                    )
                ).body;

                // Check if the user should be restricted to the account page.
                if (
                    (session['type'] === 'employee' ||
                        session['type'] === 'pos_user' ||
                        session['type'] === 'supervisor') &&
                    (!employee['dob'] || !employee['sin'])
                ) {
                    passed = false;
                    this._auth.failRedirect('account', state.url);
                }
            }
        }

        return passed;
    }
}
