import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RemovedarkthemeGuard implements CanActivate {
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        // Make sure dark mode is off.
        this.removeDarkTheme(0);
        return true;
    }

    removeDarkTheme(count: number) {
        if (document.documentElement.classList.contains('fluid-dark-theme')) {
            document.documentElement.classList.remove('fluid-dark-theme');
        }

        count++;

        if (count < 10) {
            setTimeout(this.removeDarkTheme.bind(this, count), 100);
        }
    }
}
