<h1 mat-dialog-title>Adding Store Table Product</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <div class="form-field w100 center mb15">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="product.free"
                    name="free"
                >
                    Placeholder
                </mat-slide-toggle>
            </div>
        </div>

        <div class="w100" *ngIf="product.free">
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Name"
                        [(ngModel)]="product.name"
                        name="name"
                        required
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="French Name"
                        [(ngModel)]="product.name_fr"
                        name="name_fr"
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Price Text"
                        [(ngModel)]="product.price"
                        name="price"
                        required
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="French Price Text"
                        [(ngModel)]="product.price_fr"
                        name="price_fr"
                    />
                </mat-form-field>
            </div>
            <div class="inputs">
                <div class="form-field">
                    <div class="form-field-div jcc w100">
                        <mat-slide-toggle
                            color="primary"
                            [(ngModel)]="product.enabled"
                            name="enabled"
                        >
                            Enabled
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>

        <div class="inputs" *ngIf="!product.free">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Product"
                    [matAutocomplete]="auto"
                    (input)="filterProducts()"
                    [(ngModel)]="product.id"
                    name="id"
                    required
                />
                <mat-autocomplete #auto="matAutocomplete" class="scrollbar">
                    <mat-option
                        *ngFor="let product of filteredProducts"
                        [value]="
                            product['description'] + ' (' + product['id'] + ')'
                        "
                    >
                        {{ product['description'] }} ({{ product['id'] }})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
