import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
    selector: 'partner-sidenav',
    templateUrl: './partnersidenav.component.html',
    styleUrls: [
        './partnersidenav.component.scss',
        '../customelements.component.scss'
    ]
})
export class PartnersidenavComponent implements OnInit {
    @Input() user: Object;
    @Input() partner: Object;

    @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

    constructor(private _sidenav: SidenavService) {}

    ngOnInit() {
        this._sidenav.setSidenav(this.sidenav);
    }
}
