<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Editing Product</h1>
<div mat-dialog-content class="scrollbar">
    <mat-tab-group mat-stretch-tabs class="transaction-items">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">info</mat-icon>
                Overview
            </ng-template>

            <ng-form #productForm="ngForm">
                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            placeholder="Price"
                            [(ngModel)]="data['product']['price']"
                            min="0"
                            step="0.0001"
                            pattern="^[0-9]+(?:\.[0-9]{1,4})?$"
                            required
                            name="price"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Description"
                            [(ngModel)]="data['product']['description']"
                            required
                            name="description"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select
                            placeholder="Facility"
                            [(ngModel)]="data['product']['facility']"
                            (selectionChange)="facilityChange()"
                            required
                            name="facility"
                        >
                            <mat-option
                                *ngFor="let facility of data['facilities']"
                                [value]="facility['abbr']"
                            >
                                {{ facility['name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <mat-form-field>
                        <mat-select
                            placeholder="Template"
                            [(ngModel)]="data['product']['template']"
                            (selectionChange)="templateChange()"
                            name="template"
                        >
                            <mat-option
                                *ngFor="let template of data['templates']"
                                [value]="template['id']"
                            >
                                {{ template['file'] }} ({{ template['id'] }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Product Type"
                            [(ngModel)]="data['product']['product_type']"
                            name="product_type"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Height Code"
                            [(ngModel)]="data['product']['height_code']"
                            name="height_code"
                        />
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Redeem At"
                            [(ngModel)]="data['product']['redeem_at']"
                            required
                            name="redeem_at"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            placeholder="Party Size"
                            [(ngModel)]="data['product']['party_size']"
                            (change)="partySizeChange()"
                            min="0"
                            pattern="^[0-9]+$"
                            required
                            name="party_size"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Quick 1"
                            [(ngModel)]="data['product']['quick_1']"
                            name="quick_1"
                        />
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Quick 2"
                            [(ngModel)]="data['product']['quick_2']"
                            name="quick_2"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Quick 3"
                            [(ngModel)]="data['product']['quick_3']"
                            name="quick_3"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Redeem For"
                            [(ngModel)]="data['product']['redeem_for']"
                            required
                            name="redeem_for"
                        />
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            placeholder="Max Scans"
                            [(ngModel)]="data['product']['max_scans']"
                            min="0"
                            pattern="^[0-9]+$"
                            required
                            name="max_scans"
                        />
                    </mat-form-field>
                    <mat-form-field *ngIf="data['type'] === 'Web'">
                        <mat-select
                            placeholder="Category"
                            [(ngModel)]="data['product']['category']"
                            name="category"
                        >
                            <mat-option
                                *ngFor="let c of data['categories']"
                                [value]="c['name']"
                            >
                                {{ c['name'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            [matAutocomplete]="foxyCatAuto"
                            placeholder="Foxy Category"
                            [(ngModel)]="data['product']['foxy_cat']"
                            (input)="filterFoxyCategories()"
                            [required]="
                                data['product']['sales_vector'] !==
                                'Consignment'
                            "
                            name="foxy_cat"
                        />
                        <mat-autocomplete
                            #foxyCatAuto="matAutocomplete"
                            class="scrollbar"
                        >
                            <mat-option
                                *ngFor="let category of foxyCategoriesFiltered"
                                [value]="category['name']"
                            >
                                {{ category['name'] }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <mat-form-field>
                        <mat-select
                            placeholder="Sell At (ex: CBAZ Laser Product)"
                            [(ngModel)]="data['product']['sell_at']"
                            name="sell_at"
                            required
                        >
                            <mat-option
                                *ngFor="let at of data['sellAt']"
                                [value]="at['abbr']"
                            >
                                {{ at['full'] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="inputs" *ngIf="data['type'] === 'Web'">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Allowed Attributes"
                            [(ngModel)]="data['product']['allowed_attributes']"
                            name="allowed_attributes"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Required Attributes"
                            [(ngModel)]="data['product']['required_attributes']"
                            name="required_attributes"
                        />
                    </mat-form-field>
                </div>
                <div class="inputs">
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['gatelog_entry']"
                                name="gatelog_entry"
                            >
                                Gatelog Entry
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['active']"
                                name="active"
                            >
                                Active
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="
                                    data['product']['partner_accessible']
                                "
                                name="partner_accessible"
                            >
                                Partner Accessible
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['create_pass']"
                                name="create_pass"
                            >
                                Create Pass
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="inputs">
                    <div class="form-field">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['multiscan']"
                                name="multiscan"
                            >
                                Multiscan
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field" *ngIf="data['type'] === 'Web'">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="
                                    data['product']['can_change_quantity']
                                "
                                name="can_change_quantity"
                            >
                                Can Change Quantity
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field" *ngIf="data['type'] === 'Web'">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['shipping']"
                                name="shipping"
                            >
                                Shipping
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="form-field" *ngIf="data['type'] === 'Web'">
                        <div class="form-field-div jcc w100">
                            <mat-slide-toggle
                                color="primary"
                                [(ngModel)]="data['product']['giftcard']"
                                name="giftcard"
                            >
                                Gift Card
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </ng-form>

            <div class="flex phone-block">
                <div class="w100">
                    <h2 class="center">Valid At</h2>
                    <p class="center">
                        Double click a selection box to select/deselect all
                        facilities.
                    </p>
                    <div
                        class="inputs mb15"
                        *ngIf="
                            !data['product']['valid_at'] ||
                            data['product']['valid_at'].constructor.name ===
                                'Array'
                        "
                    >
                        <mat-selection-list
                            #facilityList
                            color="primary"
                            class="list-w100 scrollbar max-250"
                            [(ngModel)]="data['product']['valid_at']"
                            (selectionChange)="
                                _misc.selectionHandler($event, facilityList)
                            "
                        >
                            <mat-list-option
                                #facilityListOption
                                *ngFor="let facility of data['facilities']"
                                [value]="facility['fluid_id']"
                                [ngClass]="{
                                    'background-radial':
                                        facilityListOption.selected
                                }"
                            >
                                {{ facility['name'] }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
                <div class="w100">
                    <h2 class="center">Taxes</h2>
                    <p class="center">
                        Double click a selection box to select/deselect all
                        taxes.
                    </p>
                    <div
                        class="inputs mb15"
                        *ngIf="
                            !data['product']['taxes'] ||
                            data['product']['taxes'].constructor.name ===
                                'Array'
                        "
                    >
                        <mat-selection-list
                            #taxesList
                            color="primary"
                            class="list-w100 scrollbar max-250"
                            [(ngModel)]="data['product']['taxes']"
                            (selectionChange)="
                                _misc.selectionHandler($event, taxesList)
                            "
                        >
                            <mat-list-option
                                #taxesListOption
                                *ngFor="let tax of data['taxes']"
                                [value]="tax['id']"
                                [ngClass]="{
                                    'background-radial':
                                        taxesListOption.selected
                                }"
                            >
                                {{ tax['name'] }} ({{ tax['amount'] * 100 }}%)
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">visibility</mat-icon>
                Appearance
            </ng-template>

            <div *ngIf="data['type'] === 'Web'">
                <div *ngIf="data['product']['image']">
                    <h3 class="center mb15">Current Image</h3>
                    <img
                        class="max-250 center-btn mb15"
                        src="https://api.fluiddynamics.ca/v1/pos/vouchers/products/images/?file={{
                            data['product']['image']
                        }}"
                        alt="Product Image"
                    />
                </div>

                <div *ngIf="!data['product']['image']">
                    <h3 class="center">No Image</h3>
                    <p class="center">
                        Since there is no image for this product, it will use
                        the facility logo.
                    </p>
                </div>

                <div class="w100 phone-block flex jcc center mb15">
                    <button
                        mat-raised-button
                        color="primary"
                        class="m-0-10 phone-mb15"
                        (click)="chooseImage()"
                    >
                        Choose Image
                    </button>
                    <button
                        *ngIf="data['product']['image']"
                        mat-raised-button
                        color="warn"
                        class="m-0-10 phone-mb15"
                        (click)="data['product']['image'] = null"
                    >
                        Delete Image
                    </button>
                </div>
            </div>

            <div
                class="consignment-product-preview"
                [hidden]="data['type'] !== 'Consignment'"
            >
                <div class="quick-info">
                    {{ data['product']['quick_1'] }}-{{
                        data['product']['quick_2']
                    }}-{{ data['product']['quick_3'] }}
                </div>
                <p class="product-description">
                    {{ data['product']['description'] }}
                </p>
                <p class="product-text">
                    Does Not Expire , Valid for:
                    {{ data['product']['party_size'] }} person(s) - Partner Name
                    - Charity<br />{{ data['product']['redeem_at'] }}
                    {{ data['product']['redeem_for'] }}.
                </p>
                <canvas
                    class="barcode top editing"
                    id="consignment-barcode"
                    jsbarcode-format="code128"
                    jsbarcode-value="VOUCH0000000000"
                    jsbarcode-height="30"
                    jsbarcode-width="1"
                    jsbarcode-margin="0"
                    jsbarcode-fontsize="10"
                    jsbarcode-background="transparent"
                ></canvas>
            </div>
            <div
                class="web-product-preview scrollbar"
                [hidden]="data['type'] !== 'Web' || !template"
            >
                <img
                    *ngIf="template && data['type'] === 'Web'"
                    src="{{ _http.API_URL }}/templates/{{ template }}"
                    alt="Preview Background Image"
                    class="template"
                />
                <div class="product-info">
                    <h2 class="description">
                        {{ data['product']['description'] }}
                    </h2>
                    <strong>Expires: </strong> Does Not Expire
                    <strong>Order Date: </strong> 1970-01-01 00:00:00
                    <br />
                    <strong>Redeem At: </strong>
                    {{ data['product']['redeem_at'] }}
                    {{ data['product']['redeem_for'] }}
                    <br />
                    <strong>Conditions: </strong> This voucher must be validated
                    and redeemed at the ticket window. Non-Refundable. No Cash
                    Value. All guests are subject to the terms and conditions
                    posted at the facility entrance and at each individual
                    attraction. Ride at Your Own Risk.
                </div>
                <canvas
                    class="barcode web-barcode web-barcode-editing"
                    id="web-barcode"
                    jsbarcode-format="code128"
                    jsbarcode-value="VOUCH0000000000"
                    jsbarcode-height="30"
                    jsbarcode-width="1"
                    jsbarcode-fontsize="10"
                    jsbarcode-background="transparent"
                ></canvas>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
