<h1 mat-dialog-title>Gift Card Detailed and Usage History</h1>
<div mat-dialog-content class="scrollbar">
    <mat-tab-group mat-stretch-tabs>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">visibility</mat-icon>
                Detailed
            </ng-template>

            <div class="detail">
                <div class="detail-mobile">
                    <div>
                        <h4>Barcode</h4>
                        <p>{{ data['giftcard']['barcode'] }}</p>
                    </div>
                    <div>
                        <h4>Pin</h4>
                        <p>{{ data['giftcard']['pin'] }}</p>
                    </div>
                    <div *ngIf="data['giftcard']['active']">
                        <h4>Campaign</h4>
                        <p>{{ data['giftcard']['campaign'] }}</p>
                    </div>
                </div>
                <div class="detail-mobile" *ngIf="data['giftcard']['active']">
                    <div>
                        <h4>Sales Vector</h4>
                        <p>{{ data['giftcard']['sales_vector'] }}</p>
                    </div>
                    <div>
                        <h4>Activated On</h4>
                        <p>
                            {{
                                data['giftcard']['activated_on'] * 1000
                                    | date: 'yyyy-MM-dd HH:mm:ss'
                            }}
                        </p>
                    </div>
                    <div>
                        <h4>Activated By</h4>
                        <p>{{ data['giftcard']['activated_by'] }}</p>
                    </div>
                </div>
                <div class="detail-mobile" *ngIf="data['giftcard']['active']">
                    <div>
                        <h4>Email</h4>
                        <p>{{ data['giftcard']['email'] }}</p>
                    </div>
                    <div>
                        <h4>
                            {{
                                data['giftcard']['sales_vector'] === 'web'
                                    ? 'Order'
                                    : 'Transaction'
                            }}
                            ID
                        </h4>
                        <p
                            (click)="openOrder()"
                            [ngClass]="{
                                pointer:
                                    data['giftcard']['sales_vector'] === 'web'
                            }"
                        >
                            {{ data['giftcard']['order_id'] }}
                        </p>
                    </div>
                    <div>
                        <h4>
                            {{
                                data['giftcard']['sales_vector'] === 'web'
                                    ? 'Voucher'
                                    : 'Item'
                            }}
                            ID
                        </h4>
                        <p>{{ data['giftcard']['item'] }}</p>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">history</mat-icon>
                Usage History
            </ng-template>

            <!-- Table -->
            <mat-table
                #table
                [dataSource]="historyDataSource"
                matSort
                class="mat-elevation-z8 w100"
            >
                <!-- Time -->
                <ng-container matColumnDef="time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{
                            history['time'] * 1000 | date: 'yyyy-MM-dd HH:mm:ss'
                        }}
                    </mat-cell>
                </ng-container>

                <!-- Type -->
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['type'] }}
                    </mat-cell>
                </ng-container>

                <!-- Sales Vector -->
                <ng-container matColumnDef="sales_vector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sales Vector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['sales_vector'] }}
                    </mat-cell>
                </ng-container>

                <!-- Order ID -->
                <ng-container matColumnDef="order_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Order ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['order_id'] }}
                    </mat-cell>
                </ng-container>

                <!-- Total -->
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['total'] | currency }}
                    </mat-cell>
                </ng-container>

                <!-- Location -->
                <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['location'] }}
                    </mat-cell>
                </ng-container>

                <!-- User -->
                <ng-container matColumnDef="user">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        User
                    </mat-header-cell>
                    <mat-cell *matCellDef="let history">
                        {{ history['user'] }}
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="[
                        'time',
                        'type',
                        'sales_vector',
                        'order_id',
                        'total',
                        'location',
                        'user'
                    ]"
                ></mat-header-row>
                <mat-row
                    *matRowDef="
                        let row;
                        columns: [
                            'time',
                            'type',
                            'sales_vector',
                            'order_id',
                            'total',
                            'location',
                            'user'
                        ]
                    "
                ></mat-row>
            </mat-table>
            <mat-paginator
                [pageSizeOptions]="[10, 20, 50, 100]"
                showFirstLastButtons
            ></mat-paginator>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="dialogRef.close(undefined)"
        color="primary"
    >
        Close
    </button>
</div>
