import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth.service';
import {
    Router,
    RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError,
    RoutesRecognized,
    GuardsCheckStart,
    ChildActivationStart,
    ActivationStart,
    ResolveStart,
    ActivatedRoute,
    Scroll,
} from '@angular/router';
import { LoadingService } from './services/loading.service';
import { Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    theme: boolean = false;
    loading: boolean = false;
    loadingText: string;
    loadingProgress: string;

    // Subscriptions
    routerSubscription: Subscription;
    themeSubscription: Subscription;

    constructor(
        public _auth: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        public _loading: LoadingService,
        private _iconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer
    ) {
        this.routerSubscription = _router.events.subscribe(
            (event: RouterEvent) => {
                // if (event instanceof NavigationCancel) debugger;

                this.navigationEvent(event);
            }
        );

        this.themeSubscription = this._loading.themeChanged.subscribe(
            (val: boolean) => {
                this.theme = val;
            }
        );

        this._loadSVGIcons();
    }

    ngOnInit() {
        // Fix the vh/vw issue on mobile with the soft keyboard by setting the meta for viewport upon init.
        setTimeout(() => {
            // Only do it for mobile.
            if (!this._auth.isMobile()) {
                return;
            }

            let viewheight =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;
            let viewwidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            let viewport = document.querySelector('meta[name=viewport]');
            viewport.setAttribute(
                'content',
                `height=${viewheight}px, width=${viewwidth}px, initial-scale=1.0`
            );
        }, 300);
    }

    ngOnDestroy() {
        this.themeSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    async chooseTheme(dark: boolean) {
        this.loading = true;
        this.loadingProgress = '50';
        this.loadingText = 'Setting your theme...';
        await this._auth.setTheme(dark, true);
        this.loading = false;
    }

    navigationEvent(event: RouterEvent): void {
        if (!this._loading.loadingEnabled()) {
            return;
        }

        // Do not need to do anything with scroll.
        if (event instanceof Scroll) return;

        // Make sure loading is true for every event, if it is an ending navigation, it will set back to false.
        if (!this.loading) this.loading = true;

        if (event instanceof NavigationStart) {
            this.loadingProgress = '0';
            this.loadingText = 'Initialising Navigation';
        } else if (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ) {
            this.loadingProgress = '100';
            this.loadingText = 'Finishing Up';
            this.loading = false;
        } else if (event instanceof RoutesRecognized) {
            this.loadingProgress = '10';
            this.loadingText = 'Route Found';
        } else if (event instanceof GuardsCheckStart) {
            this.loadingProgress = '40';
            this.loadingText = 'Checking If You Are Authorised';
        } else if (event instanceof ChildActivationStart) {
            this.loadingProgress = '50';
            this.loadingText = 'Loading Module Child';
        } else if (event instanceof ActivationStart) {
            this.loadingProgress = '50';
            this.loadingText = 'Activating Resolvers';
        } else if (event instanceof ResolveStart) {
            this.loadingProgress = '75';
            this.loadingText = 'Resolving Data';
        }
    }

    private _loadSVGIcons() {
        // Load barcode SVG into icon registry.
        this._iconRegistry.addSvgIcon(
            'barcode',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/barcode.svg'
            )
        );

        // Load SEL SVG into icon registry.
        this._iconRegistry.addSvgIcon(
            'sel',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/sel.svg')
        );

        // Load ripleys SVG into icon registry.
        this._iconRegistry.addSvgIcon(
            'ripleys',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/ripleys.svg'
            )
        );

        // Load SW SVG into icon registry.
        this._iconRegistry.addSvgIcon(
            'sw',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/sw.svg')
        );

        // Load Tidal SVG into icon registry.
        this._iconRegistry.addSvgIcon(
            'tidal',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/tidal-favicon.svg'
            )
        );
    }
}
