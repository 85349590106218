<div class="idle-container">
    <h1 class="center">{{ data.title }}</h1>
    <div mat-dialog-content class="idle-container-content">
        <p class="center">{{ data.content }}</p>
    </div>
    <button
        *ngIf="!data.hideButton"
        mat-raised-button
        (click)="yes()"
        color="primary"
        class="center-btn w100"
    >
        Yes
    </button>
</div>
