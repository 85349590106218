import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { Location } from '@angular/common';
import { MiscService } from '../services/misc.service';
import { SocketService } from '../services/socket.service';
import { SnackbarService } from '../services/snackbar.service';

import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'app-screen',
    templateUrl: './screen.component.html',
    styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent implements OnInit, OnDestroy {
    // Screen variables.
    screen: Object;
    socket: any;

    constructor(
        public _misc: MiscService,
        private _title: Title,
        private _route: ActivatedRoute,
        private _http: HttpService,
        private _location: Location,
        private _snackbar: SnackbarService,
        private _socket: SocketService,
        private _cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        // Set title.
        this._title.setTitle('Screen Viewer - FluidDynamics');

        // If no id in params.
        if (!this._route.snapshot.queryParamMap.has('id')) {
            return this._location.back();
        }

        // Check for url params.
        const id = this._route.snapshot.queryParamMap.get('id');

        // Get the screen.
        const resp = (await this._http.getLocal(`screens/screens/?id=${id}`))
            .body;

        // If no screen.
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return this._location.back();
        }

        this.screen = resp;

        // Set overflow to hidden on html.
        document.documentElement.style.setProperty(
            'overflow',
            'hidden',
            'important'
        );

        // Setup the socket.
        if (await this._socket.loadClient()) {
            await this._socket.connect(
                'fluid',
                { channel: 'menuScreen' },
                this.handleSocket.bind(this)
            );
        } else {
            this._snackbar.defaultSnackbar(
                'Unable to load socket. Live updates will be disabled.'
            );
        }
    }

    ngOnDestroy() {
        document.documentElement.style.setProperty('overflow', '');
    }

    handleSocket(socket: any) {
        this.socket = socket;

        // Handle event for system messages.
        socket.on('system', (message: Object) => {
            // Switch on the message type.
            switch (message['type']) {
                case 'denied':
                    this._socket.unloadClient(socket);
                    this._snackbar.defaultSnackbar(
                        'Unable to load socket. Live updates will be disabled.'
                    );
                    break;
            }
        });

        // Handle event for normal messages.
        socket.on('message', (message: Object) => {
            // If missing screen or media.
            if (
                !message.hasOwnProperty('screen') ||
                !message.hasOwnProperty('media')
            ) {
                return;
            }

            // If screen is not the same as the one loaded.
            if (message['screen'] !== this.screen['id']) return;

            // Set the screen media.
            this.screen['media'] = message['media'];

            // To update the media on the screen, make a copy and detect changes for nothing then set and detect again.
            const screen = cloneDeep(this.screen);
            this.screen = null;
            this._cdr.detectChanges();
            this.screen = cloneDeep(screen);
            this._cdr.detectChanges();
        });
    }
}
