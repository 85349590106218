import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';
import { HttpService } from '../services/http.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

interface Email {
    subject: string;
    body: string;
}

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Resolve variables.
    user: Object;

    // Contact variables.
    email: Email = <Email>{};
    attachments: File[] = [];

    constructor(
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {
        this.user = this._route.snapshot.data.user;
    }

    ngOnInit() {
        // Set the title.
        this._title.setTitle('Contact - FluidDynamics');
    }

    async contact() {
        // Check if subject and body are set.
        if (!this.email.body || !this.email.subject) {
            this._snackbar.defaultSnackbar(
                'Please fill out both the subject and the body.'
            );
            return;
        }

        this.loading = true;

        // Create the formdata object.
        let formData = new FormData();
        formData.append('subject', this.email.subject);
        formData.append('body', this.email.body);

        // Set the attachments.
        if (this.attachments) {
            for (let i = 0; i < this.attachments.length; i++) {
                const element = this.attachments[i];
                formData.append(element.name, element);
            }
        }

        // Call the API and show snackbars based on the response.
        let resp: HttpResponse<Object> = await this._http.postLocal(
            'contact/',
            formData,
            {
                withCredentials: true,
            }
        );

        // The status should always be 204.
        if (resp.status === 204) {
            this._snackbar.defaultSnackbar(
                'Successfully sent the contact email!'
            );

            this.email = <Email>{};
            this.attachments = [];
        } else {
            this._snackbar.defaultSnackbar(
                'An unexpected error has occurred, please try again.'
            );
        }

        this.loading = false;
    }

    addAttachments(files: FileList | File[]) {
        // Create a file array.
        files = Array.from(files);

        // Get allowed files.
        let allowedFiles = [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/pjpeg',
        ];

        // Loop through and check if file is allowed, and if it is, then add it.
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Check if file type is allowed.
            if (!allowedFiles.includes(file.type)) continue;

            // Add it to the attachments.
            if (!this.attachments.includes(file)) {
                this.attachments.push(file);
            }
        }
    }

    removedChip(chip: File) {
        this.attachments.splice(this.attachments.indexOf(chip), 1);
    }
}
