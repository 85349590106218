<div class="yesno-container">
    <h1 class="center">{{ data.title }}</h1>
    <div mat-dialog-content class="yesno-container-content mb15">
        <p class="center">{{ data.content }}</p>
    </div>
    <div class="flex yesno-buttons">
        <button
            mat-raised-button
            (click)="first()"
            color="primary"
            class="center-btn w100 m-0-10"
        >
            {{ data.buttons.first }}
        </button>
        <button
            mat-raised-button
            (click)="second()"
            color="warn"
            class="center-btn w100 m-0-10"
        >
            {{ data.buttons.second }}
        </button>
    </div>
</div>
