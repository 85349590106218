<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Purchase Credits</h1>
<div mat-dialog-content>
    <p class="mb15">
        Scan a card to add to the list that credits will be redeemed to. Note,
        the credits will be evenly split between all cards. Click/tap the
        <mat-icon color="warn">remove_circle</mat-icon> beside the card number
        to remove from list.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            (keyup.enter)="addCard()"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>

    <h4 class="center">Available</h4>
    <p class="center mb15">
        Credits: {{ data['credits'] }} | Bonus: {{ data['bonusCredits'] }}
    </p>

    <div class="purchase-cards mb15 overflow scrollbar">
        <div class="center mb15" *ngFor="let card of cards">
            <h4 class="center">
                {{ card.number }}
                <mat-icon
                    color="warn"
                    class="pointer"
                    (click)="removeCard(card)"
                >
                    remove_circle
                </mat-icon>
            </h4>
            <p class="center">Credits: {{ card.credits }}</p>
            <p class="center">Bonus: {{ card.bonusCredits }}</p>
            <div class="flex-panel-entry jcc aic" *ngIf="card.loading">
                <div class="mini-spinner flex jcc">
                    <mat-icon [inline]="true" color="primary">sync</mat-icon>
                </div>
                Loading Status
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        [disabled]="!canContinue"
        (click)="continue()"
        color="primary"
    >
        Continue
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
