<h1 mat-dialog-title>Editing Template</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-tab-group mat-stretch-tabs>
            <mat-tab label="Upload New File">
                <div class="w100 center jcc flex mb15 m-10-0 phone-block">
                    <input
                        #newFileInput
                        type="file"
                        placeholder="File Input"
                        accept="image/png"
                        [hidden]="true"
                        (change)="fileInputChange(newFileInput.files)"
                        required
                        name="fileInput"
                    />
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="newFileInput.click()"
                    >
                        Upload File
                    </button>
                    <p *ngIf="newTemplate.file" class="m-0-10 asc">
                        {{ newTemplate.file.name }}
                    </p>
                </div>
                <mat-form-field class="w100">
                    <input
                        matInput
                        placeholder="File Name"
                        [(ngModel)]="newTemplate.fileName"
                        required
                        name="fileName"
                    />
                </mat-form-field>
            </mat-tab>
            <mat-tab label="Change File Name">
                <mat-form-field class="w100 m-10-0">
                    <input
                        matInput
                        placeholder="New Name"
                        [(ngModel)]="data['template']['file']"
                        required
                        name="newName"
                    />
                </mat-form-field>
            </mat-tab>
        </mat-tab-group>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
