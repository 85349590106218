import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-posgiftcardinfo',
    templateUrl: './posgiftcardinfo.component.html',
    styleUrls: ['./posgiftcardinfo.component.scss'],
})
export class PosGiftCardInfoComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string;
    giftcard: Object;

    constructor(
        public dialogRef: MatDialogRef<PosGiftCardInfoComponent>,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    async search() {
        // Check if the card is set.
        if (!this.card) return;

        // Loading/setup.
        const card = this.card;
        this.card = '';
        this.loading = true;

        const resp = await this._http.getLocal(
            `pos/giftcards/?barcode=${card}`
        );

        this.loading = false;

        // If no gift card or not activated.
        if (resp.status !== 200 || resp.body['active'] === '0') {
            this.giftcard = null;
            this._snackbar.defaultSnackbar(
                'Gift card not found or not active.'
            );
            return;
        }

        // If gift card.
        this.giftcard = resp.body;
    }

    printBalance() {
        (document.location as any) = `mfg://?gift-card-balance=${this.giftcard['barcode']}`;
    }
}
