import { Injectable } from '@angular/core';
import { CachingService, CachedObject } from './caching.service';
import { SnackbarService } from './snackbar.service';
import { HttpService } from './http.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { FormattingService } from './formatting.service';
const pdfjsViewer = require('pdfjs-dist/web/pdf_viewer.js');

export interface Attachment {
    type: string;
    data: string | SafeUrl;
    name: string;
}

export interface AttachmentResponse {
    success: boolean;
    object: Attachment;
}

/**
 * This service is for dealing with the file viewer, mainly for attachments.
 * From getting an attachment from the API to creating a PDF viewer, this
 * service will handle it.
 */
@Injectable({
    providedIn: 'root',
})
export class FileviewerService {
    constructor(
        private _cache: CachingService,
        private _snackbar: SnackbarService,
        private _http: HttpService,
        private _sanitize: DomSanitizer,
        private _formatting: FormattingService
    ) {
        // Set workerSrc for PDFJS.
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    }

    /**
     * This function will load an attachment into the HTML page.
     * Mainly for dealing with the PDF viewer, but required for seemless multi-extension support.
     *
     *
     * @param pdfDiv The `ID` of the div that will hold the PDF.
     * @param name The name of the attachment.
     * @param type The type of attachment. For example, `log-docs` is for employee log entry attachments.
     * @param cachedKey The key to deal with caching/fetching the cached attachment.
     * @param attachmentObj An `Attachment` object if `getAttachment` was already used prior to this function.
     */
    async loadAttachment(
        pdfDiv: string,
        name?: string,
        type?: string,
        cachedKey?: string,
        attachmentObj?: Attachment
    ): Promise<AttachmentResponse> {
        // Check if an attachment was not sent.
        if (!attachmentObj) {
            attachmentObj = await this.getAttachment(name, type, cachedKey);

            // Check if the new attachment was null.
            if (!attachmentObj) {
                return;
            }
        }

        if (attachmentObj.type === 'pdf') {
            // Load the pdf.
            let doc: pdfjsLib.PDFDocumentProxy = await (pdfjsLib as any).getDocument(
                { data: atob(attachmentObj.data as string) }
            ).promise;

            // Get the canvas div to hold the canvas'.
            let canvasDiv: HTMLElement = document.getElementById(pdfDiv);

            // Clear canvas.
            while (canvasDiv.firstChild) {
                canvasDiv.firstChild.remove();
            }

            // Load pages.
            for (let i = 0; i < doc.numPages; i++) {
                // Get the page and viewport.
                let page: pdfjsLib.PDFPageProxy = await doc.getPage(i + 1);
                let viewPort: pdfjsLib.PDFPageViewport = page.getViewport({
                    scale: 1,
                });

                // Create the canvas.
                let canvas: HTMLCanvasElement = document.createElement(
                    'canvas'
                );
                canvas.classList.add('attachment-view-canvas');
                canvasDiv.append(canvas);

                // Get the context.
                let context: CanvasRenderingContext2D = canvas.getContext('2d');
                canvas.width = viewPort.width;
                canvas.height = viewPort.height;

                // Render the page.
                await page.render({
                    canvasContext: context,
                    viewport: viewPort,
                }).promise;
            }
        }

        return { success: true, object: attachmentObj };
    }

    /**
     * This function is for getting the attachment from the API.
     * Has to be called for `loadAttachment` to work.
     * Will be called internally in `loadAttachment` if an attachment was not given.
     *
     *
     * @param name The name of the attachment.
     * @param type The type of attachment. For example, `log-docs` is for employee log entry attachments.
     * @param cachedKey The key to deal with caching/fetching the cached attachment.
     */
    async getAttachment(name: string, type: string, cachedKey: string) {
        let attachmentObj: Attachment = <Attachment>{};
        attachmentObj.name = name;

        // Check if in cache.
        let cached: CachedObject | boolean = this._cache.get(cachedKey);
        if (cached instanceof Object) {
            let split = cached.data.split('*', 2);
            attachmentObj.type =
                split[0] === 'pdf'
                    ? 'pdf'
                    : split[0] === 'audio'
                    ? 'audio'
                    : 'image';
            attachmentObj.data = split[1];
        } else {
            // Get the base64 data.
            let resp = (
                await this._http.getLocal(
                    `attachments/?type=${type}&name=${encodeURIComponent(name)}`
                )
            ).body;

            // If not successful.
            if (!resp['success']) {
                this._snackbar.defaultSnackbar('Attachment not found.');
                return null;
            }

            attachmentObj.type =
                resp['type'] === 'pdf'
                    ? 'pdf'
                    : resp['type'] === 'mp3' || resp['type'] === 'wav'
                    ? 'audio'
                    : 'image';

            // If not a PDF, bypass the URL.
            if (attachmentObj.type !== 'pdf') {
                attachmentObj.data = this._sanitize.bypassSecurityTrustUrl(
                    resp['base64']
                );
            } else {
                attachmentObj.data = resp['base64'];
            }

            // Set the cache.
            this._cache.set(
                cachedKey,
                `${attachmentObj.type}*${attachmentObj.data}`
            );
        }

        return attachmentObj;
    }

    /**
     * This function is for downloading an attachment.
     *
     * @param name The name of the attachment.
     * @param type The type of attachment. For example, `log-docs` is for employee log entry attachments.
     * @param cachedKey The key to deal with caching/fetching the cached attachment.
     */
    async downloadAttachment(name: string, type: string, cachedKey: string) {
        // Get the attachment.
        const attachment = await this.getAttachment(name, type, cachedKey);
        if (!attachment) return null;

        // Download.
        const href = attachment.data.toString();
        const a = document.createElement('a');
        a.href = href.startsWith('data')
            ? href
            : `data:application/pdf;base64,${href}`;
        a.download = this._formatting.truncateMiddle(attachment.name, 24, true);
        a.click();
    }
}
