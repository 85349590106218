import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpService } from '../services/http.service';

@Injectable()
export class UserResolver implements Resolve<Object> {
    constructor(private _auth: AuthService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return this._auth.getSession();
    }
}

@Injectable()
export class UsersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('auth/users/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class EmployeesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (await this._http.getLocal('hr/employees/')).body;
    }
}

@Injectable()
export class ActiveEmployeesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (await this._http.getLocal('hr/employees/?active')).body;
    }
}

@Injectable()
export class EmployeeResolver implements Resolve<any> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        if (route.paramMap.get('id') === 'new') {
            return 'new';
        }

        return (
            await this._http.getLocal(
                `hr/employees/?id=${route.paramMap.get('id')}`
            )
        ).body;
    }
}

@Injectable()
export class ApplicationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (await this._http.getLocal('hr/applications/')).body;
    }
}

@Injectable()
export class ApplicationResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (
            await this._http.getLocal(
                `hr/applications/?id=${route.paramMap.get('id')}`
            )
        ).body;
    }
}

@Injectable()
export class CurrentAuditionsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('hr/auditions/current/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('partners/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnerResolver implements Resolve<any> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        return (
            await this._http.getLocal(
                `partners/?id=${route.paramMap.get('id')}`
            )
        ).body;
    }
}

@Injectable()
export class VtktoPartnersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('partners/?vtkto')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherCategoryProductsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/products/?categories=true')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherProductsConsignmentResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                'pos/vouchers/products/?sales_vector=Consignment'
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherProductsWebResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/products/?sales_vector=Web')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherProductsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/vouchers/products/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TicketTemplatesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/products/templates/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherProductsCategoriesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/products/categories/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherCategoriesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/categories/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class OrderResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (
            await this._http.getLocal(
                `pos/vouchers/orders/?id=${route.paramMap.get('o_id')}`
            )
        ).body;
    }
}

@Injectable()
export class OrderVouchersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `pos/vouchers/?o_id=${route.paramMap.get('o_id')}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnerOrdersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `pos/vouchers/orders/?partner_id=${route.paramMap.get('id')}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnerUnpaidOrdersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `pos/vouchers/orders/?partner_id=${route.paramMap.get(
                    'id'
                )}&unpaid`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class OfficeOrdersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        // Since there are now partner orders, get both office and partner orders.
        let orders = [];

        // Get office orders.
        let resp: Object = (
            await this._http.getLocal(
                'pos/vouchers/orders/?type=office_order&partner_type'
            )
        ).body;
        if (!resp.hasOwnProperty('success') || resp['success']) {
            orders.push(...(<Object[]>resp));
        }

        // Get partner orders.
        resp = (
            await this._http.getLocal(
                'pos/vouchers/orders/?type=partner_order&partner_type'
            )
        ).body;
        if (!resp.hasOwnProperty('success') || resp['success']) {
            orders.push(...(<Object[]>resp));
        }

        return orders;
    }
}

@Injectable()
export class PosLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/locations/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class FacilitiesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/facilities/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ProductsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/products/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class CategoriesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/categories/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TaxesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/taxes/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ActivePartnerVTKTOPassesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `partners/vtkto/passes/?sold_by=${route.paramMap.get(
                    'id'
                )}&active`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnerVTKTOGroupsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `partners/vtkto/groups/?partner_id=${route.paramMap.get('id')}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnerUsersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `auth/users/?partner_id=${route.paramMap.get('id')}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class CorporateInvoiceResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (
            await this._http.getLocal(
                `partners/invoice/?hash=${route.paramMap.get('hash')}`
            )
        ).body;
    }
}

@Injectable()
export class CorporateInvoiceVouchersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `partners/invoice/vouchers/?hash=${route.paramMap.get('hash')}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class CustomersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/customers/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class CustomerResolver implements Resolve<any> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        return (
            await this._http.getLocal(
                `admin/customers/?email=${route.paramMap.get('email')}`
            )
        ).body;
    }
}

@Injectable()
export class EmailAttachmentsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/message/upload/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class DrawersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/drawers/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class EmployeeDocumentResolver implements Resolve<any> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        return (
            await this._http.getLocal(
                `admin/documents/employee/?id=${route.paramMap.get('id')}`
            )
        ).body;
    }
}

@Injectable()
export class EmployeeDocumentsResolver implements Resolve<Object> {
    constructor(private _http: HttpService, private _auth: AuthService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let user = await this._auth.getSession();
        if (!user['emp_id']) return [];

        let resp: Object = (
            await this._http.getLocal(
                `admin/documents/employee/?employee=${user['emp_id']}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class DocumentsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('admin/documents/?minimal')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class DocumentResolver implements Resolve<any> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        if (route.paramMap.get('id') === 'new') {
            return { id: 'new' };
        }

        return (
            await this._http.getLocal(
                `admin/documents/?id=${route.paramMap.get('id')}`
            )
        ).body;
    }
}

@Injectable()
export class ParksLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('parks-canada/locations/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class QueueGroupsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/queue/?current'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class QueuePastGroupsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/queue/?past')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class NonAuthenticationDeviceResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal(
                `admin/nonlogin-devices/?type=${route.data.type}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return null;
        } else {
            return resp;
        }
    }
}

@Injectable()
export class KioskLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/kiosks/locations/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class KioskProductsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/kiosks/products/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class KioskCategoriesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/kiosks/categories/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherUniqueFacilitiesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/facilities/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class VoucherUniqueProductTypesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/vouchers/product-types/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ReservationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/reservations/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class SellAtResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/sell-at/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class BookingTypesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('pos/bookings/types/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class BookingTypesStoreGroupResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/bookings/types/?storeGroup')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class DailyEntryFacilitiesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('admin/daily-entry/facilities/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ScreenLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('screens/locations/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ScreenMediaResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('screens/media/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ScreenScreensResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('screens/screens/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class CartCategoriesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/cart/categories/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TranslationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/translations/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class StoresResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/cart/stores/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PromoCodeTypesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('admin/cart/promo-codes/types/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PartnersOutstandingResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        return (await this._http.getLocal('partners/outstanding/')).body;
    }
}

@Injectable()
export class CartsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/cart/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class GiftcardsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/giftcards/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class NonFluidLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('non-fluid-locations/'))
            .body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TemplatesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/templates/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class LogsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('admin/cart/log/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class ReservationFacilitiesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (
            await this._http.getLocal('pos/reservations/facilities/')
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class PhoneNumbersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('phone-numbers/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TextTicketsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        let resp: Object = (await this._http.getLocal('text-tickets/')).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            return [];
        } else {
            return resp;
        }
    }
}

@Injectable()
export class TextTicketResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal(
            `text-tickets/?id=${route.paramMap.get('id')}`
        );

        return resp.status === 200 ? resp.body : null;
    }
}

@Injectable()
export class PartnerPayResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal(
            `partners/invoice/pay/?id=${route.paramMap.get('id')}`
        );

        return resp.status !== 200 ? null : resp.body;
    }
}

@Injectable()
export class ChecklistTypesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal('admin/checklists/');
        return resp.status === 204 ? [] : (resp.body as Object[]);
    }
}

@Injectable()
export class CFSTemplatesResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal(
            'pos/customer-facing-screens/templates/?locations'
        );
        return resp.status !== 200 ? [] : (resp.body as Object[]);
    }
}

@Injectable()
export class SquareDrawersResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal('admin/square/drawers/');
        return resp.status !== 200 ? [] : (resp.body as Object[]);
    }
}

@Injectable()
export class SquareLocationsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal('admin/square/locations/');
        return resp.status !== 200 ? [] : (resp.body as Object[]);
    }
}

@Injectable()
export class StoreGroupsResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal('store-groups/');
        return resp.status !== 200 ? [] : (resp.body as Object[]);
    }
}

@Injectable()
export class StoreGroupResolver implements Resolve<Object> {
    constructor(private _http: HttpService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Object> {
        const resp = await this._http.getLocal(
            `store-groups/?id=${route.paramMap.get('id')}`
        );

        return resp.status !== 200 ? null : resp.body;
    }
}

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class ResolversModule {}
