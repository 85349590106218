import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

interface Data {
    file: File | Blob | string;
    title?: string;
    body?: string;
}

@Component({
    selector: 'app-confirmviewimage',
    templateUrl: './confirmviewimage.component.html',
    styleUrls: ['./confirmviewimage.component.scss'],
})
export class ConfirmviewimageComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    fileURL: string | SafeUrl;

    constructor(
        public dialogRef: MatDialogRef<ConfirmviewimageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _sanitizer: DomSanitizer
    ) {
        // If file is not a string, create object URL.
        this.fileURL =
            typeof this.data.file === 'string'
                ? this.data.file
                : this._sanitizer.bypassSecurityTrustUrl(
                      URL.createObjectURL(this.data.file)
                  );
    }

    ngOnInit() {}

    confirm() {
        this.dialogRef.close(true);
    }
}
