import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DatePickerDefault {
    start: Date;
    end: Date;
}

interface DatePickerData {
    title: string;
    content: string;
    placeholder: string;
    range: boolean;
    default?: Date | { start: Date; end: Date };
}

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
    date: Date;
    dateRange = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });

    constructor(
        public dialogRef: MatDialogRef<DatepickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DatePickerData
    ) {}

    ngOnInit() {
        if (this.data.default) {
            if (this.data.range) {
                const d = this.data.default as DatePickerDefault;
                this.dateRange = new FormGroup({
                    start: new FormControl(d.start),
                    end: new FormControl(d.end),
                });
            } else {
                this.date = this.data.default as Date;
            }
        }
    }
}
