<loading [opacity]="0.5" *ngIf="loading"></loading>

<h1 mat-dialog-title>Choose {{ data.type }} Group</h1>
<div mat-dialog-content class="w65vh">
    <div class="flex">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Search"
                [(ngModel)]="searchValue"
                (keyup.enter)="search()"
            />
        </mat-form-field>
        <button
            mat-raised-button
            class="center-btn mb15"
            color="primary"
            (click)="search()"
        >
            Search
        </button>
    </div>

    <p class="center mb15" *ngIf="groups.length">
        Note, to select a group, just double click it.
    </p>
    <div class="flex-panel-entry flex-panel-header jcc" *ngIf="groups.length">
        <p class="half">ID</p>
        <p>Email</p>
        <p>Phone</p>
        <p class="padding-right-15">Postal Code</p>
    </div>
    <div class="groups-div scrollbar overflow" *ngIf="groups.length">
        <mat-accordion class="groups-div scrollbar overflow">
            <mat-expansion-panel
                *ngFor="let group of groups"
                (dblclick)="choose(group)"
                (opened)="getMembers(group)"
            >
                <mat-expansion-panel-header class="mb15">
                    <mat-panel-title>
                        <div class="flex-panel-entry jcc">
                            <p class="half overflow scrollbar">
                                {{ group['group_id'] }}
                            </p>
                            <p class="overflow scrollbar">
                                {{ group['email'] }}
                            </p>
                            <p class="overflow scrollbar">
                                {{ group['phone'] | phone }}
                            </p>
                            <p class="overflow scrollbar">
                                {{ group['postal_code'] }}
                            </p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div
                    class="passes scrollbar"
                    *ngIf="group['members'] && group['members'].length"
                >
                    <div
                        class="pass scrollbar padding-15"
                        *ngFor="let member of group['members']"
                    >
                        <img
                            [src]="
                                member['photo'] ||
                                this._sanitizer.bypassSecurityTrustResourceUrl(
                                    _misc.defaultPhoto
                                )
                            "
                        />
                        <div class="pass-description flex col center jcc">
                            <p>
                                {{ member['first_name'] }}
                                {{ member['last_name'] }}
                            </p>
                            <p>
                                {{ member['pass_type'] }}
                            </p>
                            <p *ngIf="member['height_code']">
                                {{ member['height_code'] }}
                            </p>
                        </div>
                    </div>
                </div>

                <h2
                    class="center"
                    *ngIf="group['members'] && !group['members'].length"
                >
                    No Members
                </h2>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button [mat-dialog-close]="undefined" color="warn">
        Cancel
    </button>
</div>
