<div mat-dialog-content>
    <div class="markdown-container flex jcc phone-block">
        <div class="markdown-editor m-0-10 half phone-w100 phone-mb15">
            <h1 class="center">Editor</h1>
            <p class="center">
                This editor uses Markdown. Click
                <a
                    href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                    class="same-color underline"
                    target="_blank"
                    >here</a
                >
                for help and to see how it works.
            </p>
            <div class="markdown-editor-toolbar"></div>
            <textarea id="markdown" placeholder="Markdown Editor"></textarea>
        </div>
        <div class="markdown-preview m-0-10 half phone-w100">
            <h1 class="center">Preview</h1>
            <p class="center">
                Note, if you are editing descriptions for checklist items, the
                preview may not be 100% accurate. Once saved, please verify on
                the actual app to see if it matches what you need.
            </p>
            <div id="markdownPreview"></div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="dialogRef.close(data.markdown)"
        color="primary"
    >
        Save
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
