<loading *ngIf="loading" [opacity]="0.5"></loading>
<h1 mat-dialog-title>Rebook</h1>
<div mat-dialog-content>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Date"
            [matDatepicker]="picker"
            (click)="picker.open()"
            (focus)="picker.open()"
            (dateChange)="dateChange()"
            [(ngModel)]="date"
            required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w100">
        <mat-select placeholder="Time" [(ngModel)]="time" required>
            <mat-option
                *ngFor="let t of times"
                [value]="t['timeTaken']"
                [disabled]="!t['valid']"
            >
                {{ t['time'] }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="confirm()" color="primary">
        Confirm
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
