import { Component, OnInit, Inject, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContextmenuService } from 'src/app/services/contextmenu.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';

interface Data {
    pin: string;
    drawers: Object[];
}

@Component({
    selector: 'app-posjournal',
    templateUrl: './posjournal.component.html',
    styleUrls: ['./posjournal.component.scss'],
})
export class PosjournalComponent implements OnInit {
    // Variables.
    this = this;
    loading: boolean = false;
    selectedTransaction: Object;
    selectedDrawer: Object;
    pinUser: Object;
    dev = isDevMode();

    constructor(
        public dialogRef: MatDialogRef<PosjournalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _http: HttpService,
        private _context: ContextmenuService,
        private _snackbar: SnackbarService,
        private _auth: AuthService
    ) {}

    async ngOnInit() {
        // Get the user from the pin.
        this.pinUser = await this._auth.userFromPin(this.data.pin);
    }

    async getItems(transaction: Object, voided: boolean = false) {
        // Set selectedTransaction.
        if (!voided) this.selectedTransaction = transaction;

        if (transaction.hasOwnProperty('items')) return;

        this.loading = true;

        // Call the API.
        let resp: Object = (
            await this._http.getLocal(
                `pos/${voided ? 'voided' : 'transactions'}/items/?transaction=${
                    transaction['id']
                }`
            )
        ).body;

        if (resp.hasOwnProperty('success') && !resp['success']) {
            transaction['items'] = [];
        } else {
            transaction['items'] = <Object[]>resp;
        }

        this.loading = false;
    }

    reprintReceipt() {
        (document.location as any) = `mfg://?receipt=${this.selectedTransaction['id']}`;
    }

    async reprintVouchers() {
        this.loading = true;

        // Get vouchers.
        let resp: Object[] = <Object[]>(
            (
                await this._http.getLocal(
                    `pos/vouchers/?o_id=${this.selectedTransaction['id']}&pos`
                )
            ).body
        );

        // If none, let the user know and return.
        if (!resp.length) {
            this._snackbar.defaultSnackbar(
                'There are no vouchers to print in this order.'
            );
            this.loading = false;
            return;
        }

        // Add all vouchers to a group to print.
        let toPrint = 'mfg://?group=[';

        for (let i = 0; i < resp.length; i++) {
            toPrint += `{"voucher": ["${resp[i]['v_id']}"]},`;
        }

        // Print them.
        (document.location as any) = toPrint.slice(0, -1) + ']';

        this.loading = false;
    }

    async void() {
        if (!this.dev) return;

        this.loading = true;

        // Call the API.
        let resp: Object = (
            await this._http.postLocal('pos/transactions/void/', {
                id: this.selectedTransaction['id'],
                pin: this.data.pin,
            })
        ).body;

        // Show snackbar based on response.
        if (resp.hasOwnProperty('success') && !resp['success']) {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to void the transaction.'
            );
        } else {
            // Remove the transaction and insert into voided.
            let index = this.selectedDrawer['transactions'].findIndex(
                (transaction) =>
                    transaction['id'] === this.selectedTransaction['id']
            );
            if (index > -1) {
                this.selectedDrawer['transactions'].splice(index, 1);
            }

            resp['first_name'] = this.pinUser['first_name'];
            resp['last_name'] = this.pinUser['last_name'];
            this.selectedDrawer['voided'].push(resp);

            this._snackbar.defaultSnackbar(
                'Successfully voided the transaction.'
            );
        }

        this.loading = false;
    }

    rightClick(
        event: MouseEvent,
        menu: MatMenuTrigger,
        obj: Object,
        drawer: Object,
        component?: PosjournalComponent
    ) {
        let t = component || this;

        t.selectedDrawer = drawer;
        t.selectedTransaction = obj;
        t._context.openMenu(event, menu);
        return false;
    }
}
