import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    attachments: Object[];
}

@Component({
    selector: 'app-chooseemailattachment',
    templateUrl: './chooseemailattachment.component.html',
    styleUrls: ['./chooseemailattachment.component.scss']
})
export class ChooseemailattachmentComponent implements OnInit {
    // Filter variables.
    filterValue: string;
    filteredAttachments: Object[];

    constructor(
        public dialogRef: MatDialogRef<ChooseemailattachmentComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        this.filteredAttachments = cloneDeep(this.data.attachments);
    }

    save(attachment: Object) {
        this.dialogRef.close(attachment);
    }

    filter() {
        this.filteredAttachments = this.data.attachments.filter(attachment =>
            attachment['name']
                .toLowerCase()
                .includes(this.filterValue.toLowerCase())
        );
    }
}
