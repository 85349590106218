import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-addticketevent',
    templateUrl: './addticketevent.component.html',
    styleUrls: ['./addticketevent.component.scss'],
})
export class AddticketeventComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    event = { type: 'note', content: '' };
    types = [
        {
            display: 'Note',
            value: 'note',
        },
    ];

    constructor(
        public dialogRef: MatDialogRef<AddticketeventComponent>,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    confirm() {
        // Check for missing information.
        if (!this.event.type || !this.event.content) {
            this._snackbar.defaultSnackbar('Missing event type or content.');
            return;
        }

        this.dialogRef.close(this.event);
    }
}
