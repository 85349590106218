import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Location } from '@angular/common';
import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class CorporateinvoiceGuard implements CanActivate {
    constructor(private _location: Location, private _http: HttpService) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        // Get the invoice.
        let invoice: Object = (
            await this._http.getLocal(
                `partners/invoice/?hash=${next.paramMap.get('hash')}`
            )
        ).body;

        // If it does not exist or the ID does not match.
        if (
            (invoice.hasOwnProperty('success') && !invoice['success']) ||
            invoice['id'] !== next.paramMap.get('id')
        ) {
            this._location.back();
            return false;
        }

        return true;
    }
}
