import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormattingService } from 'src/app/services/formatting.service';

@Component({
    selector: 'app-posgroupinfo',
    templateUrl: './posgroupinfo.component.html',
    styleUrls: ['./posgroupinfo.component.scss'],
})
export class PosgroupinfoComponent implements OnInit {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    error: string;

    @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;

    constructor(
        public dialogRef: MatDialogRef<PosgroupinfoComponent>,
        private _format: FormattingService
    ) {}

    ngOnInit() {}

    confirm() {
        // Check if not all fields are filled.
        if (!this.first_name || !this.last_name || !this.phone) {
            this.error = 'Please fill out all above fields.';
            return;
        }

        this.dialogRef.close({
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this._format.numberifyPhone(this.phone),
        });
    }
}
