import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

interface Data {
    member: Object;
}

@Component({
    selector: 'app-passhistory',
    templateUrl: './passhistory.component.html',
    styleUrls: ['./passhistory.component.scss']
})
export class PasshistoryComponent implements OnInit {
    // Data Table.
    dataSource: MatTableDataSource<Object>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<PasshistoryComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        // Setup data table.
        this.dataSource = new MatTableDataSource(this.data.member['history']);
        this.dataSource.sort = this.sort;
    }
}
