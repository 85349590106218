<loading
    *ngIf="loading || documentLoading"
    [opacity]="loadingOpacity"
    [text]="loadingText"
></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <a
            href="javascript:void(0)"
            (click)="_loading.navigate('/documents', true)"
            class="back-a"
        >
            <mat-icon class="left-anim">chevron_left</mat-icon>
            Go Back
        </a>
        <h1 class="center">Document Signing</h1>

        <p class="center" *ngIf="document">
            View the document below, sign in the pad indicated below the
            document, and then click the sign document button.
        </p>
        <p class="center" *ngIf="!document">
            You have already signed this document. Below is the PDF of the
            signed document.
        </p>

        <div id="signed" class="center col flex"></div>

        <div [hidden]="!document">
            <h1 class="center">Sign Below</h1>

            <div *ngIf="inputs && inputs.length > 0">
                <p class="center mb15">
                    Please fill out all of the fields below.
                </p>

                <div class="flex col jcc aic">
                    <mat-form-field *ngFor="let input of inputs">
                        <input
                            matInput
                            [placeholder]="input.name"
                            [(ngModel)]="input.value"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>

            <p class="center">Please sign in the box below.</p>
            <canvas id="signature-pad"></canvas>

            <div class="inputs">
                <div class="form-field center">
                    <mat-checkbox color="primary" [(ngModel)]="validateSign">
                        I have signed in the box above.
                    </mat-checkbox>
                </div>
            </div>

            <div class="flex jcc">
                <button
                    mat-raised-button
                    color="primary"
                    class="center-btn m-0-10"
                    (click)="sign()"
                    [disabled]="
                        !signaturePad || signaturePad.isEmpty() || !validateSign
                    "
                >
                    Sign Document
                </button>
                <button
                    mat-raised-button
                    color="warn"
                    class="center-btn m-0-10"
                    (click)="clearSignaturePad()"
                >
                    Clear Signature
                </button>
            </div>
        </div>
    </div>
</div>
