import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    drawer: Object;
    locations: Object[];
    users: Object[];
}

@Component({
    selector: 'app-editsquaredrawer',
    templateUrl: './editsquaredrawer.component.html',
    styleUrls: ['./editsquaredrawer.component.scss'],
})
export class EditSquareDrawerComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    drawer: Object;
    filteredUsers: Object[];
    errorMessage: string;

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EditSquareDrawerComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());

        // Clone some values.
        this.drawer = cloneDeep(this.data.drawer);
        this.filteredUsers = cloneDeep(this.data.users);

        // Set the clerk if needed.
        if (this.drawer['clerk']) {
            this.drawer[
                'clerk'
            ] = `${this.drawer['clerk']['first_name']} ${this.drawer['clerk']['last_name']} (${this.drawer['clerk']['id']})`;
        }

        // Only get the location ID if needed.
        if (this.drawer['location']) {
            this.drawer['location'] = this.drawer['location']['id'];
        }
    }

    ngOnInit() {}

    filterUsers() {
        this.filteredUsers = this.data.users.filter((user) =>
            `${user['first_name']} ${user['last_name']} (${user['id']})`
                .toLowerCase()
                .includes(this.drawer['clerk'].toLowerCase())
        );
    }

    hasChanges() {
        return this.form.dirty;
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            if (this.valid()) {
                this.dialogRef.close({
                    ...this.drawer,
                    clerk: this.getClerk(),
                    location: this.getLocation(),
                });
            } else {
                this.errorMessage =
                    'Please fill out all fields and make sure they are valid. All money fields can only have up to 2 decimals.';
            }
        } else {
            this.dialogRef.close(undefined);
        }
    }

    valid() {
        // Validate location.
        if (!this.getLocation()) return false;

        // Validate the date.
        if (!this.drawer['date']) return false;

        // Try to get the clerk.
        if (!this.getClerk()) return false;

        // Validate the net_sales.
        if (
            !this.drawer.hasOwnProperty('net_sales') ||
            this.drawer['net_sales'] == null ||
            !this._misc.currencyRegex.test(this.drawer['net_sales'])
        ) {
            return false;
        }

        // Validate the rounding.
        if (
            !this.drawer.hasOwnProperty('rounding') ||
            this.drawer['rounding'] == null ||
            !this._misc.currencyRegex.test(this.drawer['rounding'])
        ) {
            return false;
        }

        // Validate the cc.
        if (
            !this.drawer.hasOwnProperty('cc') ||
            this.drawer['cc'] == null ||
            !this._misc.currencyRegex.test(this.drawer['cc'])
        ) {
            return false;
        }

        // Validate the tax.
        if (
            !this.drawer.hasOwnProperty('tax') ||
            this.drawer['tax'] == null ||
            !this._misc.currencyRegex.test(this.drawer['tax'])
        ) {
            return false;
        }

        // Validate the gc redeemed.
        if (
            !this.drawer.hasOwnProperty('gc_redeemed') ||
            this.drawer['gc_redeemed'] == null ||
            !this._misc.currencyRegex.test(this.drawer['gc_redeemed'])
        ) {
            return false;
        }

        // Validate the cash actual.
        if (
            !this.drawer.hasOwnProperty('cash_actual') ||
            this.drawer['cash_actual'] == null ||
            !this._misc.currencyRegex.test(this.drawer['cash_actual'])
        ) {
            return false;
        }

        // Validate the square_fees.
        if (
            !this.drawer.hasOwnProperty('square_fees') ||
            this.drawer['square_fees'] == null ||
            !this._misc.currencyRegex.test(this.drawer['square_fees'])
        ) {
            return false;
        }

        return true;
    }

    getClerk() {
        if (!this.drawer['clerk']) return undefined;

        return this.data.users.find((user) =>
            `${user['first_name']} ${user['last_name']} (${user['id']})`
                .toLowerCase()
                .includes(this.drawer['clerk'].toLowerCase())
        );
    }

    getLocation() {
        if (!this.drawer['location']) return undefined;

        return this.data.locations.find(
            (location) => location['id'] === this.drawer['location']
        );
    }
}
