import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DecimalPipe, CurrencyPipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RedirectComponent } from './redirect/redirect.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AccountComponent } from './account/account.component';

import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CustomelementsComponent } from './customelements/customelements.component';
import { SidenavService } from './services/sidenav.service';
import { AuthService } from './services/auth.service';
import { HttpService } from './services/http.service';
import { CustomelementsModule } from './customelements/customelements.module';
import { PipesModule } from './pipes/pipes.module';
import { ContextmenuService } from './services/contextmenu.service';
import { DialoguesModule } from './dialogues/dialogues.module';
import { FormattingService } from './services/formatting.service';
import { LoadingService } from './services/loading.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CachingService } from './services/caching.service';
import { ChartService } from './services/chart.service';
import { SnackbarService } from './services/snackbar.service';
import { FileviewerService } from './services/fileviewer.service';
import { LayoutModule } from '@angular/cdk/layout';
import { MiscService } from './services/misc.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomdirectivesModule } from './customdirectives/customdirectives.module';
import { PaymentsComponent } from './payments/payments.component';
import { MatTableModule } from '@angular/material/table';
import { GlobalerrorhandlerService } from './services/globalerrorhandler.service';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentComponent } from './documents/document/document.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ParkscanadaComponent } from './parkscanada/parkscanada.component';
import { ContactComponent } from './contact/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { HelpComponent } from './help/help.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PunchClockComponent } from './punch-clock/punch-clock.component';
import { WebcamModule } from 'ngx-webcam';
import { MatBadgeModule } from '@angular/material/badge';
import { SelfServeComponent } from './self-serve/self-serve.component';
import { SocketService } from './services/socket.service';
import { ScreenComponent } from './screen/screen.component';
import { RipleysEmployeeScreeningComponent } from './ripleys-employee-screening/ripleys-employee-screening.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PartnerPayComponent } from './partner-pay/partner-pay.component';
import { ParksKioskComponent } from './parks-kiosk/parks-kiosk.component';
import { TranslateService } from './services/translate.service';
import { TusService } from './services/tus.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RNFEmployeeScreeningComponent } from './rnf-employee-screening/rnf-employee-screening.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { StaffPassComponent } from './account/staff-pass/staff-pass.component';

@NgModule({
    declarations: [
        AppComponent,
        RedirectComponent,
        PagenotfoundComponent,
        CustomelementsComponent,
        AccountComponent,
        StaffPassComponent,
        PaymentsComponent,
        DocumentsComponent,
        DocumentComponent,
        ParkscanadaComponent,
        ContactComponent,
        HelpComponent,
        PunchClockComponent,
        SelfServeComponent,
        ScreenComponent,
        RipleysEmployeeScreeningComponent,
        RNFEmployeeScreeningComponent,
        PartnerPayComponent,
        ParksKioskComponent,
    ],
    imports: [
        BrowserModule,
        LayoutModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        CustomelementsModule,
        PipesModule,
        DialoguesModule,
        MatSnackBarModule,
        NgxMaterialTimepickerModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CustomdirectivesModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatChipsModule,
        MatExpansionModule,
        WebcamModule,
        MatBadgeModule,
        MatCheckboxModule,
        ClipboardModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
    ],
    providers: [
        AuthService,
        HttpService,
        SidenavService,
        ContextmenuService,
        FormattingService,
        LoadingService,
        CachingService,
        ChartService,
        SnackbarService,
        FileviewerService,
        MiscService,
        SocketService,
        TranslateService,
        TusService,
        DecimalPipe,
        CurrencyPipe,
        { provide: ErrorHandler, useClass: GlobalerrorhandlerService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
