import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContextmenuService } from '../services/contextmenu.service';
import { MatMenuTrigger } from '@angular/material/menu';

import * as dayjs from 'dayjs';
import { LoadingService } from '../services/loading.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Resolve variables.
    user: Object;
    documents: Object[];

    // Table variables.
    contextDocument: Object;
    documentsDataSource: MatTableDataSource<Object>;
    filter: string = '';

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private _route: ActivatedRoute,
        private _title: Title,
        private _context: ContextmenuService,
        private _loading: LoadingService
    ) {
        this.user = this._route.snapshot.data.user;
        this.documents = this._route.snapshot.data.documents;
    }

    ngOnInit() {
        // Set the title.
        this._title.setTitle('Assigned Documents - FluidDynamics');

        // Set the documents signed to a readable date.
        for (let i = 0; i < this.documents.length; i++) {
            if (this.documents[i]['signed']) {
                this.documents[i]['signed'] = dayjs
                    .unix(this.documents[i]['signed'])
                    .format('YYYY-MM-DD HH:mm:ss');
            }
        }

        // Setup the table.
        this.documentsDataSource = new MatTableDataSource(this.documents);
        this.documentsDataSource.sort = this.sort;
        this.documentsDataSource.paginator = this.paginator;
        this.documentsDataSource.filterPredicate = (
            data: any,
            _: string
        ): boolean => {
            let joined = `${data.name}${data.signed}`.toLowerCase();
            return !this.filter || joined.includes(this.filter.toLowerCase());
        };
    }

    signDocument(document: Object = null) {
        const doc = document || this.contextDocument;

        this._loading.navigate(`/documents/${doc['id']}`, true);
    }

    applyFilter() {
        // Apply a random filter (unix time in ms) so that it always fires even if filter is blank.
        this.documentsDataSource.filter = `${+new Date()}`;
    }

    rightClick(
        event: MouseEvent,
        menu: MatMenuTrigger,
        obj: Object,
        component?: DocumentsComponent
    ) {
        let t = component || this;

        t.contextDocument = obj;
        t._context.openMenu(event, menu);
        return false;
    }
}
