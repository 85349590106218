import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './phone.directive';
import { SinMaskDirective } from './sin.directive';
import { PostalCodeMaskDirective } from './postalcode.directive';
import { PhonecontextmenuDirective } from './phonecontextmenu.directive';
import { SoftkeyboardDirective } from './softkeyboard.directive';
import { CurrencyMaskDirective } from './currency.directive';
import { CreditCardDirective } from './creditCard.directive';
import { CreditCardExpiryDirective } from './creditCardExpiry.directive';
import { DragAndDropDirective } from './dragAndDrop.directive';
import { TranslationDirective } from './translation.directive';

@NgModule({
    declarations: [
        PhoneMaskDirective,
        SinMaskDirective,
        PostalCodeMaskDirective,
        PhonecontextmenuDirective,
        SoftkeyboardDirective,
        CurrencyMaskDirective,
        CreditCardDirective,
        CreditCardExpiryDirective,
        DragAndDropDirective,
        TranslationDirective,
    ],
    imports: [CommonModule],
    exports: [
        PhoneMaskDirective,
        SinMaskDirective,
        PostalCodeMaskDirective,
        PhonecontextmenuDirective,
        SoftkeyboardDirective,
        CurrencyMaskDirective,
        CreditCardDirective,
        CreditCardExpiryDirective,
        DragAndDropDirective,
        TranslationDirective,
    ],
})
export class CustomdirectivesModule {}
