import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { HttpService } from '../services/http.service';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { MiscService } from '../services/misc.service';

@Injectable({
    providedIn: 'root',
})
export class NonLoginAuthenticationGuard implements CanActivate {
    constructor(
        private _location: Location,
        private _http: HttpService,
        private _misc: MiscService
    ) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        // Set the cookie.
        if (
            window.localStorage.getItem(
                `${next.data.type.toUpperCase()}_FLUIDDYNAMICS`
            )
        ) {
            this._misc.setCookie(
                `${next.data.type.toUpperCase()}_FLUIDDYNAMICS`,
                window.localStorage.getItem(
                    `${next.data.type.toUpperCase()}_FLUIDDYNAMICS`
                )
            );
        }

        // Validate.
        let resp = await this._http.getLocal(
            `admin/nonlogin-devices/validate/?type=${next.data.type}`
        );

        // If successful, it should return a 204.
        if (resp.status === 204) {
            return true;
        } else {
            this._location.back();
            return false;
        }
    }
}
