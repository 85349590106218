import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    media: string[];
}

@Component({
    selector: 'app-choosescreenmedia',
    templateUrl: './choosescreenmedia.component.html',
    styleUrls: ['./choosescreenmedia.component.scss'],
})
export class ChoosescreenmediaComponent implements OnInit {
    // Filter variables.
    filterValue: string;
    filteredMedia: string[];

    constructor(
        public dialogRef: MatDialogRef<ChoosescreenmediaComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {
        this.filteredMedia = cloneDeep(this.data.media);
    }

    save(media: string) {
        this.dialogRef.close(media);
    }

    filter() {
        this.filteredMedia = this.data.media.filter((media) =>
            media.toLowerCase().includes(this.filterValue.toLowerCase())
        );
    }
}
