import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MiscService } from 'src/app/services/misc.service';

import cloneDeep from 'lodash-es/cloneDeep';

interface Data {
    user: Object;
    employees: Object[];
    partners: Object[];
    storeGroups: Object[];
}

@Component({
    selector: 'app-edituser',
    templateUrl: './edituser.component.html',
    styleUrls: ['./edituser.component.scss'],
})
export class EdituserComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    filteredEmployees: Object[];
    filteredPartners: Object[];

    @ViewChild('form', { static: true })
    form: NgForm;

    constructor(
        public dialogRef: MatDialogRef<EdituserComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private _dialog: MatDialog
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());
    }

    ngOnInit() {
        this.filteredEmployees = cloneDeep(this.data.employees);
        this.filteredPartners = cloneDeep(this.data.partners);

        // Set the parter and the employee to the text version of it if needed.
        if (this.data.user['emp_id']) {
            let emp = this.data.employees.find(
                (employee) => employee['id'] == this.data.user['emp_id']
            );
            if (emp) {
                this.data.user[
                    'emp_id'
                ] = `${emp['first_name']} ${emp['last_name']} (${emp['id']})`;
            }
        }

        if (this.data.user['partner_id']) {
            let partner = this.data.partners.find(
                (partner) => partner['id'] == this.data.user['partner_id']
            );
            if (partner) {
                this.data.user[
                    'partner_id'
                ] = `${partner['partner_name']} (${partner['id']})`;
            }
        }
    }

    hasChanges() {
        return this.form.dirty;
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close(this.data.user);
        } else {
            this.dialogRef.close(undefined);
        }
    }

    filterEmployees() {
        this.filteredEmployees = this.data.employees.filter((emp) =>
            `${emp['first_name']} ${emp['last_name']} (${emp['id']})`
                .toLowerCase()
                .includes(this.data.user['emp_id'].toLowerCase())
        );
    }

    filterPartners() {
        this.filteredPartners = this.data.partners.filter((partner) =>
            `${partner['partner_name']} (${partner['id']})`
                .toLowerCase()
                .includes(this.data.user['partner_id'].toLowerCase())
        );
    }
}
