import { Injectable } from '@angular/core';
import { Upload } from 'tus-js-client';

type TusType = 'screen-media';

@Injectable({
    providedIn: 'root',
})
export class TusService {
    endpoint: string = 'https://tus.fluiddynamics.ca/files/';
    auth: string = 'Bearer t8m8PUMFs!t!RTvouY*Jqu4e';

    constructor() {}

    async upload(
        type: TusType,
        file: File,
        onError: (error: Error) => void,
        onProgress: (
            percentage: string,
            bytesSent: number,
            bytesTotal: number
        ) => void,
        onSuccess: (upload: Upload) => void
    ) {
        // Create a new upload for tus.
        const upload = new Upload(file, {
            endpoint: this.endpoint,
            chunkSize: 1024 * 1024, // 1MB
            headers: {
                Authorization: this.auth,
            },
            metadata: {
                type,
                filename: file.name,
            },
            onError,
            onProgress: (bytesSent: number, bytesTotal: number) => {
                const percentage = ((bytesSent / bytesTotal) * 100).toFixed(2);
                onProgress(percentage, bytesSent, bytesTotal);
            },
            onSuccess: () => {
                onSuccess(upload);
            },
        });

        // Check if we have previous uploads and resume from the newest.
        const previousUploads = await upload.findPreviousUploads();
        if (previousUploads.length > 0) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload.
        upload.start();
    }
}
