<h1 mat-dialog-title>Editing Location</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data['location']['name']"
                name="name"
                required
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <mat-select
                placeholder="Facility"
                [(ngModel)]="data['location']['facility']"
                name="facility"
                required
            >
                <mat-option
                    *ngFor="let facility of data['facilities']"
                    [value]="facility['fluid_id']"
                >
                    {{ facility['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Attractions"
                [(ngModel)]="data['location']['attractions']"
                name="attractions"
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <mat-select
                placeholder="Moneris Credentials"
                [(ngModel)]="data['location']['moneris_creds']"
                name="moneris_creds"
                required
            >
                <mat-option value="sandspit">
                    Sandspit Entertainment Ltd
                </mat-option>
                <mat-option value="shining">Shining Waters</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="inputs mb15">
            <div class="flex w100">
                <div class="center phone-mb15 w100">
                    <h3>Sacoa</h3>
                    <mat-slide-toggle
                        color="primary"
                        [(ngModel)]="data['location']['sacoa']"
                        name="sacoa"
                    ></mat-slide-toggle>
                </div>
            </div>
        </div>

        <h2 class="center">Categories</h2>
        <p class="center">
            Double click a selection box to select/deselect all categories.
        </p>
        <div class="inputs mb15">
            <mat-selection-list
                #categoryList
                color="primary"
                class="list-w100 scrollbar max-250"
                [(ngModel)]="data['location']['categories']"
                (selectionChange)="_misc.selectionHandler($event, categoryList)"
                name="categories"
            >
                <mat-list-option
                    #categoryListOption
                    *ngFor="let category of data['categories']"
                    [value]="category['id']"
                    [ngClass]="{
                        'background-radial': categoryListOption.selected
                    }"
                >
                    {{ category['name'] }}
                    {{
                        category['facility']
                            ? '(' + category['facility'] + ')'
                            : ''
                    }}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
