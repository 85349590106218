<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Confirm Refund</h1>
<div mat-dialog-content>
    <p>{{ data['content'] }}</p>
    <mat-form-field class="w100">
        <input
            matInput
            type="password"
            placeholder="Pin"
            [formControl]="pinFormControl"
            (keydown.enter)="confirm()"
        />
        <mat-error *ngIf="pinFormControl.hasError('pattern')">
            Invalid Pin.
        </mat-error>
    </mat-form-field>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Reason"
            [(ngModel)]="reason"
            (keydown.enter)="confirm()"
        />
    </mat-form-field>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="confirm()" color="primary" class="btn-1">
        Confirm
    </button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">
        Cancel
    </button>
</div>
