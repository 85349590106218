<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Consolidate Cards</h1>
<div mat-dialog-content>
    <p class="mb15">
        First, scan the card to consolidate to. Then scan up to four other cards
        to use for consolidation. Click/tap the
        <mat-icon color="warn">remove_circle</mat-icon> beside the card number
        to remove from list for consolidation cards.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            (keyup.enter)="addCard()"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>

    <div class="aic center flex jcc mb15">
        <p class="bold margin-15 target-card">Target Card</p>
        <div class="card-background target-card-background">
            <p class="card flex aic jcc mb15">
                <span class="target-card bold">{{ targetCard }}</span>
                <mat-icon
                    *ngIf="targetCard"
                    color="warn"
                    class="pointer"
                    (click)="removeCard(targetCard)"
                >
                    remove_circle
                </mat-icon>
            </p>
        </div>
    </div>

    <h2 class="center">Consolidation Cards</h2>
    <div class="consolidate mb15 overflow scrollbar">
        <div
            class="w100 h100 center card-background"
            *ngFor="let i of [0, 1, 2, 3]"
        >
            <p class="center card" *ngIf="cards.length > i">
                {{ cards[i] }}
                <mat-icon
                    color="warn"
                    class="pointer"
                    (click)="removeCard(cards[i])"
                >
                    remove_circle
                </mat-icon>
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button
        mat-raised-button
        [disabled]="cards.length < 1 || !targetCard"
        (click)="consolidate()"
        color="primary"
    >
        Consolidate
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
