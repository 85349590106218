import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Location } from '@angular/common';
import { HttpService } from '../services/http.service';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentGuard implements CanActivate {
    constructor(
        private _location: Location,
        private _http: HttpService,
        private _auth: AuthService
    ) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        // Get the employee_document by ID to see if they exist.
        let emp_doc: Object = (
            await this._http.getLocal(
                `admin/documents/employee/?id=${next.paramMap.get('id')}`
            )
        ).body;

        // If they do not exist, then go back a route.
        if (emp_doc.hasOwnProperty('success') && !emp_doc['success']) {
            this._location.back();
            return false;
        }

        let user: Object = await this._auth.getSession();

        // Check if the user should be able to access this document.
        if (user['type'] !== 'admin' && emp_doc['employee'] !== user['emp_id']) {
            this._location.back();
            return false;
        }

        return true;
    }
}
