<loading
    *ngIf="loading"
    [text]="loadingText"
    [opacity]="loadingOpacity"
></loading>
<h1 mat-dialog-title class="center transaction-title">Complete Transaction</h1>
<div mat-dialog-content class="scrollbar transaction-content center-btn">
    <!-- Payment Method -->
    <div class="payment-method w100 h100" *ngIf="state === 'payment_method'">
        <div class="amount-due">
            <p class="center">
                {{ data.transaction.total | currency }}
            </p>
        </div>
        <div class="cash-or-card flex jcc">
            <div class="cash-or-card-item">
                <p class="center">Cash</p>
                <button
                    mat-raised-button
                    class="w100"
                    color="primary"
                    (click)="cash()"
                >
                    <mat-icon class="payment-method-icon">
                        attach_money
                    </mat-icon>
                </button>
            </div>

            <div class="cash-or-card-item">
                <p class="center">Card</p>
                <button
                    mat-raised-button
                    class="w100"
                    color="primary"
                    (click)="card()"
                >
                    <mat-icon class="payment-method-icon">
                        credit_card
                    </mat-icon>
                </button>
            </div>
        </div>

        <div class="flex w100 tender-cancel-button-div">
            <button
                mat-raised-button
                color="warn"
                class="w100"
                (click)="dialogRef.close(undefined)"
            >
                Cancel
            </button>
        </div>
    </div>

    <!-- Gift Cards -->
    <div class="gift-cards w100 h100" *ngIf="state === 'gift_cards'">
        <div class="amount-due">
            <p class="center">Gift Cards</p>
        </div>

        <div
            class="mb15"
            *ngIf="purchasedGiftCards && purchasedGiftCards.length > 0"
        >
            <div
                *ngFor="
                    let purchasedGiftCard of purchasedGiftCards;
                    let i = index
                "
            >
                <p class="center">{{ purchasedGiftCard['itemName'] }}</p>
                <h2 class="center">
                    {{ purchasedGiftCard['amount'] | currency }}
                </h2>
                <p class="center" *ngIf="purchasedGiftCard['giftcard']">
                    {{ purchasedGiftCard['giftcard']['barcode'] }}
                </p>

                <mat-form-field class="w100">
                    <input
                        matInput
                        id="gift-card-input-{{ i }}"
                        placeholder="Gift Card"
                        [(ngModel)]="purchasedGiftCard['giftCardInput']"
                        (keyup.enter)="nextGiftCardInput(i)"
                    />
                </mat-form-field>
                <p class="red-text" *ngIf="purchasedGiftCard['error']">
                    {{ purchasedGiftCard['error'] }}
                </p>
            </div>
        </div>

        <div class="flex w100 final-button-div">
            <button
                mat-raised-button
                color="primary"
                class="w100 m-0-10"
                (click)="enterPaymentMethodState()"
                [disabled]="!purchasedGiftCardsFilled()"
            >
                Payment
            </button>
            <button
                mat-raised-button
                color="warn"
                class="w100 m-0-10"
                (click)="dialogRef.close(undefined)"
            >
                Cancel
            </button>
        </div>
    </div>

    <!-- Cash Tendering -->
    <div class="cash-tendering w100 h100" *ngIf="state === 'cash_tendering'">
        <div class="amount-due amount-due-auto">
            <p class="center">
                {{ data.transaction.total | currency }}
            </p>
        </div>

        <div class="common-cash-values jcc">
            <button
                mat-raised-button
                color="primary"
                (click)="tender(cashTendered.exact)"
            >
                {{ cashTendered.exactDisplay }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="tender(cashTendered.nearestDollar)"
            >
                {{ cashTendered.nearestDollar }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="tender(cashTendered.nearestFive)"
            >
                {{ cashTendered.nearestFive }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="tender(cashTendered.nearestTwenty)"
            >
                {{ cashTendered.nearestTwenty }}
            </button>
            <button mat-raised-button color="primary" (click)="tender(-1)">
                Custom
            </button>
        </div>

        <div class="flex w100 tender-cancel-button-div">
            <button
                mat-raised-button
                color="warn"
                class="w100"
                (click)="dialogRef.close(undefined)"
            >
                Cancel
            </button>
        </div>
    </div>

    <!-- Await Card -->
    <div class="await-card w100 h100" *ngIf="state === 'await_card'">
        <div class="amount-due">
            <p class="center">
                {{ data.transaction.total | currency }}
            </p>
        </div>

        <div class="card-explanation">
            <p class="center">
                Transaction has completed correctly, click the finish button
                below.
            </p>
        </div>

        <div class="flex w100 final-button-div">
            <button
                mat-raised-button
                color="primary"
                class="w100 m-0-10"
                (click)="dialogRef.close(true)"
            >
                Finish
            </button>
        </div>
    </div>

    <!-- Cash Final -->
    <div class="cash-final w100 h100" *ngIf="state === 'cash_final'">
        <div class="amount-to-give">
            <h1 class="center">Give</h1>
            <p class="center">
                {{ data.transaction.change_given | currency }}
            </p>
            <h1 class="center">To The Customer</h1>
        </div>

        <div class="flex w100 final-button-div">
            <button
                mat-raised-button
                color="primary"
                class="w100"
                (click)="dialogRef.close(successTimerStart || true)"
            >
                Finish
            </button>
        </div>
    </div>

    <!-- Transaction Error -->
    <div
        class="transaction-error cash-final w100 h100"
        *ngIf="state === 'transaction_error'"
    >
        <h1 class="center mb15">
            {{ transactionError }}
        </h1>

        <div class="flex w100 final-button-div">
            <button
                mat-raised-button
                color="warn"
                class="w100"
                (click)="dialogRef.close(undefined)"
            >
                Close
            </button>
        </div>
    </div>

    <div
        #cfsRendererDiv
        class="cfs-templates w100 h100"
        [ngClass]="{ 'cfs-templates-hidden': state !== 'template' }"
    ></div>
</div>
