<loading *ngIf="loading" [opacity]="0.5"></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container print" *ngIf="employee">
    <div class="main-content remove-padding print">
        <h1 class="center">Staff Pass</h1>
        <div class="w100 mb15">
            <button
                mat-raised-button
                class="center-btn"
                onclick="window.print()"
                color="primary"
            >
                <mat-icon>print</mat-icon>
                Print/Save
            </button>
        </div>

        <div class="staff-pass print">
            <img
                class="print staff-pass__bg"
                src="https://api.fluiddynamics.ca/img/career-bg.jpg"
            />

            <img class="print staff-pass__photo" [src]="photo" />

            <p class="print staff-pass__first-name">
                {{ employee['preferred_name'] || employee['first_name'] }}
            </p>
            <p class="print staff-pass__last-name">
                {{ employee['last_name'] }}
            </p>

            <div class="print staff-pass__staff">
                <img
                    class="print"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNUrdn9HwAEhwJd2ibc1wAAAABJRU5ErkJggg=="
                />

                <p class="print">Staff</p>
                <p class="print">Valid Only at PEI Locations</p>
            </div>

            <div class="print staff-pass__barcode">
                <canvas
                    class="print"
                    id="staff-pass-barcode"
                    jsbarcode-format="code128"
                    [attr.jsbarcode-value]="barcode"
                    jsbarcode-height="50"
                    jsbarcode-width="1"
                    jsbarcode-margin="0"
                    jsbarcode-fontsize="15"
                ></canvas>
            </div>

            <img
                class="print staff-pass__mfg-logo"
                src="https://api.fluiddynamics.ca/img/mfg-logo.png"
            />
        </div>
    </div>
</div>
