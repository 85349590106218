<h1 mat-dialog-title>Editing Location</h1>
<div mat-dialog-content class="scrollbar">
    <ng-form #form="ngForm">
        <mat-form-field class="w100">
            <input
                matInput
                placeholder="Name"
                [(ngModel)]="data['category']['name']"
                required
                name="name"
            />
        </mat-form-field>
        <mat-form-field class="w100">
            <input
                matInput
                type="number"
                placeholder="Discount"
                [(ngModel)]="data['category']['discount']"
                min="0"
                step="0.0001"
                pattern="^0\.(?:[0-9]{1,4})$"
                required
                name="discount"
            />
        </mat-form-field>
    </ng-form>
</div>
<div mat-dialog-actions class="fr">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        [disabled]="attemptingClose"
    >
        Save
    </button>
    <button
        mat-raised-button
        (click)="askClose()"
        color="warn"
        [disabled]="attemptingClose"
    >
        Cancel
    </button>
</div>
