<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Choose Document</h1>
<div mat-dialog-content class="scrollbar">
    <mat-tab-group
        (selectedIndexChange)="tabChange($event)"
        [selectedIndex]="startIndex"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>info</mat-icon>
                &nbsp;Info
            </ng-template>

            <div>
                <div class="detail mb15">
                    <div class="detail-mobile">
                        <div>
                            <h4>ID</h4>
                            <p>{{ data.drawer['id'] }}</p>
                        </div>
                        <div>
                            <h4>Facility</h4>
                            <p>{{ data.drawer['facility']['name'] }}</p>
                        </div>
                        <div>
                            <h4>Location</h4>
                            <p>{{ data.drawer['location']['name'] }}</p>
                        </div>
                    </div>
                    <div class="detail-mobile">
                        <div>
                            <h4>User</h4>
                            <p>
                                {{ data.drawer['user']['first_name'] }}
                                {{ data.drawer['user']['last_name'] }}
                            </p>
                        </div>
                        <div>
                            <h4>Start</h4>
                            <p>{{ data.drawer['start_readable'] }}</p>
                        </div>
                        <div>
                            <h4>End</h4>
                            <p>
                                {{
                                    data.drawer['end_readable']
                                        ? data.drawer['end_readable']
                                        : 'Not Closed'
                                }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="center">
                    <h4>Cash Actual</h4>
                    <p>{{ data.drawer['cash_actual'] | currency }}</p>
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="dialogRef.close('cash_actual')"
                    >
                        Set Cash Actual
                    </button>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>assessment</mat-icon>
                &nbsp;Report
            </ng-template>

            <div>
                <button
                    mat-raised-button
                    color="primary"
                    class="mb15 mt15 center-btn no-print"
                    (click)="generateReport()"
                >
                    Generate Report
                </button>

                <button
                    mat-raised-button
                    class="center-btn mb15 no-print"
                    onclick="window.print()"
                    color="primary"
                    *ngIf="report"
                >
                    <mat-icon>print</mat-icon>
                    Print
                </button>

                <div *ngIf="report" class="center report print print-in">
                    <h2 class="print">Drawer Report</h2>
                    <p class="print">
                        {{ report['start'] }} - {{ report['end'] }}
                    </p>
                    <p class="print">{{ report['facility']['name'] }}</p>
                    <p class="print">
                        {{ report['location']['name'] }} ({{
                            report['location']['id']
                        }})
                    </p>
                    <p class="print mb15">
                        Drawer ID: {{ report['drawer']['id'] }}
                    </p>

                    <p class="print mb15">
                        Attendant: {{ report['drawer']['first_name'] }}
                        {{ report['drawer']['last_name'] }}
                    </p>
                    <p class="print mb15">
                        Transactions: {{ report['amtTransactions'] }}
                    </p>

                    <h3 class="print">Passes</h3>
                    <p class="print">
                        Season Pass Groups: {{ report['seasonPassGroups'] }}
                    </p>
                    <p class="print">
                        Season Passes: {{ report['seasonPasses'] }}
                    </p>
                    <p class="print">
                        Weekly Pass Groups: {{ report['weeklyPassGroups'] }}
                    </p>
                    <p class="print mb15">
                        Weekly Passes: {{ report['weeklyPasses'] }}
                    </p>

                    <h3 class="print">Voids/Refunds</h3>
                    <table class="print mb15">
                        <tbody class="print">
                            <tr class="print">
                                <td class="print"></td>
                                <td class="print">$$$</td>
                            </tr>
                            <tr class="print">
                                <td class="print">
                                    Refunds ({{ report['amtRefunds'] }})
                                </td>
                                <td class="print">
                                    {{ report['refundTotal'] }}
                                </td>
                            </tr>
                            <tr class="print">
                                <td class="print">
                                    Voids ({{ report['amtVoided'] }})
                                </td>
                                <td class="print">
                                    {{ report['voidedTotal'] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="print">Totals</h3>
                    <table class="print mb15">
                        <tbody class="print">
                            <tr class="print">
                                <td class="print"></td>
                                <td class="print">$$$</td>
                            </tr>
                            <tr class="print">
                                <td class="print">Subtotal</td>
                                <td class="print">{{ report['subtotal'] }}</td>
                            </tr>
                            <tr class="print">
                                <td class="print">Tax</td>
                                <td class="print">{{ report['tax'] }}</td>
                            </tr>
                            <tr class="print">
                                <td class="print">Total</td>
                                <td class="print">{{ report['total'] }}</td>
                            </tr>
                            <tr class="print">
                                <td class="print">Cash Rounding</td>
                                <td class="print">
                                    {{ report['cashRounding'] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="print">Other Totals</h3>
                    <table class="print mb15">
                        <tbody class="print">
                            <tr class="print">
                                <td class="print">Type</td>
                                <td class="print">$$$</td>
                            </tr>
                            <tr class="print">
                                <td class="print">
                                    Cash ({{ report['amtCash'] }})
                                </td>
                                <td class="print">{{ report['cashTotal'] }}</td>
                            </tr>
                            <tr class="print">
                                <td class="print">
                                    Gift Card ({{ report['amtGiftCards'] }})
                                </td>
                                <td class="print">
                                    {{ report['giftCardTotal'] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="print">Card Type Totals</h3>
                    <table class="print">
                        <tbody class="print">
                            <tr class="print">
                                <td class="print">Type</td>
                                <td class="print">$$$</td>
                            </tr>

                            <tr
                                class="print"
                                *ngFor="let cardType of report['cardTotals']"
                            >
                                <td class="print">
                                    {{ cardType['name'] }} ({{
                                        cardType['amount']
                                    }})
                                </td>
                                <td class="print">{{ cardType['total'] }}</td>
                            </tr>
                            <tr class="print total-tr">
                                <td class="print">
                                    Total ({{ report['amtCard'] }})
                                </td>
                                <td class="print">{{ report['cardTotal'] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close()" color="warn">
        Cancel
    </button>
</div>
