import { Component, OnInit, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Subscription } from 'rxjs';
import { MiscService } from 'src/app/services/misc.service';
import { AuthService } from 'src/app/services/auth.service';

import cloneDeep from 'lodash-es/cloneDeep';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
    selector: 'app-createeditreservations',
    templateUrl: './createeditreservations.component.html',
    styleUrls: ['./createeditreservations.component.scss'],
})
export class CreateeditreservationsComponent implements OnInit {
    backdropSubscription: Subscription;
    attemptingClose: boolean = false;
    reservation: Object;
    timePickerTheme: NgxMaterialTimepickerTheme;

    constructor(
        public dialogRef: MatDialogRef<CreateeditreservationsComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _dialog: MatDialog,
        private _auth: AuthService,
        private _snackbar: SnackbarService
    ) {
        // Disable close for esc and backdrop.
        this.dialogRef.disableClose = true;

        // Subscribe to backdrop clicks to make a check if there were changes.
        this.backdropSubscription = this.dialogRef
            .backdropClick()
            .subscribe((_) => this.askClose());

        // If sent a reservation, set it to reservation.
        if (this.data.hasOwnProperty('reservation')) {
            this.reservation = cloneDeep(this.data['reservation']);
        }
    }

    async ngOnInit() {
        // Set the theme.
        this.timePickerTheme = await this._misc.getTimePickerTheme();
    }

    addSlot() {
        this.reservation['slots'].push({
            start: null,
            end: null,
            max_people: null,
            partner_inventory: 0,
            open: true,
            partner_accessible: true,
            web_taken: 0,
            partner_taken: 0,
            taken: 0,
        });
    }

    removeSlot(index: number) {
        // Only if there is less than 1 taken.
        if (this.reservation['slots'][index]['taken'] < 1) {
            this.reservation['slots'].splice(index, 1);
        }
    }

    hasChanges() {
        return (
            this.data.hasOwnProperty('reservation') &&
            JSON.stringify(this.reservation) !==
                JSON.stringify(this.data['reservation'])
        );
    }

    async askClose() {
        this.attemptingClose = true;

        if (this.hasChanges()) {
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: 'Confirm Close',
                    content:
                        'Are you sure you want to close the dialog? There are unsaved changes.',
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) {
                this.attemptingClose = false;
                return;
            }
        }

        // Unsubscribe from backdrop clicks and close the dialog.
        this.backdropSubscription.unsubscribe();
        this.dialogRef.close(undefined);
    }

    save() {
        // Check if we are missing any information.
        if (
            !this.reservation['date'] ||
            !this.reservation['facility'] ||
            !this.reservation['slots'] ||
            this.reservation['slots'].length < 1 ||
            this.reservation['slots'].some(
                (slot) =>
                    !slot['start'] ||
                    !slot['end'] ||
                    !slot.hasOwnProperty('max_people') ||
                    slot['max_people'] < 0 ||
                    !slot.hasOwnProperty('partner_inventory') ||
                    slot['partner_inventory'] < 0 ||
                    !slot.hasOwnProperty('open') ||
                    !slot.hasOwnProperty('partner_accessible')
            )
        ) {
            this._snackbar.defaultSnackbar(
                'Please fill out all required information and at least 1 slot.'
            );
            return;
        }

        // Unsubscribe from backdrop clicks.
        this.backdropSubscription.unsubscribe();

        // If bypassing the change check on saving.
        if (
            this.data.hasOwnProperty('bypassSaveChange') &&
            this.data['bypassSaveChange']
        ) {
            this.dialogRef.close(this.reservation);
            return;
        }

        // Depending on changes, close with information.
        if (this.hasChanges()) {
            this.dialogRef.close(this.reservation);
        } else {
            this.dialogRef.close(undefined);
        }
    }
}
