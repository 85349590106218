import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../services/http.service';

import * as dayjs from 'dayjs';
import { MiscService } from '../services/misc.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
    selector: 'app-partner-pay',
    templateUrl: './partner-pay.component.html',
    styleUrls: ['./partner-pay.component.scss'],
})
export class PartnerPayComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Resolve variables.
    payment: Object;

    // Data Table.
    invoicesDataSource: MatTableDataSource<Object>;

    // Payment variables.
    total: number = 0;
    paymentInfo = {
        email: '',
        first_name: '',
        last_name: '',
        cardNumber: '',
        nameOnCard: '',
        expirationDate: '',
        securityCode: '',
    };
    showSuccess: boolean = false;

    constructor(
        public _misc: MiscService,
        private _route: ActivatedRoute,
        private _title: Title,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {
        this.payment = this._route.snapshot.data.payment;
    }

    ngOnInit() {
        // If no payment.
        if (!this.payment) {
            window.location.assign('/');
            return;
        }

        // Set the title.
        this._title.setTitle('Partner Pay - FluidDynamics');

        // Set the totals.
        if (this.payment['invoices']) {
            this.total = this.payment['invoices'].reduce(
                (prev, curr) => prev + +curr['invoice']['total'],
                0
            );

            // Create the data source.
            this.invoicesDataSource = new MatTableDataSource(
                this.payment['invoices']
            );
        }
    }

    async pay() {
        // If cannot pay.
        if (!this.canPay) return;

        this.loading = true;

        // Call the API.
        const resp = await this._http.postLocal('partners/invoice/pay/', {
            id: this.payment['id'],
            ...this.paymentInfo,
        });

        this.loading = false;

        // If success (204), reload.
        if (resp.status === 204) {
            this.showSuccess = true;
            setTimeout(() => {
                window.location.reload();
                return;
            }, 10000);
            return;
        }

        // If a 400, we need to invalidate the payment.
        if (resp.status === 400) {
            this.payment['invalid'] = true;
            return;
        }

        // Else, there was an error.
        this._snackbar.defaultSnackbar(resp.body['error']);
    }

    get canPay() {
        // If already paid.
        if (this.payment['completed']) return false;

        // If invalid payment.
        if (this.payment['invalid']) return false;

        // If not a valid email.
        if (!this._misc.emailRegex.test(this.paymentInfo.email)) return false;

        // If not a valid first name.
        if (
            !this.paymentInfo.first_name ||
            this.paymentInfo.first_name.length > 50
        ) {
            return false;
        }

        // If not a valid last name.
        if (
            !this.paymentInfo.last_name ||
            this.paymentInfo.last_name.length > 50
        ) {
            return false;
        }

        // If not valid card number.
        if (!this.luhnAlgorithm(this.paymentInfo.cardNumber)) return false;

        // If no name on card.
        if (!this.paymentInfo.nameOnCard) return false;

        // If not a valid expiration date.
        if (!/^\d{2}\/\d{2}$/.test(this.paymentInfo.expirationDate)) {
            return false;
        }

        // If expired card.
        const firstTwoInYear = dayjs().format('YYYY').slice(0, 2);
        const expiry = dayjs(
            `${firstTwoInYear}${this.paymentInfo.expirationDate.slice(3)}`
        )
            .add(+this.paymentInfo.expirationDate.slice(0, 2), 'month')
            .endOf('month');
        if (dayjs().isAfter(expiry)) return false;

        // If invalid security code.
        if (!/^\d{3,4}$/.test(this.paymentInfo.securityCode)) return false;

        // We can pay.
        return true;
    }

    luhnAlgorithm(card: string) {
        // Gotten from https://gist.github.com/ShirtlessKirk/2134376#gistcomment-158290 with small modifications.
        if (!card) return false;

        const nums = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

        // Replace spaces in card number.
        card = card.replace(/ /g, '');

        let s = 0;
        for (let i = card.length, b = 1, v; i; ) {
            v = parseInt(card.charAt(--i), 10);
            s += (b ^= 1) ? nums[v] : v;
        }

        return s && 0 === s % 10;
    }
}
