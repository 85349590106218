<h1 mat-dialog-title>Gift Card Information</h1>
<div mat-dialog-content>
    <p class="mb15">Scan a gift card to show the balance.</p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Gift Card"
            [(ngModel)]="card"
            (keyup.enter)="search()"
            required
        />
    </mat-form-field>

    <div class="flex-panel-entry jcc aic mb15" *ngIf="loading">
        <div class="mini-spinner flex jcc">
            <mat-icon [inline]="true" color="primary">sync</mat-icon>
        </div>
        Loading Information
    </div>

    <div class="mb15" *ngIf="!loading && giftcard">
        <h3 class="center">Gift Card</h3>
        <p class="center mb15">{{ giftcard['barcode'] }}</p>

        <h3 class="center">Balance</h3>
        <p class="center mb15">
            {{ giftcard['balance'] | currency }}
        </p>

        <button
            mat-raised-button
            color="primary"
            class="center-btn"
            (click)="printBalance()"
        >
            Print Balance
        </button>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
