<h1 mat-dialog-title class="center numpad-title">{{ data.title }}</h1>
<div mat-dialog-content class="scrollbar numpad-content center-btn">
    <p class="numpad-value w100 center">
        {{ data.value }}
    </p>

    <div class="numpad pcc">
        <button mat-raised-button (click)="add('1')">1</button>
        <button mat-raised-button (click)="add('2')">2</button>
        <button mat-raised-button (click)="add('3')">3</button>
        <button mat-raised-button (click)="remove()" class="numpad-icon">
            <mat-icon>backspace</mat-icon>
        </button>
        <button mat-raised-button (click)="add('4')">4</button>
        <button mat-raised-button (click)="add('5')">5</button>
        <button mat-raised-button (click)="add('6')">6</button>
        <button mat-raised-button class="numpad-hidden"></button>
        <button mat-raised-button (click)="add('7')">7</button>
        <button mat-raised-button (click)="add('8')">8</button>
        <button mat-raised-button (click)="add('9')">9</button>
        <button mat-raised-button class="numpad-hidden"></button>
        <button mat-raised-button (click)="add('0')">0</button>
        <button mat-raised-button (click)="add('00')">00</button>
        <button mat-raised-button class="numpad-hidden"></button>
        <button mat-raised-button (click)="clear()">C</button>
    </div>
</div>
<div mat-dialog-actions class="flex numpad-actions w100">
    <button
        mat-raised-button
        (click)="save()"
        color="primary"
        class="w100"
        [disabled]="data.validCash && !validCash()"
    >
        Save Choice
    </button>
    <button
        mat-raised-button
        (click)="dialogRef.close()"
        color="warn"
        class="w100"
    >
        Cancel
    </button>
</div>
