<h1 mat-dialog-title class="center pick-title">Choose Attraction</h1>
<div mat-dialog-content class="scrollbar pick-content">
    <img
        [src]="_http.API_URL + '/pos-attractions/' + attraction + '.png'"
        *ngFor="let attraction of attractions"
        (click)="choose(attraction)"
    />
</div>
<button
    mat-raised-button
    class="center-btn mt15"
    color="primary"
    (click)="choose('Scan For None')"
>
    Scan For None
</button>
