<!-- Contextmenu -->
<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
    <button mat-menu-item oncontextmenu="return false;" (click)="editProduct()">
        Edit
    </button>
    <button
        mat-menu-item
        oncontextmenu="return false;"
        (click)="deleteProduct()"
    >
        Delete
    </button>
</mat-menu>

<!-- Create Product -->
<mat-expansion-panel class="mb15">
    <mat-expansion-panel-header>Create Product</mat-expansion-panel-header>

    <mat-divider class="thick-divider mb15"></mat-divider>
    <h2 class="center mb15">Main Properties</h2>
    <mat-divider class="thick-divider mb15"></mat-divider>

    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                type="number"
                placeholder="Price"
                [(ngModel)]="newProduct.price"
                min="0"
                step="0.0001"
                pattern="^[0-9]+(?:\.[0-9]{1,4})?$"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Description"
                [(ngModel)]="newProduct.description"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-select
                placeholder="Facility"
                [(ngModel)]="newProduct.facility"
                (selectionChange)="facilityChange()"
                required
            >
                <mat-option
                    *ngFor="let facility of facilities"
                    [value]="facility['abbr']"
                >
                    {{ facility['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select
                placeholder="Template"
                [(ngModel)]="newProduct.template"
                (selectionChange)="templateChange()"
            >
                <mat-option
                    *ngFor="let template of templates"
                    [value]="template['id']"
                >
                    {{ template['file'] }} ({{ template['id'] }})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="Product Type"
                [(ngModel)]="newProduct.product_type"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Height Code"
                [(ngModel)]="newProduct.height_code"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Redeem At"
                [(ngModel)]="newProduct.redeem_at"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                type="number"
                placeholder="Party Size"
                [(ngModel)]="newProduct.party_size"
                (change)="partySizeChange()"
                min="0"
                pattern="^[0-9]+$"
                required
            />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                placeholder="Quick 1"
                [(ngModel)]="newProduct.quick_1"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Quick 2"
                [(ngModel)]="newProduct.quick_2"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Quick 3"
                [(ngModel)]="newProduct.quick_3"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Redeem For"
                [(ngModel)]="newProduct.redeem_for"
                required
            />
        </mat-form-field>
    </div>
    <div class="inputs">
        <mat-form-field>
            <input
                matInput
                type="number"
                placeholder="Max Scans"
                [(ngModel)]="newProduct.max_scans"
                min="0"
                pattern="^[0-9]+$"
                required
            />
        </mat-form-field>
        <mat-form-field *ngIf="type === 'Web'">
            <mat-select
                placeholder="Category"
                [(ngModel)]="newProduct.category"
            >
                <mat-option *ngFor="let c of categories" [value]="c['name']">
                    {{ c['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                [matAutocomplete]="foxyCatAuto"
                placeholder="Foxy Category"
                [(ngModel)]="newProduct.foxy_cat"
                (input)="filterFoxyCategories()"
                [required]="newProduct.sales_vector !== 'Consignment'"
            />
            <mat-autocomplete #foxyCatAuto="matAutocomplete" class="scrollbar">
                <mat-option
                    *ngFor="let category of foxyCategoriesFiltered"
                    [value]="category['name']"
                >
                    {{ category['name'] }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-select
                placeholder="Sell At (ex: CBAZ Laser Product)"
                [(ngModel)]="newProduct.sell_at"
                required
            >
                <mat-option *ngFor="let at of sellAt" [value]="at['abbr']">
                    {{ at['full'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="inputs" *ngIf="type === 'Web'">
        <mat-form-field>
            <input
                matInput
                placeholder="Allowed Attributes (CSV)"
                [(ngModel)]="newProduct.allowed_attributes"
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                placeholder="Required Attributes (CSV)"
                [(ngModel)]="newProduct.required_attributes"
            />
        </mat-form-field>
    </div>

    <mat-divider class="thick-divider mb15"></mat-divider>
    <h2 class="center mb15">Product Flags</h2>
    <mat-divider class="thick-divider mb15"></mat-divider>

    <div class="inputs">
        <div class="form-field">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.gatelog_entry"
                >
                    Gatelog Entry
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.active"
                >
                    Active
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.partner_accessible"
                >
                    Partner Accessible
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.create_pass"
                >
                    Create Pass
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="inputs">
        <div class="form-field">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.multiscan"
                >
                    Multiscan
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field" *ngIf="type === 'Web'">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.can_change_quantity"
                >
                    Can Change Quantity
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field" *ngIf="type === 'Web'">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.shipping"
                >
                    Shipping
                </mat-slide-toggle>
            </div>
        </div>
        <div class="form-field" *ngIf="type === 'Web'">
            <div class="form-field-div jcc w100">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="newProduct.giftcard"
                >
                    Gift Card
                </mat-slide-toggle>
            </div>
        </div>
    </div>

    <div *ngIf="type === 'Web'">
        <mat-divider class="thick-divider mb15"></mat-divider>
        <h2 class="center mb15">Product Image (optional)</h2>
        <mat-divider class="thick-divider mb15"></mat-divider>

        <div *ngIf="newProduct.image">
            <h3 class="center mb15">Current Image</h3>
            <img
                class="max-250 center-btn mb15"
                src="https://api.fluiddynamics.ca/v1/pos/vouchers/products/images/?file={{
                    newProduct.image
                }}"
                alt="Product Image"
            />
        </div>

        <div *ngIf="!newProduct.image">
            <h3 class="center">No Image</h3>
            <p class="center">
                Since there is no image for this product, it will use the
                facility logo.
            </p>
        </div>

        <div class="w100 phone-block flex jcc center mb15">
            <button
                mat-raised-button
                color="primary"
                class="m-0-10 phone-mb15"
                (click)="chooseImage()"
            >
                Choose Image
            </button>
            <button
                *ngIf="newProduct.image"
                mat-raised-button
                color="warn"
                class="m-0-10 phone-mb15"
                (click)="newProduct.image = null"
            >
                Delete Image
            </button>
        </div>
    </div>

    <mat-divider class="thick-divider mb15"></mat-divider>
    <h2 class="center mb15">Product Selections</h2>
    <mat-divider class="thick-divider mb15"></mat-divider>

    <div class="flex phone-block">
        <div class="w100">
            <h2 class="center">Valid At</h2>
            <p class="center">
                Double click a selection box to select/deselect all facilities.
            </p>
            <div
                class="inputs mb15"
                *ngIf="
                    !newProduct.valid_at ||
                    newProduct.valid_at.constructor.name === 'Array'
                "
            >
                <mat-selection-list
                    #facilityList
                    color="primary"
                    class="list-w100 scrollbar max-250"
                    [(ngModel)]="newProduct.valid_at"
                    (selectionChange)="
                        _misc.selectionHandler($event, facilityList)
                    "
                >
                    <mat-list-option
                        #facilityListOption
                        *ngFor="let facility of facilities"
                        [value]="facility['fluid_id']"
                        [ngClass]="{
                            'background-radial': facilityListOption.selected
                        }"
                    >
                        {{ facility['name'] }}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="w100">
            <h2 class="center">Taxes</h2>
            <p class="center">
                Double click a selection box to select/deselect all taxes.
            </p>
            <div
                class="inputs mb15"
                *ngIf="
                    !newProduct.taxes ||
                    newProduct.taxes.constructor.name === 'Array'
                "
            >
                <mat-selection-list
                    #taxesList
                    color="primary"
                    class="list-w100 scrollbar max-250"
                    [(ngModel)]="newProduct.taxes"
                    (selectionChange)="
                        _misc.selectionHandler($event, taxesList)
                    "
                >
                    <mat-list-option
                        #taxesListOption
                        *ngFor="let tax of taxes"
                        [value]="tax['id']"
                        [ngClass]="{
                            'background-radial': taxesListOption.selected
                        }"
                    >
                        {{ tax['name'] }} ({{ tax['amount'] * 100 }}%)
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>

    <mat-divider class="thick-divider mb15"></mat-divider>
    <h2 class="center mb15">Product Preview</h2>
    <mat-divider class="thick-divider mb15"></mat-divider>

    <div class="consignment-product-preview" *ngIf="type === 'Consignment'">
        <div class="quick-info">
            {{ newProduct.quick_1 }}-{{ newProduct.quick_2 }}-{{
                newProduct.quick_3
            }}
        </div>
        <p class="product-description">{{ newProduct.description }}</p>
        <p class="product-text">
            Does Not Expire , Valid for:
            {{ newProduct.party_size }} person(s) - Partner Name - Charity<br />{{
                newProduct.redeem_at
            }}
            {{ newProduct.redeem_for }}.
        </p>
        <canvas
            class="barcode top"
            id="consignment-barcode"
            jsbarcode-format="code128"
            jsbarcode-value="VOUCH0000000000"
            jsbarcode-height="30"
            jsbarcode-width="1"
            jsbarcode-margin="0"
            jsbarcode-fontsize="10"
            jsbarcode-background="transparent"
        ></canvas>
    </div>
    <div
        class="web-product-preview scrollbar"
        *ngIf="type === 'Web' && template"
    >
        <img
            src="{{ _http.API_URL }}/templates/{{ template }}"
            alt="Preview Background Image"
            class="template"
        />
        <canvas
            class="barcode web-barcode"
            id="web-barcode"
            jsbarcode-format="code128"
            jsbarcode-value="VOUCH0000000000"
            jsbarcode-height="30"
            jsbarcode-width="1"
            jsbarcode-fontsize="10"
            jsbarcode-background="transparent"
        ></canvas>
        <div class="product-info">
            <h2 class="description">{{ newProduct.description }}</h2>
            <strong>Expires: </strong> Does Not Expire
            <strong>Order Date: </strong> 1970-01-01 00:00:00
            <br />
            <strong>Redeem At: </strong> {{ newProduct.redeem_at }}
            {{ newProduct.redeem_for }}
            <br />
            <strong>Conditions: </strong> This voucher must be validated and
            redeemed at the ticket window. Non-Refundable. No Cash Value. All
            guests are subject to the terms and conditions posted at the
            facility entrance and at each individual attraction. Ride at Your
            Own Risk.
        </div>
    </div>
    <div class="w100 center">
        <button mat-raised-button color="primary" (click)="createProduct()">
            <mat-icon>add</mat-icon>
            Create
        </button>
    </div>
</mat-expansion-panel>

<!-- Sort -->
<mat-form-field class="w100">
    <input
        matInput
        placeholder="Filter"
        [(ngModel)]="filter"
        (input)="applyFilter()"
    />
</mat-form-field>

<!-- Table -->
<mat-table
    #table
    [dataSource]="productsDataSource"
    matSort
    class="mat-elevation-z8 w100"
>
    <!-- Description -->
    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            Description
        </mat-header-cell>
        <mat-cell *matCellDef="let product">
            {{ product['description'] }}
        </mat-cell>
    </ng-container>

    <!-- Price -->
    <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="jfe">
            Price
        </mat-header-cell>
        <mat-cell *matCellDef="let product" class="jfe">
            {{ product['price'] }}
        </mat-cell>
    </ng-container>

    <!-- Quicks Delimited -->
    <ng-container matColumnDef="quicks">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="jfe">
            Quick
        </mat-header-cell>
        <mat-cell *matCellDef="let product" class="jfe">
            {{ product['quick_1'] }}-{{ product['quick_2'] }}-{{
                product['quick_3']
            }}
        </mat-cell>
    </ng-container>

    <mat-header-row
        *matHeaderRowDef="['description', 'price', 'quicks']"
    ></mat-header-row>
    <mat-row
        *matRowDef="let row; columns: ['description', 'price', 'quicks']"
        [phoneContextMenu]="{
            function: rightClick,
            trigger: menuTrigger,
            row: row,
            component: this
        }"
        (contextmenu)="rightClick($event, menuTrigger, row)"
        [ngClass]="{
            'background-radial':
                contextProduct && contextProduct['id'] === row['id']
        }"
    ></mat-row>
</mat-table>
<mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
></mat-paginator>
