import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-posbookings',
    templateUrl: './posbookings.component.html',
    styleUrls: ['./posbookings.component.scss'],
})
export class PosbookingsComponent implements OnInit {
    loading: boolean = false;
    bookingType: Object;
    formattedBookingDate: string;
    product: Object;
    dates: Object = {};
    products: Object = {};

    constructor(
        public dialogRef: MatDialogRef<PosbookingsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService
    ) {}

    ngOnInit() {}

    async fetchDates() {
        this.product = null;
        this.loading = true;

        // Load the products for this booking.
        this.products[this.bookingType['id']] = (
            await this._http.getLocal(
                `pos/bookings/products/?id=${this.bookingType['id']}`
            )
        ).body;

        // Load the dates.
        this.formattedBookingDate = dayjs().format('YYYY-MM-DD');
        this.dates[this.bookingType['abbr']] = (
            await this._http.getLocal(
                `pos/bookings/?booking=${this.bookingType['id']}&date=${this.formattedBookingDate}`
            )
        ).body;

        // Load the correct dates.
        const date = this.dates[this.bookingType['abbr']]['dates'].find(
            (d) => d['date'] === this.formattedBookingDate && d['open'] == '1'
        );

        // If no date found.
        if (!date) {
            this.bookingType = null;
            this.loading = false;
            return;
        }

        // Get starting values.
        this.dates[this.bookingType['abbr']]['times'] = [];
        let start: number = dayjs(
            `${date['date']} ${date['open_time']}`
        ).unix();
        const end: number = dayjs(
            `${date['date']} ${date['close_time']}`
        ).unix();

        // Loop to get the values.
        while (start < end) {
            // Get the dayjs object.
            const d = dayjs.unix(start);
            const timeInterval = +this.dates[this.bookingType['abbr']]['type'][
                'time_interval'
            ];
            const timeBreak = +this.dates[this.bookingType['abbr']]['type'][
                'time_break'
            ];
            const totalTime = timeInterval + timeBreak;

            // Check if valid.
            let blocked: boolean = false;
            for (
                let i = 0;
                i < this.dates[this.bookingType['abbr']]['blocking'].length;
                i++
            ) {
                if (
                    start + totalTime >
                        this.dates[this.bookingType['abbr']]['blocking'][i][
                            'start'
                        ] &&
                    start <
                        this.dates[this.bookingType['abbr']]['blocking'][i][
                            'end'
                        ]
                ) {
                    blocked = true;
                }
            }

            // Check if taken is already set.
            let taken: boolean = false;
            const dateTime = d.format('YYYY-MM-DD HH:mm:ss');
            const max =
                this.bookingType['max_bookings'] != null
                    ? +this.bookingType['max_bookings']
                    : +this.bookingType['max_attendance'];

            // If has taken and taken has this date and if max_bookings then get the length, else get the reduced party_size.
            if (
                this.dates[this.bookingType['abbr']].hasOwnProperty('taken') &&
                this.dates[this.bookingType['abbr']]['taken'].hasOwnProperty(
                    dateTime
                ) &&
                (this.bookingType['max_bookings'] != null
                    ? this.dates[this.bookingType['abbr']]['taken'][dateTime]
                          .length
                    : this.dates[this.bookingType['abbr']]['taken'][
                          dateTime
                      ].reduce((prev, curr) => prev + +curr['party_size'], 0) >=
                      max)
            ) {
                taken = true;
            }

            // Push the time to the date.
            const endSlot = d.add(totalTime, 'minute');
            const time = `${d.format('HH:mm')} - ${endSlot
                .subtract(timeBreak, 'minute')
                .format('HH:mm')}`;
            const timeTranslate = time
                .match(/(\d{1,2}):(\d{1,2}) \- (\d{1,2}):(\d{1,2})/)
                .slice(1)
                .reduce((prev, curr, i) => {
                    prev[i] = curr;
                    return prev;
                }, {});
            this.dates[this.bookingType['abbr']]['times'].push({
                time,
                timeTranslate,
                timeTaken: `${this.formattedBookingDate} ${timeTranslate[0]}:${timeTranslate[1]}:00`,
                valid: !blocked && !taken,
                blocked,
            });

            start = endSlot.unix();
        }

        this.loading = false;
    }

    chooseTime(time: Object) {
        // Check if valid.
        if (!time['valid'] || !this.product) return;

        // Get the product.
        this.dialogRef.close({
            booking: this.bookingType,
            date: this.formattedBookingDate,
            time,
            product: this.product,
            taken:
                this.dates[this.bookingType['abbr']].hasOwnProperty('taken') &&
                this.dates[this.bookingType['abbr']]['taken'].hasOwnProperty(
                    time['timeTaken']
                )
                    ? this.dates[this.bookingType['abbr']]['taken'][
                          time['timeTaken']
                      ]
                    : [],
        });
    }
}
