import { Injectable, isDevMode } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor() {}

    loadClient(): Promise<any> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://${
                isDevMode() ? 'dev.ws' : 'ws'
            }.fluiddynamics.ca/socket.io/socket.io.js`;
            script.type = 'text/javascript';
            script.id = 'socketioclient_fddsl';
            script.async = true;
            script.addEventListener('load', (_) => resolve(true));
            script.addEventListener('error', (_) => resolve(null));

            document.head.appendChild(script);
        });
    }

    unloadClient(socket: any = null) {
        // Close the socket.
        if (socket) socket.close();

        // Remove element from the page.
        const element = document.getElementById('socketioclient_fddsl');
        if (element) document.head.removeChild(element);
    }

    connect(
        uri: string = 'fluid',
        channelOpts: Object,
        c: Function
    ): Promise<any> {
        // If fluid.
        if (uri === 'fluid') {
            uri = `https://${isDevMode() ? 'dev.ws' : 'ws'}.fluiddynamics.ca`;
        }

        return new Promise((resolve, reject) => {
            const socket = (window as any).io(uri);
            socket.on('connect', () => {
                // Emit to join the channel immediately.
                socket.emit('channelJoin', {
                    ...channelOpts,
                    auth: '4%6kP02wA1AFAjfrsOH3mD1a',
                });

                c(socket);
                resolve(socket);
            });
        });
    }
}
