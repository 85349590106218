<loading *ngIf="loading" [opacity]="0.5"></loading>
<h1 mat-dialog-title>Bookings</h1>
<div mat-dialog-content>
    <div class="flex">
        <mat-form-field class="w100 m-0-10">
            <mat-select
                placeholder="Booking Type"
                [(ngModel)]="bookingType"
                (selectionChange)="fetchDates()"
                required
            >
                <mat-option
                    *ngFor="let type of data['bookingTypes']"
                    [value]="type"
                >
                    {{ type['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field
            class="w100 m-0-10"
            *ngIf="bookingType && products.hasOwnProperty(bookingType['id'])"
        >
            <mat-select
                placeholder="Booking Product"
                [(ngModel)]="product"
                required
            >
                <mat-option
                    *ngFor="let prod of products[bookingType['id']]"
                    [value]="prod"
                >
                    {{ prod['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <p
        class="center"
        *ngIf="bookingType && bookingType['max_bookings'] != null"
    >
        Max Groups Per Slot: {{ bookingType['max_bookings'] }}
    </p>
    <p
        class="center"
        *ngIf="bookingType && bookingType['max_attendance'] != null"
    >
        Max People Per Slot: {{ bookingType['max_attendance'] }}
    </p>

    <div
        class="bookings-calendar"
        *ngIf="bookingType && dates.hasOwnProperty(bookingType['abbr'])"
    >
        <div
            class="flex"
            *ngFor="let time of dates[bookingType['abbr']]['times']"
        >
            <div>
                <p>
                    {{ time['timeTranslate'][0] }}:{{
                        time['timeTranslate'][1]
                    }}
                </p>
            </div>
            <div
                *ngIf="
                    !dates[bookingType['abbr']].hasOwnProperty('taken') ||
                    !dates[bookingType['abbr']]['taken'].hasOwnProperty(
                        time['timeTaken']
                    )
                "
                [ngClass]="{ pointer: time['valid'] }"
                (click)="chooseTime(time)"
            >
                <div class="booking-blocked" *ngIf="time['blocked']"></div>
            </div>
            <div
                class="flex"
                *ngIf="
                    dates[bookingType['abbr']].hasOwnProperty('taken') &&
                    dates[bookingType['abbr']]['taken'].hasOwnProperty(
                        time['timeTaken']
                    )
                "
                [ngClass]="{
                    pointer: time['valid']
                }"
                (click)="chooseTime(time)"
            >
                <div class="booking-blocked" *ngIf="time['blocked']"></div>

                <!-- List of groups. -->
                <div
                    *ngFor="
                        let group of dates[bookingType['abbr']]['taken'][
                            time['timeTaken']
                        ]
                    "
                >
                    <p>
                        {{ group['first_name'] }} {{ group['last_name'] }} ({{
                            group['party_size']
                        }})
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="fr">
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Cancel
    </button>
</div>
