import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as dayjs from 'dayjs';

interface Data {
    reservations: Object[];
    vouchers: Object[];
}

@Component({
    selector: 'app-reschedulereservationadmin',
    templateUrl: './reschedulereservationadmin.component.html',
    styleUrls: ['./reschedulereservationadmin.component.scss'],
})
export class ReschedulereservationadminComponent implements OnInit {
    date: Date;
    reservation: Object;
    timeSlot: string;
    dateFilter = this.rDateFilter.bind(this);

    constructor(
        public dialogRef: MatDialogRef<ReschedulereservationadminComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {}

    ngOnInit() {}

    confirm() {
        this.dialogRef.close({
            reservation: this.reservation,
            timeSlot: this.timeSlot,
        });
    }

    dateChange() {
        this.reservation = this.getReservation(this.date);
        this.timeSlot = undefined;
    }

    rDateFilter(date: Date) {
        // Get the reservation.
        const reservation = this.getReservation(date);

        // If no reservation or it is not open.
        return (
            reservation &&
            !reservation['slots'].every(
                (slot) =>
                    slot['open'] == '0' || slot['taken'] >= slot['max_people']
            )
        );
    }

    private getReservation(date: Date): Object | undefined {
        // Get month and date from date object.
        const formatted = dayjs(date).format('YYYY-MM-DD');

        // Find the reservation from selected store.
        return this.data.reservations.find((r) => r['date'] === formatted);
    }
}
