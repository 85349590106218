import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SidenavService } from '../../services/sidenav.service';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MiscService } from 'src/app/services/misc.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Notification {
    content: string;
    path: string;
}

@Component({
    selector: 'custom-nav',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() user: Object;
    @Input() disableSidenav?: boolean;
    darkTheme: boolean;
    subscription: Subscription;
    notifications: Notification[] = [];
    offline: boolean = false;
    onNetworkStatusChange = this._onNetworkStatusChange.bind(this);

    // Allowed variables.
    allowed = {
        admin: false,
        hr: false,
        crm: false,
        pos: false,
        partners: false,
        partnersmanage: false,
        'store-groups': false,
    };

    constructor(
        public _auth: AuthService,
        public _misc: MiscService,
        public _sanitizer: DomSanitizer,
        public _loading: LoadingService,
        private _http: HttpService,
        private _sidenav: SidenavService
    ) {
        this.subscription = this._auth.themeChange.subscribe((theme) => {
            this.darkTheme = theme;
        });
    }

    async ngOnInit() {
        this.getAllowed();

        this.darkTheme =
            (await this._auth.getTheme()) === 'dark' ? true : false;

        await this.getNotifications();

        // Event listeners for online/offline and initial function call to check.
        window.addEventListener('offline', this.onNetworkStatusChange);
        window.addEventListener('online', this.onNetworkStatusChange);
        this.onNetworkStatusChange();
    }

    async getNotifications() {
        if (this.user['emp_id']) await this.getDocumentNotifications();
    }

    async getDocumentNotifications() {
        // Get the documents needed to be signed.
        let resp: Object = (
            await this._http.getLocal(
                `admin/documents/employee/?employee=${this.user['emp_id']}`
            )
        ).body;

        if (resp.hasOwnProperty('success') && !resp['success']) return;

        // Filter/push the documents.
        this.notifications.push(
            ...(<Notification[]>(<Object[]>resp)
                .filter((document) => !document['signed'])
                .map((document) => {
                    return {
                        content: `You have an unsigned document named "${document['name']}".`,
                        path: `/documents/${document['id']}`,
                    };
                }))
        );
    }

    async getAllowed() {
        const locations = [
            'admin',
            'hr',
            'pos',
            'partners',
            'partners/manage',
            'crm',
            'store-groups',
        ];
        const resp = (
            await this._http.postLocal('auth/allowed/', { locations })
        ).body;

        for (let i = 0; i < locations.length; i++) {
            const originalLocation = locations[i];

            // Replace slash with nothing and uppercase next letter.
            const slashIndex = locations[i].indexOf('/');
            if (slashIndex > -1) {
                locations[i] = locations[i].replace('/', '');
                locations[i].charAt(slashIndex).toUpperCase();
            }

            // Set variable.
            this.allowed[locations[i]] = resp[originalLocation];
        }
    }

    toggleSidenav() {
        this._sidenav.toggle();
    }

    toggleDark(icon: boolean = false) {
        if (icon) this.darkTheme = !this.darkTheme;
        this._auth.setTheme(this.darkTheme);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    redirectHome() {
        this._loading.setLoading(false);
        this._http.redirectHome();
    }

    _onNetworkStatusChange() {
        this.offline = !navigator.onLine;
    }
}
