import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SidenavService } from 'src/app/services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { PosComponent } from 'src/app/pos/pos/pos.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'pos-sidenav',
    templateUrl: './possidenav.component.html',
    styleUrls: [
        './possidenav.component.scss',
        '../customelements.component.scss',
    ],
})
export class PossidenavComponent implements OnInit {
    darkTheme: boolean;
    opened: boolean = false;
    @Input() pos: PosComponent;
    @Input() location: Object;
    @Input() isPos: boolean;
    @Input() queueManager?: boolean;

    @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

    constructor(private _auth: AuthService, private _sidenav: SidenavService) {}

    async ngOnInit() {
        this.darkTheme = (await this._auth.getTheme()) === 'dark';

        this._sidenav.setSidenav(this.sidenav);
    }

    async toggleTheme(slide: boolean = false) {
        await this._auth.toggleTheme();

        if (!slide) {
            this.darkTheme = !this.darkTheme;
        }
    }

    callFunction(f: string, ...args: any[]) {
        this.pos[f](this.pos, ...args);
        this._sidenav.close();
    }
}
