import {
    Injectable,
    Output,
    EventEmitter,
    HostListener,
    OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionLike } from 'rxjs';

/**
 * This service is used to control the loading of the application.
 * It can enable or disable the loading for navigation, as well as
 * enabling/disabing the UI for setting your theme. Since this
 * handles loading, it also contains a helper function for navigation
 * that turns off the loading screen if true.
 */
@Injectable({
    providedIn: 'root'
})
export class LoadingService implements OnDestroy {
    private loading: boolean;
    private theme: boolean;
    @Output() themeChanged: EventEmitter<boolean> = new EventEmitter();

    private locationSub: SubscriptionLike;

    constructor(private _router: Router, private _location: Location) {
        this.loading = true;
        this.theme = false;

        this.locationSub = this._location.subscribe(
            _ => (this.loading = false)
        );
    }

    ngOnDestroy() {
        this.locationSub.unsubscribe();
    }

    /**
     * This function is the getter for the property `loading`.
     */
    loadingEnabled() {
        return this.loading;
    }

    /**
     * This function is the getter for the property `theme`.
     */
    themeEnabled() {
        return this.theme;
    }

    /**
     * This function is the setter for the property `loading`.
     * Loading handles whether the loading UI is enabled.
     *
     *
     * @param val The new `boolean` value.
     */
    setLoading(val: boolean) {
        this.loading = val;
    }

    /**
     * This function is the setter for the property `theme`.
     * Theme handles whether the theme UI should be shown.
     *
     *
     * @param val The new `boolean` value.
     */
    setTheme(val: boolean) {
        this.theme = val;
        this.themeChanged.emit(val);
    }

    /**
     * This function handles setting the `loading` property along
     * with navigating. Doing this allows popups asking the user
     * if they would like to save their changes before leaving the page.
     *
     *
     * @param location The new location to navigate to.
     * @param loading A `boolean` used to set the `loading` property.
     */
    navigate(location: string, loading: boolean) {
        this.loading = loading;
        this._router.navigate([location]);
    }
}
