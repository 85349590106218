<div class="loader background" [ngStyle]="{ opacity: opacity }">
    <div>
        <mat-icon class="spinner select-none" [inline]="true" color="accent">
            sync
        </mat-icon>
    </div>
    <mat-progress-bar
        class="spinner progress"
        *ngIf="progress"
        mode="determinate"
        [value]="progress"
    ></mat-progress-bar>
    <p class="spinner text" *ngIf="text">{{ text }}</p>
</div>
