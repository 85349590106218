import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { MiscService } from 'src/app/services/misc.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
    selector: 'app-vieweditcart',
    templateUrl: './vieweditcart.component.html',
    styleUrls: ['./vieweditcart.component.scss'],
})
export class VieweditcartComponent implements OnInit {
    // Other variables.
    loading: boolean = false;

    // Cart variables.
    logs: Object[];
    bypassValidation: boolean = false;
    promoCode: string;

    constructor(
        public dialogRef: MatDialogRef<VieweditcartComponent>,
        public _misc: MiscService,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _dialog: MatDialog,
        private _snackbar: SnackbarService,
        private _cdr: ChangeDetectorRef,
        private _http: HttpService
    ) {}

    ngOnInit() {
        this.setupCart();
    }

    get hasShipping() {
        return (
            this.data['cart'] &&
            this.data['cart']['items'] &&
            this.data['cart']['items'].some((item) => item['shipping'])
        );
    }

    async saveFields() {
        await this.patch(
            'Confirm Update',
            'Are you sure you want to update these fields? CAUTION: This could impact a customers experience and should only be used if absolutely needed.',
            {
                id: this.data['cart']['id'],
                fields: JSON.stringify(this.data['cart']['fields']),
            },
            'Successfully updated the carts fields.',
            'An error occurred while attempting to update the carts fields. Make sure you update fields before attempting to save.'
        );
    }

    async removePromoCode() {
        if (
            await this.patch(
                'Remove Promo Code',
                'Are you sure you want to remove the promo code for this cart?',
                {
                    id: this.data['cart']['id'],
                    promo_code: null,
                },
                'Successfully removed the promo code.',
                'An error occurred while attempting to remove the promo code.'
            )
        ) {
            await this.refresh();
        }
    }

    async addPromoCode() {
        // If missing code.
        if (!this.promoCode) return;

        // If bypass.
        if (this.bypassValidation) {
            // Patch the promo code onto the cart.
            await this.patch(
                null,
                null,
                {
                    id: this.data['cart']['id'],
                    promo_code: this.promoCode,
                },
                'Successfully added the promo code.',
                'An error occurred while attempting to add the promo code.'
            );

            // Refresh the cart.
            this.refresh();
            return;
        }

        this.loading = true;

        // If not bypass, call another API.
        const resp = await this._http.postLocal('admin/cart/promo-code/', {
            id: this.data['cart']['id'],
            code: this.promoCode,
        });
        if (resp.status === 204) {
            this._snackbar.defaultSnackbar(
                'Successfully added the promo code.'
            );
            await this.refresh();
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while adding the promo code.'
            );
        }

        this.loading = false;
    }

    async removeItem(item: Object) {
        this.loading = true;

        // Call the API.
        const resp = await this._http.deleteLocal(
            `admin/cart/item/?id=${item['id']}&cart=${this.data['cart']['id']}`
        );

        // Show snackbar based on response.
        if (resp.status === 204) {
            this._snackbar.defaultSnackbar(
                'Successfully removed the item from the cart.'
            );
            await this.refresh();
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to add the item to the cart.'
            );
        }

        this.loading = false;
    }

    async updateQuantity(item: Object, e: Event) {
        // Check if the input is invalid.
        const input = e.target as HTMLInputElement;
        if (!input.validity.valid) {
            input.value = `${item['quantity']}`;
            return;
        }

        // Parse into an int.
        const value = parseInt(input.value);
        if (isNaN(value)) {
            input.value = `${item['quantity']}`;
            return;
        }

        // If the new value is less than 1.
        if (value < 1) return await this.removeItem(item);

        // It greater than 0 and a valid number, update the quantity.
        this.loading = true;
        const resp = await this._http.patchLocal('admin/cart/item/', {
            id: item['id'],
            cart: this.data['cart']['id'],
            quantity: value,
        });

        // Show snackbar based on response.
        if (resp.status === 204) {
            this._snackbar.defaultSnackbar(
                'Successfully updated the quantity for the item.'
            );
            await this.refresh();
        } else {
            this._snackbar.defaultSnackbar(
                'An error occurred while attempting to update the quantity for the item.'
            );
        }

        this.loading = false;
    }

    async patch(
        confirmTitle: string,
        confirmContent: string,
        data: Object,
        successText: string,
        errorText: string
    ) {
        if (confirmTitle && confirmContent) {
            // Ask if they are sure.
            const dialogRef = this._dialog.open(ConfirmComponent, {
                data: {
                    title: confirmTitle,
                    content: confirmContent,
                },
            });

            let ans = await dialogRef.afterClosed().toPromise();

            if (!ans) return;
        }

        this.loading = true;

        // Call the API.
        const resp = await this._http.patchLocal('admin/cart/', data);

        // Show snackbar based on resp.
        if (resp.status === 204) {
            this._snackbar.defaultSnackbar(successText);
        } else {
            this._snackbar.defaultSnackbar(errorText);
        }

        this.loading = false;
        return resp.status === 204;
    }

    async refresh() {
        this.loading = true;

        // Call API to get the cart.
        this.data['cart'] = (
            await this._http.getLocal(
                `admin/cart/?id=${this.data['cart']['id']}`
            )
        ).body;

        // Setup cart again.
        this.setupCart();

        this.loading = false;
    }

    async getBrowserInformation(
        log: Object,
        div: HTMLDivElement,
        _null: boolean = false
    ) {
        this.loading = true;

        log['user_agent_detailed'] = _null
            ? null
            : (
                  await this._http.getLocal(
                      `user-agent/?ua=${log['user_agent']}&detailed`
                  )
              ).body['detailed'];

        this._cdr.detectChanges();
        this._misc.toggleCollapse(div);
        this._cdr.detectChanges();
        this._misc.toggleCollapse(div);

        this.loading = false;
    }

    private async getLog() {
        const resp = (
            await this._http.getLocal(
                `admin/cart/log/?cart=${this.data['cart']['id']}`
            )
        ).body;
        if (resp.hasOwnProperty('success') && !resp['success']) {
            this.logs = [];
        } else {
            this.logs = (resp as Object[]).sort(
                (a, b) => a['time'] - b['time']
            );
        }
    }

    private setupCart() {
        // Get the log.
        this.getLog();

        // Parse the fields if needed from JSON into an object.
        this.data['cart']['fields'] = this.data['cart']['fields']
            ? JSON.parse(this.data['cart']['fields'])
            : {};

        // If missing customer in fields, set it.
        if (!this.data['cart']['fields'].hasOwnProperty('customer')) {
            this.data['cart']['fields']['customer'] = { shipping: {} };
        }

        // If missing review in fields, set it.
        if (!this.data['cart']['fields'].hasOwnProperty('review')) {
            this.data['cart']['fields']['review'] = {};
        }

        // If missing shipping in customer, set it.
        if (
            !this.data['cart']['fields']['customer'].hasOwnProperty('shipping')
        ) {
            this.data['cart']['fields']['customer']['shipping'] = {};
        }
    }
}
