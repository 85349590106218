import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-sacoazerocards',
    templateUrl: './sacoazerocards.component.html',
    styleUrls: ['./sacoazerocards.component.scss'],
})
export class SacoazerocardsComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string = '';
    cards: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<SacoazerocardsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    addCard() {
        // If card is not set or already in cards.
        if (
            !this.card ||
            this.cards.includes(this.card) ||
            !/^[0-9]{6,8}$/.test(this.card)
        )
            return;

        // Add the card.
        this.cards.push(this.card);
        this.card = '';
    }

    removeCard(card: string) {
        // If not in cards.
        if (!this.cards.includes(card)) return;

        // If in cards, remove.
        const index = this.cards.findIndex((c) => c === card);
        if (index > -1) {
            this.cards.splice(index, 1);
        }
    }

    async zero() {
        // Make sure there are cards to zero.
        if (this.cards.length < 1) return;

        this.loading = true;

        // Send the cards to the backend to zero them.
        const resp = await this._http.postLocal('pos/sacoa/zero-cards/', {
            cards: this.cards,
            posId: this.data['posId'],
        });

        // If error.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'An unexpected error has occurred while attempting to zero cards.'
            );
            this.loading = false;
            return;
        }

        // Start snackbar message.
        let message = `Successfully zeroed ${resp.body['success']} card${
            resp.body['success'] === 1 ? '' : 's'
        }`;
        message +=
            resp.body['errors'].length > 0
                ? ` and had an error zeroing ${
                      resp.body['errors'].length
                  } card${
                      resp.body['errors'].length === 1 ? '' : 's'
                  }. The card${
                      resp.body['errors'].length === 1 ? '' : 's'
                  } with errors will stay in the zero list.`
                : '.';
        this._snackbar.durationSnackbar(message, null, 10000);

        // Remove all but the errors from the list.
        this.cards = this.cards.filter((c) => resp.body['errors'].includes(c));

        this.loading = false;
    }
}
