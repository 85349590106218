<h1 mat-dialog-title class="center pick-title">
    Order {{ data.order['web_id'] || data.order['o_id'] }} Vouchers
</h1>
<div mat-dialog-content class="pick-content">
    <div class="vouchers scrollbar">
        <div
            [ngClass]="{
                'background-radial': voucher['selected']
            }"
            class="voucher scrollbar padding-15 pointer center"
            (click)="voucher['selected'] = !voucher['selected']"
            *ngFor="let voucher of data.order['vouchers']"
        >
            <h3 class="mb15">{{ voucher['description'] }}</h3>
            <p *ngIf="voucher['reservation']">
                <span class="bold">Valid On: </span>
                {{ voucher['reservation_date'] }}
                {{ voucher['reservation_time'] }}
            </p>
            <p *ngIf="voucher['booking']">
                <span class="bold">Valid On: </span>
                {{ voucher['booking_time'] }}
            </p>
            <p class="mb15">
                <span class="bold">Participant/Group: </span>
                {{ voucher['first_name'] || data.order['first_name'] }}
                {{ voucher['last_name'] || data.order['last_name'] }}
            </p>
            <p>{{ voucher['v_id'] }}</p>
        </div>
    </div>
    <button
        class="pick-finish-button w100"
        mat-raised-button
        (click)="save()"
        color="primary"
    >
        Finish
    </button>
</div>
