import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpService } from '../services/http.service';

@Component({
    selector: 'app-pagenotfound',
    templateUrl: './pagenotfound.component.html',
    styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {
    constructor(public _http: HttpService, public _location: Location) {}

    ngOnInit() {}
}
