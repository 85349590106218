<loading *ngIf="loading" [opacity]="0.5"></loading>
<custom-nav [user]="user" [disableSidenav]="true"></custom-nav>

<div class="container">
    <div class="main-content">
        <h1 class="center">My Account</h1>

        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
            <!-- Fluid Account Tab -->
            <mat-tab>
                <ng-template mat-tab-label>FluidDynamics Account</ng-template>

                <mat-card>
                    <mat-card-content>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        type="email"
                                        placeholder="Username (email)"
                                        [(ngModel)]="editedUser['username']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc"
                                    (click)="updateFluidSetting('username')"
                                    *ngIf="
                                        user['username'] !==
                                        editedUser['username']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        type="password"
                                        placeholder="Password"
                                        [(ngModel)]="editedUser['password']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc"
                                    (click)="updateFluidSetting('password')"
                                    *ngIf="editedUser['password']"
                                >
                                    Save
                                </button>
                            </div>
                            <div
                                class="w100 flex phone-div m-0-10"
                                *ngIf="
                                    user['type'] === 'admin' ||
                                    user['type'] === 'supervisor'
                                "
                            >
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="PIN"
                                        [(ngModel)]="editedUser['pin']"
                                        pattern="^[0-9]{6}$"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc"
                                    (click)="updateFluidSetting('pin')"
                                    *ngIf="editedUser['pin'] !== user['pin']"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="inputs mb15">
                            <div class="flex col w100">
                                <div class="center phone-mb15">
                                    <h3>Default Theme</h3>
                                    <mat-label
                                        class="mat-slide-toggle-content double-toggle-text"
                                    >
                                        Light&nbsp;
                                    </mat-label>
                                    <mat-slide-toggle
                                        color="primary"
                                        [(ngModel)]="currentTheme"
                                        (change)="
                                            _auth.setTheme(currentTheme, false)
                                        "
                                    ></mat-slide-toggle>
                                    <mat-label
                                        class="mat-slide-toggle-content double-toggle-text"
                                    >
                                        &nbsp;Dark
                                    </mat-label>
                                </div>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateFluidSetting('theme')"
                                    *ngIf="currentTheme !== startingTheme"
                                >
                                    Save
                                </button>
                            </div>
                        </div>

                        <p class="center" *ngIf="user['type'] === 'pos_user'">
                            If you are looking to go to the POS, click
                            <a class="same-color" [routerLink]="['/pos']"
                                >here</a
                            >.
                        </p>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            <!-- Employee Account Tab -->
            <mat-tab *ngIf="employee">
                <ng-template mat-tab-label>Employee Account</ng-template>

                <mat-card class="mb15">
                    <mat-card-content>
                        <h2 class="center">Employee Pass</h2>
                        <!-- <div class="photo">
                            <div
                                class="employee-photo flex col"
                                *ngIf="!editingPhoto"
                            >
                                <img
                                    [src]="
                                        editedEmployee['photo'] ||
                                        this._sanitizer.bypassSecurityTrustResourceUrl(
                                            _misc.defaultPhoto
                                        )
                                    "
                                    alt="Employee Photo"
                                />
                                <button
                                    mat-raised-button
                                    color="primary"
                                    (click)="editingPhoto = !editingPhoto"
                                    *ngIf="
                                        editedEmployee['photo_locked'] == '0'
                                    "
                                >
                                    <mat-icon>edit</mat-icon>
                                    Change Photo
                                </button>
                            </div>

                            <webcam
                                [height]="500"
                                [width]="500"
                                [trigger]="triggerObservable"
                                (imageCapture)="handleImage($event)"
                                *ngIf="editingPhoto && !screenshotTaken"
                                (initError)="webcamInitError($event)"
                                class="max100"
                            ></webcam>
                            <div
                                class="snapshot"
                                *ngIf="
                                    webcamImage &&
                                    editingPhoto &&
                                    screenshotTaken
                                "
                            >
                                <img [src]="webcamImage.imageAsDataUrl" />
                            </div>
                        </div>
                        <mat-chip-list class="photo-edit">
                            <mat-chip
                                class="pointer"
                                color="primary"
                                selected
                                (click)="takeScreenshot()"
                                *ngIf="editingPhoto && !screenshotTaken"
                            >
                                Take Photo
                            </mat-chip>
                            <mat-chip
                                class="pointer"
                                color="warn"
                                selected
                                (click)="editingPhoto = !editingPhoto"
                                *ngIf="editingPhoto && !screenshotTaken"
                            >
                                Cancel
                            </mat-chip>
                            <mat-chip
                                class="pointer"
                                (click)="screenshotTaken = !screenshotTaken"
                                *ngIf="editingPhoto && screenshotTaken"
                            >
                                Take Another
                            </mat-chip>
                            <mat-chip
                                class="pointer"
                                color="primary"
                                selected
                                (click)="updatePhoto()"
                                *ngIf="editingPhoto && screenshotTaken"
                            >
                                Use This Photo
                            </mat-chip>
                        </mat-chip-list> -->

                        <div
                            class="mt15 center"
                            *ngIf="
                                !editedEmployee[
                                    'tidal_staff_pass_transaction_id'
                                ]
                            "
                        >
                            <button
                                mat-raised-button
                                color="primary"
                                (click)="createStaffPass()"
                            >
                                <mat-icon>add_circle</mat-icon>
                                Generate Staff Pass
                            </button>
                        </div>

                        <div
                            class="mt15 center"
                            *ngIf="
                                editedEmployee[
                                    'tidal_staff_pass_transaction_id'
                                ]
                            "
                        >
                            <button
                                mat-raised-button
                                color="primary"
                                (click)="viewStaffPass()"
                            >
                                <mat-icon>confirmation_number</mat-icon>
                                View Staff Pass
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mb15">
                    <mat-card-content>
                        <h2 class="center">General Information</h2>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <mat-label>Gender</mat-label>
                                    <mat-select
                                        [(ngModel)]="editedEmployee['gender']"
                                    >
                                        <mat-option value="Male">
                                            Male
                                        </mat-option>
                                        <mat-option value="Female">
                                            Female
                                        </mat-option>
                                        <mat-option value="Other">
                                            Other
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('gender')"
                                    *ngIf="
                                        editedEmployee['gender'] !==
                                        employee['gender']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Preferred Name"
                                        [(ngModel)]="
                                            editedEmployee['preferred_name']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="
                                        updateEmployeeSetting('preferred_name')
                                    "
                                    *ngIf="
                                        editedEmployee['preferred_name'] !==
                                        employee['preferred_name']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Preferred Pronouns"
                                        [(ngModel)]="
                                            editedEmployee['preferred_pronouns']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="
                                        updateEmployeeSetting(
                                            'preferred_pronouns'
                                        )
                                    "
                                    *ngIf="
                                        editedEmployee['preferred_pronouns'] !==
                                        employee['preferred_pronouns']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        type="email"
                                        placeholder="Email"
                                        [(ngModel)]="editedEmployee['email']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('email')"
                                    *ngIf="
                                        editedEmployee['email'] !==
                                        employee['email']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        phoneMask
                                        placeholder="Phone 1"
                                        [(ngModel)]="editedEmployee['phone_1']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('phone_1')"
                                    *ngIf="
                                        editedEmployee['phone_1'] !==
                                        employee['phone_1']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        phoneMask
                                        placeholder="Phone 2"
                                        [(ngModel)]="editedEmployee['phone_2']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('phone_2')"
                                    *ngIf="
                                        editedEmployee['phone_2'] !==
                                        employee['phone_2']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        phoneMask
                                        placeholder="Cell (for SMS)"
                                        [(ngModel)]="editedEmployee['cell']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('cell')"
                                    *ngIf="
                                        editedEmployee['cell'] !==
                                        employee['cell']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Address"
                                        [(ngModel)]="editedEmployee['address']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('address')"
                                    *ngIf="
                                        editedEmployee['address'] !==
                                        employee['address']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="City"
                                        [(ngModel)]="editedEmployee['city']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('city')"
                                    *ngIf="
                                        editedEmployee['city'] !==
                                        employee['city']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <mat-label>Province</mat-label>
                                    <mat-select
                                        [(ngModel)]="editedEmployee['province']"
                                    >
                                        <mat-option value="PE">PE</mat-option>
                                        <mat-option value="NB">NB</mat-option>
                                        <mat-option value="NS">NS</mat-option>
                                        <mat-option value="AB">AB</mat-option>
                                        <mat-option value="BC">BC</mat-option>
                                        <mat-option value="MB">MB</mat-option>
                                        <mat-option value="NF">NF</mat-option>
                                        <mat-option value="NT">NT</mat-option>
                                        <mat-option value="NU">NU</mat-option>
                                        <mat-option value="ON">ON</mat-option>
                                        <mat-option value="QC">QC</mat-option>
                                        <mat-option value="SK">SK</mat-option>
                                        <mat-option value="YT">YT</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('province')"
                                    *ngIf="
                                        editedEmployee['province'] !==
                                        employee['province']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        postalCodeMask
                                        placeholder="Postal Code"
                                        [(ngModel)]="
                                            editedEmployee['postal_code']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="
                                        updateEmployeeSetting('postal_code')
                                    "
                                    *ngIf="
                                        editedEmployee['postal_code'] !==
                                        employee['postal_code']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="mb15">
                    <mat-card-content>
                        <h2 class="center">Personal Details</h2>
                        <div class="inputs">
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Emergency Contact"
                                        [(ngModel)]="
                                            editedEmployee['e_contact']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('e_contact')"
                                    *ngIf="
                                        editedEmployee['e_contact'] !==
                                        employee['e_contact']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        phoneMask
                                        placeholder="Emergency Contact Phone"
                                        [(ngModel)]="editedEmployee['e_phone']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('e_phone')"
                                    *ngIf="
                                        editedEmployee['e_phone'] !==
                                        employee['e_phone']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        phoneMask
                                        placeholder="Emergency Alternate Phone"
                                        [(ngModel)]="
                                            editedEmployee['e_phone_2']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('e_phone_2')"
                                    *ngIf="
                                        editedEmployee['e_phone_2'] !==
                                        employee['e_phone_2']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="inputs mb-15">
                            <div
                                class="w100 flex phone-div m-0-10"
                                *ngIf="age < 16"
                            >
                                <mat-form-field>
                                    <input
                                        matInput
                                        type="email"
                                        placeholder="Parental Email"
                                        [(ngModel)]="
                                            editedEmployee['parental_email']
                                        "
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="
                                        updateEmployeeSetting('parental_email')
                                    "
                                    *ngIf="
                                        editedEmployee['parental_email'] !==
                                        employee['parental_email']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        sinMask
                                        placeholder="SIN"
                                        [(ngModel)]="editedEmployee['sin']"
                                    />
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('sin')"
                                    *ngIf="
                                        editedEmployee['sin'] !==
                                        employee['sin']
                                    "
                                >
                                    Save
                                </button>
                            </div>
                            <div class="w100 flex phone-div m-0-10">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Date of Birth"
                                        [matDatepicker]="dobPicker"
                                        (click)="dobPicker.open()"
                                        (focus)="dobPicker.open()"
                                        (dateChange)="checkAge()"
                                        [(ngModel)]="editedEmployee['dob']"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="dobPicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #dobPicker></mat-datepicker>
                                    <mat-hint>Age: {{ age }}</mat-hint>
                                </mat-form-field>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="h-half asc m-10-0"
                                    (click)="updateEmployeeSetting('dob')"
                                    *ngIf="
                                        dayjs(editedEmployee['dob']).format(
                                            'YYYY-MM-DD'
                                        ) !==
                                        dayjs(employee['dob']).format(
                                            'YYYY-MM-DD'
                                        )
                                    "
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <h2 class="center">Application Refer Link</h2>
                        <p class="center mb15">Click the link to copy.</p>

                        <button
                            class="application-button blue-text pointer center-btn mb15"
                            [cdkCopyToClipboard]="generateApplicationURL()"
                            (cdkCopyToClipboardCopied)="copiedApplicationURL()"
                        >
                            {{ generateApplicationURL() }}
                        </button>

                        <div class="share-buttons">
                            <button
                                class="share-button-facebook"
                                (click)="shareButton('facebook')"
                            >
                                <svg
                                    style="
                                        width: 24px;
                                        height: 24px;
                                        fill: white;
                                    "
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
                                    />
                                </svg>
                            </button>
                            <button
                                class="share-button-twitter"
                                (click)="shareButton('twitter')"
                            >
                                <svg
                                    style="
                                        width: 24px;
                                        height: 24px;
                                        fill: white;
                                    "
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
