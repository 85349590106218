<loading *ngIf="loading" [opacity]="0.5"></loading>

<h1 mat-dialog-title>Transfer</h1>
<div mat-dialog-content>
    <p class="mb15">
        Scan the original card and then scan the target card. This will transfer
        all balances from the first card to the second.
    </p>
    <mat-form-field class="w100">
        <input
            matInput
            placeholder="Card #"
            [(ngModel)]="card"
            (keyup.enter)="targetInput.focus()"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>
    <mat-form-field class="w100">
        <input
            #targetInput
            matInput
            placeholder="Target Card #"
            [(ngModel)]="targetCard"
            pattern="^[0-9]{6,8}$"
            required
        />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="transfer()" color="primary">
        Transfer
    </button>
    <button mat-raised-button (click)="dialogRef.close(undefined)" color="warn">
        Close
    </button>
</div>
