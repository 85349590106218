import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
    selector: 'app-sacoatransfer',
    templateUrl: './sacoatransfer.component.html',
    styleUrls: ['./sacoatransfer.component.scss'],
})
export class SacoatransferComponent implements OnInit {
    // Loading variables.
    loading: boolean = false;

    // Card variables.
    card: string;
    targetCard: string;

    constructor(
        public dialogRef: MatDialogRef<SacoatransferComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private _http: HttpService,
        private _snackbar: SnackbarService
    ) {}

    ngOnInit() {}

    async transfer() {
        // Check if card is set.
        if (!this.card || !/^[0-9]{6,8}$/.test(this.card)) return;

        // Check if target card is set.
        if (!this.targetCard || !/^[0-9]{6,8}$/.test(this.targetCard)) return;

        this.loading = true;

        // Add or remove the amount.
        const resp = await this._http.postLocal('pos/sacoa/transfer/', {
            card: this.card,
            targetCard: this.targetCard,
            posId: this.data['posId'],
        });

        // If not 200. Bad client request.
        if (resp.status !== 200) {
            this._snackbar.defaultSnackbar(
                'A client error has occurred. Make sure the card numbers are valid.'
            );
            this.loading = false;
            return;
        }

        // If not successful, just stop the loading, show snackbar, and return.
        if (resp.body.hasOwnProperty('success') && !resp.body['success']) {
            this._snackbar.durationSnackbar(
                `The following Sacoa error has occurred: ${resp.body['response']}.`,
                null,
                10000
            );
            this.loading = false;
            return;
        }

        // If successful, clear inputs and stop loading and show snackbar.
        this._snackbar.defaultSnackbar(
            'Successfully transfered balance to new card.'
        );
        this.card = '';
        this.targetCard = '';
        this.loading = false;
    }
}
