import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Data {
    length: number;
    hideButton?: boolean;
    title?: string;
    content?: string;
}

@Component({
    selector: 'app-idle',
    templateUrl: './idle.component.html',
    styleUrls: ['./idle.component.scss']
})
export class IdleComponent implements OnInit {
    timer: NodeJS.Timer;

    constructor(
        public dialogRef: MatDialogRef<IdleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Data
    ) {
        this.dialogRef.disableClose = true;

        if (!this.data.title) this.data.title = 'Idle';
        if (!this.data.content) this.data.content = 'Are you still there?';
    }

    ngOnInit() {
        // Basically just set a timeout.
        this.timer = setTimeout(() => {
            this.dialogRef.close(false);
        }, this.data.length);
    }

    yes() {
        clearTimeout(this.timer);
        this.dialogRef.close(true);
    }
}
